define("client/pods/components/contacts/persons/pickup-location/editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactsPersonsPickupLocationEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('transporter'), _dec3 = Ember.inject.service('settings'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ContactsPersonsPickupLocationEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "transporterService", _descriptor2, this);

      _initializerDefineProperty(this, "settingsService", _descriptor3, this);

      _initializerDefineProperty(this, "model", _descriptor4, this);

      _initializerDefineProperty(this, "modelTransporter", _descriptor5, this);

      _defineProperty(this, "adapterName", 'contacts/persons');

      _defineProperty(this, "modelTransporterAdapterName", 'contacts');

      this.crud.addLists(this, ['transporter/lists/pickup-editor-extension']);
      this.setupModelTransporterDirty(this.args.parentModel);
      this.setupDirty(this.args.model);
    }

    get isRunning() {
      return this.removeTask.isRunning || this.updateTask.isRunning || this.addTask.isRunning;
    }

    setupModelTransporterDirty(model) {
      this.modelTransporter = this.crud.setupDirty({
        adapterName: this.modelTransporterAdapterName,
        model
      });
      this.modelTransporter.setTransporterKeysToPersonDetailsModel();
    }

    setupDirty(model) {
      const isEditing = this.args?.isEditing === true;

      if (isEditing) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterName,
          model
        });
      } else {
        this.model = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          data: {
            type: 'pickupLocation',
            name: this.settingsService?.settingsModel?._data?.company
          }
        });
        const transporterExtension = this.args.parentModel._data.transporterExtension;
        const setupPersonOptions = {
          transporterExtension,
          transporterSenderTelephone: this.settingsService?.settingsModel?._data?.companyTel
        };
        this.model.setupPerson(setupPersonOptions);
      }
    }

    *updateTask(onCloseDialogAction, parentModel, model) {
      yield this.transporterService.replacePersonTask.perform(parentModel, model);
      onCloseDialogAction();
    }

    *removeTask(onCloseDialogAction, parentModel, model) {
      yield this.transporterService.removePersonTask.perform(parentModel, model);
      onCloseDialogAction();
    }

    *addTask(onCloseDialogAction, parentModel, model) {
      yield this.transporterService.addPersonTask.perform(parentModel, model);
      onCloseDialogAction();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "transporterService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "modelTransporter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupModelTransporterDirty", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setupModelTransporterDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupDirty", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "addTask"), _class.prototype)), _class));
  _exports.default = ContactsPersonsPickupLocationEditorComponent;
});