define("client/pods/components/extensions/delete-sub/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8YK6L/l0",
    "block": "{\"symbols\":[\"subTab\",\"@tab\",\"@errors\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",false],[12,\"class\",\"tab-grid__header is-sticky-row-auto\"],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTop\"]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row-container flex-row-space-between u-full-width\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"menus/tab-menu-new\",[],[[\"@tab\",\"@tabs\",\"@errors\",\"@menu\",\"@onSelect\"],[[23,2,[]],[23,2,[\"subTabs\"]],[23,3,[]],[23,0,[\"crud\",\"lists\",\"extensions-lists-submenu\"]],[28,\"fn\",[[23,0,[\"tabsManager\",\"triggerMenuAction\"]],[23,0,[]]],null]]]],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flex-container-row flex-container--align-items-center\"],[8],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__content\"],[8],[0,\"\\n\\t\\t\"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,0,[\"fetchOrdersTask\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n    \\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[23,2,[\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tabParent\",\"tab\",\"onFetch\"],[[23,2,[]],[23,1,[]],[28,\"action\",[[23,0,[]],\"fetch\"],null]]]],false],[0,\"\\n    \\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/delete-sub/tab-container/template.hbs"
    }
  });

  _exports.default = _default;
});