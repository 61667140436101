define("client/pods/products/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/products/model"], function (_exports, R, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/products',
    modelObj: _model.default,
    idParam: '_id',

    serialize(model) {
      const data = this._super(model);

      if (data.isSet) {
        const setDetailsRequiredAttrs = ['itemKey'];
        const setDetails = data.setDetails || [];
        data.setDetails = R.filter(detail => this.isNotEmptyDetail(detail, setDetailsRequiredAttrs))(setDetails);
      } else {
        data.setDetails = [];
      }

      if (data.hasVariants) {
        const variantsRequiredAttrs = ['name'];
        const variants = data.variants || [];
        data.variants = R.pipe(R.filter(detail => this.isNotEmptyDetail(detail, variantsRequiredAttrs)), R.map(variant => {
          variant.options = R.pipe(R.propOr([], 'options'), R.filter(option => this.isNotEmptyDetail(option, variantsRequiredAttrs)), R.sortWith([R.ascend(R.prop('index'))]))(variant);
          return variant;
        }))(variants);
      } else {
        data.variants = [];
      }

      if (data.isSetWithChoices) {
        const choicesRequiredAttrs = ['itemKey'];
        const choices = data.choices || [];
        data.choices = R.pipe( // R.filter(detail => this.isNotEmptyDetail(detail, ['qty'])),
        R.map(choice => {
          choice.details = R.filter(option => this.isNotEmptyDetail(option, choicesRequiredAttrs))(choice.details || []);
          return choice;
        }))(choices);
      } else {
        data.choices = [];
      }

      if (data.hasCustomizations) {
        const customizationsRequiredAttrs = ['name'];
        const customizations = data.customizations || [];
        data.customizations = R.pipe(R.filter(detail => this.isNotEmptyDetail(detail, customizationsRequiredAttrs)), R.map(customization => {
          customization.options = R.pipe(R.propOr([], 'options'), R.filter(option => this.isNotEmptyDetail(option, customizationsRequiredAttrs)), R.sortWith([R.ascend(R.prop('index'))]))(customization);
          return customization;
        }))(customizations);
      } else {
        data.customizations = [];
      }

      return data;
    }

  });

  _exports.default = _default;
});