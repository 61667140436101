define("client/pods/components/helpers/info-tooltip/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * When to use: when the label and content are together.
   */
  let HelpersInfoTooltip = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class HelpersInfoTooltip extends _component.default {
    // clickToClose = false
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _initializerDefineProperty(this, "clickToView", _descriptor, this);

      _initializerDefineProperty(this, "translate", _descriptor2, this);

      _initializerDefineProperty(this, "hasContent", _descriptor3, this);

      _initializerDefineProperty(this, "isTooltipVisible", _descriptor4, this);

      _initializerDefineProperty(this, "hasModalOverlay", _descriptor5, this);

      _initializerDefineProperty(this, "mouseLeft", _descriptor6, this);

      _initializerDefineProperty(this, "hasInfoTooltip", _descriptor7, this);

      if (this.args.translate == null) {
        this.translate = true;
      } else {
        this.translate = this.args.translate;
      }

      if (this.args.clickToView == null) {
        this.clickToView = true;
      } else {
        this.clickToView = this.args.clickToView;
      }

      if (this.args.hasContent == null) {
        this.hasContent = true;
      } else {
        this.hasContent = this.args.hasContent;
      }

      if (this.args.hasModalOverlay == null) {
        this.hasModalOverlay = true;
      } else {
        this.hasModalOverlay = this.args.hasModalOverlay;
      }

      if (!this.clickToView) {
        this.hasModalOverlay = false;
      }
    }

    handleMouseEnter(e) {
      if (!this.clickToView) {
        this.isTooltipVisible = true;
        this.mouseLeft = false;
      }
    }

    handleMouseLeave(e) {
      if (!this.clickToView) {
        this.mouseLeft = true;
        window.setTimeout(() => {
          if (this.mouseLeft) {
            this.isTooltipVisible = false;
          }
        }, 100);
      }
    }

    toggleIsVisible(e) {
      if (this.clickToView) {
        if (!this.isTooltipVisible) {
          this.isTooltipVisible = true;
        }
      }
    }

    toggleClickToView() {
      this.clickToView = !this.clickToView;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "clickToView", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "translate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R.pathOr(true, ['args', 'translate'])(this);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasContent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isTooltipVisible", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.isAlwaysVisible || false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hasModalOverlay", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mouseLeft", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasInfoTooltip", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return R.pathOr(true, ['args', 'hasInfoTooltip'])(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleMouseEnter", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseLeave", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleIsVisible", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleIsVisible"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleClickToView", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleClickToView"), _class.prototype)), _class));
  _exports.default = HelpersInfoTooltip;
});