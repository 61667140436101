define("client/pods/components/channels/shop/delivery-date-options/delivery-date-options-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ETM8qjdJ",
    "block": "{\"symbols\":[\"@model\",\"@errors\",\"@isReadonly\",\"@addItemComponent\",\"@customAdapterName\",\"@isEditing\",\"@addItemOptions\",\"@isNew\",\"@onAddItem\",\"@onAfterAddItem\",\"@onCloseDialogAction\",\"@parentModel\",\"@tab\"],\"statements\":[[5,\"channels/shop/delivery-date-options/info-editor\",[],[[\"@dispatchType\",\"@model\",\"@errors\",\"@isReadonly\",\"@subtitle\",\"@allowTodayLabel\",\"@latestShippingTimeLabel\",\"@latestShippingTimeDescription\",\"@dispatchMinDayDescription\",\"@dispatchMaxDayDescription\",\"@minDayLabel\",\"@maxDayLabel\",\"@disallowedDispatchDaysLabel\",\"@disallowedArrivalDaysLabel\",\"@addItemComponent\",\"@customAdapterName\",\"@isEditing\",\"@addItemOptions\",\"@isNew\",\"@onAddItem\",\"@onAfterAddItem\",\"@onCloseDialogAction\",\"@parentModel\",\"@tab\"],[\"zones\",[23,1,[]],[23,2,[]],[23,3,[]],\"expected arrival date subtitle\",\"calculate dispatch days starting from today\",\"latest dispatch time\",\"latest dispatch time description\",\"dispatch min day description\",\"dispatch max day description\",\"delivery min days\",\"delivery max days\",\"disallowed dispatch days\",\"disallowed arrival days\",[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/delivery-date-options/delivery-date-options-editor/template.hbs"
    }
  });

  _exports.default = _default;
});