define("client/pods/components/settings/settings-editor/emails-editor/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let SettingsSettingsEditorEmailsEditor = (_dec = Ember._action, _dec2 = Ember._action, (_class = class SettingsSettingsEditorEmailsEditor extends _component.default {
    newBlankPaymentReminderEmail() {
      const model = this.args.model;
      const newBlankPaymentReminderEmail = model.newBlankPaymentReminderEmail();
      model.getData('paymentReminderEmails').pushObject(newBlankPaymentReminderEmail);
    }

    removePaymentReminderEmail(paymentReminderEmail) {
      const msg = 'are you sure you want to remove';

      if (window.confirm(msg)) {
        const model = this.args.model;
        model.getData('paymentReminderEmails').removeObject(paymentReminderEmail);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "newBlankPaymentReminderEmail", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "newBlankPaymentReminderEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removePaymentReminderEmail", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "removePaymentReminderEmail"), _class.prototype)), _class));
  _exports.default = SettingsSettingsEditorEmailsEditor;
});