define("client/pods/components/elements/element-toggle-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YdM1Pqjk",
    "block": "{\"symbols\":[\"@toggleIsOn\",\"@disabled\",\"&attrs\",\"&default\"],\"statements\":[[7,\"span\",false],[12,\"class\",[29,[\"element-toggle-wrapper \",[28,\"if\",[[23,2,[]],\"disabled\",\"\"],null]]]],[13,3],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,0,[\"isToggler\"]],[23,0,[\"hasToggleIcon\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[5,\"elements/element-icon\",[[12,\"class\",\"u-v-align-auto element-icon--left\"]],[[\"@icon\"],[[23,0,[\"toggleOnIconClassName\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"elements/element-icon\",[[12,\"class\",\"u-v-align-auto element-icon--left\"]],[[\"@icon\"],[[23,0,[\"toggleOffIconClassName\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n  \"],[14,4],[0,\"\\n\\n\"],[4,\"if\",[[28,\"and\",[[23,0,[\"isToggler\"]],[23,0,[\"hasToggleIconRight\"]]],null]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"      \"],[5,\"elements/element-icon\",[[12,\"class\",\"u-v-align-auto element-icon--right\"]],[[\"@icon\"],[[23,0,[\"toggleOnIconRightClassName\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[5,\"elements/element-icon\",[[12,\"class\",\"u-v-align-auto element-icon--right\"]],[[\"@icon\"],[[23,0,[\"toggleOffIconRightClassName\"]]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-toggle-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});