define("client/pods/components/products/panels-display/sets-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AB3kUVf9",
    "block": "{\"symbols\":[\"@resourceList\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"products/products-editor/sets-editor\",null,[[\"tab\",\"model\",\"isReadonly\",\"isDisabled\",\"resourceList\"],[[24,[\"tab\"]],[24,[\"model\"]],[24,[\"isReadonly\"]],[24,[\"isDisabled\"]],[23,1,[]]]]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-display/sets-display/template.hbs"
    }
  });

  _exports.default = _default;
});