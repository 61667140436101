define("client/pods/protected/companies/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // inject ember intl service
    auth: Ember.inject.service(),
    storage: Ember.inject.service(),
    queryParams: {
      lang: {
        refreshModel: true
      }
    },
    model: function (params) {
      const companyName = this.storage.companyName;
      this.auth.isAlreadyAuthenticated(companyName, params.user_id, params);
      return {};
    }
  });

  _exports.default = _default;
});