define("client/pods/workflows/batch/adapter", ["exports", "ramda", "@ember-data/adapter", "client/mixins/adapters-new"], function (_exports, R, _adapter, _adaptersNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/workflows/batch',
    idParam: false,

    serialize(workflows) {
      return R.map(workflow => {
        return this._serialize(workflow._data);
      })(workflows);
    },

    updateIndexes() {
      let {
        data,
        appendPath,
        serverType
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const path = this.getFullPath({
        pathName: 'updatePath',
        appendPath,
        serverType
      });
      data = R.map(R.pick(['_key', 'index']))(data);
      data = this._addAdapterInfo(data, appendPath);
      const payload = {
        batch: data
      };

      try {
        return this.ajax.PATCH(path, payload);
      } catch (e) {
        console.error(e);
      }
    }

  });

  _exports.default = _default;
});