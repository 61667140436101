define("client/pods/components/websites/websites-editor/page-import-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dONnheYB",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@label\",\"@disabled\",\"@tab\",\"@onInsertTemplate\",\"@onReplaceTemplate\"],\"statements\":[[5,\"elements/element-btn-with-modal-dialog\",[],[[\"@label\",\"@icon\",\"@disabled\",\"@closeOnOverlayClick\"],[[23,2,[]],\"fas fa-file-import\",[23,3,[]],false]],{\"statements\":[[0,\"\\n\\n  \"],[5,\"websites/websites-editor/templates\",[],[[\"@tab\",\"@onCloseDialogAction\",\"@onInsertTemplate\",\"@onReplaceTemplate\"],[[23,4,[]],[23,1,[]],[23,5,[]],[23,6,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/websites-editor/page-import-btn/template.hbs"
    }
  });

  _exports.default = _default;
});