define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/is-set-with-choices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xJOAO/Ur",
    "block": "{\"symbols\":[\"choice\",\"choice\",\"@fetchPriceHistoryTask\",\"@formInitData\",\"@isPriceReadonly\",\"@isReadonly\",\"@item\",\"@onToggleIsSetNew\",\"@parentModel\",\"@tab\",\"@errors\",\"@isRowsOnly\"],\"statements\":[[4,\"if\",[[23,12,[]]],null,{\"statements\":[[4,\"each\",[[23,7,[\"_data\",\"choices\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[5,\"channels/shop/campaigns/campaigns-item-editor/is-set-with-choices/choice\",[],[[\"@choice\",\"@fetchPriceHistoryTask\",\"@formInitData\",\"@isPriceReadonly\",\"@isReadonly\",\"@item\",\"@onToggleIsSetNew\",\"@parentModel\",\"@tab\",\"@errors\"],[[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]]]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,7,[\"_data\",\"choices\"]]],null,{\"statements\":[[0,\"\\t\"],[5,\"forms/form-group\",[],[[\"@context\",\"@contentClassNames\",\"@formInitData\",\"@isAccordian\",\"@tab\",\"@title\"],[\"setWithChoicesPrice_group\",\"form-group__content-container--u-full-width\",[23,4,[]],true,[23,10,[]],\"product_sets_with_choices\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"table\",true],[10,\"class\",\"u-table product-children-table u-full-width\"],[8],[0,\"\\n\"],[4,\"each\",[[23,7,[\"_data\",\"choices\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"channels/shop/campaigns/campaigns-item-editor/is-set-with-choices/choice\",[],[[\"@choice\",\"@fetchPriceHistoryTask\",\"@formInitData\",\"@isPriceReadonly\",\"@isReadonly\",\"@item\",\"@onToggleIsSetNew\",\"@parentModel\",\"@tab\",\"@errors\"],[[23,1,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-item-editor/is-set-with-choices/template.hbs"
    }
  });

  _exports.default = _default;
});