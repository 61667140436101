define("client/pods/documents/waybills/adapter", ["exports", "@ember-data/adapter", "client/pods/documents/mixins/adapters", "client/pods/documents/model", "ramda"], function (_exports, _adapter, _adapters, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adapters.default, {
    resourcePath: 'api/protected/documents/sales/invoices/waybills',
    modelObj: _model.default,
    idParam: false,

    serialize(model) {
      const serializedData = this._serialize(model);

      return R.pick(['_key', 'docType', 'docName', 'transporterKey', 'transporterExtension', 'isNewWaybill', 'isUpdate'])(serializedData);
    },

    /**
     * @todo: UNUSED - SHOULD USE AN ADAPTER TO DOWNLOAD WAYBILL
     * @param documents
     * @returns {*}
     */
    downloadBatch(documents) {
      const batch = R.pipe(R.map(document => {
        let documentData = R.prop('_data')(document);

        if (!documentData) {
          documentData = document;
        }

        return {
          resource: `${documentData?.docType}-${documentData?.docName}`,
          resourceKey: documentData?._key
        };
      }))(documents);
      const path = `/batch`;
      return this.call({
        method: 'POST',
        appendPath: path,
        data: {
          batch: documents
        }
      }).then(R.propOr([], 'data'));
    }

  });

  _exports.default = _default;
});