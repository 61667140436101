define("client/pods/grapesjs/plugins/cell", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = editor => {
    const pluginName = 'cell';
    const bigordrFlexCell = 'bigordr-flex-cell';
    const bigordrFlexCellBlockId = `${bigordrFlexCell}-block`;
    const options = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const {
      intl = {
        t: txt => txt
      },

      /** @type {Categories} */
      categories
    } = options;
    const bigordrCellLabel = intl.t('bigordr-cell');
    const bigordrFlexCellLabel = intl.t(bigordrFlexCell);
    const {
      DomComponents,
      BlockManager
    } = editor;
    DomComponents.addType(pluginName, {
      model: {
        defaults: {
          tagName: 'div',
          name: bigordrCellLabel,
          classes: ['bigordr-cell'],
          draggable: ['.bigordr-row', '.bigordr-inner-row'],

          droppable(itemModel, targetModel) {
            const itemType = itemModel.get('type');
            const slider = targetModel.closestType('lory-slider');

            if (slider) {
              const disabledArr = ['bigordr-slider', 'bigordr-background-fixed', 'bigordr-blogs-wrapper', 'bigordr-products-wrapper', 'bigordr-tabs-with-header', 'bigordr-grid-images', 'tabs', 'lory-slider'];
              const disableDrop = R.includes(itemType)(disabledArr);

              if (disableDrop) {
                return false;
              }
            }

            return true;
          },

          traits: []
        }
      }
    });
    DomComponents.addType(bigordrFlexCell, {
      extend: pluginName,
      model: {
        defaults: {
          name: bigordrFlexCellLabel,
          classes: ['bigordr-cell', 'flex-container-column', 'flex-item--align-self-center'],
          droppable: false,
          components: [{
            type: 'default',
            name: intl.t('default'),
            tagName: 'div',
            style: {
              margin: 'auto',
              height: '100%',
              width: '100%'
            },
            classes: ['bigordr-cell', 'flex-container-column'],
            hoverable: false,
            selectable: false,
            draggable: false
          }],
          traits: []
        }
      },
      view: {
        onRender(_ref) {
          let {
            model
            /* , editor, el */

          } = _ref;
          // add id as class name. so, style can be added to a specific image
          const className = `id-${model.getId()}`; // Remove style from copy
          // const modelClasses = model.getClasses()
          // const removeClasses = R.filter(R.includes('id'))(modelClasses)
          // model.removeClass(removeClasses)

          model.addClass(className);
        }

      }
    });
    const attrsCell = `class="bigordr-cell" data-gjs-draggable=".bigordr-row, .bigordr-inner-row" data-gjs-resizable=\'{"tl":0,"tc":0,"tr":0,"cl":1,"cr":1,"bl":0,"br":0,"bc":0,"keyWidth":"flex-basis"}\' data-gjs-name=${intl.t('bigordr-cell')} data-gjs-type="cell" data-gjs-unstylable=\'["width"]\' data-gjs-stylable-require=\'["flex-basis"]\'`;
    BlockManager.add('bigordr-blocks-cell', {
      label: intl.t('add cell'),
      category: categories.basic,
      content: `
        <div ${attrsCell}></div>
      </div>
    `,
      render: _ref2 => {
        let {
          model
        } = _ref2;
        return `
      <div class="gjs-block__media">
        <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'>
          <g transform='matrix(7.09919e-17,-1.15939,1.15939,7.09919e-17,-146.697,1100.93)'>
                <g transform='matrix(0.987223,1.2326e-32,-6.16298e-33,0.839935,1.85971,94.4041)'>
                    <rect x='196.848' y='321.016' width='631.615' height='327.658' style='fill:white;stroke:black;stroke-width:32.94px;'/>
                </g>
                <g transform='matrix(9.35262e-17,1.5274,-1.5274,9.35262e-17,1251.14,-573.016)'>
                    <g transform='matrix(1.09375,2.6963e-32,-2.6963e-32,1.09375,128.028,12.4834)'>
                        <circle cx='608.595' cy='433.442' r='79.613' style='stroke:black;stroke-width:18.07px;stroke-linejoin:round;'/>
                    </g>
                    <g transform='matrix(3.97777e-17,-0.649619,0.649619,3.97777e-17,272.62,652.086)'>
                        <path d='M243.134,868.614L243.134,813.425L188.356,813.425L188.356,790.36L243.134,790.36L243.134,735.583L266.473,735.583L266.473,790.36L321.25,790.36L321.25,813.425L266.473,813.425L266.473,868.614L243.134,868.614Z' style='fill:white;fill-rule:nonzero;stroke:white;stroke-width:30.42px;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:2;'/>
                    </g>
                </g>
            </g>
        </svg>
        <div class="block-label">${model.get('label')}</div>
        </div>
      `;
      }
    });
    BlockManager.add(bigordrFlexCellBlockId, {
      label: bigordrFlexCellLabel,
      category: categories.basic,
      content: {
        type: bigordrFlexCell
      },
      render: _ref3 => {
        let {
          model
        } = _ref3;
        return `
    <div class="gjs-block__media">
<svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'>
    <g transform='matrix(7.09919e-17,-1.15939,1.15939,7.09919e-17,-146.697,1100.93)'>
        <g transform='matrix(1.16987,-2.56318e-22,-2.18076e-22,1.95806,-91.7749,-447.717)'>
            <rect x='196.848' y='321.016' width='631.615' height='327.658' style='fill:white;stroke:black;stroke-width:21.39px;'/>
        </g>
        <g transform='matrix(9.35262e-17,1.5274,-1.5274,9.35262e-17,1080.15,-389.837)'>
            <g transform='matrix(1.09375,2.6963e-32,-2.6963e-32,1.09375,128.028,12.4834)'>
                <circle cx='608.595' cy='433.442' r='79.613' style='stroke:black;stroke-width:18.07px;stroke-linejoin:round;'/>
            </g>
            <g transform='matrix(3.97777e-17,-0.649619,0.649619,3.97777e-17,272.62,652.086)'>
                <path d='M243.134,868.614L243.134,813.425L188.356,813.425L188.356,790.36L243.134,790.36L243.134,735.583L266.473,735.583L266.473,790.36L321.25,790.36L321.25,813.425L266.473,813.425L266.473,868.614L243.134,868.614Z' style='fill:white;fill-rule:nonzero;stroke:white;stroke-width:30.42px;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:2;'/>
            </g>
        </g>
        <g transform='matrix(2.10801e-15,34.4265,-34.4265,2.10801e-15,17263.5,-14971.5)'>
            <text x='443.443px' y='490.748px' style="font-family:'JCkg', 'GungSeo';font-size:12px;">中</text>
        </g>
    </g>
</svg>
<div class="block-label">${model.get('label')}</div>
</div>
`;
      }
    });
    editor.on('component:styleUpdate', (component, property) => {
      if (component && component.is && component.is('cell') && property === 'text-align') {
        const bigordrVideoAlignmentWrapper = component.find('div > .bigordr-video-alignment-wrapper');
        const hasVideoComponents = R.pipe(R.isEmpty, R.not)(bigordrVideoAlignmentWrapper);

        if (hasVideoComponents) {
          bigordrVideoAlignmentWrapper.forEach(videoComponent => {
            const style = component.getStyle();
            videoComponent.set(property, style[property]);
          });
        }
      }
    });
  };

  _exports.default = _default;
});