define("client/pods/validation/service", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ValidationService = (_dec = Ember.inject.service, (_class = class ValidationService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    /**
     * Get Error Message
     * @param {Object} args
     * @param {Object} args.tab
     * @param {string} args.context
     * @return {string|*}
     */
    getValidationErrorMessage() {
      let {
        tab,
        context
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const errors = tab?.errors?.errors || [];
      const error = R.find(R.anyPass([R.pathEq(['context', 'label'], context), R.pathEq(['context', 'key'], context)]))(errors);

      if (!error) {
        return;
      }

      const errorLimit = error.context?.limit;
      const errorMessage = error.message;

      if (errorLimit && errorMessage) {
        let limit = '';

        if (error.type === 'string.min') {
          limit = 'min length';
        } else if (error.type === 'string.max') {
          limit = 'max length';
        }

        if (limit) {
          return `${this.intl.t(errorMessage)}. ${this.intl.t(limit)}: ${errorLimit}`;
        }
      }

      if (errorMessage) {
        return this.intl.t(errorMessage);
      }

      return '';
    }

    setErrorsTracker(_ref) {
      let {
        tab,
        model,
        context
      } = _ref;

      if (RA.isNotNilOrEmpty(tab) && RA.isNotNilOrEmpty(model) && RA.isNotNilOrEmpty(context)) {
        const instanceId = model?.instanceId;

        if (tab && instanceId) {
          tab?.setErrorTrackerOnModel?.({
            context,
            instanceId,
            isModal: false,
            tabId: tab?.id
          });
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ValidationService;
});