define("client/pods/components/channels/shop/campaigns/panels-display/component", ["exports", "ramda", "ramda-extension", "ramda-adjunct", "client/mixins/crud", "client/config/environment", "client/utils/nventor", "ember-concurrency"], function (_exports, R, R_, RA, _crud, _environment, _nventor, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    settings: Ember.inject.service(),
    adapterName: 'channels/shop/campaigns',
    copyErrors: null,
    headerBottom: null,
    selectedItems: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['users', 'statuses', 'toggles/auto', 'toggles/onOff', 'toggles/onOff', 'paymentMethods/shop', 'channels/shop/campaigns/statuses', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'channels/shop/campaigns/lists/subPanels', 'channels/shop/campaigns/lists/settings-editor/editor-sub-panels', 'channels/shop/campaigns/deliveryDateSettings', 'channels/shop/campaigns/deliveryDateSettingsItems', 'extensions/apps/tw/invoicingAuto', 'products/lists/tags', 'shipping/feeType', 'commissions/methods', 'dispatch/lists/processes']);
      this.set('copyErrors', []);
      this.set('selectedItems', []);
      this.set('config', _environment.default);
      const master = this.model.getData('_key');
      const productsFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/filters',
        props: {
          showStarred: true,
          showFilters: true,
          // dynamicFilterKeys will never be saved, but will always get from current filters to load from saved
          dynamicFilterKeys: ['master', 'resultsProperty', 'resultsAdapterName', 'appendPath']
        },
        defaultFiltersData: {
          master,
          resultsProperty: 'results',
          resultsAdapterName: 'channels/shop/campaigns/details',
          appendPath: `/master/${master}`,
          shopProductsSort: this.settings.settingsModel?._data?.shopProductsSort || 'recommended',
          statuses: ['active', 'inactive', 'preOrder', 'soldOut', 'draft'],
          sort: [{
            by: 'index',
            direction: 'ASC'
          }]
        }
      });
      this.set('productsFilters', productsFilters);
      this.tabsManager.setHeader(this.tabParent, this.model.getData('name'));
    },

    hasSalesPersons: Ember.computed('model._data.salesPersons.[]', function () {
      // @TODO TEST
      const salesPersons = this.get('model._data.salesPersons');

      if (Ember.isEmpty(salesPersons)) {
        return false;
      }

      return salesPersons;
    }),
    filteredSubPanels: Ember.computed('model._data.showCampaignPeriodDataOnly', 'crud.lists.channels-shop-campaigns-lists-subPanels.[]', function () {
      const subPanels = R.pathOr([], ['crud', 'lists', 'channels-shop-campaigns-lists-subPanels'])(this);
      return subPanels;
    }),
    duplicateRecordTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        adapterName,
        model,
        onAfter
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        adapterName = adapterName || _this.adapterName;
        model = model || _this.dirty;
        return yield _this.crud.createRecordTask.perform({
          adapterName,
          model,
          errorsProp: 'copyErrors',
          onAfter
        }); // try {
        //   let saved = yield this.get('dataManager').createRecord(adapterName, model)
        //   return this.get('onDisplay')(saved)
        // } catch (err) {
        //   this.set('copyErrors', err.data)
        //   nventor.throw('duplicateRecordTask failed', err)
        // }
      }();
    }).drop(),
    // displaySubPanel (resourceModel) {
    //   const subTabOptions = {
    //     component: resourceModel.getData('component'),
    //     value: resourceModel.getData('value')
    //   }
    //   const tab = this.tab
    //   this.tabsManager.reuseSubTab(tab, subTabOptions)
    // },
    removeProductsBatchTask: (0, _emberConcurrency.task)(function (e, originatingComponent) {
      var _this2 = this;

      let additionalOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return function* () {
        const master = _this2.get('model._data._key');

        let selectedItems = _this2.get('selectedItems') || [];
        selectedItems = R.filter(item => {
          if (item.isDeleted) {
            return false;
          }

          if (item?._data?.isDeleted) {
            return false;
          }

          return true;
        })(selectedItems);

        if (RA.isNilOrEmpty(selectedItems) || !master) {
          return false;
        }

        const removedBatch = yield _this2.crud.removeBatchTask.perform({
          adapterName: 'channels/shop/campaigns/details',
          appendPath: `/master/${master}/batch`,
          batch: selectedItems,
          noConfirm: true
        }, e, originatingComponent, additionalOptions);
        const hasRemovedBatch = RA.isNotNilOrEmpty(removedBatch);

        if (!hasRemovedBatch) {
          return;
        } // @TODO: display unsuccessful removed details on table view


        const removedItems = R.pipe(R.pathOr([], ['resolved']), R.map(data => {
          const campaignDetailId = R.pathOr('', ['removed', 'campaignDetail', '_id'])(data);
          const item = R.find(R.pathEq(['_data', '_id'], campaignDetailId))(selectedItems);

          if (!item) {
            return;
          }

          item.set('isDeleted', true);
          return item;
        }))(removedBatch);

        _this2.set('selectedItems', []);

        return removedItems;
      }();
    }).drop(),

    editPanel() {
      const tab = this.tab;
      const model = this.model;
      const loadInstructions = this.tabsManager.getLoadInstructionsForActivePanels(tab, [{
        componentPath: '_data.editingComponent',
        menu: R_.dotPath('crud.lists.channels-shop-campaigns-lists-subPanels')(this) || []
      }, {
        componentPath: '_data.component',
        menu: R_.dotPath('crud.lists.channels-shop-campaigns-lists-settings-editor-editor-sub-panels')(this)
      }]);
      const tabOptions = {
        loadInstructions
      };
      this.onEdit(model, {
        tabOptions
      });
    },

    actions: {
      selectItem(item, isSelected) {
        const selected = this.get('selectedItems') || [];

        if (isSelected === true) {
          selected.pushObject(item);
        } else {
          selected.removeObject(item);
        }

        this.set('selectedItems', selected);
      },

      toggleSelectAllItems(tableViewOptions) {
        const campaignDetails = tableViewOptions.campaignDetails;
        const selectedItems = this.get('selectedItems') || [];

        if (selectedItems === campaignDetails) {
          this.set('selectedItems', []);
        } else {
          this.set('selectedItems', campaignDetails);
        }
      },

      prepareDuplicateRecord() {
        const model = this.model;
        const adapterName = this.adapterName;
        const adapter = this.dataManager.getAdapter(adapterName);
        const data = adapter.serialize(model);
        const newModel = this.setupNewRecord({
          adapterName,
          data
        });
        let name = model.getData('name');
        let shopUrl = model.getData('shopUrl');
        name = `${name}-copy-` + _nventor.default.random.digits(3);
        shopUrl = `${shopUrl}-copy-` + _nventor.default.random.digits(3);
        newModel.setData('status', this.get('constants.status.inactive'));
        newModel.setData('name', name);
        newModel.setData('password', '');
        newModel.setData('shopUrl', shopUrl);
        newModel.setData('_key', '');
        newModel.setData('_id', '');
        newModel.setData('_rev', '');
        newModel.setData('modified', '');
        newModel.setData('created', '');
        newModel.setData('copyFromKey', model.getData('_key'));
        this.set('dirty', newModel);
      },

      cancelDuplicateRecord(dirty) {
        const adapterName = this.adapterName;
        this.dataManager.cancelDirty(adapterName, dirty); // this.cancelEditing({
        //   adapterName,
        //   dirty
        // })
      },

      assignSalesPerson(person) {
        const dirty = this.dirty;
        dirty.addSalesPerson(person); // set commission default to

        dirty.setData('commissionMethod', this.get('constants.commissionMethod.percentagePrice'));
      },

      unassignSalesPerson(person) {
        const dirty = this.dirty;
        dirty.removeSalesPerson(person);
      },

      toggleCommissionMethod() {
        const dirty = this.dirty;
        dirty.setData('commissionFlat', '');
        dirty.setData('commissionPercentage', '');
      },

      resetSelectedItems() {
        this.set('selectedItems', []);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "editPanel", [_dec], Object.getOwnPropertyDescriptor(_obj, "editPanel"), _obj)), _obj)));

  _exports.default = _default;
});