define("client/pods/components/elements/element-textarea/component", ["exports", "client/mixins/translate", "ramda", "ramda-adjunct"], function (_exports, _translate, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_translate.default, {
    validationService: Ember.inject.service('validation'),
    // export default Ember.Component.extend(
    //   translateMixin,
    //   isDirtyMixin, {
    tagName: 'span',
    classNames: ['element-textarea'],
    classNameBindings: ['getClassNames', 'classNames'],
    getClassNames: Ember.computed('hasCurrentError', function () {
      if (this?.hasCurrentError) {
        return 'has-error';
      }

      return '';
    }),
    autofocus: false,
    translate: true,
    // set default rows to 2 lines
    rows: 1,
    maxRows: 5,
    autoResize: true,
    onInput: null,

    init() {
      // @NOTE: ember textarea becomes two lines if value=undefined
      this._super(...arguments);

      if (this.value == null) {
        this.set('value', '');
      }

      const tab = this.tab;
      const model = this.model;
      this.validationService.setErrorsTracker({
        tab,
        model,
        context: this.context
      });
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.autofocus && !this.readonly && !this.disabled) {
        setTimeout(() => {
          try {
            this.element.firstChild.focus();
          } catch (e) {
            console.error('cannot autofocus text area', e);
          }
        }, 400);
      }
    },

    currentErrors: Ember.computed('tab', 'tab.errors.errors.[]', function () {
      return this.tab?.errors?.errors || [];
    }),
    hasCurrentError: Ember.computed('currentErrors.[]', 'context', function () {
      const currentErrors = this?.currentErrors || [];
      const context = this?.context;
      return R.pipe(R.find(R.anyPass([R.pathEq(['context', 'key'], context), R.pathEq(['context', 'label'], context), R.pathEq(['context', 'keyWithIndex'], context)])), RA.isNotNilOrEmpty)(currentErrors);
    }),
    inputPlaceHolder: Ember.computed('isRequired', function () {
      if (this.isRequired) {
        return this.intl.t('is required');
      }

      return this.translatedPlaceholder || '';
    }),
    actions: {
      submit: function () {
        // send onSubmit action
        if (this.onSubmit) {
          this.onSubmit();
        }
      }
    }
  });

  _exports.default = _default;
});