define("client/pods/components/products/files/files-default-image/component", ["exports", "client/pods/components/products/files/mixins/products-files", "ramda-adjunct"], function (_exports, _productsFiles, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_productsFiles.default, (_dec = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    fetchUploads: true,
    fetchProduct: true,

    init() {
      this._super(...arguments);

      const isToggler = RA.defaultWhen(RA.isNilOrEmpty, false, this.isToggler);
      Ember.set(this, 'isToggler', isToggler);
    },

    toggle() {
      const resourceKey = this.resourceKey;

      if (!resourceKey) {
        return;
      }

      const parentComponent = this.parentComponent;
      const toggleProperty = this.toggleProperty;
      return this.applicationService.toggleComponentProperty(parentComponent, toggleProperty);
    }

  }, (_applyDecoratedDescriptor(_obj, "toggle", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggle"), _obj)), _obj)));

  _exports.default = _default;
});