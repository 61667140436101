define("client/pods/components/elements/element-btn-with-modal-dialog/custom-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U5RB3L/l",
    "block": "{\"symbols\":[\"@onSubmit\",\"@label\"],\"statements\":[[7,\"span\",false],[12,\"class\",\"u-tappable\"],[3,\"on\",[\"click\",[28,\"fn\",[[23,1,[]]],null]]],[8],[0,\"\\n  \"],[1,[23,2,[]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-btn-with-modal-dialog/custom-label/template.hbs"
    }
  });

  _exports.default = _default;
});