define("client/pods/components/news/news-editor/component", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    disabled: false,
    translate: true,
    defaultProductType: null,
    defaultProductStatus: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['news/lists/tags', 'statuses']);
    },

    actions: {
      autoSetUrl(value) {
        const model = this.model;
        const url = R.pipe(R.trim, R.toLower, R.replace(/\\/g, ''), R.replace(/\s/g, '-'))(value);
        model.setData('url', url);
      }

    }
  });

  _exports.default = _default;
});