define("client/pods/components/transporters/payuni-seven-eleven-waybill/component", ["exports", "@glimmer/component", "ramda", "ember-concurrency-decorators", "ember-concurrency"], function (_exports, _component, R, _emberConcurrencyDecorators, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TransportersPayuniSevenElevenWaybillComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('dispatch'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, (_class = class TransportersPayuniSevenElevenWaybillComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "constants", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "transporter", _descriptor3, this);

      _initializerDefineProperty(this, "dispatchService", _descriptor4, this);

      _initializerDefineProperty(this, "sevenElevenHtmlWaybills", _descriptor5, this);

      _initializerDefineProperty(this, "waybillsPerPage", _descriptor6, this);

      _initializerDefineProperty(this, "transporterArray", _descriptor7, this);

      this.crud.addLists(this, ['extensions/apps/tw/seven-eleven/lists/waybill-print-per-page']);
    }

    *fetchTransporter(models) {
      const transporterArrayTask = R.pipe(R.map(R.pathOr('', ['_data', 'transporterKey'])), R.uniq, R.map(transporterKey => this.transporter.fetchTransporterTask.perform({
        transporterKey
      })))(models);
      const transporterArray = yield (0, _emberConcurrency.all)(transporterArrayTask);
      const transporter = transporterArray[0];
      const waybillsPerPage = R.pathOr(4, ['_data', 'sevenEleven', 'waybillsPerPage'])(transporter);
      const waybillPrintPerPageModel = R.pipe(R.pathOr([], ['crud', 'lists', 'extensions-apps-tw-seven-eleven-lists-waybill-print-per-page']), R.filter(R.pathEq(['_data', 'value'], waybillsPerPage)), R.path([0]))(this);

      if (waybillPrintPerPageModel) {
        this.waybillsPerPage = waybillsPerPage;
        this.args.onSetWaybillsPerPage(waybillPrintPerPageModel);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "transporter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dispatchService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sevenElevenHtmlWaybills", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "waybillsPerPage", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 4;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "transporterArray", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchTransporter", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchTransporter"), _class.prototype)), _class));
  _exports.default = TransportersPayuniSevenElevenWaybillComponent;
});