define("client/pods/components/menus/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8yczz1By",
    "block": "{\"symbols\":[\"step\",\"index\",\"&default\",\"@itemClassNames\",\"@current\",\"@currentIndex\",\"@steps\",\"@translate\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"progress-container\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[10,\"class\",\"progress-steps\"],[8],[0,\"\\n\"],[4,\"each\",[[23,7,[]]],null,{\"statements\":[[0,\"      \"],[5,\"menus/progress-bar/step\",[],[[\"@classNames\",\"@current\",\"@currentIndex\",\"@hasCurrent\",\"@index\",\"@step\",\"@steps\",\"@translate\"],[[23,4,[]],[23,5,[]],[23,6,[]],[23,0,[\"hasCurrent\"]],[23,2,[]],[23,1,[]],[23,7,[]],[23,8,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[25,3]],null,{\"statements\":[[0,\"      \"],[14,3],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});