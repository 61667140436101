define("client/pods/components/settings/tab-container/component", ["exports", "ember-concurrency", "ramda-adjunct"], function (_exports, _emberConcurrency, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    adapterName: 'settings',
    query: '',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['taxes', 'documents/lists/print', 'settings/wizard']);
    },

    afterLoadLists() {
      this._super(...arguments);

      const tab = this.tab;
      const wizardMode = tab?.loadInstructions?.wizardMode;

      if (wizardMode) {
        this.set('isWizard', true);
      }

      this.fetchAndDisplayTask.perform();
    },

    fetchAndDisplayTask: (0, _emberConcurrency.task)(function* () {
      const model = yield this.crud.findTask.perform({
        adapterName: this.adapterName
      });

      if (this.isWizard) {
        this.wizard(model);
      } else {
        this.display(model);
      }
    }),

    edit(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.set('isWizard', false);
      const tab = this.tab;
      const subTabOptions = {
        component: 'settings/panels-edit',
        model
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions, tabOptions);
    },

    display(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.set('isWizard', false);
      const tab = this.tab;
      const subTabOptions = {
        component: 'settings/panels-display',
        model
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions, tabOptions);
    },

    async wizard() {
      let model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.set('isWizard', true);
      const tab = this.tab;

      if (RA.isNilOrEmpty(model)) {
        model = await this.crud.findTask.perform({
          adapterName: this.adapterName
        });
      }

      const subTabOptions = {
        component: 'settings/panels-edit',
        title: 'settings',
        adapterName: 'settings',
        target: 'settings',
        model
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions, tabOptions);
    },

    async wizardNext(model) {
      let {
        tabOptions,
        updateProps
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const tab = tabOptions?.tab;
      await this.crud.updateRecordTask.perform({
        adapterName: this.adapterName,
        appendPath: '/wizard',
        model,
        tab,
        updateProps
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "edit", [_dec], Object.getOwnPropertyDescriptor(_obj, "edit"), _obj), _applyDecoratedDescriptor(_obj, "display", [_dec2], Object.getOwnPropertyDescriptor(_obj, "display"), _obj), _applyDecoratedDescriptor(_obj, "wizard", [_dec3], Object.getOwnPropertyDescriptor(_obj, "wizard"), _obj), _applyDecoratedDescriptor(_obj, "wizardNext", [_dec4], Object.getOwnPropertyDescriptor(_obj, "wizardNext"), _obj)), _obj)));

  _exports.default = _default;
});