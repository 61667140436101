define("client/pods/components/extensions/apps/tw/seven-eleven/print-item-meta/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-extension"], function (_exports, _component, _emberConcurrencyDecorators, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwSevenElevenPrintItemMetaComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('dispatch'), _dec3 = Ember.inject.service('constants'), _dec4 = Ember.inject.service('transporter'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.computed('this.args.model._data.convenienceStoreData.storeId'), _dec10 = Ember.computed('args.model._data.convenienceStoreData.storeId'), _dec11 = Ember.computed('args.model._data.convenienceStoreData.storeName'), _dec12 = Ember.computed('args.model._data.convenienceStoreData.storeAddress'), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = class ExtensionsAppsTwSevenElevenPrintItemMetaComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dispatchService", _descriptor2, this);

      _initializerDefineProperty(this, "constantsService", _descriptor3, this);

      _initializerDefineProperty(this, "transporterService", _descriptor4, this);

      _initializerDefineProperty(this, "convenienceStoreWindow", _descriptor5, this);

      _initializerDefineProperty(this, "sync", _descriptor6, this);

      _initializerDefineProperty(this, "isStartUpdateConvenienceStore", _descriptor7, this);

      _initializerDefineProperty(this, "transporterModel", _descriptor8, this);

      this.crud.addLists(this, ['extensions/apps/tw/t-cat/lists/thermosphere']);
      this.setupTask.perform(this.args.model);
    }

    get transporterPackageTemperatureList() {
      const transporterPackageTemperatureList = this.crud.lists['extensions-apps-tw-t-cat-lists-thermosphere'] || [];
      return transporterPackageTemperatureList.filter(t => t._data.value !== 'refrigerated');
    }

    get oldStore() {
      return R.pipe(R.pathOr([], ['args', 'model', '_data', 'convenienceStoreData', 'history']), R.last)(this);
    }

    get allowUpdateConvenienceStore() {
      const allowUpdateConvenienceStore = R.pathEq(['args', 'allowUpdateConvenienceStore'], true)(this);

      if (allowUpdateConvenienceStore) {
        return allowUpdateConvenienceStore;
      }

      const provider = R.pathOr('', ['args', 'tab', 'value'])(this);
      return R.includes(this.constantsService.transporterExtensions.sevenEleven)(provider);
    }

    get storeId() {
      return R_.dotPath('args.model._data.convenienceStoreData.storeId')(this);
    }

    get storeName() {
      return R_.dotPath('args.model._data.convenienceStoreData.storeName')(this);
    }

    get storeAddress() {
      return R_.dotPath('args.model._data.convenienceStoreData.storeAddress')(this);
    }

    closeUpdateConvenienceStoreWindow(storeId, rev) {
      this.updateSync();

      try {
        if (this.convenienceStoreWindow && this.convenienceStoreWindow.close) {
          this.convenienceStoreWindow.close();

          if (this.args?.outerOnCloseDialogAction) {
            this.args.outerOnCloseDialogAction();
          }
        }
      } catch (error) {// DO NOTHING ON STORE WINDOWS REFERENCE ERROR
      }
    }

    *setupTask(model) {
      if (!model) {
        return;
      }

      const transporterKey = model?._data?.transporterKey;

      if (!transporterKey) {
        return;
      }

      this.transporterModel = yield this.transporterService.fetchTransporterTask.perform({
        transporterKey
      });

      if (!this.args.model._data.transporterAccount) {
        const transporterAccount = R.pipe(R.pathOr([], ['transporterAccounts', 0, '_data']))(this.transporterModel);

        if (transporterAccount) {
          this.args.model.set('_data.transporterAccount', transporterAccount);
        }
      }
    }
    /**
     * @param {Object} model - The model object.
     * @return {void} - This method does not return anything.
     */


    *updateConvenienceStoreTask(model) {
      let personId;

      if (this.args.upsertPersonTask && this.args?.isNewPerson) {
        personId = this.args?.personModel?._data?.personId;
        const transporterModel = yield this.args.upsertPersonTask.perform();
        const hasPerson = R.pipe(R.pathOr([], ['_data', 'persons']), R.find(R.pathEq(['_data', 'personId'], personId)))(transporterModel);

        if (!hasPerson) {
          personId = null;
        }
      } else if (model?._data?.transporterDefaultPickupLocationPersonId) {
        personId = model?._data?.transporterDefaultPickupLocationPersonId;
      } else if (this.args?.personModel?._data?.personId) {
        personId = this.args?.personModel?._data?.personId;
      } else {
        const transporterAccount = this.args.model?._data?.transporterAccount;
        personId = this.transporterModel.getPersonIdByTransporterAccount(transporterAccount);
      }

      const transporterKey = this.args?.transporterKey || this.args?.model?._data?.transporterKey || '';

      try {
        const url = yield this.dispatchService.updateConvenienceStoreUrl.perform({
          model,
          transporterKey,
          personId
        });
        this.convenienceStoreWindow = window.open(url, '_blank');
        this.args.outerOnCloseDialogAction();
      } catch (error) {// DO NOTHING ON STORE WINDOWS REFERENCE ERROR
      }
    }

    updateSync() {
      this.sync = Math.random();
    }

    toggleStartUpdateConvenienceStore() {
      this.isStartUpdateConvenienceStore = !this.isStartUpdateConvenienceStore;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dispatchService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "constantsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "transporterService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "convenienceStoreWindow", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "sync", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isStartUpdateConvenienceStore", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "oldStore", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "oldStore"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "storeId", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "storeId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "storeName", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "storeName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "storeAddress", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "storeAddress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeUpdateConvenienceStoreWindow", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "closeUpdateConvenienceStoreWindow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateConvenienceStoreTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateConvenienceStoreTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSync", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "updateSync"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleStartUpdateConvenienceStore", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "toggleStartUpdateConvenienceStore"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwSevenElevenPrintItemMetaComponent;
});