define("client/pods/components/elements/element-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['element-label'],
    translate: true,
    _documentationResource: Ember.computed('formInitData.documentationResource', 'documentationResource', function () {
      return this.formInitData?.documentationResource || this.documentationResource;
    }),
    _documentationIsMinimized: Ember.computed('formInitData.documentationIsMinimized', 'documentationIsMinimized', function () {
      return this.formInitData?.documentationIsMinimized || this.documentationIsMinimized;
    })
  });

  _exports.default = _default;
});