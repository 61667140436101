define("client/pods/components/users/users-selector/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    adapterName: 'users/associates',
    filtersName: 'users/associates/filters',
    isSingleSelect: true,
    //currently only supports sinlge select. as list searcher does not allow more
    autofocus: false,
    allowIsAssociates: true,
    query: '',

    init() {
      this.crud.addLists(this, ['users/lists/menu']);

      this._super(...arguments);

      const filters = this?.filters || this.search.setupFilters({
        adapterName: this.filtersName
      });
      this.set('filters', filters);
    },

    afterLoadLists() {
      this._super(...arguments);

      if (!this.readonly) {
        this.loadTabTask.perform();
      }
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.filters;
      filters.setData('query', '');
      filters.setData('allowIsAssociates', this.allowIsAssociates);
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: filters,
        component: this,
        resultsProperty: 'results'
      });
    }),

    assignSalesPerson(person) {
      if (this.onAssignSalesPerson) {
        this.onAssignSalesPerson(person);
      }

      this.set('resultsToggle', false);
      this.set('query', '');
    },

    unassignSalesPerson(person) {
      if (this.onUnassignSalesPerson) {
        this.onUnassignSalesPerson(person);
      }

      this.set('resultsToggle', false);
      this.set('query', '');
    },

    _placeholder: Ember.computed('placeholder', function () {
      return this.placeholder || 'search';
    })
  }, (_applyDecoratedDescriptor(_obj, "assignSalesPerson", [_dec], Object.getOwnPropertyDescriptor(_obj, "assignSalesPerson"), _obj), _applyDecoratedDescriptor(_obj, "unassignSalesPerson", [_dec2], Object.getOwnPropertyDescriptor(_obj, "unassignSalesPerson"), _obj)), _obj)));

  _exports.default = _default;
});