define("client/pods/components/elements/status-tag/component", ["exports", "@glimmer/component", "ramda", "ramda-extension"], function (_exports, _component, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ElementsStatusBadgeComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "translate", R.isNil(this.args.translate) ? this.args.translate : true);
    }

    get statusModel() {
      const list = this.args.list || [];
      const status = this.args.status;
      const statusPath = this.args.statusPath || '_data.value';
      const statusModel = R.find(R_.dotPathEq(statusPath, status))(list);
      return statusModel;
    }

    get statusBadgeClassNames() {
      if (this.args.statusBadgeClassNames) {
        return this.args.statusBadgeClassNames;
      }

      const statusModel = this.statusModel;
      const statusClassNamePath = this.args.statusClassNamePath || '_data.textClassNames' || '_data.classNames';
      return R_.dotPath(statusClassNamePath)(statusModel) || '';
    }

    get statusLabel() {
      if (this.args.statusLabel) {
        return this.args.statusLabel;
      }

      const statusLabelPath = this.args.statusLabelPath || '_data.label';
      const statusModel = this.statusModel;
      return R_.dotPath(statusLabelPath)(statusModel) || '';
    }

  }

  _exports.default = ElementsStatusBadgeComponent;
});