define("client/pods/components/forms/form-container/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormsFormContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = class FormsFormContainerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor2, this);

      _initializerDefineProperty(this, "inViewport", _descriptor3, this);

      _initializerDefineProperty(this, "containerModel", _descriptor4, this);

      _initializerDefineProperty(this, "tab", _descriptor5, this);
    }

    get columnsClassName() {
      if (!this.args.hasColumns) {
        return '';
      }

      const className = 'form-container--columns';
      const colCount = Number(this.args.colCount) || 1;
      return `${className} form-container--columns-count-${colCount}`;
    }
    /**
     * To check if the user has access
     * @returns {Boolean}
     */


    get isUserAllowed() {
      if (this.args.debug) {
        debugger; // eslint-disable-line
      }

      const isUserAllowed = this?.args?.isUserAllowed;

      if (isUserAllowed) {
        return true;
      } // const context = this.args?.context || ''


      const containerId = this.args?.containerId || '';
      const resourceList = this.args?.formInitData?.resourceList || [];

      if (RA.isNilOrEmpty(resourceList)) {
        return false;
      }

      if (!containerId) {
        return false;
      }

      const foundResource = R.find(R.pathEq(['_data', 'value'], containerId))(resourceList);
      const isWizard = this.args?.formInitData?.wizardContainerComponent?.isWizard;

      if (!isWizard) {
        return foundResource;
      }

      const isContainerAllowed = R.pathEq(['args', 'formInitData', 'wizardContainerComponent', 'currentStep', '_data', 'containerId'], containerId)(this);
      return foundResource && isContainerAllowed;
    }
    /**
     * Register guide menu and the viewport
     * @param {HTMLDivElement} element Default provided by glimmer component
     * @void
     */


    didInsert(element) {// if (this.args?.formInitData?.showGuideMenu) {
      //   this.tab = this.args?.tab || {}
      // let className = ''
      // if (this.args?.containerId) {
      //   className = `form-container__${this.args?.containerId}`
      // }
      // this.containerModel = this.tabsManager.registerMenu({
      //   menuId: this.args?.formInitData?.menuId,
      //   tab: this.tab,
      //   label: this.args?.containerId,
      //   type: 'container',
      //   className
      // })
      // const tabHeader = document.querySelector('.panel-temp-grid__tab-header') || document.querySelector('.panel-grid__header') || {}
      // const tabFooter = document.querySelector('.panel-grid__footer') || {}
      // const tolerenceTop = -tabHeader.getBoundingClientRect?.()?.bottom || -164
      // const tolerenceBottom = -tabFooter.getBoundingClientRect?.()?.height || 0
      // const viewportTolerance = { top: tolerenceTop, bottom: tolerenceBottom }
      // const { onEnter, onExit } = this.inViewport.watchElement(
      //   element,
      //   {
      //     viewportTolerance
      //   }
      // )
      // onEnter(this.didEnterViewport.bind(this))
      // onExit(this.didExitViewport.bind(this))
      // }
    }
    /**
     * Hooks of ember-in-viewport
     * Check if the FormContainer has entered the viewport and set isActive=true for the corresponding guide menu item
     * @void
     */


    didEnterViewport() {// this.tab.setMenuItemAsActive?.(this.containerModel)
    }
    /**
     * Hooks of ember-in-viewport
     * Check if the FormContainer has exit the viewport and set isActive=false for the corresponding guide menu item
     * @void
     */


    didExitViewport() {// this.tab.setMenuItemAsInactive?.(this.containerModel)
    }
    /**
     * Hooks of ember-in-viewport
     * Unregiter the viewport
     * @param {HTMLDivElement} element Default provided by glimmer component
     * @void
     */


    willDestroy(element) {// if (this?.isDestroyed === false) {
      //   this.inViewport.stopWatching(element)
      //   super.willDestroy(...arguments)
      // }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "inViewport", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "containerModel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tab", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype)), _class));
  _exports.default = FormsFormContainerComponent;
});