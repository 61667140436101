define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-products-limits/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0alPEWdE",
    "block": "{\"symbols\":[\"@model\",\"@isReadonly\",\"@onAddItem\",\"@onEditItem\",\"@onDisplayItem\",\"@tab\",\"@allowDuplicating\",\"@copyErrors\",\"@duplicateRecordTask\",\"@errorMsg\",\"@onAssignSalesPerson\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\"],\"statements\":[[7,\"div\",false],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"fetchCampaignDetailsLimitsTask\"]]],null]]],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"campaigns-products-content\"],[8],[0,\"\\n\\t\\t\"],[5,\"channels/shop/campaigns/campaigns-editor/products-limits-editor\",[],[[\"@campaignModel\",\"@fetchCampaignDetailsLimitsTask\",\"@filters\",\"@isBulkEditing\",\"@isReadonly\",\"@onAddItem\",\"@onEditItem\",\"@onDisplayItem\",\"@onPaginationTask\",\"@tab\"],[[23,1,[]],[23,0,[\"fetchCampaignDetailsLimitsTask\"]],[23,0,[\"filters\"]],[23,0,[\"isBulkEditing\"]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,0,[\"customPaginateSearchRecordsTask\"]],[23,6,[]]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[5,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",[],[[\"@allowDuplicating\",\"@copyErrors\",\"@duplicateRecordTask\",\"@errorMsg\",\"@hasEditBtn\",\"@isBulkEditing\",\"@isRunning\",\"@model\",\"@onAssignSalesPerson\",\"@onBulkEdit\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleBulkEdit\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\",\"@tab\"],[[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],false,[22,\"isBulkEditing\"],[23,0,[\"crud\",\"searchRecordsTask\",\"isRunning\"]],[23,1,[]],[23,11,[]],[28,\"fn\",[[23,0,[\"bulkEdit\"]]],null],[23,12,[]],[23,13,[]],[23,14,[]],[28,\"fn\",[[23,0,[\"toggleBulkEdit\"]]],null],[23,15,[]],[23,16,[]],[23,17,[]],[23,18,[]],[23,6,[]]]]],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-products-limits/template.hbs"
    }
  });

  _exports.default = _default;
});