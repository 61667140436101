define("client/pods/components/transporters/hct-waybill/component", ["exports", "client/mixins/droppable", "client/mixins/date", "ramda", "ember-concurrency-decorators"], function (_exports, _droppable, _date, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_date.default, _droppable.default, (_obj = {
    big: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    tagName: 'div',
    classNames: ['hct-waybill__container-outer'],
    transporterWaybillBase64Image: null,
    arrivalDate: Ember.computed('model._data.arrivalDate', function () {
      const model = this.model;
      let arrivalDate = '';
      const transporterArrivalDateZ = model.getData('transporterArrivalDateZ');
      const tomorrow = this.getTomorrow();
      const tomorrowZ = this.getStartOfDayZ(tomorrow);

      if (transporterArrivalDateZ && (this.isEqualWeekday(transporterArrivalDateZ, 6) || transporterArrivalDateZ > tomorrowZ)) {
        arrivalDate = this.formatDate(transporterArrivalDateZ, 'YYYY-MM-DD');
      }

      return arrivalDate;
    }),
    waybillStyle: Ember.computed('model._data.waybill', 'transporterWaybillBase64Image', function () {
      return `
  width: 100%;
  height: 100%;
  display: block;
  `;
    }),
    // for print all, hasGroups always true
    hasDocumentsWithCash: Ember.computed('model._data.{paymentMethodKey,groups.@each.synced}', function () {
      const model = this.model || {};
      let paymentMethodKeys = [];

      if (model) {
        paymentMethodKeys = model.getGroupedDocumentsPaymentMethodKeys({
          document: model,
          hasGroups: true,
          hasPaymentLastFiveDigits: false,
          isJoinResults: false,
          isTranslate: false
        });
      }

      return R.includes('cash', paymentMethodKeys);
    }),
    groupedDocsTotal: Ember.computed('model._data.{groups.@each.synced,exclTotal,inclTotal,taxTotal,cashCollectionInclTotal,paymentMethodKey}', function () {
      const model = this.model || {};

      if (model) {
        return model.getGroupedDocumentsTotal({
          document: model,
          hasGroups: true
        });
      }

      return {
        exclTotal: 0,
        taxTotal: 0,
        inclTotal: 0,
        cashCollectionInclTotal: 0
      };
    }),

    *fetchWaybillRecord(model) {
      const result = yield this.dispatch.hctFetchWaybillRecordTask.perform({
        component: this,
        documents: [model]
      });
      const transporterWaybillBase64Image = result?.data?.[0]?.transporterWaybillBase64Image;
      this.set('transporterWaybillBase64Image', transporterWaybillBase64Image);
    }

  }, (_applyDecoratedDescriptor(_obj, "fetchWaybillRecord", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "fetchWaybillRecord"), _obj)), _obj));

  _exports.default = _default;
});