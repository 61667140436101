define("client/pods/users/service", ["exports", "ramda", "ember-concurrency-decorators"], function (_exports, _ramda, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let UsersService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class UsersService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "storage", _descriptor, this);

      _initializerDefineProperty(this, "server", _descriptor2, this);

      _initializerDefineProperty(this, "accessRights", _descriptor3, this);

      _initializerDefineProperty(this, "isDocumentationsEditorGlobal", _descriptor4, this);
    }

    get isDocumentationsEditor() {
      if (!this.isDocumentationsEditorGlobal) {
        return false;
      }

      return this.getProp('isDocumentationsEditor');
    }

    getUserKey() {
      const userData = this.storage.get('tokenData') || {};
      return userData.uid || '';
    }

    getProp(prop) {
      const userData = this.storage.get('tokenData') || {};
      return userData[prop] || '';
    }

    tokenData(prop) {
      return this.storage.get('tokenData') || {};
    }

    findPersonByKey(users, person) {
      const personKey = person._data?._key || person._key;
      return _ramda.default.find(_ramda.default.pathEq(['_data', '_key'], personKey))(users);
    }

    assign(model, propPath, person) {
      const users = model.get(propPath) || [];
      const found = this.findPersonByKey(users, person);

      if (!found) {
        users.pushObject(person);
        model.set(propPath, users);
      } else {
        this.unassign(model, propPath, found);
      }
    }

    unassign(model, propPath, person) {
      const users = model.get(propPath) || [];
      const toRemove = this.findPersonByKey(users, person);
      users.removeObject(toRemove);
      model.set(propPath, users);
    }

    *hasAccessRightsTask(resource) {
      if (!resource) {
        return false;
      }

      const path = 'api/protected/app/lists/allowed';
      const fullResource = `api/protected/${resource}`;

      const alreadyFetchedResult = _ramda.default.path(['accessRights', fullResource])(this);

      if (alreadyFetchedResult != null) {
        return alreadyFetchedResult;
      }

      const res = yield this.server.call('POST', path, {
        resource: fullResource
      });
      const isAccessAllowed = res?.data?.isAllowed;
      this.accessRights[fullResource] = isAccessAllowed;
      return isAccessAllowed;
    }

    _createModel(data) {
      data._id = `members/${data._key}`;
      return this.dataManager.setAsRecord({
        adapterName: 'users',
        data: data
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "accessRights", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isDocumentationsEditorGlobal", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getUserKey", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "getUserKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getProp", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "getProp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tokenData", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "tokenData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "findPersonByKey", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "findPersonByKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "assign", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "assign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unassign", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "unassign"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasAccessRightsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "hasAccessRightsTask"), _class.prototype)), _class));
  _exports.default = UsersService;
});