define("client/pods/components/users/search-results-persons-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    tagName: 'div',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['users/lists/types', 'users/lists/roles/internal', 'statuses']);
    }

  });

  _exports.default = _default;
});