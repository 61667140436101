define("client/pods/components/settings/settings-editor/payments-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-extension"], function (_exports, _component, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SettingsSettingsEditorPaymentsEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class SettingsSettingsEditorPaymentsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      this.crud.addLists(this, ['paymentMethods/creditCards/banks', 'paymentMethods/installmentPeriods', 'paymentMethods/creditCards/banks', 'paymentMethods/shop', {
        name: 'workflows/lists/items/activities/templates',
        appendPath: 'authorization'
      }]);
    }

    get shopPaymentMethodsDefaultsList() {
      const paymentMethods = R_.dotPath('crud.lists.paymentMethods-shop')(this) || [];
      const allowedPaymentMethods = this.args.model?._data?.shopPaymentMethods || [];
      return R.filter(method => R.includes(method.getData('value'), allowedPaymentMethods), paymentMethods);
    }

    get ecPayInstallmentPeriods() {
      const periods = this.crud.lists['paymentMethods-installmentPeriods'] || [];
      const allowed = [3, 6, 12, 18, 24];
      return R.filter(period => {
        return R.includes(period?._data?.value)(allowed);
      })(periods);
    }

    setDefaultVirtualAccount(defaultVirtualAccountModel) {
      //Reset virtual accounts
      const settingsModel = this.args.model;

      if (settingsModel) {
        settingsModel.setBspDefaultVirtualAccount(defaultVirtualAccountModel);
      }
    }

    selectBank(key, bank) {
      const bankLabel = bank.getData('label');
      const model = this.args.model;
      model.setData(`${key}.bankLabel`, bankLabel);
    }

    toggleEcPayPayments() {
      const model = this.args.model;
      const enabledCreditCard = R.pathOr(false, ['_data', 'tw_payments_ecPay', 'enabledCreditCard'])(model);
      const enabledCreditCardInstallments = R.pathOr(false, ['_data', 'tw_payments_ecPay', 'enabledCreditCardInstallments'])(model);
      const enabledVirtualAccount = R.pathOr(false, ['_data', 'tw_payments_ecPay', 'enabledVirtualAccount'])(model);
      model.setData('tw_payments_ecPay.enabled', enabledCreditCard || enabledCreditCardInstallments || enabledVirtualAccount);
    }

    setShopPaymentMethodsDefault() {
      let model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const shopPaymentMethodsDefaultsList = this.shopPaymentMethodsDefaultsList || [];
      const currentDefault = model.getData('shopPaymentMethodsDefault');
      let resetDefault = false;

      if (!R.includes(currentDefault, shopPaymentMethodsDefaultsList)) {
        resetDefault = true;
      }

      if (!currentDefault) {
        resetDefault = true;
      }

      if (resetDefault) {
        const first = shopPaymentMethodsDefaultsList.get('firstObject');

        if (first) {
          model.setData('shopPaymentMethodsDefault', first.getData('value'));
        } else {
          model.setData('shopPaymentMethodsDefault', '');
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setDefaultVirtualAccount", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultVirtualAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectBank", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectBank"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleEcPayPayments", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEcPayPayments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setShopPaymentMethodsDefault", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setShopPaymentMethodsDefault"), _class.prototype)), _class));
  _exports.default = SettingsSettingsEditorPaymentsEditorComponent;
});