define("client/pods/grapesjs/plugins/image", ["exports", "ramda", "ramda-adjunct"], function (_exports, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global grapesjs */
  const pluginName = 'optimized-image';
  const Plugin = grapesjs.plugins.add(pluginName, function (editor) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const components = editor.DomComponents;
    const pluginOptions = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const {
      intl = {
        t: foo => foo
      }
    } = pluginOptions;
    const styles = `
    <style>
    .optimized-image {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
    </style>
  `;
    const defaultOptions = {
      extend: 'image',
      model: {
        defaults: {
          draggable: '.bigordr-cell, [data-gjs-type="link"]',
          resizable: {
            tl: 1,
            tc: 0,
            tr: 1,
            cl: 0,
            cr: 0,
            bl: 1,
            bc: 0,
            br: 1,
            ratioDefault: 1
          },
          activeOnRender: 1,
          classes: [pluginName],
          components: [styles],
          traits: [{
            type: 'bigordr-animation-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }, {
            type: 'bigordr-animation-iteration-trait',
            name: 'animationIterationSelector',
            label: intl.t('select iteration count')
          }, {
            type: 'bigordr-animation-duration-trait',
            name: 'animationDuration',
            label: intl.t('enter duration (sec)')
          }, {
            type: 'bigordr-link-trait'
          }]
        },

        init() {
          if (this?.parent()?.get('type') === 'bigordr-picture') {// get picture id
          }
        }

      },
      view: {
        onRender(_ref) {
          let {
            model,
            editor,
            el
          } = _ref;
          // add id as class name. so, style can be added to a specific image
          const className = `id-${model.getId()}`;
          model.addClass(className); // append default style

          model.append(styles); // append parent id

          const isCopy = model.get('isCopy');
          const parentId = model.get('data-parent-id');
          const isParentTypeBigordrPicture = model.parent().get('type') === 'bigordr-picture';

          if (isParentTypeBigordrPicture && !parentId) {
            // get picture id
            model.set('data-parent-id', model.parent().getId());
          } else if (isParentTypeBigordrPicture && parentId && isCopy) {
            // RESET PARENT ID
            model.set('data-parent-id', model.parent().getId());
          } else if (parentId && !isParentTypeBigordrPicture) {
            el.setAttribute('data-remove-now', 'true');
            el.style.display = 'none'; // move parent to here

            const parentId = model.get('data-parent-id');
            const parentPictureArray = editor.Components.getComponent().find(`#${parentId}`);

            if (RA.isNilOrEmpty(parentPictureArray)) {
              el.remove();
              editor.refresh();
              model.remove();
              editor.refresh();
              return;
            }

            const parentPicture = R.path([0])(parentPictureArray);
            const imageAttributes = model.getAttributes();
            const newPicture = model.replaceWith({
              desktopUrl: parentPicture.get('desktopUrl'),
              desktopUuid: parentPicture.get('desktopUuid'),
              tabletUrl: parentPicture.get('tabletUrl'),
              tabletUuid: parentPicture.get('tabletUuid'),
              mobileUrl: parentPicture.get('mobileUrl'),
              mobileUuid: parentPicture.get('mobileUuid'),
              imageType: pluginName,
              imageAttributes: R.pick(['data-href-title', 'data-href', 'data-href-target'])(imageAttributes),
              type: 'bigordr-picture'
            });
            editor.refresh();
            editor.selectRemove(model);
            parentPicture.remove();
            model.remove();
            editor.refresh();
            const newImg = newPicture.findType(pluginName)[0];
            editor.select(newImg);
            const previousClasses = model.getClasses();
            R.forEach(className => newImg.addClass(className))(previousClasses);
          } // ONLY ON GJS
          // Remove src and srcset attributes
          // Let Uploadcare blinkloader handle the image
          // const src = el.getAttribute('src') || ''
          // const isUploadcare = R.startsWith('https://ucarecdn.com')(src)
          // if (isUploadcare) {
          //   el.removeAttribute('src')
          //   el.setAttribute('src', src + '/-/format/webp/-/quality/smart/-/preview/')
          // }

        }

      }
    };
    options = R.mergeDeepRight(defaultOptions, options);
    components.addType(pluginName, options);
    editor.on('component:remove', component => {
      if (component && component.is(pluginName)) {
        // REMOVE PICTURE TAG
        const componentParent = component.parent();

        if (componentParent) {
          const isParentAPicture = componentParent.get('type') === 'bigordr-picture';

          if (isParentAPicture) {
            componentParent.remove();
          }
        }
      }
    });
    editor.on('component:clone', component => {
      if (component && component.is(pluginName)) {
        component.set('isCopy', true);
      }
    });
  });
  var _default = Plugin;
  _exports.default = _default;
});