define("client/pods/components/dispatch/processes-bar-container/component", ["exports", "ramda", "ember-concurrency"], function (_exports, R, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    tagName: 'span',
    classNames: ['list-btn', 'u-tappable'],
    dispatch: Ember.inject.service(),
    date: Ember.inject.service(),
    showProcessBar: true,
    selectCustomDate: false,
    isDispatchProcessModel: Ember.computed('steps.@each.code', function () {
      const dispatchProcesses = this.steps || [];
      return R.find(R.pathEq(['_data', 'code'], 'dispatched'))(dispatchProcesses);
    }),
    allowDispatchProcesses: Ember.computed('model.allowDispatchProcesses', function () {
      if (this.model) {
        return this.get('model.allowDispatchProcesses');
      }

      return true;
    }),
    submitTask: (0, _emberConcurrency.task)(function* (step, dispatchDateZ) {
      const model = this.model || this.batch;
      yield this.preSetDispatchProcessTask.perform(model, step, dispatchDateZ);

      if (this.onAfterSubmit) {
        this.onAfterSubmit(model, step, dispatchDateZ);
      }

      if (this.onRefresh) {
        this.onRefresh(step);
      }
    }).drop(),
    submitCustomDateTask: (0, _emberConcurrency.task)(function* (step, onCloseDialogAction, batchDispatchDateZ) {
      // const batchDispatchDateZ = this.get('batchDispatchDateZ')
      const model = this.model || this.batch;
      yield this.preSetDispatchProcessTask.perform(model, step, batchDispatchDateZ);

      if (this.onAfterSubmit) {
        this.onAfterSubmit(model, step, batchDispatchDateZ);
      }

      if (this.onRefresh) {
        const period = this.date.getPeriod(batchDispatchDateZ);
        this.onRefresh(step, period);
      }

      onCloseDialogAction();
    }).drop(),
    preSetDispatchProcessTask: (0, _emberConcurrency.task)(function* (model, dispatchProcess, dispatchDateZ) {
      this.set('preDispatchProcess', '');
      this.set('preDispatchProcessDispatchDateZ', '');
      const preDispatchShippingModal = this.settings.getProp('preDispatchShippingModal');

      if (!this.hasPreDispatchDialog || !preDispatchShippingModal || dispatchProcess.getData('code') !== 'dispatched') {
        this.set('preDispatchProcess', dispatchProcess);
        this.set('preDispatchProcessDispatchDateZ', dispatchDateZ);
        return yield this.get('dispatch.setDispatchProcessTask').perform(model, dispatchProcess, dispatchDateZ);
      }

      if (!R.is(Array, model) && dispatchProcess.getData('code') === 'dispatched') {
        if (this.hasPreDispatchDialog) {
          this.set('preDispatchProcess', dispatchProcess);
          this.set('preDispatchProcessDispatchDateZ', dispatchDateZ);
          const onShowPreDispatchProcessDialog = this.onShowPreDispatchProcessDialog;

          if (onShowPreDispatchProcessDialog) {
            onShowPreDispatchProcessDialog(dispatchDateZ);
          }
        }
      }
    }).drop(),
    setIsDispatchedProcessTask: (0, _emberConcurrency.task)(function* (dispatchDateZ) {
      const model = this.model || this.batch;
      let dispatchProcess = this.preDispatchProcess;

      if (!dispatchProcess) {
        dispatchProcess = this.isDispatchProcessModel;
      }

      const results = yield this.get('dispatch.setDispatchProcessTask').perform(model, dispatchProcess, dispatchDateZ);
      const onClosePreDispatchProcessDialog = this.onClosePreDispatchProcessDialog;

      if (onClosePreDispatchProcessDialog) {
        onClosePreDispatchProcessDialog();
      }

      if (this.onAfterSubmit) {
        this.onAfterSubmit(model, dispatchProcess, dispatchDateZ);
      }

      if (this.onRefresh) {
        const period = this.date.getPeriod(dispatchDateZ);
        this.onRefresh(dispatchProcess, period, true);
      }

      return results;
    }).drop(),

    onToggleModalDialog() {
      this.set('showProcessBar', false);
    }

  }, (_applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj)), _obj)));

  _exports.default = _default;
});