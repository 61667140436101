define("client/pods/components/websites/panels-display/sub-panels-store-location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yILjQkjF",
    "block": "{\"symbols\":[\"@tab\",\"@model\"],\"statements\":[[5,\"store-location/tab-container\",[],[[\"@tab\",\"@master\"],[[23,1,[]],[23,2,[\"_data\",\"_key\"]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/websites/panels-display/sub-panels-store-location/template.hbs"
    }
  });

  _exports.default = _default;
});