define("client/pods/channels/shop/campaigns/details/limits/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        statuses: [],
        product: {
          name: '',
          code: '',
          hasImages: '',
          types: [],
          setTypes: [],
          salesLimitedTypes: []
        },
        fetchMax: false,
        hasLimitTypes: [],
        sort: [{
          by: 'product.name',
          direction: 'ASC'
        }]
      };
    },

    typesCount: Ember.computed('_data.product.types.[]', function () {
      return this.getPropLength('_data.product.types');
    }),
    setTypesCount: Ember.computed('_data.product.setTypes.[]', function () {
      return this.getPropLength('_data.product.setTypes');
    }),
    hasLimitTypesCount: Ember.computed('_data.hasLimitTypes.[]', function () {
      return this.getPropLength('_data.hasLimitTypes');
    })
  });

  _exports.default = _default;
});