define("client/pods/components/layouts/sidebar/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eYqJL9UG",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[7,\"div\",false],[12,\"class\",\"panel-grid__sidebar-menu\"],[13,1],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"is-sticky-col-auto\"],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTop\"]]]],[8],[0,\"\\n    \"],[14,2,[[23,0,[]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/sidebar/menu/template.hbs"
    }
  });

  _exports.default = _default;
});