define("client/pods/components/elements/element-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4oVWfxy4",
    "block": "{\"symbols\":[\"@documentationIsLabel\",\"@tab\"],\"statements\":[[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[[24,[\"icon\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"tt\",[[24,[\"label\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"defaultLabel\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"tt\",[[24,[\"defaultLabel\"]]],[[\"translate\"],[[24,[\"translate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[23,0,[\"_documentationResource\"]]],null,{\"statements\":[[0,\"  \"],[5,\"documentations/documentation\",[],[[\"@isLabel\",\"@documentationIsMinimized\",\"@options\",\"@tab\"],[[23,1,[]],[23,0,[\"_documentationIsMinimized\"]],[28,\"hash\",null,[[\"documentationId\",\"documentationResource\",\"context\"],[[24,[\"documentationId\"]],[23,0,[\"_documentationResource\"]],[24,[\"context\"]]]]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-label/template.hbs"
    }
  });

  _exports.default = _default;
});