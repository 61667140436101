define("client/pods/components/forms/form-title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lUF5eHgk",
    "block": "{\"symbols\":[\"documentationWrapper\",\"@title\",\"@context\",\"@betaPath\",\"@featurePath\",\"@hideForFeaturePath\",\"@formInitData\"],\"statements\":[[5,\"settings/is-beta-allowed\",[],[[\"@betaPath\",\"@featurePath\",\"@hideForFeaturePath\"],[[23,4,[]],[23,5,[]],[23,6,[]]]],{\"statements\":[[0,\"\\n  \"],[5,\"documentations/documentation\",[],[[\"@tab\",\"@documentationId\",\"@documentationResource\",\"@context\",\"@documentationIsMinimized\",\"@documentationIsMinimizedOverride\",\"@options\"],[[23,0,[\"_tab\"]],[23,7,[\"documentationId\"]],[23,0,[\"documentationResource\"]],[23,3,[]],[23,7,[\"documentationIsMinimized\"]],true,[28,\"hash\",null,[[\"documentationId\",\"documentationResource\",\"context\"],[[23,7,[\"documentationId\"]],[23,0,[\"documentationResource\"]],[23,3,[]]]]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[23,2,[]],[23,0,[\"desc\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"tt\",[[23,2,[]]],[[\"translate\"],[[23,0,[\"translate\"]]]]],false],[0,\"\\n      \"],[5,\"documentations/documentation-icon\",[],[[\"@isGroup\",\"@documentationResource\",\"@context\",\"@documentation\",\"@documentationId\",\"@updateDocumentation\",\"@tab\",\"@documentationWrapper\"],[true,[23,0,[\"documentationResource\"]],[23,3,[]],[23,1,[\"documentation\"]],[23,1,[\"documentationId\"]],[23,1,[\"updateDocumentation\"]],[23,0,[\"_tab\"]],[23,1,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/forms/form-title/template.hbs"
    }
  });

  _exports.default = _default;
});