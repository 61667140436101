define("client/pods/components/transporters/payuni-seven-eleven-editor/component", ["exports", "@glimmer/component", "ramda", "ember-concurrency-decorators"], function (_exports, _component, R, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TransportersPayuniSevenElevenEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('transporter'), _dec3 = Ember.inject.service('settings'), _dec4 = Ember.inject.service('dispatch'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed('args.model.originalModel._data.convenienceStoreData.storeId'), _dec8 = Ember.computed('args.model.originalModel._data.convenienceStoreData.storeName'), _dec9 = Ember._action, (_class = class TransportersPayuniSevenElevenEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "transporterService", _descriptor2, this);

      _initializerDefineProperty(this, "settingsService", _descriptor3, this);

      _initializerDefineProperty(this, "dispatchService", _descriptor4, this);

      _initializerDefineProperty(this, "convenienceStoreWindow", _descriptor5, this);

      _initializerDefineProperty(this, "transporterModel", _descriptor6, this);

      this.crud.addLists(this, ['extensions/apps/tw/payuni-seven-eleven/accounts', 'extensions/apps/tw/payuni-seven-eleven/type']);
    }

    get storeId() {
      return R.path(['args', 'model', 'originalModel', '_data', 'convenienceStoreData', 'storeId'])(this) || R.pathOr('', ['args', 'model', '_data', 'convenienceStoreData', 'storeId'])(this);
    }

    get storeName() {
      return R.path(['args', 'model', 'originalModel', '_data', 'convenienceStoreData', 'storeName'])(this) || R.pathOr('', ['args', 'model', '_data', 'convenienceStoreData', 'storeName'])(this);
    }

    get senderNamePlaceHolder() {
      return this.transporterService.getSenderNamePlaceHolder();
    }

    get allowUpdatePackageQty() {
      const isReadonly = R.pathEq(['args', 'isReadonly'], true)(this);

      if (isReadonly) {
        return false;
      }

      const hasCollectionAmount = this.transporterService.hasCollectionAmount(this.args.model);
      return !hasCollectionAmount;
    }

    closeUpdateConvenienceStoreWindow(storeId) {
      try {
        if (this.convenienceStoreWindow && this.convenienceStoreWindow.close) {
          this.convenienceStoreWindow.close();

          if (this.args?.outerOnCloseDialogAction) {
            this.args.outerOnCloseDialogAction();
          }
        }
      } catch (error) {// DO NOTHING ON STORE WINDOWS REFERENCE ERROR
      }
    }
    /**
     * @param {Object} model - The model object.
     * @return {void} - This method does not return anything.
     */


    *updateConvenienceStoreTask(model) {
      let personId;

      if (this.args.upsertPersonTask && this.args?.isNewPerson) {
        personId = this.args?.personModel?._data?.personId;
        const transporterModel = yield this.args.upsertPersonTask.perform();
        const hasPerson = R.pipe(R.pathOr([], ['_data', 'persons']), R.find(R.pathEq(['_data', 'personId'], personId)))(transporterModel);

        if (!hasPerson) {
          personId = null;
        }
      } else if (model?._data?.transporterDefaultPickupLocationPersonId) {
        personId = model?._data?.transporterDefaultPickupLocationPersonId;
      } else if (this.args?.personModel?._data?.personId) {
        personId = this.args?.personModel?._data?.personId;
      } else {
        const transporterAccount = this.args.model?._data?.transporterAccount;
        personId = this.transporterModel.getPersonIdByTransporterAccount(transporterAccount);
      }

      const transporterKey = this.args?.transporterKey || this.args?.model?._data?.transporterKey || '';

      try {
        const url = yield this.dispatchService.updateConvenienceStoreUrl.perform({
          model,
          transporterKey,
          personId
        });
        this.convenienceStoreWindow = window.open(url, '_blank');
        this.args.outerOnCloseDialogAction();
      } catch (error) {// DO NOTHING ON STORE WINDOWS REFERENCE ERROR
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "transporterService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dispatchService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "convenienceStoreWindow", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "transporterModel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "storeId", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "storeId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "storeName", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "storeName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeUpdateConvenienceStoreWindow", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "closeUpdateConvenienceStoreWindow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateConvenienceStoreTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateConvenienceStoreTask"), _class.prototype)), _class));
  _exports.default = TransportersPayuniSevenElevenEditorComponent;
});