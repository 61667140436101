define("client/pods/components/elements/accordian-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mly0Hi9F",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[4,\"if\",[[23,0,[\"isAccordian\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"accordian-container \",[28,\"if\",[[23,0,[\"toggleIsOn\"]],\"is-expanded\"],null]]]],[13,2],[8],[0,\"\\n    \"],[14,1,[[23,0,[]]]],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/accordian-container/template.hbs"
    }
  });

  _exports.default = _default;
});