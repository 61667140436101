define("client/pods/application/route", ["exports", "client/config/environment", "ramda"], function (_exports, _environment, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // inject ember intl service
    intl: Ember.inject.service(),
    server: Ember.inject.service(),
    storage: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    applicationService: Ember.inject.service('application'),
    lastErrorMsgs: [],
    beforeModel: function () {
      // add script
      this.setupScript();

      (() => {
        const self = this;
        window.addEventListener('error', function (evt) {
          console.log('Caught[via \'error\' event]:  \'' + evt.message + '\' from ' + evt.filename + ':' + evt.lineno);
          console.log(evt); // has srcElement / target / etc

          if (_environment.default.environment === 'production' || _environment.default.environment === 'staging') {
            const error = {
              from: 'bigordr-app',
              filename: evt.filename,
              lineno: evt.lineno,
              error: evt.message,
              stack: evt?.error?.stack || ''
            };
            const previousErrorMessages = self.get('lastErrorMsgs') || [];
            const currentErrorMsg = `${evt.filename}_${evt.lineno}`;

            if (!R.includes(currentErrorMsg)(previousErrorMessages)) {
              previousErrorMessages.pushObject(currentErrorMsg);
              self.set('lastErrorMsgs', previousErrorMessages);
              self.server.logError({
                error
              });
            }

            evt.preventDefault(); // const msg = self.intl.t('encountered unknown error')
            // window.alert(msg)
          }
        });
      })(); // set locale
      // @TODO: set locale dynamically based on url/location
      // let preferredLanguage = this.get('storage').get('preferredLanguage')
      // if (!preferredLanguage) {


      const preferredLanguage = 'zh-tw'; // }
      // this.get('storage').set('preferredLanguage', preferredLanguage)

      document.getElementById('initial-spinner').classList.remove('is-visible');
      return this.intl.setLocale(preferredLanguage);
    },

    setupScript() {
      // Setup CKEDITOR
      // add script
      const ckeditor = 'https://cdnjs.cloudflare.com/ajax/libs/ckeditor/4.21.0/ckeditor.js';
      const ckeditorAlternative = 'https://cdn.jsdelivr.net/npm/ckeditor4@4.21.0/ckeditor.js';
      this.applicationService.addScript.perform(ckeditor, {
        alternativeUrls: [ckeditorAlternative]
      });
    }

  });

  _exports.default = _default;
});