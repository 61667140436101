define("client/pods/components/documentations/documentation-desc/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DocumentationsDocumentationDescComponent extends _component.default {}

  _exports.default = DocumentationsDocumentationDescComponent;
});