define("client/pods/users/personal-menu/collection/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.collection = this._transformCollection(data);

      this._super(...arguments);
    },

    _transformCollection(data) {
      return R.pipe(R.propOr([], 'collection'), R.map(menuData => {
        menuData.isPinned = true;
        return this.dataManager.setAsRecord({
          adapterName: 'users/personal-menu',
          data: menuData
        });
      }))(data);
    },

    // moved to users/personal-menu/collection computed prop
    allowedUserMenu: Ember.computed('crud.lists.application-lists-main.[]', 'synced', 'settings.settingsModel.synced', function () {
      const allowed = R.pathOr([], ['crud', 'lists', 'application-lists-main'])(this);
      const allPinned = R.pathOr([], ['_data', 'collection'])(this);
      const userFavoriteMenu = this.applicationService.processMenu({
        allowed,
        allPinned,
        onlyPinned: true
      }) || [];

      if (RA.isNilOrEmpty(userFavoriteMenu)) {
        return [];
      }

      const favoritesData = {
        isFavorites: true,
        label: 'favorites',
        pinLabel: 'favorites',
        translate: true,
        isExpanded: true,
        icon: 'fas fa-star',
        sub: userFavoriteMenu
      };
      const favoriteMenu = this.crud.setupNewRecord({
        adapterName: 'members-menu',
        data: favoritesData
      });
      return RA.list(favoriteMenu);
    })
  });

  _exports.default = _default;
});