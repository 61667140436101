define("client/pods/components/websites/tab-container/component", ["exports", "client/mixins/crud", "client/pods/components/websites/mixins/websites-list-by", "ember-concurrency", "ramda-adjunct", "ramda"], function (_exports, _crud, _websitesListBy, _emberConcurrency, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_websitesListBy.default, _crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),
    results: null,
    showResults: true,
    query: '',
    adapterName: 'websites',
    filtersAdapterName: 'websites/filters',

    init() {
      this.crud.addLists(this, ['websites/lists/menu', 'websites/lists/new-website-wizard']);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      this.loadTabTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = tab?.loadInstructions;
      const adapterName = this.adapterName;

      if (loadInstructions) {
        if (loadInstructions?.wizardMode) {
          this.set('isWizard', true);
        }

        let model = R.pathOr('', ['_data', 'model'])(loadInstructions);

        if (!model) {
          const id = R.pathOr('', ['_data', 'find', 'id'])(loadInstructions);

          if (id) {
            model = yield this.crud.findTask.perform({
              adapterName,
              appendPath: id
            });
          }
        }

        if (this.isWizard) {
          this.wizard();
        } else {
          this.display(model);
        }
      } else {
        const filters = this.filters;
        const results = yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters,
          resultsProperty: 'results'
        });
        this.set('results', results); // this.dash(results)
      }
    }),

    dash(results) {
      // because website/panels-dash menu is disabled
      // const menuActionsList = R_.dotPath('crud.lists.websites-lists-menu')(this) || []
      // const found = menuActionsList.findBy('_data.component', 'websites/panels-dash')
      // if (!found) {
      //   return this.schedule()
      // }
      // if (!results) {
      //   const adapterName = this.adapterName
      //   const filters = this.filters
      //   this.fetchDefaultDataTask.perform({ adapterName, filters, resultsProperty: 'results' })
      // }
      const tab = this.tab;
      this.set('showResults', true);
      const panelOptions = {
        component: 'websites/panels-empty'
      };
      return this.tabsManager.replaceSubTab(tab, panelOptions);
    },

    display(model) {
      let {
        tabOptions
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.set('showResults', false);
      const tab = this.tab; // tabOptions = tabOptions || this.tabsManager.getDefaultLoadInstructions(tab)

      const panelOptions = {
        component: 'websites/panels-display',
        model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, tabOptions);
      this.set('isWizard', false);
    },

    new(panelOptions) {
      // add new contact sub tab
      const tab = this.tab;
      const settingsModel = this.settings.settingsModel;
      const wizardEnable = settingsModel?._data?.wizardEnable;

      if (wizardEnable) {
        this.wizard();
      } else {
        this.tabsManager.replaceSubTab(tab, panelOptions);
      }
    },

    wizard() {
      let model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.set('resultsToggle', false);
      this.set('showResults', false);
      this.set('isWizard', true);
      const tab = this.tab;

      if (RA.isNilOrEmpty(model)) {
        model = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          tab
        });
      }

      const subTabOptions = {
        component: 'websites/panels-new',
        title: 'websites',
        adapterName: 'websites',
        target: 'websites',
        model
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions, {});
    },

    async wizardNew(model) {
      let {
        tabOptions,
        updateProps
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const tab = tabOptions?.tab;
      return this.crud.createRecordTask.perform({
        adapterName: this.adapterName,
        appendPath: '/wizard',
        model,
        tab
      });
    },

    async wizardNext(model) {
      let {
        tabOptions,
        updateProps
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const tab = tabOptions?.tab;
      await this.crud.updateRecordTask.perform({
        adapterName: this.adapterName,
        appendPath: '/wizard',
        model,
        tab,
        updateProps
      });
    },

    async wizardDone(model) {
      let {
        tabOptions,
        updateProps
      } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const tab = tabOptions?.tab;
      await this.crud.updateRecordTask.perform({
        adapterName: this.adapterName,
        appendPath: '/wizard',
        model,
        tab,
        updateProps
      });
      this.display(...arguments);
    },

    actions: {
      dash() {
        this.set('showResults', false); // should deselect results if any..
        // this.deselectResult();

        this.dash();
      },

      schedule() {
        // should deselect results if any..
        // this.deselectResult();
        this.schedule();
      },

      display(model) {
        let {
          tabOptions
        } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        this.set('resultsToggle', false);

        if (model) {
          this.display(...arguments);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      new(panelOptions) {
        this.set('resultsToggle', false);
        this.set('showResults', false);
        this.new(...arguments);
      },

      edit() {
        let model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        let {
          tabOptions
        } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        this.set('resultsToggle', false);
        this.set('isWizard', false);
        const tab = this.tab;
        tabOptions = tabOptions || this.tabsManager.getDefaultLoadInstructions(tab);
        const subTabOptions = {
          component: 'websites/panels-edit',
          model
        };
        this.tabsManager.replaceSubTab(tab, subTabOptions, tabOptions);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "wizard", [_dec], Object.getOwnPropertyDescriptor(_obj, "wizard"), _obj), _applyDecoratedDescriptor(_obj, "wizardNew", [_dec2], Object.getOwnPropertyDescriptor(_obj, "wizardNew"), _obj), _applyDecoratedDescriptor(_obj, "wizardNext", [_dec3], Object.getOwnPropertyDescriptor(_obj, "wizardNext"), _obj), _applyDecoratedDescriptor(_obj, "wizardDone", [_dec4], Object.getOwnPropertyDescriptor(_obj, "wizardDone"), _obj)), _obj)));

  _exports.default = _default;
});