define("client/pods/components/products/products-editor/variants-editor/child-editor/component", ["exports", "ember-concurrency", "client/mixins/crud", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, _crud, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    productsService: Ember.inject.service('products'),
    tagName: '',
    linkChildToExistingProduct: false,
    autoFillNameValue: '',
    autoFillNameLabel: '',
    autoFillCodeValue: '',
    autoFillCodeLabel: '',
    originalChildProduct: '',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['products/lists/variants/auto-fill']);

      if (this.childProduct) {
        const childProductCopy = this.dataManager.copyRecord('products/variants/children', this.childProduct);
        this.set('originalChildProduct', childProductCopy);
      }
    },

    isReadonlyOrDisabled: Ember.computed('isReadonly', 'isSetChild', function () {
      if (this.isReadonly) {
        return true;
      }

      if (this.isSetChild) {
        return true;
      }

      return false;
    }),
    showSearchProductToggler: Ember.computed('linkChildToExistingProduct', 'childProduct._data._key', function () {
      const hasKey = this.get('childProduct._data._key');

      if (hasKey) {
        return false;
      }

      return true;
    }),
    searchNonSetProductsTask: (0, _emberConcurrency.task)(function (_ref, query) {
      var _this = this;

      let {
        adapterName = 'products'
      } = _ref;
      return function* () {
        const model = _this.model;

        if (query?.trim?.() !== '') {
          const filters = _this.search.setupFilters({
            adapterName: 'products/filters',
            defaultFiltersData: {
              query,
              excludes: [{
                key: 'setType',
                value: 'isSetWithChoices'
              }, {
                key: 'setType',
                value: 'hasVariants'
              }, {
                key: 'setType',
                value: 'isSet'
              }, {
                key: 'isVariantChild',
                value: true
              }, {
                key: '_key',
                value: model.getData('_key')
              }, {
                key: 'hasCustomizations',
                value: true
              }]
            }
          });

          const results = yield _this.searchRecordsTask.perform({
            adapterName,
            filters
          });
          const toLowerQuery = R.toLower(query?.trim?.());
          const resultsFromUnlinked = R.pipe(R.pathOr([], ['_data', 'productsToBeUnlinked']), R.filter(product => {
            const name = R.toLower(product._data.name || '');
            const code = R.toLower(product._data.code || '');
            const description = R.toLower(product._data.description || '');
            const barcode = R.toLower(product._data.barcode || '');
            const notes = R.toLower(product._data.notes || '');
            return R.includes(toLowerQuery, name) || R.includes(toLowerQuery, code) || R.includes(toLowerQuery, description) || R.includes(toLowerQuery, barcode) || R.includes(toLowerQuery, notes);
          }))(model);
          const resultsCombined = R.concat(results, resultsFromUnlinked);

          _this.set('productsList', resultsCombined);

          return results;
        }
      }();
    }),
    filteredProductsList: Ember.computed('productsList', 'childProduct._data._key', 'model.synced', function () {
      const productsList = this?.productsList || [];
      const model = this.model;
      let childProductPath = '_createVariantChildren';

      if (this.isReadonly) {
        childProductPath = 'variantChildren';
      }

      if (RA.isNotNilOrEmpty(model) && RA.isNotNilOrEmpty(productsList)) {
        const childProductKeys = R.pipe(R.pathOr([], ['_data', childProductPath]), R.map(childProduct => {
          return childProduct?._data?._key || '';
        }), R.reject(RA.isNilOrEmpty))(model);
        return R.reject(product => {
          const productKey = product?._data?._key || '';

          if (R.includes(productKey, childProductKeys)) {
            return true;
          }

          return false;
        })(productsList);
      }

      return productsList;
    }),
    actions: {// deselectChildItem () {
      //   this.set('childProduct._data._key', '')
      //   this.set('linkChildToExistingProduct', false)
      //   this.set('childProduct._data.unlink', true)
      // },
      // loadChildItem (product) {
      //   this.set('childProduct._data._key', product.getData('_key'))
      //   this.set('childProduct._data.unlink', false)
      // },
      // startSearch () {
      //   this.set('childProduct._data.name', '')
      // }
    },

    setLinkedAndUnlinkedProduct() {
      let type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      const model = this.model;
      const childProductClone = this.dataManager.copyRecord('products/variants/children', this.childProduct);
      const originalChildProduct = this.originalChildProduct;
      let productsToBeUnlinked = model?._data?.productsToBeUnlinked || [];
      let productToBeLinked = this?.productToBeLinked || [];

      if (R.equals(type, 'link')) {
        const childProductKey = childProductClone?._data?._key || '';
        const isExistInUnlink = productsToBeUnlinked.find(product => R.equals(childProductKey, product?._data?._key || ''));

        if (isExistInUnlink) {
          productsToBeUnlinked = R.reject(R.pathEq(['_data', '_key'], childProductKey))(productsToBeUnlinked);
          model.setData('productsToBeUnlinked', productsToBeUnlinked);
        } else {
          productToBeLinked.pushObject(childProductClone);
          this.set('originalChildProduct', childProductClone);
          this.childProduct.setData('productToBeLinked', true);
        }
      }

      if (R.equals(type, 'unlink')) {
        const originalChildProductKey = originalChildProduct?._data?._key || '';
        const isExistInLink = productToBeLinked.find(product => R.equals(originalChildProductKey, product?._data?._key || ''));

        if (isExistInLink) {
          productToBeLinked = R.reject(R.pathEq(['_data', '_key'], originalChildProductKey))(productToBeLinked);
          this.set('productToBeLinked', productToBeLinked);
        } else {
          originalChildProduct.setData('unlink', true);
          productsToBeUnlinked.pushObject(originalChildProduct);
        }
      }
    },

    deselectChildItem() {
      this.set('childProduct._data._key', '');
      this.set('linkChildToExistingProduct', false);
      this.setLinkedAndUnlinkedProduct('unlink');
    },

    loadChildItem(product) {
      this.set('childProduct._data._key', product.getData('_key'));
      this.setLinkedAndUnlinkedProduct('link');
    },

    resetAutoFill() {
      this.set('autoFillNameValue', '');
      this.set('autoFillNameLabel', '');
      this.set('autoFillCodeValue', '');
      this.set('autoFillCodeLabel', '');
    }

  }, (_applyDecoratedDescriptor(_obj, "setLinkedAndUnlinkedProduct", [_dec], Object.getOwnPropertyDescriptor(_obj, "setLinkedAndUnlinkedProduct"), _obj), _applyDecoratedDescriptor(_obj, "deselectChildItem", [_dec2], Object.getOwnPropertyDescriptor(_obj, "deselectChildItem"), _obj), _applyDecoratedDescriptor(_obj, "loadChildItem", [_dec3], Object.getOwnPropertyDescriptor(_obj, "loadChildItem"), _obj), _applyDecoratedDescriptor(_obj, "resetAutoFill", [_dec4], Object.getOwnPropertyDescriptor(_obj, "resetAutoFill"), _obj)), _obj)));

  _exports.default = _default;
});