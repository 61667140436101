define("client/pods/components/channels/shop/delivery-date-options/info-editor/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopDeliveryDateOptionsInfoEditor = (_dec = Ember.inject.service('date'), _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, (_class = class ChannelsShopDeliveryDateOptionsInfoEditor extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dateService", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "trackedDeliveryMinDays", _descriptor3, this);

      _initializerDefineProperty(this, "trackedDeliveryMaxDays", _descriptor4, this);

      _initializerDefineProperty(this, "disallowedDispatchDays", _descriptor5, this);

      _initializerDefineProperty(this, "disallowedArrivalDays", _descriptor6, this);

      _initializerDefineProperty(this, "dayOptions", _descriptor7, this);

      _initializerDefineProperty(this, "isAllowedToday", _descriptor8, this);

      _initializerDefineProperty(this, "trackedShippingDays", _descriptor9, this);

      _initializerDefineProperty(this, "selectedDates", _descriptor10, this);

      _defineProperty(this, "adapterName", 'channels/shop/delivery-date-options');

      const tab = this.args?.tab;
      const isEditing = this.args?.isEditing;
      let model = this.args.model;

      if (isEditing) {
        model = this.crud.setupDirty({
          adapterName: this.adapterName,
          model,
          tab
        });
      } else {
        model = this.crud.setupNewRecord({
          adapterName: this.adapterName,
          tab
        });
      }

      this.model = model;
      this.trackedDeliveryMinDays = this.getPrepareDays();
      this.trackedDeliveryMaxDays = this.getDeliveryMaxDays();
      this.trackedShippingDays = this.getShippingDays();
      this.isAllowedToday = this.getIsAllowedToday();
      this.selectedDates = model?._data?.excludedSpecificDates || [];
      model.setData('dispatchType', this.args.dispatchType);
      this.crud.addLists(this, ['days-of-week', 'channels/shop/campaigns/calendar-type-settings']);
    }

    getPrepareDays() {
      const model = this.model;
      return R.pathOr([''], ['_data', 'prepareDays'])(model);
    }

    getDeliveryMaxDays() {
      const model = this.model;
      return R.pathOr([''], ['_data', 'deliveryMaxDays'])(model);
    }

    getIsAllowedToday() {
      const model = this.model;
      return R.pathOr([''], ['_data', 'isAllowedToday'])(model);
    }

    getShippingDays() {
      const model = this.model;
      return R.pathOr([''], ['_data', 'shippingDays'])(model);
    }

    get isRunning() {
      return this.crud.removeRecordTask.isRunning || this.crud.updateRecordTask.isRunning || this.crud.createRecordTask.isRunning;
    }
    /**
     * calculate the min selectday
     * @return {minDate} the min date
     * if isAllowedToday, include today, if not exclude today
     */


    get calculatedMinDate() {
      const today = this.dateService.getToday();
      let minDate = '';
      const trackedDeliveryMinDays = parseInt(this.trackedDeliveryMinDays) || 0;

      if (this.isAllowedToday) {
        minDate = today.add(trackedDeliveryMinDays, 'days');
      } else {
        minDate = today.add(trackedDeliveryMinDays + 1, 'days');
      }

      return minDate.add(this.trackedShippingDays, 'days');
    }
    /**
     * calculate the max selectday
     * @return {maxDate} the max date
     */


    get calculatedMaxDate() {
      const minDate = this.calculatedMinDate;
      const trackedDeliveryMaxDays = parseInt(this.trackedDeliveryMaxDays) || 0;
      const maxDate = minDate.add(trackedDeliveryMaxDays, 'days');
      return this.dateService.formatDate(maxDate, 'YYYY-MM-DD');
    }
    /**
     * calculate the min selectday for dispatch method
     * @return {minDate} the min date
     * if isAllowedToday, include today, if not exclude today
     */


    get minDateForDispatchMethod() {
      const today = this.dateService.getToday();
      const trackedDeliveryMinDays = parseInt(this.trackedDeliveryMinDays) || 0;
      let minDate = '';
      let trackedDeliveryMinDaysToAdd = trackedDeliveryMinDays;

      if (!this.isAllowedToday) {
        trackedDeliveryMinDaysToAdd = trackedDeliveryMinDaysToAdd + 1;
      }

      minDate = today.add(trackedDeliveryMinDaysToAdd, 'days');
      return minDate;
    }
    /**
     * calculate the max selectday for dispatch method
     * @return {maxDate} the max date
     */


    get maxDateForDispatchMethod() {
      const minDate = this.minDateForDispatchMethod;
      const trackedDeliveryMaxDays = parseInt(this.trackedDeliveryMaxDays) || 0;
      const maxDate = minDate.add(trackedDeliveryMaxDays, 'days');
      return this.dateService.formatDate(maxDate, 'YYYY-MM-DD');
    }

    updateMinDays(event) {
      const inputValue = parseInt(event?.target?.value, 10) || 0;
      this.trackedDeliveryMinDays = inputValue;
    }

    updateMaxDays(event) {
      this.trackedDeliveryMaxDays = parseInt(event?.target?.value, 10) || 0;
    }

    updateShippingDays(event) {
      this.trackedShippingDays = parseInt(event?.target?.value, 10) || 0;
    }

    updateIsAllowedToday(newValue) {
      this.isAllowedToday = newValue;
    }

    cancel() {
      this.args?.onCloseDialogAction?.();
    }

    afterCreateRecordTask(model) {
      this.args?.onAddItem?.(model);
      this.args?.onCloseDialogAction?.();
    }

    afterUpdateRecordTask(model) {
      this.args?.onUpdateItem?.(model);
      this.args?.onCloseDialogAction?.();
    }

    afterRemoveRecordTask(model) {
      this.args?.onRemoveItem?.(model);
      this.args?.onCloseDialogAction?.();
    }

    handleDatesSelection(_ref) {
      let {
        dates
      } = _ref;
      this.selectedDates = dates.map(date => date.toISOString().substring(0, 10));
    }

    removeExcludedSpecificDates(date) {
      const excludedSpecificDates = R.pathOr([], ['model', '_data', 'excludedSpecificDates'])(this);
      excludedSpecificDates.removeObject(date);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "trackedDeliveryMinDays", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "trackedDeliveryMaxDays", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "disallowedDispatchDays", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "disallowedArrivalDays", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "dayOptions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [0, 1, 2, 3, 4, 5, 6];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isAllowedToday", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "trackedShippingDays", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedDates", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateMinDays", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updateMinDays"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateMaxDays", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "updateMaxDays"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateShippingDays", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "updateShippingDays"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateIsAllowedToday", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "updateIsAllowedToday"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterCreateRecordTask", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreateRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterUpdateRecordTask", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "afterUpdateRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterRemoveRecordTask", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "afterRemoveRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDatesSelection", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "handleDatesSelection"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeExcludedSpecificDates", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "removeExcludedSpecificDates"), _class.prototype)), _class));
  _exports.default = ChannelsShopDeliveryDateOptionsInfoEditor;
});