define("client/pods/components/forms/form-entity/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FormsFormEntityComponent = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = class FormsFormEntityComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "applicationService", _descriptor, this);

      _initializerDefineProperty(this, "inViewport", _descriptor2, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);

      _initializerDefineProperty(this, "settings", _descriptor4, this);

      _initializerDefineProperty(this, "toggle", _descriptor5, this);

      _initializerDefineProperty(this, "isViewable", _descriptor6, this);

      _initializerDefineProperty(this, "isInView", _descriptor7, this);

      _initializerDefineProperty(this, "containerModel", _descriptor8, this);

      _initializerDefineProperty(this, "_element", _descriptor9, this);

      _initializerDefineProperty(this, "_className", _descriptor10, this);

      _defineProperty(this, "translate", true);

      _defineProperty(this, "isModal", false);

      _defineProperty(this, "toggleOnIconClassName", 'fas fa-caret-down');

      _defineProperty(this, "toggleOffIconClassName", 'fas fa-caret-up');
    }

    get wizardAllowed() {
      if (!this.isWizard) {
        return true;
      }

      const entities = R.path(['wizardContainerComponent', 'entities'])(this.args);
      const isNotArray = !Array.isArray(entities);

      if (!entities || isNotArray) {
        return true;
      }

      const context = this.args.context || '';

      if (entities.includes(context)) {
        return true;
      }

      return false;
    }

    get isAccordianCollapsed() {
      if (this.isAccordian && this.toggle === false) {
        return true;
      }

      return false;
    }

    get hasErrorClassNames() {
      const error = this.error;

      if (!error) {
        return '';
      }

      if (RA.isNilOrEmpty(error)) {
        return '';
      }

      return 'has-error';
    }

    get isBetaAllowed() {
      if (!this.args?.betaPath) {
        return true;
      }

      return this.settings.getProp(this.args.betaPath);
    }

    get isMenu() {
      // @TODO: remove computed prop?
      return this.args.formInitData?.showGuideMenu || this.args.isMenu || false;
    }

    get isWizard() {
      // @TODO: remove computed prop?
      return this.args.formInitData?.wizardContainerComponent?.isWizard || this.args.isWizard || false;
    }

    get menuId() {
      // @TODO: remove computed prop?
      return this.args.formInitData?.menuId || this.args?.menuId;
    }

    get documentationResource() {
      // @TODO: remove computed prop?
      return this.args.formInitData?.documentationResource || this.args?.documentationResource;
    }

    get parentLabel() {
      // @TODO: remove computed prop?
      return this.args?.formContainer?.containerId || this.args?.parent;
    }
    /**
     * @returns {String} ClassName of outer container
     */


    get outerContainerClassName() {
      if (this.isWizard) {
        return 'form-section__outer-container--wizard';
      }

      if (this.isMenu && !this.isInView) {
        return 'form-section__outer-container--no-border';
      }

      if (this.args?.betaPath && !this.isBetaAllowed) {
        return 'form-section__outer-container--no-border';
      }

      if (this.args?.isNarrowOuterContainer) {
        return 'form-section__outer-container--no-padding';
      }

      return 'form-section__outer-container';
    }
    /**
     * Register guide menu and the viewport
     * @void
     */


    didInsert(element) {// if (!this.isMenu) {
      //   return
      // }
      // const menuId = this.menuId
      // const context = this.args.context || ''
      // this._className = context
      //   ? `form-entity__${context}`
      //   : ''
      // this.containerModel = this.tabsManager.registerMenu({
      //   menuId,
      //   tab: this.args?.tab,
      //   label: this.args?.title,
      //   parentLabel: this.parentLabel,
      //   type: 'sub',
      //   className: this._className
      // })
      // if (RA.isNotNilOrEmpty(this.containerModel)) {
      //   set(this, 'containerModel', this.containerModel)
      //   element.classList.add(this._className)
      // }
      // this._element = element
      // const tabHeader = document.querySelector('.panel-temp-grid__tab-header') || document.querySelector('.panel-grid__header') || {}
      // const tabFooter = document.querySelector('.panel-grid__footer') || {}
      // const tolerenceTop = -tabHeader.getBoundingClientRect?.()?.bottom || -164
      // const tolerenceBottom = -tabFooter.getBoundingClientRect?.()?.height || 0
      // const viewportTolerance = { top: tolerenceTop, bottom: tolerenceBottom }
      // const { onEnter, onExit } = this.inViewport.watchElement(
      //   this._element,
      //   {
      //     viewportTolerance
      //   }
      // )
      // onEnter(this.didEnterViewport.bind(this))
      // onExit(this.didExitViewport.bind(this))
    }
    /**
     * Hooks of ember-in-viewport
     * Check if the FormSection has entered the viewport and set isActive=true for the corresponding guide menu item
     * Also to control the scroll position of the guide menu
     * @void
     */


    didEnterViewport() {// const subs = this.containerModel?.sub
      // this.setIsInView()
      // if (RA.isNilOrEmpty(subs)) {
      //   return
      // }
      // for (const s in subs) {
      //   const sub = subs[s]
      //   if (sub?.className === this._className) {
      //     const tab = this.args?.tab
      //     tab?.setMenuItemAsActive?.(sub)
      //     break
      //   }
      // }
    }
    /**
     * Hooks of ember-in-viewport
     * Check if the FormSection has exit the viewport and set isActive=false for the corresponding guide menu item
     * @void
     */


    didExitViewport() {// const subs = this.containerModel?.sub
      // if (RA.isNilOrEmpty(subs)) {
      //   return
      // }
      // for (const s in subs) {
      //   const sub = subs[s]
      //   if (sub?.className === this._className) {
      //     const tab = this.args?.tab
      //     tab?.setMenuItemAsInactive?.(sub)
      //     this._scrollToActiveMenuNavItem()
      //     break
      //   }
      // }
    }
    /**
     * Hooks of ember-in-viewport
     * Unregiter the viewport
     * @void
     */


    willDestroy() {// if (this?.isDestroyed === false) {
      //   if (!this.isMenu) {
      //     return
      //   }
      //   this.inViewport?.stopWatching?.(this._element)
      //   super.willDestroy(...arguments)
      // }
    }
    /**
     * To set isInView, used to speed up loading
     * @void
     */


    setIsInView() {
      this.isInView = true;
    }
    /**
     * To invoke passed in function
     * @param {Function} toggleAction
     */


    preToggleAction(toggleAction) {
      if (this.isAccordian) {
        toggleAction();
      }
    }
    /**
     * Depends on isMobile to scroll different part and direction of guide menu
     * @void
     */
    // _scrollToActiveMenuNavItem () {
    //   const activeMenuItem = document.querySelector('.guide-menu-item__content.active') || {}
    //   if (RA.isNilOrEmpty(activeMenuItem)) {
    //     return
    //   }
    //   if (this.applicationService?.isMobile) {
    //     const activeMenuItemWrapper = activeMenuItem.closest?.('.guide-menu-item__wrapper') || {}
    //     if (RA.isNilOrEmpty(activeMenuItemWrapper)) {
    //       return
    //     }
    //     nventor.goToElement({
    //       parent: activeMenuItemWrapper,
    //       goTo: activeMenuItem,
    //       offset: 100,
    //       horizontal: true
    //     })
    //   } else {
    //     const guideMenuWrapper = activeMenuItem.closest?.('.guide-menu-tab__wrapper') || {}
    //     if (RA.isNotNilOrEmpty(guideMenuWrapper)) {
    //       return
    //     }
    //     nventor.goToElement({
    //       parent: guideMenuWrapper,
    //       goTo: activeMenuItem,
    //       offset: 100
    //     })
    //   }
    // }


  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "inViewport", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isViewable", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isInView", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "containerModel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "_element", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_className", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setIsInView", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setIsInView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "preToggleAction", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "preToggleAction"), _class.prototype)), _class));
  _exports.default = FormsFormEntityComponent;
});