define("client/pods/components/products/products-editor/sets-with-choices-editor/choices-editor/details-editor/component", ["exports", "client/mixins/errors", "client/mixins/crud", "ember-concurrency"], function (_exports, _errors, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object'
  var _default = Ember.Component.extend(_crud.default, _errors.default, {
    productsService: Ember.inject.service('products'),
    tagName: '',
    showVariantDetails: false,

    init() {
      this._super(...arguments);

      const model = this.model;

      if (model) {
        const productsFilters = this.search.setupFilters({
          adapterName: 'products/filters',
          defaultFiltersData: {
            resultsProperty: 'productsList',
            excludes: [{
              key: '_key',
              value: model.getData('_key')
            }, {
              key: 'setType',
              value: 'isSetWithChoices'
            }, {
              key: 'hasCustomizations',
              value: true
            }]
          }
        });
        this.set('productsFilters', productsFilters);
      }
    },

    searchProductsTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        adapterName = 'products',
        query
      } = _ref;
      return function* () {
        if (query?.trim?.() !== '') {
          const filters = _this.productsFilters; // filters.setData('query', query)

          const results = yield _this.searchInputTask.perform({
            adapterName,
            filters
          }, query);
          return results;
        }
      }();
    })
  });

  _exports.default = _default;
});