define("client/pods/components/elements/element-select/select-option/component", ["exports", "ramda", "ramda-adjunct", "@glimmer/component"], function (_exports, R, RA, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ElementsElementSelectSelectOptionComponent extends _component.default {
    get hasTranslate() {
      const option = this.args.option;
      const translate = RA.isNotNilOrEmpty(R.path(['translate'])(option)) ? R.path(['translate'])(option) : RA.isNotNilOrEmpty(R.path(['_data', 'translate'])(option)) ? R.path(['_data', 'translate'])(option) : R.pathOr(true, ['translate'])(this.args);
      return translate;
    }

  }

  _exports.default = ElementsElementSelectSelectOptionComponent;
});