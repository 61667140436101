define("client/pods/components/transporters/iframe-waybills/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "En1F2ctm",
    "block": "{\"symbols\":[\"@waybillsHtml\"],\"statements\":[[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[28,\"or\",[[23,0,[\"dispatchService\",\"generateAndDownloadWaybillsTask\",\"isRunning\"]],[23,0,[\"dispatchService\",\"hctFetchWaybillRecordTask\",\"isRunning\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"u-section u-no-print\"],[8],[9],[0,\"\\n      \"],[7,\"iframe\",false],[12,\"id\",\"waybillIframe\"],[12,\"srcdoc\",[29,[[23,1,[]]]]],[12,\"class\",\"u-full-width\"],[3,\"did-insert\",[[23,0,[\"setOnloadEvent\"]]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/iframe-waybills/template.hbs"
    }
  });

  _exports.default = _default;
});