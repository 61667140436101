define("client/pods/components/channels/shop/shipping-zones/panels-dash/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ggUNhnOj",
    "block": "{\"symbols\":[\"@filters\",\"@onDisplay\",\"@onEdit\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onSearchTask\",\"@tab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content table-view-container\"],[8],[0,\"\\n    \"],[5,\"layouts/table-view\",[],[[\"@detailsRowComponent\",\"@hasHeadersSort\",\"@filters\",\"@onDisplay\",\"@onEdit\",\"@onMainAction\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@onSearchTask\",\"@results\",\"@tab\",\"@tableClassNames\"],[\"channels/shop/shipping-zones/table-view/detail-row\",true,[23,1,[]],[23,2,[]],[23,3,[]],[23,2,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,1,[\"results\"]],[23,7,[]],\"shipping-zones-dash\"]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n  \"],[7,\"table\",true],[10,\"class\",\"u-table-align-middle u-full-width\"],[8],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"td\",true],[8],[0,\"\\n          \"],[5,\"helpers/pagination-controls\",[],[[\"@classNames\",\"@filters\",\"@list\",\"@onPaginationTask\",\"@showPageNumbers\"],[\"pagination-controls-container--small\",[23,1,[]],[23,1,[\"results\"]],[23,5,[]],true]]],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/shipping-zones/panels-dash/template.hbs"
    }
  });

  _exports.default = _default;
});