define("client/pods/components/elements/element-btn-more/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8HjSYwcA",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"&default\"],\"statements\":[[5,\"lists/list-btn\",[],[[\"@disabled\",\"@hasToggleIconRight\",\"@icon\"],[[23,0,[\"isDisabled\"]],false,\"fas fa-ellipsis-v\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"u-results\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"u-results__list\"],[8],[0,\"\\n      \"],[14,2],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-btn-more/template.hbs"
    }
  });

  _exports.default = _default;
});