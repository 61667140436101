define("client/pods/transporter/lists/credentials-modal/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model", "client/constants"], function (_exports, _adapter, _nventor, _adaptersNew, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: _constants.default.transporterExtensions.hct,
        label: _constants.default.transporterExtensions.hct,
        component: 'extensions/apps/tw/hct/credentials'
      }, {
        value: _constants.default.transporterExtensions.tCat,
        label: _constants.default.transporterExtensions.tCat,
        component: 'extensions/apps/tw/t-cat/credentials'
      }, {
        value: _constants.default.transporterExtensions.sevenEleven,
        label: _constants.default.transporterExtensions.sevenEleven,
        component: 'extensions/apps/tw/seven-eleven/credentials'
      }, {
        value: _constants.default.transporterExtensions.payuniSevenEleven,
        label: _constants.default.transporterExtensions.payuniSevenEleven,
        component: 'extensions/apps/tw/payuni-seven-eleven/credentials'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});