define("client/pods/components/transporters/credentials-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ur22Xt68",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@isReadonly\",\"@onDisplay\",\"@model\"],\"statements\":[[5,\"elements/element-btn-with-modal-dialog\",[],[[\"@icon\",\"@disabled\",\"@modalDialogTitle\",\"@label\"],[\"fas fa-key\",[23,2,[]],[28,\"concat\",[[23,4,[\"_data\",\"transporterExtension\"]],\" \",\"credentials\"],null],\"credentials\"]],{\"statements\":[[0,\"\\n  \"],[7,\"span\",false],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"setupDirty\"]],[23,4,[]]],null]]],[8],[9],[0,\"\\n  \"],[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n\\n    \"],[1,[28,\"component\",[[23,0,[\"transporterComponent\"]]],[[\"model\",\"isReadonly\"],[[23,0,[\"dirty\"]],[23,2,[]]]]],false],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/footer-btns\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"layouts/btns-group\",[],[[\"@classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"dirty\",\"_data\",\"_key\"]]],null,{\"statements\":[[0,\"          \"],[5,\"elements/btn-cancel\",[],[[\"@isRunning\",\"@onSubmit\"],[[23,0,[\"onSubmitTask\",\"isRunning\"]],[23,1,[]]]]],[0,\"\\n\\n          \"],[5,\"elements/btn-save\",[],[[\"@disabled\",\"@label\",\"@isRunning\",\"@onSubmit\"],[[23,2,[]],\"\",[23,0,[\"onSubmitTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"onSubmitTask\"]],[23,1,[]],[23,3,[]]],null]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[5,\"elements/btn-save\",[],[[\"@disabled\",\"@icon\",\"@label\",\"@onSubmit\"],[[23,2,[]],false,\"done\",[28,\"perform\",[[23,0,[\"onSubmitTask\"]],[23,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/credentials-modal/template.hbs"
    }
  });

  _exports.default = _default;
});