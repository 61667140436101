define("client/helpers/currency", ["exports", "client/mixins/big"], function (_exports, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_big.default, {
    intl: Ember.inject.service(),

    compute(params, hash) {
      let number = params[0];
      const defaultValue = params[1];
      const rounding = hash.rounding;
      const autoRound = hash.autoRound;
      const negative = hash.negative;
      const dontWrapHtmlSafe = hash?.dontWrapHtmlSafe;
      const str = number + '';

      if (number == null || str.trim() === '') {
        if (defaultValue != null) {
          return defaultValue;
        }

        return '$ 0';
      }

      if (negative) {
        number = this.newBig(number).times(-1).toFixed();
      }

      let options = {};

      if (rounding != null) {
        if (autoRound) {
          number = this.newBig(number);
          number = this.autoRound(rounding, number);
        } else {
          number = this.newBig(number).toFixed(rounding);
        }

        options = {
          maximumFractionDigits: rounding
        };
      }

      const prefix = '$ ';

      if (dontWrapHtmlSafe) {
        return prefix + this.intl.formatNumber(number, options);
      }

      return Ember.String.htmlSafe('<span class="u-no-wrap">' + prefix + this.intl.formatNumber(number, options) + '</span>');
    }

  });

  _exports.default = _default;
});