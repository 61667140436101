define("client/pods/components/websites/websites-editor/website-wizard/quick-shop/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WebsitesWebsitesEditorWebsiteWizardWebsiteShopComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class WebsitesWebsitesEditorWebsiteWizardWebsiteShopComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _defineProperty(this, "adapterName", 'websites');

      _defineProperty(this, "filtersAdapterName", 'channels/shop/campaigns/filters');

      _defineProperty(this, "campaignsAdapterName", 'channels/shop/campaigns');

      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      Ember.set(this, 'filters', filters);
    }

    get shopEditBtnIcon() {
      const model = this.args.model || {};
      const shopKey = model?._data?.shopKey;

      if (shopKey) {
        return 'fas fa-pen';
      }

      return 'fas fa-plus';
    }

    fetchShopTask() {
      var _this = this;

      let model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const shopKey = model?._data?.shopKey;

        if (shopKey) {
          const shop = yield _this.crud.findTask.perform({
            adapterName: _this.campaignsAdapterName,
            appendPath: `/${shopKey}`
          });

          _this.setShopModel(shop);
        }

        _this.unloadShopModel();
      }();
    }

    setShopModel(shop) {
      const tab = this.args.tab;
      Ember.set(tab, 'model', shop);
      Ember.set(this, 'shop', shop);
    }

    unloadShopModel() {
      const tab = this.args.tab;
      Ember.set(tab, 'model', '');
      Ember.set(this, 'shop', '');
    }

    loadShopSource() {
      let dirtyModel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let shop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      const shopKey = shop?._data?._key;

      if (shopKey) {
        dirtyModel.setData('shopKey', shopKey);
      }
    }

    unloadShopSource() {
      let dirtyModel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      dirtyModel.setData('shopKey', '');
    }

    fetchAndSetShopModel(onCloseDialogAction) {
      let model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      onCloseDialogAction?.();
      this.fetchShopTask.perform(model);
    }

    editShopSource() {
      let model = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model,
        tab: this.args.tab
      });
      Ember.set(this, 'dirtyModel', dirty);
    }

    cancelEditShopSource() {
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty: this.dirtyModel,
        tab: this.args.tab
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fetchShopTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchShopTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadShopSource", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadShopSource", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "unloadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchAndSetShopModel", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAndSetShopModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editShopSource", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "editShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelEditShopSource", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEditShopSource"), _class.prototype)), _class));
  _exports.default = WebsitesWebsitesEditorWebsiteWizardWebsiteShopComponent;
});