define("client/pods/components/contacts/tab-container/component", ["exports", "client/mixins/crud", "client/mixins/contacts-list-by", "ember-concurrency", "client/utils/nventor"], function (_exports, _crud, _contactsListBy, _emberConcurrency, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_contactsListBy.default, _crud.default, (_dec = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    adapterName: 'contacts',
    filtersAdapterName: 'contacts/filters',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['statuses', 'contacts/lists/genders', 'contacts/lists/taxMethods', 'contacts/lists/relationships', 'contacts/lists/detailTypes', 'contacts/lists/importColumns', 'contacts/lists/menu', 'contacts/lists/tags', 'contacts/lists/isTransporter', 'contacts/rewards/lists/historyPeriods', 'contacts/transporters', 'paymentMethods', 'paymentTerms', 'channels/shop/rewards/periods', 'channels/shop/rewards/types', 'channels/shop/rewards', {
        name: 'users',
        appendPath: 'internal'
      }]);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      filters.set('showStarred', true);
      this.set('filters', filters);
    },

    afterLoadLists() {
      this._super(...arguments);

      this.loadTabTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');

          if (id) {
            const found = yield this.findTask.perform({
              appendPath: id
            });
            this.display(found);
          }

          const query = loadInstructions.getData('search.query');

          if (query) {
            const filters = this.filters;
            filters.setData('query', query);
            const results = yield this.searchRecordsTask.perform({
              filters
            });
            const found = results.get('firstObject');
            this.display(found);
          }
        }
      } else {
        const adapterName = this.adapterName;
        const filters = this.filters;
        const results = yield this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
        this.dash({}, results);
      }
    }),
    fetchDefaultDataTask: (0, _emberConcurrency.task)(function* () {
      const filters = this.filters;
      const adapterName = this.adapterName;
      const results = yield this.listByTask.perform({
        adapterName,
        filters,
        byLatest: true
      });
      return results;
    }),

    generic(tab, subTabOptions) {
      // subTabOptions = {
      //   componentName,
      //   model (option)
      // }
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    dash(model, results) {
      const tab = this.tab;
      this.filters.set('showResults', false);

      if (_nventor.default.isNilOrEmpty(results)) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        filters.setData('query', '');
        this.fetchDefaultDataTask.perform({
          adapterName,
          filters
        });
      }

      const subTabOptions = {
        component: 'contacts/panels-dash',
        model
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    display(model) {
      const subTabOptions = {
        component: 'contacts/panels-display',
        model: model
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    toggleResults(results) {
      const subTab = this.tabsManager.getActiveSubTab(this.tab);

      if (subTab?.component === 'contacts/panels-dash') {
        return results;
      }

      Ember.set(this, 'showResults', true);
      return results;
    },

    new(subTabOptions) {
      this.filters.set('showResults', false); // add new contact sub tab

      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    report(subTabOptions) {
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    groups(subTabOptions) {
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    actions: {
      dash() {
        // should deselect results if any..
        // this.deselectResult();
        this.dash();
      },

      /**
       * display the contact using the contact object
       * @param  {object} contact
       */
      display(model) {
        if (model) {
          this.display(model);
        } else {
          // if no model then display dash (when model has been deleted)
          this.dash();
        }
      },

      // selectResult(model) {
      //
      //   this.display(model);
      // }

      /**
       * open up new contact panel
       * @param  {object} panelItem
       */
      new(subTabOptions) {
        this.new(subTabOptions);
      },

      edit(model) {
        let {
          tabOptions
        } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        this.filters.set('showResults', false);
        const subTabOptions = {
          component: 'contacts/panels-edit',
          model
        };
        this.tabsManager.replaceSubTab(this.tab, subTabOptions, tabOptions);
      },

      import(subTabOptions) {
        const tab = this.tab;
        this.tabsManager.replaceSubTab(tab, subTabOptions);
      },

      report(subTabOptions) {
        this.report(subTabOptions);
      },

      groups(subTabOptions) {
        this.groups(subTabOptions);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "toggleResults", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleResults"), _obj)), _obj)));

  _exports.default = _default;
});