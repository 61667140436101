define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-3rd-party/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WEPGVutZ",
    "block": "{\"symbols\":[\"@headerBottom\",\"@model\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\",\"@allowDuplicating\",\"@copyErrors\",\"@dirty\",\"@duplicateRecordTask\",\"@errorMsg\",\"@onAssignSalesPerson\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor/3rd-party-editor\",[],[[\"@headerBottom\",\"@isEditing\",\"@isReadonly\",\"@model\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\"],[[23,1,[]],false,true,[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]]]]],[0,\"\\n\\n\"],[5,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",[],[[\"@allowDuplicating\",\"@copyErrors\",\"@dirty\",\"@duplicateRecordTask\",\"@errorMsg\",\"@hasEditBtn\",\"@model\",\"@onAssignSalesPerson\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\",\"@tab\"],[[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],true,[23,2,[]],[23,12,[]],[23,13,[]],[23,14,[]],[23,15,[]],[23,16,[]],[23,17,[]],[23,3,[]],[23,4,[]],[23,5,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-3rd-party/template.hbs"
    }
  });

  _exports.default = _default;
});