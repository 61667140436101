define("client/pods/components/menus/tab-menu-side/component", ["exports", "@glimmer/component", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _component, _ramda, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenusTabMenuNewComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class MenusTabMenuNewComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "tabsManager", _descriptor, this);

      _initializerDefineProperty(this, "selected", _descriptor2, this);

      _initializerDefineProperty(this, "alreadySelected", _descriptor3, this);

      _defineProperty(this, "menuLabelKey", this.args?.menuLabelKey || '_data.label');

      _defineProperty(this, "classNames", this.args?.classNames || 'u-tab-menu');
    }

    setup() {
      this.setSelected();
    }

    setupAfterTabs() {
      // @NOTE: this has a racing condition problem.
      // because tab headers and tab content are different components,
      // currently there is no way for the header to know that the contents
      // has rendered. so if a sub panel is loaded manually elsewhere, the headers
      // cannot know which menu item to set as active.
      // possible solution: should not load content manually. should define which tab menu item to load.
      if (this.alreadySelected) {
        return false;
      } // this runs on first load ONLY. is user already manually selects another tab dont reselect
      // get from active sub tabs list


      const tabs = this.args.tabs || [];
      const menu = this.args?.menu || [];
      const displayedTab = this.tabsManager.getActiveTab(tabs);
      const displayedComponent = displayedTab?.component || '';
      let activeSubTabMenu;

      if (displayedComponent) {
        activeSubTabMenu = _ramda.default.find(_ramda.default.pathEq(['_data', 'component'], displayedComponent))(menu);
      }

      if (activeSubTabMenu) {
        this.setSelected(activeSubTabMenu);
      }
    }

    setSelected(item) {
      const menu = this.args?.menu || [];

      if (menu?.length <= 0) {
        return {};
      }

      let activeSubTabMenu = menu[0];

      if (RA.isNotNilOrEmpty(item)) {
        this.alreadySelected = true;
        activeSubTabMenu = item;
      }

      if (RA.isNilOrEmpty(activeSubTabMenu)) {
        this.selected = null;
        return {};
      }

      this.selected = activeSubTabMenu;
      return activeSubTabMenu;
    }

    get translate() {
      return _ramda.default.pathOr(true, ['args', 'translate'])(this);
    }

    get groupedMenu() {
      const menu = this.args?.menu || [];
      const isGrouped = this.args?.isGrouped || false;

      if (!isGrouped) {
        return {
          '': menu
        };
      }

      const groupByPath = this.args?.groupByPath || '_data.group';

      const grouped = _ramda.default.groupBy(R_.dotPath(groupByPath))(menu);

      return grouped;
    }
    /**
     * Select tab item
     * @param {Object} item
     * @void
     */


    select(item) {
      this.setSelected(item);

      if (this.args?.onSelect) {
        this.args.onSelect(item);
      }
    }
    /**
     * Set css prop of tab header-title
     * @param {Object} params
     * @param {HTMLElement} params.tabMenuContainer
     * @param {HTMLElement|Null} params.tabGridContent
     * @param {HTMLElement|Null} params.headerTitle
     * @param {HTMLElement|Null} params.outerPanelContent if not null, that means it is a panel grid inside another one
     * @void
     */


    _setHeaderTitleCss() {
      let {
        tabMenuContainer,
        tabGridContent,
        headerTitle,
        outerPanelContent
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (tabGridContent) {
        const tabContentOnly = this.args?.tabContentOnly || false;

        if (tabContentOnly) {
          tabMenuContainer.style.top = 'auto';
        } else {
          const offsetTop = tabGridContent.offsetTop || 128;
          tabMenuContainer.style.top = `${offsetTop}px`;
        }
      }

      if (outerPanelContent) {
        if (headerTitle) {
          headerTitle.style.zIndex = 'unset';
        }

        tabMenuContainer.style.zIndex = 'unset';
      } else {// if (headerTitle && !headerTitle.classList.contains('hidden')) {
        //   const tabMenuTop = tabMenuContainer?.offsetTop
        //   const tabMenuHeight = tabMenuContainer?.offsetHeight
        //   if (!tabMenuTop || !tabMenuHeight) {
        //     return
        //   }
        //   const tabMenuBottom = tabMenuTop + tabMenuHeight
        //   headerTitle.style.top = `${tabMenuBottom}px`
        //   headerTitle.style.position = 'sticky'
        // }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selected", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "alreadySelected", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupAfterTabs", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setupAfterTabs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelected", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = MenusTabMenuNewComponent;
});