define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ve1alhF2",
    "block": "{\"symbols\":[\"subTab\",\"@tab\",\"@model\",\"@allowDuplicating\",\"@copyErrors\",\"@dirty\",\"@duplicateRecordTask\",\"@errorMsg\",\"@onAssignSalesPerson\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[8],[0,\"\\n\\t\"],[7,\"div\",false],[12,\"class\",\"panel-grid__header is-sticky-row-auto\"],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTop\"]]]],[8],[0,\"\\n\\t\\t\"],[5,\"menus/tab-menu-new\",[],[[\"@tab\",\"@tabs\",\"@menu\",\"@onSelect\"],[[23,2,[]],[23,2,[\"subTabs\"]],[23,0,[\"activityDocTypes\"]],[28,\"fn\",[[23,0,[\"tabsManager\",\"displaySubPanelNew\"]],[23,2,[]]],null]]]],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"campaigns-products-content\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[23,2,[\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"model\",\"tab\"],[[23,3,[]],[23,1,[]]]]],false],[0,\"\\n\\t\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"channels/shop/campaigns/panels-display/sub-panels-footer-btns\",[],[[\"@allowDuplicating\",\"@copyErrors\",\"@dirty\",\"@duplicateRecordTask\",\"@errorMsg\",\"@hasEditBtn\",\"@model\",\"@onAssignSalesPerson\",\"@onCancelDuplicateRecord\",\"@onEditPanel\",\"@onPrepareDuplicateRecord\",\"@onToggleCommissionMethod\",\"@onUnassignSalesPerson\",\"@parentTab\",\"@resourceList\",\"@tab\"],[[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],false,[23,3,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]],[23,14,[]],[23,15,[]],[23,16,[]],[23,2,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-activity/template.hbs"
    }
  });

  _exports.default = _default;
});