define("client/pods/components/extensions/apps/tw/hct/credentials/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y3CRzUBL",
    "block": "{\"symbols\":[\"@isReadonly\",\"@model\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@label\",\"@required\",\"@isRequired\",\"@errors\",\"@context\",\"@documentationResource\"],[\"user\",true,true,[23,0,[\"errors\"]],\"hctUser\",\"user\"]],{\"statements\":[[0,\"\\n  \"],[5,\"elements/element-input\",[],[[\"@isRequired\",\"@readonly\",\"@value\"],[true,[23,1,[]],[23,2,[\"_data\",\"hctUser\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@required\",\"@isRequired\",\"@errors\",\"@context\",\"@documentationResource\"],[\"password\",true,true,[23,0,[\"errors\"]],\"hctPassword\",\"password\"]],{\"statements\":[[0,\"\\n  \"],[5,\"elements/element-input\",[],[[\"@type\",\"@isRequired\",\"@readonly\",\"@value\"],[\"password\",true,[23,1,[]],[23,2,[\"_data\",\"hctPassword\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@required\",\"@isRequired\",\"@errors\",\"@context\",\"@documentationResource\"],[\"transporter accounts\",true,true,[23,0,[\"errors\"]],\"transporterAccounts\",\"transporterAccounts\"]],{\"statements\":[[0,\"\\n  \"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@translate\",\"@disabled\",\"@itemValueKey\",\"@itemLabelKey\",\"@list\",\"@value\",\"@addItemComponent\"],[true,false,[23,1,[]],\"_data.account\",\"_data.name\",[23,2,[\"_data\",\"transporterAccounts\"]],[23,2,[\"_data\",\"defaultTransporterAccount\"]],\"contacts/transporters-accounts-editor\"]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/hct/credentials/template.hbs"
    }
  });

  _exports.default = _default;
});