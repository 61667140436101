define("client/pods/components/ledgers/journals/cashbook/tab-container/component", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _date, _crud, _emberConcurrency, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, _date.default, (_dec = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    search: Ember.inject.service(),
    model: null,
    errors: null,
    adapterName: 'ledgers/journals/cashbook',

    init() {
      this.crud.addLists(this, ['ledgers/accounts/lists/banks', 'ledgers/journals/cashbook/lists/menu']);
      this.set('contactsList', []);

      this._super(...arguments);
    },

    afterLoadLists() {
      this._super(...arguments);

      this.loadTabTask.perform();
    },

    loadTabTask: (0, _emberConcurrency.task)(function* () {
      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const id = loadInstructions.getData('find.id');

        if (id) {
          this.set('selectedBankAccountKey', id);
          this.resourceDataRedirect();
        }
      } else {
        // when tab component is created, it will automatically fetch txData for default bank account.
        // this can only happen once list of bank accounts has been fetched.
        const defaultBankAccountKey = this.getDefaultBankAccountKey();
        this.set('selectedBankAccountKey', defaultBankAccountKey);
        this.resourceDataRedirect();
      }
    }),
    accountsListsBanksWithAll: Ember.computed('crud.lists.ledgers-accounts-lists-banks', function () {
      const bankLists = R_.dotPathOr([], 'crud.lists.ledgers-accounts-lists-banks')(this);
      const allBanks = this.setupNewRecord({
        data: {
          _key: 'allBanks',
          name: 'all banks',
          ledger: 'bank',
          isNotEditable: true,
          translate: true
        }
      });
      return R.prepend(allBanks, bankLists);
    }),

    resourceDataRedirect() {
      const resourceData = R_.dotPathOr({}, 'tab.resourceData')(this);

      if (RA.isNotNilOrEmpty(resourceData)) {
        this.tabsManager.displaySubPanel(this, resourceData);
      }
    },

    getDefaultBankAccountKey() {
      const bankAccounts = this?.accountsListsBanksWithAll || [];
      let defaultAccount = bankAccounts.findBy('_data.isDefault', true);

      if (!defaultAccount) {
        defaultAccount = bankAccounts.get('firstObject');
      }

      this.set('account', defaultAccount);

      if (defaultAccount) {
        return defaultAccount.getData('_key');
      }

      return '';
    },

    setBankAccount(account) {
      this.set('account', account);
      const tab = this.tab;
      const activeSubPanel = this.tabsManager.getActiveSubTab(tab);
      this.tabsManager.displaySubPanel(this, activeSubPanel);
    }

  }, (_applyDecoratedDescriptor(_obj, "setBankAccount", [_dec], Object.getOwnPropertyDescriptor(_obj, "setBankAccount"), _obj)), _obj)));

  _exports.default = _default;
});