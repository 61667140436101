define("client/pods/components/settings/settings-editor/basic-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "ramda-extension", "ember-concurrency"], function (_exports, _component, R, RA, R_, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SettingsSettingsEditorBasicEditor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('application'), _dec3 = Ember.inject.service('products'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = class SettingsSettingsEditorBasicEditor extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "applicationService", _descriptor2, this);

      _initializerDefineProperty(this, "productService", _descriptor3, this);

      _initializerDefineProperty(this, "inventoryResetDateZ", _descriptor4, this);

      _initializerDefineProperty(this, "resetInventoryDate", _descriptor5, this);

      this.crud.addLists(this, ['contacts/lists/tag-report-options', 'discounts/types', 'documents/lists/print', 'documents/lists/print/product-code', 'documents/lists/print/unit', 'documents/purchases/terms', 'documents/sales/terms', 'extensions/apps/tw/invoicing', 'products/lists/tracking', 'products/lists/types', 'settings/editor-sub-panels', 'settings/rounding', 'taxes', 'taxMethods', 'taxMethods/filters', 'units/dimensions', 'units/weight']);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "productService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "inventoryResetDateZ", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "resetInventoryDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  })), _class));
  _exports.default = SettingsSettingsEditorBasicEditor;
});