define("client/pods/components/extensions/apps/tw/invoices/print-all-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U98Flw3y",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"printDialog\"],\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"label\",\"disabled\",\"hasSectionWrapper\"],[\"print all invoices\",[24,[\"disabled\"]],true]],{\"statements\":[[4,\"extensions/apps/tw/invoices/print-dialog\",null,null,{\"statements\":[[0,\"\\t\\t\"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"onSubmit\"],[\"print all invoices\",[28,\"action\",[[23,0,[]],\"printAllInvoices\",[23,1,[]],[23,2,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[28,\"msgs/error-msg\",null,[[\"msg\",\"hasError\"],[[24,[\"printError\"]],[24,[\"printError\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/invoices/print-all-btn/template.hbs"
    }
  });

  _exports.default = _default;
});