define("client/pods/components/layouts/starred/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wHa00pmA",
    "block": "{\"symbols\":[\"savedFilter\",\"@isReadonly\"],\"statements\":[[4,\"if\",[[23,0,[\"personalSavedFilters\",\"_data\",\"collection\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"tags-container filter-group-container filter-group-container--saved\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tags-header u-v-align-auto\"],[8],[0,\"\\n      \"],[5,\"elements/element-icon\",[],[[\"@icon\",\"@classNames\"],[\"fas fa-star\",\"u-v-align-auto\"]]],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"each\",[[23,0,[\"personalSavedFilters\",\"_data\",\"collection\"]]],null,{\"statements\":[[0,\"      \"],[5,\"elements/tag-btn\",[],[[\"@truncateClassName\",\"@model\",\"@label\",\"@isRunning\",\"@onSubmit\",\"@onRemove\"],[\"u-truncate--100\",[23,1,[]],[23,1,[\"_data\",\"label\"]],[28,\"or\",[[23,0,[\"search\",\"removeSavedFiltersTask\",\"isRunning\"]],[23,0,[\"search\",\"loadFromSavedFilters\",\"isRunning\"]]],null],[28,\"perform\",[[23,0,[\"searchTask\"]],[23,1,[]]],null],[28,\"perform\",[[23,0,[\"search\",\"removeSavedFiltersTask\"]],[23,1,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasSearched\"]]],null,{\"statements\":[[0,\"      \"],[5,\"elements/element-btn\",[],[[\"@label\",\"@icon\",\"@disabled\",\"@isRunning\",\"@onSubmit\"],[\"clear\",\"fas fa-times\",[23,2,[]],[23,0,[\"resetTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"resetTask\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/starred/template.hbs"
    }
  });

  _exports.default = _default;
});