define("client/pods/components/products/panels-new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JGFvBH0c",
    "block": "{\"symbols\":[\"@isDuplicate\",\"@onCancel\",\"@onImportTask\",\"@tab\",\"@onAfterCreateRecordTask\"],\"statements\":[[5,\"products/products-editor\",[],[[\"@adapterName\",\"@isDuplicate\",\"@isNew\",\"@isRunning\",\"@model\",\"@onCancel\",\"@onImportTask\",\"@onSaveTask\",\"@tab\"],[[23,0,[\"adapterName\"]],[23,1,[]],true,[23,0,[\"crud\",\"createRecordTask\",\"isRunning\"]],[23,0,[\"model\"]],[23,2,[]],[23,3,[]],[28,\"perform\",[[23,0,[\"crud\",\"createRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"onAfter\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[\"model\"]],[23,5,[]],[23,4,[]]]]]],null],[23,4,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-new/template.hbs"
    }
  });

  _exports.default = _default;
});