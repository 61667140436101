define("client/pods/components/channels/shop/campaigns/campaigns-editor/settings-editor/payments-editor/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/search-contacts", "client/mixins/crud", "client/mixins/transporter", "ember-concurrency-decorators", "ramda-extension", "client/utils/nventor"], function (_exports, R, RA, _searchContacts, _crud, _transporter, _emberConcurrencyDecorators, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // import { task } from 'ember-concurrency'
  var _default = Ember.Component.extend(_crud.default, _searchContacts.default, _transporter.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    settings: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    tabsManager: Ember.inject.service(),
    contacts: Ember.inject.service(),
    constants: Ember.inject.service(),
    transporterService: Ember.inject.service('transporter'),
    transporterAccounts: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['contacts/lists/tags', 'dispatch/lists/processes', 'extensions/apps/tw/invoicing', 'extensions/apps/tw/convenience-stores/lists/brands', 'channels/shop/campaigns/lists/review-steps', 'channels/shop/campaigns/lists/statuses', 'channels/shop/campaigns/lists/variantSelectorStyle', 'channels/shop/campaigns/lists/cartBtnStyle', 'settings/checkout-date-method', {
        name: 'workflows/lists/items/activities/templates',
        appendPath: 'authorization'
      }]);
      const campaignFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/filters'
      });
      this.set('campaignFilters', campaignFilters);
      this.loadDefaultCampaignsReview(); // const shippingZonesFilters = this.search.setupFilters({
      //   adapterName: 'channels/shop/shipping-zones/filters',
      //   defaultFiltersData: {
      //     resultsProperty: 'shippingZonesLists',
      //     query: ''
      //   }
      // })
      // this.set('shippingZonesFilters', shippingZonesFilters)

      if (this.isNew) {
        // @NOTE: always use defaults from settings when creating new campaign
        this.model.setData('useDefaultsShippingFlatFee', true);
      }

      if (this.isCopy) {
        this.setupCommissionTask.perform();
      }

      this.setupTransporterTask.perform();
    },

    *setupTransporterTask() {
      const model = this.model;
      const transporter = yield this.transporterService.fetchTransporterTask.perform({
        model
      });
      this.set('transporterModel', transporter);
      this.loadTransporterAccounts(model, transporter);
    },

    *setupCommissionTask() {
      const model = this.model;
      model.loadSalesPersonSettings();

      if (this.isEditing) {
        const hasAnyCommissionData = yield this.campaignsService.checkHasAnyCommissionData.perform({
          model: this.model
        });
        this.set('hasAnyCommissionData', hasAnyCommissionData?.hasAnyCommissions);
      }

      const associatesContactsFilters = this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData: {
          isAssociates: true
        }
      });
      const associatesContacts = yield this.contacts.loadContactTask.perform(associatesContactsFilters);
      this.set('associatesContacts', associatesContacts);
    },

    loadDefaultCampaignsReview() {
      const model = this.model;

      if (!this.isReadonly) {
        const settingsModel = this.settings.getModel();
        let defaultReviewStepsKey = settingsModel.getData('defaultReviewStepsKey');
        defaultReviewStepsKey = R.clone(defaultReviewStepsKey);
        model.setData('defaultReviewStepsKey', defaultReviewStepsKey);
      }
    },

    hasCreditCardInstallmentsPaymentMethod: Ember.computed('model._data.paymentMethods.[]', 'settings.settingsModel.hasInstallments', function () {
      const allowInstallments = this.settings?.settingsModel?.hasInstallments || false;
      const model = this.model;
      const paymentMethods = model?._data?.paymentMethods || [];
      const hasCreditCardSelected = R.includes('creditCard')(paymentMethods);

      if (allowInstallments && hasCreditCardSelected) {
        return true;
      }

      return false;
    }),
    allowPickup: Ember.computed('model._data.useDefaultsPickupSettings', 'model._data.allowPickup', function () {
      const useDefaultsPickupSettings = this.model?._data?.useDefaultsPickupSettings;

      if (useDefaultsPickupSettings) {
        return this.settings?.settingsModel?._data?.shopAllowPickup || false;
      }

      return this.model?._data?.allowPickup || false;
    }),
    allowedPaymentMethods: Ember.computed('crud.lists.paymentMethods-shop.[]', 'model._data.paymentMethods.[]', function () {
      let paymentMethodsShop = this.crud.lists['paymentMethods-shop'] || []; // FILTER LINE PAY

      const linePayEnabled = R.path(['settings', 'settingsModel', '_data', 'linePay_tapPay', 'enabled'])(this) || false;

      if (!linePayEnabled) {
        paymentMethodsShop = R.reject(method => method.getData('value') === 'linePay')(paymentMethodsShop);
      }

      return paymentMethodsShop;
    }),
    shopPaymentMethodsDefaultsList: Ember.computed('crud.lists.paymentMethods-shop.[]', 'model._data.paymentMethods.[]', function () {
      const paymentMethods = R_.dotPath('crud.lists.paymentMethods-shop')(this) || [];
      const allowedPaymentMethods = this.get('model._data.paymentMethods') || [];
      return R.filter(method => R.includes(method.getData('value'), allowedPaymentMethods), paymentMethods);
    }),
    defaultShippingZonesIds: Ember.computed('crud.lists.channels-shop-shipping-zones-all.@each.synced', function () {
      const shippingZones = this.crud.lists['channels-shop-shipping-zones-all'] || [];
      return R.map(R.path(['_data', 'zoneId']))(shippingZones);
    }),
    selectedShippingZonesIds: Ember.computed('model._data.shippingZonesIds.[]', 'crud.lists.channels-shop-shipping-zones-all.@each.synced', function () {
      const shippingZonesIds = this.get('model._data.shippingZonesIds') || [];
      const shippingZones = this.crud.lists['channels-shop-shipping-zones-all'] || [];
      return R.filter(zone => {
        return R.pipe(R.path(['_data', 'zoneId']), R.indexOf(R.__, shippingZonesIds), R.gt(R.__, -1))(zone);
      })(shippingZones);
    }),
    allowedDispatchProcesses: Ember.computed('crud.lists.dispatch-lists-processes', function () {
      const allDispatchProcesses = this.get('crud.lists.dispatch-lists-processes') || [];
      return R.reject(R.pathEq(['_data', 'isDispatched'], true))(allDispatchProcesses);
    }),

    openTab() {
      this.tabsManager.openTab('left', {
        component: 'channels/shop/shipping-zones/tab-container',
        title: 'shipping zones'
      });
    },

    loadShopSource(shop) {
      const model = this.model;
      model.setData('childOfKey', shop.getData('_key'));
    },

    unloadShopSource() {
      const model = this.model;
      model.setData('childOfKey', '');
    },

    generateRandomPassword() {
      const password = _nventor.default.random.alphaNum(8).toLowerCase();

      const model = this.model;
      model.setData('campaignPassword', password);
    },

    refreshModel(model) {
      this.set('transporterModel', model);
    },

    updateSenderDetails(person) {
      const personId = person?.personId;
      const defaultPickupLocationPersonId = this.transporterModel?._data?.defaultPickupLocationPersonId || '';

      if (defaultPickupLocationPersonId !== personId) {
        this.model.set('_data.defaultPickupLocationPersonId', personId);
      }
    },

    onSelectTransporter(model, transporter) {
      this.set('transporterModel', transporter);
      this.loadTransporter(model, transporter);
    },

    actions: {
      loadTransporter(model, transporter) {
        model.setData('transporterKey', transporter.getData('_key'));
        this.loadTransporterAccounts(model, transporter);
      },

      unloadTransporter(model) {
        model.setData('transporterKey', '');
        model.setData('transporterAccountNumber', '');
        model.setData('transporterAccount', '');
        this.set('transporterAccounts', '');
      },

      setTransporterAccountLabel(model, account) {
        model.setData('transporterAccount', account.getData('name'));
      },

      assignSalesPerson() {
        const model = this.model;
        this.onAssignSalesPerson(...arguments);
        model.loadSalesPersonSettings();
      },

      unassignSalesPerson() {
        const model = this.model;
        this.onUnassignSalesPerson(...arguments);
        model.loadSalesPersonSettings();
      },

      toggleCommissionMethod() {
        this.onToggleCommissionMethod(...arguments);
      },

      setShopPaymentMethodsDefault(model) {
        const shopPaymentMethodsDefaultsList = this.shopPaymentMethodsDefaultsList || [];
        const currentDefault = model.getData('paymentMethodsDefault');
        let resetDefault = false;

        if (!R.includes(currentDefault, shopPaymentMethodsDefaultsList)) {
          resetDefault = true;
        }

        if (!currentDefault) {
          resetDefault = true;
        }

        if (resetDefault) {
          const first = shopPaymentMethodsDefaultsList.get('firstObject');
          const hasFirst = RA.isNotNilOrEmpty(first);

          if (hasFirst) {
            model.setData('paymentMethodsDefault', first.getData('value'));
          } else {
            model.setData('paymentMethodsDefault', '');
          }
        }
      },

      addDispatchProcess(model, dispatchProcess) {
        const dispatchProcessLabelTranslate = dispatchProcess.getData('translate');
        const dispatchProcessLabel = dispatchProcess.getData('name');
        model.setData('dispatchProcessLabel', dispatchProcessLabel);
        model.setData('dispatchProcessLabelTranslate', dispatchProcessLabelTranslate);
      },

      removeDispatchProcess(model) {
        model.setData('dispatchProcessLabel', '');
        model.setData('dispatchProcessLabelTranslate', '');
      } //   onSelectShippingZoneId (selectedItem) {
      //     const zoneId = selectedItem.getData('zoneId')
      //     if (RA.isNotNilOrEmpty(zoneId)) {
      //       let shippingZoneIds = this.model.getData('shippingZonesIds') || []
      //       shippingZoneIds = R.pipe(
      //         R.append(zoneId),
      //         R.uniq
      //       )(shippingZoneIds)
      //       this.model.setData('shippingZonesIds', shippingZoneIds)
      //     }
      //     this.model.set('shippingZoneName', '')
      //   }


    },

    checkValidStartAndEndDateZ() {
      const model = this?.model || {};
      const dateStartZ = model?._data?.dateStartZ || '';
      const dateEndZ = model?._data?.dateEndZ || '';

      if (RA.isNotNilOrEmpty(dateStartZ) && RA.isNotNilOrEmpty(dateEndZ) && dateEndZ < dateStartZ) {
        model.set('dateError', true);
      } else {
        model.set('dateError', false);
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "setupTransporterTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "setupTransporterTask"), _obj), _applyDecoratedDescriptor(_obj, "setupCommissionTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "setupCommissionTask"), _obj), _applyDecoratedDescriptor(_obj, "openTab", [_dec], Object.getOwnPropertyDescriptor(_obj, "openTab"), _obj), _applyDecoratedDescriptor(_obj, "loadShopSource", [_dec2], Object.getOwnPropertyDescriptor(_obj, "loadShopSource"), _obj), _applyDecoratedDescriptor(_obj, "unloadShopSource", [_dec3], Object.getOwnPropertyDescriptor(_obj, "unloadShopSource"), _obj), _applyDecoratedDescriptor(_obj, "generateRandomPassword", [_dec4], Object.getOwnPropertyDescriptor(_obj, "generateRandomPassword"), _obj), _applyDecoratedDescriptor(_obj, "refreshModel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "refreshModel"), _obj), _applyDecoratedDescriptor(_obj, "updateSenderDetails", [_dec6], Object.getOwnPropertyDescriptor(_obj, "updateSenderDetails"), _obj), _applyDecoratedDescriptor(_obj, "onSelectTransporter", [_dec7], Object.getOwnPropertyDescriptor(_obj, "onSelectTransporter"), _obj), _applyDecoratedDescriptor(_obj, "checkValidStartAndEndDateZ", [_dec8], Object.getOwnPropertyDescriptor(_obj, "checkValidStartAndEndDateZ"), _obj)), _obj)));

  _exports.default = _default;
});