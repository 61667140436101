define("client/pods/components/elements/element-btn/component", ["exports", "client/mixins/has-dropdown", "client/pods/components/elements/mixins/has-date-picker"], function (_exports, _hasDropdown, _hasDatePicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_hasDropdown.default, _hasDatePicker.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    tagName: 'button',
    attributeBindings: ['disabled', 'tabindex', 'style'],
    tabindex: 0,
    translate: true,
    classNames: ['button', 'element-btn', 'u-tappable'],
    btnClassNames: null,
    classNameBindings: ['disabled', 'toggleIsOn:on:off', 'isRunning:is-loading', 'isOn:is-on', 'isActivated:is-primary'],
    isRunning: false,
    datePickerClassName: null,
    iconClassName: null,
    toggleIsOn: false,
    isToggler: false,
    hasModalDialog: false,
    modalDialogIsVisible: false,
    toggleOnIconClassName: 'fas fa-caret-up',
    toggleOffIconClassName: 'fas fa-caret-down',
    toggleOnIconRightClassName: 'fas fa-caret-up',
    toggleOffIconRightClassName: 'fas fa-caret-down',
    bubbles: false,
    hasToggleIcon: false,
    hasToggleIconRight: true,
    isPrintBtn: false,
    hasBadge: true,
    hasIconSpacers: true,
    _tooltipTranslate: Ember.computed('tooltipTranslate', function () {
      const tooltipTranslate = this.get('tooltipTranslate');

      if (tooltipTranslate === false) {
        return false;
      }

      if (tooltipTranslate === true) {
        return true;
      }

      return this.translate;
    }),
    hasBadgeComputed: Ember.computed('hasBadge', function () {
      if (this.hasBadge === null || this.hasBadge === undefined) {
        return true;
      }

      return Boolean(this.hasBadge);
    }),

    submit(e) {
      if (this.isPrintBtn) {
        this.printDialog();
      } else {
        if (this.disabled !== true) {
          if (this.hasModalDialog) {
            this.showModalDialog();
          }

          this._submit(e);
        }
      }
    },

    _submit(e) {
      if (this.onPreSubmit) {
        this.onPreSubmit(e, this);
      }

      var isToggler = this.isToggler;

      if (isToggler) {
        this.toggleProperty('toggleIsOn');
      }

      if (this.isDatePicker) {
        this.showDatePicker();
      }

      if (this.onSubmit) {
        if (this.requiresConfirmation) {
          const msg = this.confirmMsgTranslate ? this.intl.t(this.confirmMsg) : this.confirmMsg || this.intl.t('are you sure you want to delete');
          const confirmed = window.confirm(msg);

          if (confirmed) {
            return this.onSubmit(e, this);
          }
        } else {
          this.onSubmit(e, this);
        }
      }
    },

    click(e) {
      if (this.bubbles !== true) {
        e.stopPropagation();
      }

      this.submit(e);
    },

    keyUp(e) {
      if (this.bubbles !== true) {
        e.stopPropagation();
      }

      if (e.which === 13) {
        this.submit(e);
      }
    },

    showModalDialog() {
      this.set('modalDialogIsVisible', true);
    },

    hideModalDialog() {
      this.set('modalDialogIsVisible', false);
    },

    printDialog() {
      window.print();
    }

  }, (_applyDecoratedDescriptor(_obj, "submit", [_dec], Object.getOwnPropertyDescriptor(_obj, "submit"), _obj), _applyDecoratedDescriptor(_obj, "_submit", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_submit"), _obj), _applyDecoratedDescriptor(_obj, "showModalDialog", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showModalDialog"), _obj), _applyDecoratedDescriptor(_obj, "hideModalDialog", [_dec4], Object.getOwnPropertyDescriptor(_obj, "hideModalDialog"), _obj), _applyDecoratedDescriptor(_obj, "printDialog", [_dec5], Object.getOwnPropertyDescriptor(_obj, "printDialog"), _obj)), _obj)));

  _exports.default = _default;
});