define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-products/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsPanelsDisplaySubPanelsProductsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = class ChannelsShopCampaignsPanelsDisplaySubPanelsProductsComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "settings", _descriptor3, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor4, this);

      _initializerDefineProperty(this, "batch", _descriptor5, this);

      _initializerDefineProperty(this, "results", _descriptor6, this);

      _initializerDefineProperty(this, "isEditing", _descriptor7, this);

      _initializerDefineProperty(this, "mode", _descriptor8, this);

      _initializerDefineProperty(this, "itemModel", _descriptor9, this);

      _initializerDefineProperty(this, "isBulkEditing", _descriptor10, this);

      if (this.args.productsFilters) {
        Ember.set(this, 'filters', this.args.productsFilters);
      } else {
        // @TODO: is this necessary?
        const model = this.args.model;
        const master = model.getData('_key');
        const filters = this.search.setupFilters({
          adapterName: 'channels/shop/campaigns/details/filters',
          props: {
            showStarred: true,
            showFilters: true,
            // dynamicFilterKeys will never be saved, but will always get from current filters to load from saved
            dynamicFilterKeys: ['master', 'resultsProperty', 'resultsAdapterName', 'appendPath']
          },
          defaultFiltersData: {
            master,
            resultsProperty: 'results',
            resultsAdapterName: 'channels/shop/campaigns/details',
            appendPath: `/master/${master}`,
            shopProductsSort: this.settings.settingsModel?._data?.shopProductsSort || 'recommended',
            statuses: ['active', 'inactive', 'preOrder', 'soldOut', 'draft'],
            sort: [{
              by: 'index',
              direction: 'ASC'
            }]
          }
        });
        Ember.set(this, 'filters', filters);
      }
    } // _getBulkEditingDirty (batch) {
    //   return R.map(detail => {
    //     return this.crud.setupDirty({
    //       adapterName: 'channels/shop/campaigns/details',
    //       model: detail
    //     })
    //   })(batch)
    // }


    *fetchCampaignDetailsTask() {
      const model = this.args.model;
      const master = model.getData('_key');

      if (master) {
        model.set('detailsUpdated', false);
        yield this.crud.searchRecordsTask.perform({
          adapterName: 'channels/shop/campaigns/details',
          appendPath: `/master/${master}`,
          filters: this.filters
        });
      }
    } // @TODO: use the new crud service. but this does not work correctly
    // @task
    // * customPaginateSearchRecordsTask ({ filters }) {
    //   const model = this.args.model
    //   const master = model.getData('_key')
    //   let batch = yield this.crud.paginateSearchRecordsTask.perform({
    //     adapterName: 'channels/shop/campaigns/details',
    //     appendPath: `/master/${master}`,
    //     filters,
    //   })
    //   if (this.isBulkEditing) {
    //     batch = this._getBulkEditingDirty(batch)
    //   }
    //   this.batch = batch
    //   model.set('details', batch)
    // }


    selectMode(mode) {
      let toDisplayResults = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];

      if (this.mode === mode) {
        return;
      }

      Ember.set(this, 'mode', mode);

      if (mode === 'browse') {
        if (RA.isNotNilOrEmpty(toDisplayResults)) {
          const tab = this.args.tab;
          const itemModel = R.path([0])(toDisplayResults);

          if (RA.isNilOrEmpty(itemModel)) {
            Ember.set(this, 'mode', 'overview');
          } // this.displayItem(tab, itemModel)

        }
      }
    } // @action
    // editItem (tab, itemModel) {
    //   this.selectMode('browse')
    //   set(this, 'isEditing', true)
    //   const panelOptions = {
    //     component: 'channels/shop/campaigns/campaigns-item-editor',
    //     model: itemModel
    //   }
    //   this.tabsManager.replaceSubTab(tab, panelOptions)
    //   set(this, 'itemModel', itemModel)
    // }
    // @action
    // displayItem (tab, itemModel) {
    //   this.selectMode('browse')
    //   set(this, 'isEditing', false)
    //   const panelOptions = {
    //     component: 'channels/shop/campaigns/campaigns-item-editor',
    //     model: itemModel
    //   }
    //   this.tabsManager.replaceSubTab(tab, panelOptions)
    //   set(this, 'itemModel', itemModel)
    // }


    bulkEdit() {
      this.isBulkEditing = true; // const batch = this.batch || []
      // const model = this.args.model
      // this.batch = this._getBulkEditingDirty(batch)
      // model.set('batch', this._getBulkEditingDirty(batch))
    }

    toggleBulkEdit() {
      this.isBulkEditing = !this.isBulkEditing;

      if (!this.isBulkEditing) {
        this.fetchCampaignDetailsTask.perform();
      }
    }

    afterCreateItem(onCloseDialogAction) {
      this.fetchCampaignDetailsTask.perform();

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "batch", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "results", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isEditing", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "mode", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'overview';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "itemModel", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isBulkEditing", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchCampaignDetailsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchCampaignDetailsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectMode", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "selectMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bulkEdit", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "bulkEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleBulkEdit", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "toggleBulkEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterCreateItem", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreateItem"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsPanelsDisplaySubPanelsProductsComponent;
});