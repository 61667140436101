define("client/pods/websites/lists/sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let {
        data = {},
        appendPath,
        serverType
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let list = [{
        value: 'webpages',
        label: 'webpages',
        component: 'websites/panels-display/sub-panels-pages',
        resource: 'api/protected/websites/lists/menu/sub-panels/webpages',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/pages-editor'
      }, {
        value: 'shop',
        label: 'shop',
        component: 'websites/panels-display/sub-panels-shop',
        resource: 'api/protected/websites/lists/menu/sub-panels/shop',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/shop-editor'
      }, {
        value: 'blogs',
        label: 'blogs',
        component: 'websites/panels-display/sub-panels-blogs',
        resource: 'api/protected/websites/lists/menu/sub-panels/blogs',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/blogs-editor'
      }, {
        value: 'news',
        label: 'news',
        component: 'websites/panels-display/sub-panels-news',
        resource: 'api/protected/websites/lists/menu/sub-panels/news',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/news-editor'
      }, {
        value: 'storeLocation',
        label: 'store locations',
        component: 'websites/panels-display/sub-panels-store-location',
        resource: 'api/protected/websites/lists/menu/sub-panels/store-location',
        tabType: 'reuseSubTab',
        editingComponent: 'websites/websites-editor/store-location'
      }, {
        value: 'settings',
        label: 'settings',
        component: 'websites/panels-display/sub-panels-settings',
        editingComponent: 'websites/websites-editor/settings-editor',
        resource: 'api/protected/websites/lists/menu/sub-panels/settings',
        tabType: 'reuseSubTab'
      }, {
        value: 'headers-editor',
        label: 'header-settings',
        component: 'websites/panels-display/sub-panels-headers',
        editingComponent: 'websites/websites-editor/headers-editor',
        resource: 'api/protected/websites/sub-panels-edit/settings',
        tabType: 'reuseSubTab'
      }, {
        value: 'style-editor',
        label: 'settings_style',
        component: 'websites/panels-display/sub-panels-style',
        editingComponent: 'websites/websites-editor/style-editor',
        resource: 'api/protected/websites/sub-panels-edit/settings',
        tabType: 'reuseSubTab'
      }, {
        value: 'seo-editor',
        label: 'seo',
        component: 'websites/panels-display/sub-panels-seo',
        editingComponent: 'websites/websites-editor/seo-editor',
        resource: 'api/protected/websites/sub-panels-edit/settings',
        tabType: 'reuseSubTab'
      }, {
        value: '3rd-party',
        label: '3rd-party',
        component: 'websites/panels-display/sub-panels-3rd-party',
        editingComponent: 'websites/websites-editor/3rd-party-editor',
        resource: 'api/protected/websites/sub-panels-edit/settings',
        tabType: 'reuseSubTab'
      }];
      const isVirtualOnly = this.settings?.isFeatureAllowed('features.isVirtualOnly');

      if (isVirtualOnly) {
        const allowed = ['webpages', 'settings', 'style-editor', 'seo-editor'];
        list = R.filter(item => {
          return R.includes(item.value)(allowed);
        })(list);
      }

      const customData = {
        list
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});