define("client/pods/components/documentations/documentation/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentationComponent = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class DocumentationComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "applicationService", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "users", _descriptor3, this);

      _initializerDefineProperty(this, "_showDocumentation", _descriptor4, this);

      _defineProperty(this, "adapterName", 'documentation');

      const dynamicList = {
        name: 'documentation/lists/resource',
        appendPath: this.documentationResource
      };
      this.crud.addLists(this, ['documentation/lists/tags', 'statuses', dynamicList]);
    }

    get documentationResource() {
      const documentationResource = this?.args?.documentationResource;
      return documentationResource || this?.args?.options?.documentationResource;
    }

    get documentationId() {
      if (this.args.options) {
        const {
          documentationId,
          documentationResource,
          context
        } = this.args.options;

        if (documentationId) {
          return documentationId;
        }

        if (documentationResource && context) {
          return `${documentationResource}-${context}`;
        }

        return null;
      }

      return null;
    }

    get documentation() {
      const documentationResource = `documentation-lists-resource--${this.documentationResource}`;
      const documentation = R.pipe(R.pathOr([], ['crud', 'lists', documentationResource]), R.find(R.pathEq(['_data', 'documentationId'], this.documentationId)))(this);

      if (!this.users.isDocumentationsEditor) {
        if (documentation?._data?.status !== 'active') {
          return {};
        }
      }

      return documentation;
    }

    toggleDocumentation() {
      this._showDocumentation = !this._showDocumentation;
    }

    get showDocumentation() {
      const isHelp = this.args.isHelp;

      if (isHelp) {
        return true;
      }

      if (RA.isNilOrEmpty(this._showDocumentation)) {
        // if _showDocumentation is NULL it means that its on init
        if (this.applicationService.documentationIsMinimized && this.args.isGroup) {
          return false;
        }

        if (RA.isNotNilOrEmpty(this.args.documentationIsMinimizedOverride)) {
          return !this.args.documentationIsMinimizedOverride;
        }

        if (RA.isNilOrEmpty(this.args.documentationIsMinimized)) {
          return true;
        }

        return !this.args.documentationIsMinimized;
      }

      return this._showDocumentation;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_showDocumentation", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleDocumentation", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDocumentation"), _class.prototype)), _class));
  _exports.default = DocumentationComponent;
});