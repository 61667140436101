define("client/pods/components/extensions/delete-sub/delete-panels-display/component", ["exports", "client/mixins/date", "client/mixins/search-contacts"], function (_exports, _date, _searchContacts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import nventor from '../../../../../../../utils/nventor';
  // import BankModel from '../../../../../../ledgers/accounts/banks/model';
  var _default = Ember.Component.extend(_date.default, _searchContacts.default, {
    crud: Ember.inject.service(),
    contactsList: null,

    // model: null,
    // errors: null,
    init() {
      this.crud.addLists(this, ['paymentMethods', 'contacts/transporters']);

      this._super(...arguments);

      this.set('contactsList', []);
      this.set('model', this.get('tab.model'));
      this.set('extName', this.get('tab.extName'));
      const saved = this.get('tab.saved') || [];

      if (saved.length > 0) {
        this.set('savedCount', saved.length);
      }
    },

    /**
     * keep track of number of selected items
     * @return {integer} number of selected orders
     */
    selectedCount: Ember.computed('model._data.orders.@each.isSelected', function () {
      const model = this.model;
      const orders = model.getData('orders') || [];
      const selected = orders.filterBy('isSelected', true);
      return selected.length;
    }),
    isFetching: Ember.computed('model._data.orders.@each.isFetching', function () {
      const model = this.model;
      const orders = model.getData('orders') || [];
      const isFetching = orders.filterBy('isFetching', true);

      if (isFetching.length > 0) {
        return true;
      }

      return false;
    }),
    isReady: Ember.computed('model._data.orders.@each.isFetching', 'selectedCount', function () {
      const model = this.model;
      const orders = model.getData('orders') || [];
      const isFetching = orders.filterBy('isFetching', true);

      if (isFetching.length > 0) {
        return false;
      }

      if (this.selectedCount > 0) {
        return true;
      }

      return false;
    }),
    actions: {
      selectAll() {
        const model = this.model;
        const orders = model.getData('orders') || [];
        orders.forEach(function (order) {
          if (order.getData('hasValidProducts')) {
            order.set('isSelected', true);
          }
        });
      },

      submit() {
        const self = this;
        const model = self.get('model');
        const extName = self.get('extName');
        const data = [];
        const orders = model.getData('orders') || [];
        orders.forEach(function (order) {
          if (order.get('isSelected')) {
            data.push(order._data);
          }
        });

        if (data.length > 0) {
          // skip data store and create record
          const appendPath = `/${extName}/orders`;
          const batchData = {
            batch: data
          };
          self.set('isSaving', true);
          self.get('dataManager').getAdapter('channels/shop/web').save(batchData, appendPath).then(function (saved) {
            if (!self.get('isDestroyed')) {
              self.set('isSaving', false);
              self.get('onFetch')(saved);
            } // console.log('=====saved====')
            // console.log(saved)

          });
        }
      },

      searchTransporters() {// @TODO: move to a mixin
        // @TODO: should use transporter adapter to search which will include
        // @TODO: default transporters
      },

      cancel() {}

    }
  });

  _exports.default = _default;
});