define("client/pods/components/ledgers/accounts/aging/tab-container/component", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _date, _crud, _emberConcurrency, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    date: Ember.inject.service(),
    adapterName: 'contacts/aging',
    filtersAdapterName: 'contacts/aging/filters',
    currentPeriod: '',
    paymentDueDateZ: '',

    init() {
      this._super(...arguments);

      const tab = this.tab;
      const docType = tab.get('docType');
      this.set('docType', docType);
      const pastMonths = this.getPastMonths(12);
      this.set('pastMonths', pastMonths);
      const agingFilters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.agingFilters = agingFilters;
      this.crud.addLists(this, ['contacts/lists/allowed-payment-method', 'filters/count']);
    },

    afterLoadLists() {
      const thisMonth = this.date.getPeriod();
      this.displayByMonth.perform(thisMonth);
      this.display(thisMonth, this.agingFilters);
    },

    display(period, filters) {
      const subTabOptions = {
        component: 'ledgers/accounts/aging/panels-display',
        period: period,
        filters: filters,
        docType: this?.tab?.docType || ''
      };
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    displayAgingTask: (0, _emberConcurrency.task)(function* (period) {
      const docType = this.docType;
      const yyyy = period?.yyyy || '';
      const mm = period?.mm || '';
      const allowedPaymentMethodForStatement = R.pipe(R_.dotPathOr([], 'crud.lists.contacts-lists-allowed-payment-method'), R.pluck('_data'))(this);
      const agingFilters = this.agingFilters;
      agingFilters.setData('docType', docType);
      agingFilters.setData('yyyy', yyyy);
      agingFilters.setData('mm', mm);
      agingFilters.setData('allowedPaymentMethodForStatement', allowedPaymentMethodForStatement);
      agingFilters.setData('paymentDueDateZ', this?.paymentDueDateZ || '');
      yield this.crud.searchRecordsTask.perform({
        adapterName: `contacts/aging/${docType}`,
        filters: agingFilters
      });
    }),
    reloadStatementOnDueDateChangeTask: (0, _emberConcurrency.task)(function* () {
      const currentPeriod = this?.currentPeriod || {};

      if (RA.isNotNilOrEmpty(currentPeriod)) {
        yield this.displayAgingTask.perform(currentPeriod);
      }
    }),
    displayByMonth: (0, _emberConcurrency.task)(function* (period) {
      const dateEnd = this.date.formatDateForUrl(this.date.getMonthEnd(period.yyyy, period.mm));
      this.set('paymentDueDateZ', this.date.getMoment(dateEnd).toISOString());
      this.set('currentPeriod', period);
      yield this.displayAgingTask.perform(period);
    })
  });

  _exports.default = _default;
});