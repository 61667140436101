define("client/pods/components/guests/app-logged-in/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    applicationService: Ember.inject.service('application'),
    guests: Ember.inject.service(),

    init() {
      this.guests.setup(this.guestResource);

      this._super(...arguments);

      const tabOptions = {
        component: 'guests/campaign-main',
        isActive: true
      };
      this.tabsManager.openTab('left', tabOptions);
    }

  });

  _exports.default = _default;
});