define("client/pods/components/users/search-results-persons-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gl7R0Bob",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"name\"]],[24,[\"query\"]]],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"result\",\"_data\",\"companyName\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"u-results__text\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"highlight\",[[24,[\"result\",\"_data\",\"companyName\"]],[24,[\"query\"]]],null],false],[0,\"\\n\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"u-results__text u-desc u-desc--mini\"],[8],[0,\"\\n\\n\\t\"],[1,[28,\"lists/list-tags\",null,[[\"list\",\"value\"],[[23,0,[\"crud\",\"lists\",\"users-lists-types\"]],[24,[\"result\",\"_data\",\"type\"]]]]],false],[0,\"\\n\\n\\t\"],[1,[28,\"lists/list-tags\",null,[[\"list\",\"value\"],[[23,0,[\"crud\",\"lists\",\"users-lists-roles-internal\"]],[24,[\"result\",\"_data\",\"role\"]]]]],false],[0,\"\\n\\n\\t\"],[1,[28,\"lists/list-tags\",null,[[\"list\",\"value\"],[[23,0,[\"crud\",\"lists\",\"statuses\"]],[24,[\"result\",\"_data\",\"status\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/users/search-results-persons-item/template.hbs"
    }
  });

  _exports.default = _default;
});