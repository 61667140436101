define("client/pods/components/grapesjs/preview-container/component", ["exports", "@glimmer/component", "grapesjs", "ramda", "client/pods/grapesjs/plugins/banner", "client/pods/grapesjs/plugins/blocks", "client/pods/grapesjs/plugins/button", "client/pods/grapesjs/categories", "client/pods/grapesjs/plugins/cell", "client/pods/grapesjs/plugins/full-width-image", "client/pods/grapesjs/plugins/image", "client/pods/grapesjs/plugins/non-editable", "client/pods/grapesjs/plugins/panels", "client/pods/grapesjs/plugins/product", "client/pods/grapesjs/plugins/products", "client/pods/grapesjs/plugins/row", "client/pods/grapesjs/plugins/scratch-card", "client/pods/grapesjs/plugins/slider-native", "client/pods/grapesjs/plugins/strip", "client/pods/grapesjs/plugins/video", "client/pods/grapesjs/plugins/grid", "client/pods/grapesjs/plugins/double-row", "client/pods/grapesjs/plugins/form", "client/config/environment"], function (_exports, _component, _grapesjs, R, _banner, _blocks, _button, _categories, _cell, _fullWidthImage, _image, _nonEditable, _panels, _product, _products, _row, _scratchCard, _sliderNative, _strip, _video, _grid, _doubleRow, _form, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * A Page to render
   * @typedef {Object} PageType
   * @property {{html:string,css:string?}} pageDataJson - Page content
   */
  let GrapesjsPreviewContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class GrapesjsPreviewContainerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grapesjs", _descriptor2, this);

      _initializerDefineProperty(this, "editor", _descriptor3, this);

      _initializerDefineProperty(this, "displayTopPanel", _descriptor4, this);

      _defineProperty(this, "id", Math.floor(new Date().getTime() / 1000.0));

      this.displayTopPanel = R.pathOr(true, ['args', 'displayTopPanel'])(this);
    }

    async appendComponents(appendData, styles) {
      const editor = this.editor;
      return new Promise(function (resolve, reject) {
        editor.setStyle(styles);
        setTimeout(() => {
          const components = editor.getComponents().models.firstObject;
          const compArr = components.append(appendData);
          const hasAddedComp = R.pipe(R.isEmpty, R.not)(compArr);

          if (hasAddedComp) {
            return resolve(compArr);
          }

          return reject(compArr);
        }, 10);
      });
    }
    /**
     * Get the html and css from object
     * @param {PageType} page
     * @return {{components: string, styles: string}}
     */


    extractComponentsAndStyles(page) {
      const pageDataJson = R.pathOr({}, ['pageDataJson'])(page);
      const components = pageDataJson.components || pageDataJson.html;
      const styles = pageDataJson.styles || pageDataJson.css;
      return {
        components,
        styles
      };
    }

    updateStyle(model) {
      if (this.editor) {
        const editorStyle = this.editor.getStyle();

        if (editorStyle) {
          const themeStyle = this.grapesjs.genThemeFromWebsiteModel(model);
          editorStyle.add(themeStyle);
        }
      }
    }

    setup(page) {
      const {
        components,
        styles
      } = this.extractComponentsAndStyles(page);
      const categories = (0, _categories.default)(this.intl);
      const plugins = [_banner.default, _blocks.default, _button.default, _cell.default, _fullWidthImage.default, _image.default, _nonEditable.default, _panels.default, _product.default, _products.default, _row.default, _scratchCard.default, _sliderNative.default, _strip.default, _video.default, _grid.default, _doubleRow.default, _form.default];
      const pluginsOpts = {
        'bigordr-slider-native': {
          categories,
          intl: this.intl
        },
        'ckeditor-plugin': {
          categories,
          intl: this.intl
        },
        'bigordr-animation-trait': {
          categories,
          intl: this.intl
        },
        'bigordr-animation-text-trait': {
          categories,
          intl: this.intl
        },
        'bigordr-animation-iteration-trait': {
          categories,
          intl: this.intl
        },
        'bigordr-link-trait': {
          categories,
          intl: this.intl
        },
        tabs: {
          categories,
          intl: this.intl
        },
        'bigordr-tabs-with-header': {
          categories,
          intl: this.intl
        },
        'bigordr-background-fixed': {
          categories,
          intl: this.intl
        },
        'bigordr-blogs': {
          categories,
          intl: this.intl
        },
        'double-row': {
          categories,
          intl: this.intl
        },
        'bigordr-form': {
          categories,
          intl: this.intl
        },
        text: {
          categories,
          intl: this.intl
        },
        row: {
          categories,
          intl: this.intl
        },
        cell: {
          categories,
          intl: this.intl
        },
        'full-width-image': {
          categories,
          intl: this.intl
        },
        'bigordr-grid': {
          categories,
          intl: this.intl
        },
        'bigordr-grid-images': {
          categories,
          intl: this.intl
        },
        'bigordr-blocks': {
          categories,
          intl: this.intl
        },
        'bigordr-banner': {
          categories,
          intl: this.intl
        },
        'bigordr-strip': {
          categories,
          intl: this.intl
        },
        'bigordr-panels': {
          categories,
          intl: this.intl
        },
        'bigordr-button': {
          categories,
          intl: this.intl
        },
        'bigordr-slider': {
          categories,
          intl: this.intl
        },
        'bigordr-video': {
          categories,
          intl: this.intl
        },
        'bigordr-product': {
          categories,
          intl: this.intl
        },
        'bigordr-products': {
          categories,
          intl: this.intl
        },
        'bigordr-video-full-width': {
          categories,
          intl: this.intl
        },
        'bigordr-picture': {
          categories,
          intl: this.intl
        },
        'bigordr-picture-layer-container': {
          categories,
          intl: this.intl
        },
        'bigordr-tabs': {
          categories,
          intl: this.intl
        },
        'bigordr-scratch-card': {
          categories,
          intl: this.intl
        },
        'bigordr-stories': {
          categories,
          intl: this.intl
        }
      };
      this.editor = _grapesjs.default.init({
        // Indicate where to init the editor. You can also pass an HTMLElement
        container: `.gjs-preview-${this.id}`,
        // Get the content for the canvas directly from the element
        // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
        fromElement: true,
        // Size of the editor
        height: '100%',
        width: 'auto',
        noticeOnUnload: false,
        // Disable the storage manager for the moment
        storageManager: false,
        // Avoid any default panel
        panels: {
          defaults: [{
            id: 'panel-devices',
            el: `.panel__devices-${this.id}`,
            buttons: [{
              id: 'device-desktop',
              command: 'set-device-desktop',
              className: 'fa fa-desktop'
            }, {
              id: 'device-tablet',
              command: 'set-device-tablet',
              className: 'fa fa-tablet'
            }, {
              id: 'device-mobile',
              command: 'set-device-mobile',
              className: 'fa fa-mobile'
            }]
          }]
        },
        deviceManager: {
          devices: [{
            name: 'Desktop',
            width: '' // default size

          }, {
            name: 'Tablet',
            width: '650px',
            widthMedia: '810px' // the width that will be used for the CSS media

          }, {
            name: 'Mobile',
            width: '320px',
            // this value will be used on canvas width
            widthMedia: '480px' // this value will be used in CSS @media

          }]
        },
        commands: {
          defaults: [{
            id: 'set-device-desktop',

            run(editor) {
              editor.setDevice('Desktop');
            }

          }, {
            id: 'set-device-tablet',

            run(editor) {
              editor.setDevice('Tablet');
            }

          }, {
            id: 'set-device-mobile',

            run(editor) {
              editor.setDevice('Mobile');
            }

          }]
        },
        plugins,
        pluginsOpts,
        // By default Grapes injects base CSS into the canvas. For example, it sets body margin to 0
        // and sets a default background color of white. This CSS is desired in most cases.
        // use this property if you wish to overwrite the base CSS to your own CSS. This is most
        // useful if for example your template is not based off a document with 0 as body margin.
        baseCss: `
    * {
      box-sizing: border-box;
    }
    html, body, [data-gjs-type=wrapper] {
      min-height: 100vh;
    }
    body {
      margin: 0;
      height: 100%;
    }
    [data-gjs-type=wrapper] {
      overflow: auto;
      overflow-x: hidden;
    }
    * ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1)
    }
    * ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.2)
    }
    * ::-webkit-scrollbar {
      width: 10px
    }
    .button:hover {
      background-color: var(--themeButtonHoverColor);
      color: var(--themeButtonHoverTextColor);
    }

    .button1 {
      background-color: var(--themeButtonColor1) !important;
      border: 1px solid var(--themeButtonBorderColor1) !important;
      color: var(--themeButtonTextColor1) !important;
      border-radius: var(--themeButtonBorderRadius1) !important;
    }
    .button1:hover {
      background-color: var(--themeButtonHoverColor1);
      color: var(--themeButtonHoverTextColor1);
    }
    .button2 {
      background-color: var(--themeButtonColor2) !important;
      border: 1px solid var(--themeButtonBorderColor2) !important;
      color: var(--themeButtonTextColor2) !important;
      border-radius: var(--themeButtonBorderRadius2) !important;
    }
    .button2:hover {
      background-color: var(--themeButtonHoverColor2);
      color: var(--themeButtonHoverTextColor2) !important;
    }
    .button3 {
      background-color: var(--themeButtonColor3) !important;
      border: 1px solid var(--themeButtonBorderColor3) !important;
      color: var(--themeButtonTextColor3) !important;
      border-radius: var(--themeButtonBorderRadius3) !important;
    }
    .button3:hover {
      background-color: var(--themeButtonHoverColor3);
      color: var(--themeButtonHoverTextColor3) !important;
    }
    .button-variant {
      background-color: var(--themeVariantButtonColor) !important;
      border: 1px solid var(--themeVariantButtonBorderColor) !important;
      color: var(--themeVariantButtonTextColor) !important;
      border-radius: var(--themeVariantButtonBorderRadius) !important;
    }
    .button-variant:hover {
      background-color: var(--themeVariantButtonHoverColor) !important;
      border: 1px solid var(--themeVariantButtonHoverBorderColor) !important;
      color: var(--themeVariantButtonHoverTextColor) !important;
    }
    .button-variant.is-selected {
      background-color: var(--themeVariantButtonSelectedColor) !important;
      border: 1px solid var(--themeVariantButtonSelectedBorderColor) !important;
      color: var(--themeVariantButtonSelectedTextColor) !important;
    }
    .nav-item .button {
      color: var(--themeMenuLinkColor);
    }
    .nav-item .button:hover {
      color: var(--themeMenuLinkHoverColor);
    }
    body {
      background-color: var(--themeBackgroundPrimaryColor);
    }

    .menu-list__no-hover a:hover {
      background-color: transparent;
    }

    .menu-list__footer-header-color p {
      color: var(--themeFooterHeaderColor);
    }

    .grapesjs-custom-code {
      min-height: 75px;
    }
  `,
        canvas: {
          styles: [`${_environment.default.APP.storageUrl}/api/proxy/file/vendor/css`, `${_environment.default.APP.storageUrl}/api/proxy/file/shop/css`, 'https://fonts.googleapis.com/css?family=Noto+Sans+TC|Noto+Serif+TC'],
          scripts: ['https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/js/all.min.js']
        }
      });
      this.editor.Panels.addPanel({
        id: 'panel-top',
        el: `.panel__top-${this.id}`
      });
      this.editor.setComponents({
        tagName: 'div',
        classes: ['readonly']
      });
      this.editor.DomComponents.getWrapper().onAll(comp => comp.set({
        badgable: false,
        copyable: false,
        draggable: false,
        droppable: false,
        editable: false,
        highlightable: false,
        hoverable: false,
        layerable: false,
        propagate: ['badgable', 'copyable', 'draggable', 'droppable', 'editable', 'highlightable', 'hoverable', 'layerable', 'removable', 'resizable', 'selectable', 'stylable'],
        removable: false,
        resizable: false,
        selectable: false,
        stylable: false
      }));
      this.appendComponents(components, styles).catch(error => console.error('ERROR WHILE APPENDING COMPONENTS & STYLE: ', error));
    }
    /**
     * Update gjs content
     * @param {PageType} page
     */


    updateContent(page) {
      const {
        components,
        styles
      } = this.extractComponentsAndStyles(page);

      try {
        const readonlyWrapper = this.editor.Components.getComponent().find('div > .readonly').firstObject;

        if (readonlyWrapper) {
          readonlyWrapper.empty();
          readonlyWrapper.append(components);

          if (styles) {
            this.editor.setStyle(styles);
          }
        }
      } catch (error) {
        console.error('ERROR WHILE UPDATING CONTENT: ', error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grapesjs", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "editor", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "displayTopPanel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateStyle", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateStyle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateContent", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateContent"), _class.prototype)), _class));
  _exports.default = GrapesjsPreviewContainerComponent;
});