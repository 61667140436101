define("client/pods/components/layouts/right-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hlRUDrEx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"isShowing\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"layouts__right-bar u-z-index--4000\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layouts__right-bar-contents\"],[8],[0,\"\\n\"],[4,\"layouts/section-row\",null,[[\"title\",\"translate\"],[\"notifications\",true]],{\"statements\":[[0,\"        \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"hasModalOverlay\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"modals/modal-overlay\",null,[[\"toggle\",\"modalClassNames\"],[[24,[\"isShowing\"]],\"u-z-index--3000\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/right-bar/template.hbs"
    }
  });

  _exports.default = _default;
});