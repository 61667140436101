define("client/pods/components/elements/element-select/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ElementsElementSelect = (_dec = Ember._action, (_class = class ElementsElementSelect extends _component.default {
    get optionLabelKey() {
      return R.pathOr('label', ['optionLabelKey'])(this.args);
    }

    get optionValueKey() {
      return R.pathOr('value', ['optionValueKey'])(this.args);
    }

    get translate() {
      return R.pathOr(false, ['translate'])(this.args);
    }

    get hasEmpty() {
      return R.pathOr(false, ['hasEmpty'])(this.args);
    }

    get _options() {
      const options = R.pathOr([], ['options'])(this.args);
      return R.map(option => {
        if (R.is(Object, option)) {
          return option;
        }

        return Ember.Object.create({
          label: option,
          value: option
        });
      })(options);
    }

    select(e) {
      const selectedOption = R.pipe(R.pathOr([], ['target', 'selectedOptions']), R.head)(e);
      let selected = '';

      if (selectedOption) {
        selected = R.propOr('', 'value')(selectedOption);
      }

      return this.args?.onSelect?.(selected);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "select", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype)), _class));
  _exports.default = ElementsElementSelect;
});