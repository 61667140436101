define("client/pods/components/channels/shop/campaigns/campaigns-editor/commissions-editor/commission-base-cost-table/commission-base-cost-row/details/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRowDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRowDetails extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "big", _descriptor3, this);

      _initializerDefineProperty(this, "campaignsProducts", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "settings", _descriptor6, this);

      _initializerDefineProperty(this, "productList", _descriptor7, this);

      _initializerDefineProperty(this, "fillerType", _descriptor8, this);

      _defineProperty(this, "productsFilters", null);

      _defineProperty(this, "productsFiltersAdapterName", 'products/filters');

      this.crud.addLists(this, ['channels/shop/campaigns/lists/commission-filler']);
      let excludes = [];

      if (this.isAutomations) {
        excludes = [{
          key: 'setType',
          value: 'isSetWithChoices'
        }, {
          key: 'setType',
          value: 'hasVariants'
        }];
      }

      const productsFilters = this.search.setupFilters({
        adapterName: this.productsFiltersAdapterName,
        defaultFiltersData: {
          resultsProperty: 'productsList',
          excludes,
          count: 20,
          page: 1
        }
      });
      this.productsFilters = productsFilters;
      this.productList = [];

      if (this?.args?.model && !this.args.isPresetTableView && !R.equals(this?.args?.tableViewOptions?.selectedCommissionBaseCostMethod, 'byBaseCost')) {
        this.args.tableViewOptions.recalculateCommissionTotal({
          useExclAmt: this.useExclAmt,
          item: this?.args?.model,
          isFromCommissionAmount: true
        });
      }

      this.commissionErrorMsg = this.intl.t('commission_adjustment_error_msg');
      const autoFillCommissionsFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/commission-base-cost/history/filters'
      });
      this.autoFillCommissionsFilters = autoFillCommissionsFilters;
    }

    autoFillCommissionsTask(_ref) {
      var _this = this;

      let {
        type = 'commissions'
      } = _ref;
      return function* () {
        let commissionPropertyName = _this.commissionPropertyName;
        const isPresetTableView = _this.args.isPresetTableView;
        const isUsingAverage = _this?.args?.tableViewOptions?.isUsingAverage || true;
        const salesPersonKey = _this?.args?.tableViewOptions?.salesPersonKey || '';
        const autoFillCommissionsFilters = _this.autoFillCommissionsFilters;
        autoFillCommissionsFilters.setData('isUsingAverage', isUsingAverage);
        autoFillCommissionsFilters.setData('salesPersonKey', salesPersonKey);
        const selectedCommissionBaseCostMethod = _this?.args?.tableViewOptions?.selectedCommissionBaseCostMethod || 'normalPercent';

        if (R.equals(type, 'commissions')) {
          autoFillCommissionsFilters.setData('selectedCommissionBaseCostMethod', selectedCommissionBaseCostMethod);
        }

        if (R.equals(type, 'adjustedShippingFee')) {
          const hasAdjustedShippingFee = R.pathOr(false, ['args', 'tableViewOptions', 'hasAdjustedShippingFee'])(_this);
          const isCalculatedByExclAmt = R.pathOr(true, ['args', 'tableViewOptions', 'isCalculatedByExclAmt'])(_this);
          autoFillCommissionsFilters.setData('type', 'adjustedShippingFee');
          autoFillCommissionsFilters.setData('hasAdjustedShippingFee', hasAdjustedShippingFee);
          autoFillCommissionsFilters.setData('isCalculatedByExclAmt', isCalculatedByExclAmt);
          commissionPropertyName = 'adjustedShippingFee';
        }

        const filterResults = _this?.args?.results || [];
        let q = [];
        R.forEach(result => {
          const itemKey = result.getData('itemKey');

          const autoFillCommissionsFilterCopy = _this.search.copyFilters({
            adapterName: 'channels/shop/campaigns/details/commission-base-cost/history/filters',
            filters: autoFillCommissionsFilters
          });

          autoFillCommissionsFilterCopy.setData('itemKey', itemKey);
          let taxRate = result?._data?.taxRate;

          if (RA.isNilOrEmpty(taxRate)) {
            const taxRateFromSettings = _this?.settings?.settingsModel?._data?.taxRate || 0;
            taxRate = taxRateFromSettings;
          }

          autoFillCommissionsFilterCopy.setData('taxRate', taxRate);
          const hasMultipleTaxRate = R.pathOr(false, ['_data', 'hasMultipleTaxRate'])(result);

          if (R.equals(type, 'adjustedShippingFee') && hasMultipleTaxRate) {
            q.push({});
            return;
          }

          q.push(_this.crud.searchRecordsTask.perform({
            adapterName: 'channels/shop/campaigns/details/commission-base-cost/history',
            filters: autoFillCommissionsFilterCopy,
            rawResponse: true
          }));
        })(filterResults);
        const allResults = yield (0, _emberConcurrency.all)(q);
        q = [];

        if (RA.isNotNilOrEmpty(allResults) && R.equals(R.length(allResults), R.length(filterResults))) {
          RA.mapIndexed((result, index) => {
            if (RA.isNotNilOrEmpty(allResults[index])) {
              let commissionBaseCostNew;

              if (isPresetTableView) {
                commissionBaseCostNew = result.commissionBaseCostNew;

                if (RA.isNilOrEmpty(commissionBaseCostNew.getData(commissionPropertyName))) {
                  const autoFillValue = allResults[index][commissionPropertyName] || '';
                  commissionBaseCostNew.setData(commissionPropertyName, autoFillValue);
                  q.push(_this.args.tableViewOptions.saveCommissionBaseCostNewModelTask.perform({
                    model: commissionBaseCostNew,
                    campaignsDetailsModel: result,
                    selectedCommissionBaseCostMethod,
                    isUsingAverage
                  }));
                }
              } else {
                commissionBaseCostNew = result;

                if (RA.isNilOrEmpty(commissionBaseCostNew.getData(commissionPropertyName))) {
                  const autoFillValue = allResults[index][commissionPropertyName] || '';
                  commissionBaseCostNew.setData(commissionPropertyName, autoFillValue);
                }
              }
            }
          })(filterResults);

          if (RA.isNilOrEmpty(q) && !isPresetTableView) {
            _this.args.tableViewOptions.recalculateAdjustmentTable();
          } else {
            yield (0, _emberConcurrency.all)(q);
          }
        }
      }();
    }

    setAllCommissionsTask(_ref2) {
      var _this2 = this;

      let {
        model,
        commissionPropertyName
      } = _ref2;
      return function* () {
        const fillerType = model.get('fillerType');
        const fillerValue = model.getData(commissionPropertyName);
        const selectedCommissionBaseCostMethod = _this2?.args?.tableViewOptions?.selectedCommissionBaseCostMethod || 'normalPercent';
        const isUsingAverage = _this2?.args?.tableViewOptions?.isUsingAverage || true;
        const itemKey = model.getData('itemKey');
        const filterResults = _this2?.args?.results || [];
        const modelIndex = R.findIndex(R.pathEq(['_data', 'itemKey'], itemKey))(filterResults);
        const isPresetTableView = _this2.args.isPresetTableView;
        const q = [];
        let commissionBaseCostNew;
        RA.mapIndexed((result, index) => {
          commissionBaseCostNew = result;

          if (isPresetTableView) {
            commissionBaseCostNew = result.commissionBaseCostNew;
          }

          if ((R.equals(fillerType, 'fillTop') && R.lt(index, modelIndex) || R.equals(fillerType, 'fillBottom') && R.gt(index, modelIndex) || R.equals(fillerType, 'fillTopAndBottom')) && RA.isNilOrEmpty(commissionBaseCostNew.getData(commissionPropertyName))) {
            commissionBaseCostNew.setData(commissionPropertyName, fillerValue);

            if (isPresetTableView) {
              q.push(_this2.args.tableViewOptions.saveCommissionBaseCostNewModelTask.perform({
                model: commissionBaseCostNew,
                campaignsDetailsModel: result,
                selectedCommissionBaseCostMethod,
                isUsingAverage
              }));
            }
          }
        })(filterResults);

        if (RA.isNilOrEmpty(q) && !isPresetTableView) {
          _this2.args.tableViewOptions.recalculateAdjustmentTable();
        } else {
          yield (0, _emberConcurrency.all)(q);
        }

        Ember.set(model, 'fillerType', '');
        Ember.set(model, 'fillerLabel', '');
      }();
    }

    get commissionPropertyName() {
      const adjustmentModel = this?.args?.tableViewOptions?.adjustmentModel;
      let selectedCommissionBaseCostMethod;

      if (adjustmentModel) {
        selectedCommissionBaseCostMethod = adjustmentModel.getData('selectedCommissionBaseCostMethod') || '';
      } else {
        selectedCommissionBaseCostMethod = this.args.tableViewOptions.selectedCommissionBaseCostMethod;
      }

      if (R.equals(selectedCommissionBaseCostMethod, 'normalPercent')) {
        return 'normalPercent';
      }

      if (R.equals(selectedCommissionBaseCostMethod, 'twPercent')) {
        return 'twPercent';
      }

      if (R.equals(selectedCommissionBaseCostMethod, 'byBaseCost')) {
        return 'byBaseCost';
      }

      if (R.equals(selectedCommissionBaseCostMethod, 'byAmount')) {
        return 'byAmount';
      }

      return '';
    }

    get totalPrice() {
      const model = this?.args?.model || {};
      const isPresetTableView = R.pathOr(false, ['args', 'isPresetTableView'])(this);
      const adjustedShippingFee = this.big.newBig(model?._data?.adjustedShippingFee || 0);
      let totalPriceProp;

      if (!isPresetTableView) {
        let qty = model?._data?.commissionQty || '';

        if (RA.isNilOrEmpty(qty)) {
          qty = model?._data?.qty || 0;
        }

        const qtyBig = this.big.newBig(qty);
        const useExclAmt = this.useExclAmt;
        let amountBig = this.big.newBig(0);

        if (useExclAmt) {
          let exclAmt = model?._data?.discExclPriceExclShipping || '';

          if (RA.isNilOrEmpty(exclAmt)) {
            exclAmt = model?._data?.discExclPrice || '';
          }

          if (RA.isNilOrEmpty(exclAmt)) {
            exclAmt = model?._data?.exclAmt || '';
          }

          amountBig = this.big.newBig(exclAmt);
        } else {
          let inclAmt = model?._data?.discInclPriceExclShipping || '';

          if (RA.isNilOrEmpty(inclAmt)) {
            inclAmt = model?._data?.discInclPrice || '';
          }

          if (RA.isNilOrEmpty(inclAmt)) {
            inclAmt = model?._data?.inclAmt || '';
          }

          amountBig = this.big.newBig(inclAmt);
        }

        amountBig = amountBig.minus(adjustedShippingFee);
        totalPriceProp = amountBig.times(qtyBig);
        return this.big.autoRound(0, totalPriceProp.toFixed());
      }
    }

    get useExclAmt() {
      let useExclAmt = this?.args?.tableViewOptions?.adjustmentModel?._data?.isCalculatedByExclAmt;

      if (RA.isNilOrEmpty(useExclAmt)) {
        useExclAmt = true;
      }

      return useExclAmt;
    }

    get isInputReadonly() {
      if (this?.args?.isChoices) {
        return true;
      }

      if (this?.args?.model?._data?.isSetWithChoices) {
        return true;
      }

      if (this?.args?.model?._data?.hasVariants) {
        return true;
      } // if (this?.args?.model?._data?.isSet && !this?.args?.model?._data?.isSetChild) {
      //   return true
      // }

    }

    loadItem(item, product) {
      item.setData('item', product.getData('name'));
    }

    unloadItem(item, product) {
      item.setData('item', '');
    }

    removeAdjustmentRow(item, onAfter) {
      const details = this?.args?.tableViewOptions?.details || [];

      if (RA.isNotNilOrEmpty(details)) {
        details.removeObject(item);
      }

      if (onAfter) {
        onAfter();
      }
    }

    searchProductsTask(_ref3) {
      var _this3 = this;

      let {
        adapterName = 'products',
        query
      } = _ref3;
      return function* () {
        if (query?.trim?.() !== '') {
          let campaignKey;

          if (_this3.args.isPresetTableView) {
            campaignKey = _this3.args.campaignKey;
          } else {
            campaignKey = _this3.args.tableViewOptions.campaignKey;
          }

          const filters = _this3.productsFilters;
          const results = yield _this3.crud.searchInputTask.perform({
            adapterName,
            appendPath: `/campaigns/${campaignKey}`,
            filters
          }, query);
          return results;
        }
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "big", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "campaignsProducts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "productList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fillerType", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "autoFillCommissionsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "autoFillCommissionsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setAllCommissionsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setAllCommissionsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadItem", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "loadItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadItem", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "unloadItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAdjustmentRow", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "removeAdjustmentRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchProductsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchProductsTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsCampaignsEditorCommissionsEditorCommissionBaseCostTableCommissionBaseCostRowDetails;
});