define("client/pods/components/grapesjs/form-modal/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GrapesjsFormModalComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class GrapesjsFormModalComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "filters", _descriptor3, this);

      _initializerDefineProperty(this, "selectedForm", _descriptor4, this);

      _initializerDefineProperty(this, "formFlows", _descriptor5, this);

      _initializerDefineProperty(this, "selectedFormValue", _descriptor6, this);

      _defineProperty(this, "adapterName", 'flows');

      _defineProperty(this, "flowsFiltersAdapterName", 'flows/filters');

      _defineProperty(this, "gjsPreviewBodyStyle", {
        body: {
          margin: '30px !important',
          height: '100%',
          'background-color': '#fff'
        }
      });

      this.filters = this.search.setupFilters({
        adapterName: this.flowsFiltersAdapterName,
        defaultFiltersData: {
          websiteKey: this.args?.websiteKey,
          shopKey: this.args?.shopKey,
          type: 'form'
        }
      });
      this.getDefaultsDetails.perform();
    }

    *getDefaultsDetails() {
      const bigordrformComponents = this.args?.editor?.getSelected();
      const selectedFormKey = bigordrformComponents?.getAttributes()['data-gjs-form-key'];
      const hasSelectedForm = R.pipe(R.isEmpty, R.not)(selectedFormKey);

      if (hasSelectedForm) {
        yield this.fetchFormFlowsTask.perform(selectedFormKey);
        return;
      }

      yield this.fetchFormFlowsTask.perform();
    } // @restartableTask
    // * searchInputTask (query) {
    //   yield this.crud.searchInputTask.perform(
    //     {
    //       adapterName: this.adapterName,
    //       filters: this.filters,
    //       resultsProperty: 'flows',
    //       component: this
    //     }, 
    //     query
    //   )
    // }


    fetchFormFlowsTask() {
      var _this = this;

      let selectedFormKey = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return function* () {
        const getFormFlows = yield _this.crud.searchRecordsTask.perform({
          adapterName: _this.adapterName,
          filters: _this.filters
        });
        _this.formFlows = getFormFlows;

        const isSelectedFormKey = record => R.pathEq(['_data', '_key'], selectedFormKey, record);

        _this.selectedForm = R.filter(isSelectedFormKey, getFormFlows)[0];

        if (RA.isNotNilOrEmpty(_this.selectedForm)) {
          _this.selectedFormValue = _this.selectedForm?._data?.name || '';
        }
      }();
    }

    onSelect(formFlow) {
      this.selectedForm = formFlow;
      this.selectedFormValue = this.selectedForm?._data?.name || '';
    }

    setForm(onCloseDialogAction) {
      const editor = this.args?.editor;
      const selectedComponent = editor?.getSelected();
      const formKey = this.selectedForm?.getData('_key');
      const formName = this.selectedForm?._data?.name;
      const updatedAttributes = {
        'data-gjs-form-key': formKey,
        'data-gjs-form-name': formName,
        content: formName
      };
      selectedComponent.setAttributes(updatedAttributes);
      this.selectedForm = onCloseDialogAction();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedForm", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formFlows", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedFormValue", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getDefaultsDetails", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getDefaultsDetails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchFormFlowsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchFormFlowsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setForm", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setForm"), _class.prototype)), _class));
  _exports.default = GrapesjsFormModalComponent;
});