define("client/pods/components/admin/extensions/apps/tw/e-invoices/tab-container/component", ["exports", "client/mixins/date", "client/mixins/big", "client/pods/components/extensions/apps/tw/e-invoices/mixins/invoices", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _big, _invoices, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _invoices.default, _big.default, _date.default, {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    model: null,
    errors: null,
    customDateZ: '',
    adapterName: 'admin/extensions/apps/tw/e-invoices',
    filtersAdapterName: 'admin/extensions/apps/tw/e-invoices/filters',
    selectedProvider: 'bigOrdr',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['periods/months', 'periods/years', 'extensions/apps/tw/e-invoices/lists/providers']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.set('filters', filters);
      const pastDays = this.getPastDays(14);
      this.set('pastDays', pastDays);
      const pastMonths = this.getPastMonths(14);
      this.set('pastMonths', pastMonths);
    },

    afterSearchInputTask(results) {
      this.set('hasStatement', false);
      return results;
    },

    displaySummaryPanel(period) {
      const subTabOptions = {
        component: 'admin/extensions/apps/tw/e-invoices/panels-display',
        period
      };
      this.set('hasStatement', true);
      const tab = this.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    },

    checkPendingEInvoicesTask: (0, _emberConcurrency.task)(function* () {
      if (window.confirm('check all pending?')) {
        yield this.server.call('POST', 'api/protected/admin/einvoices/pending');
      }
    }),
    checkPendingSchedulesTask: (0, _emberConcurrency.task)(function* () {
      if (window.confirm('check all pending?')) {
        yield this.server.call('POST', 'api/protected/admin/schedules/pending');
      }
    }),
    actions: {
      displayByMonth(period) {
        const dateStart = this.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(period.yyyy, period.mm)); // this.get('displaySummaryPanelTask').perform({ dateEnd, dateStart })

        this.displaySummaryPanel({
          dateEnd,
          dateStart
        });
        this.set('mm', period.mm);
        this.set('yyyy', period.yyyy);
      },

      displayByCustomMonth(mm, yyyy) {
        const dateStart = this.formatDateForUrl(`${yyyy}-${mm}-01`);
        const dateEnd = this.formatDateForUrl(this.getMonthEnd(yyyy, mm)); // this.get('displaySummaryPanelTask').perform({ dateEnd, dateStart })

        this.displaySummaryPanel({
          dateEnd,
          dateStart
        });
      }

    }
  });

  _exports.default = _default;
});