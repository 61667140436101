define("client/pods/wizards/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct"], function (_exports, _model, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WizardsModel = (_dec = Ember.inject.service, _dec2 = Ember.computed('synced', 'crud.lists.application-lists-wizards.[]'), (_class = class WizardsModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);
    }

    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data.collection = this._transformCollection(data);
      this._data = data;
    }

    _transformCollection(data) {
      return R.pipe(R.propOr([], 'collection'), R.map(menuData => {
        menuData.isPinned = true;
        return this.dataManager.setAsRecord({
          adapterName: 'members-menu',
          data: menuData
        });
      }))(data);
    }

    get allowedUserMenu() {
      const allowed = R.pathOr([], ['crud', 'lists', 'application-lists-wizards'])(this);
      const collection = R.pathOr([], ['_data', 'collection'])(this);
      const allPinned = R.pipe(R.map(menuItem => {
        if (menuItem?._data?.isFinished === false) {
          menuItem._data.isPinned = true;
        }

        return menuItem;
      }), R.filter(menuItem => menuItem?._data?.isPinned))(collection);
      const userWizardsMenu = this.applicationService.processMenu({
        allowed,
        allPinned
      }) || [];
      const wizardsData = {
        label: 'wizards',
        pinLabel: 'wizards',
        translate: true,
        isExpanded: true,
        icon: 'fas fa-book',
        sub: userWizardsMenu
      };
      const wizardsMenu = this.crud.setupNewRecord({
        adapterName: 'members-menu',
        data: wizardsData
      });
      return RA.list(wizardsMenu);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "allowedUserMenu", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "allowedUserMenu"), _class.prototype)), _class));
  _exports.default = WizardsModel;
});