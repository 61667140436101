define("client/pods/components/transporters/payuni-seven-eleven-waybill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ufcAaB86",
    "block": "{\"symbols\":[\"@errors\",\"@isReadonly\",\"@tab\",\"@onSetWaybillsPerPage\",\"@waybillsHtml\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"waybills per page\",[23,1,[]],\"sevenEleven.waybillsPerPage\",\"sevenElevenPayOnCheckoutShopId\"]],{\"statements\":[[0,\"\\n  \"],[5,\"lists/list-dropdown\",[],[[\"@disabled\",\"@list\",\"@isBtn\",\"@itemLabelKey\",\"@itemValueKey\",\"@readonly\",\"@tab\",\"@translate\",\"@value\",\"@isRunning\",\"@onSelect\"],[[23,2,[]],[23,0,[\"crud\",\"lists\",\"extensions-apps-tw-seven-eleven-lists-waybill-print-per-page\"]],true,\"_data.label\",\"_data.value\",[23,2,[]],[23,3,[]],false,[23,0,[\"waybillsPerPage\"]],[23,0,[\"fetchTransporter\",\"isRunning\"]],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"transporters/iframe-waybills\",[],[[\"@waybillsHtml\"],[[23,5,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/payuni-seven-eleven-waybill/template.hbs"
    }
  });

  _exports.default = _default;
});