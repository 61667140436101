define("client/pods/components/channels/shop/items-import-btn/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda-adjunct", "ramda-extension", "ramda", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, RA, R_, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopItemsLimitsImportBtnComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = (0, _emberConcurrencyDecorators.enqueueTask)({
    maxConcurrency: 3
  }), _dec26 = Ember._action, _dec27 = Ember._action, (_class = class ChannelsShopItemsLimitsImportBtnComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "excel", _descriptor3, this);

      _initializerDefineProperty(this, "export", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "search", _descriptor6, this);

      _initializerDefineProperty(this, "server", _descriptor7, this);

      _initializerDefineProperty(this, "hasUnsuccessful", _descriptor8, this);

      _initializerDefineProperty(this, "hasSuccessful", _descriptor9, this);

      _initializerDefineProperty(this, "isImported", _descriptor10, this);

      _initializerDefineProperty(this, "isPaidImported", _descriptor11, this);

      _initializerDefineProperty(this, "isShowImportModal", _descriptor12, this);

      _initializerDefineProperty(this, "sheets", _descriptor13, this);

      _initializerDefineProperty(this, "selectedSheets", _descriptor14, this);

      _initializerDefineProperty(this, "showImportPaidModal", _descriptor15, this);

      _initializerDefineProperty(this, "successful", _descriptor16, this);

      _initializerDefineProperty(this, "successfulImportData", _descriptor17, this);

      _initializerDefineProperty(this, "templateFilename", _descriptor18, this);

      _initializerDefineProperty(this, "importData", _descriptor19, this);

      _initializerDefineProperty(this, "importedSheetHeaders", _descriptor20, this);

      _initializerDefineProperty(this, "unsuccessful", _descriptor21, this);

      _initializerDefineProperty(this, "unsuccessfulImportData", _descriptor22, this);

      this.crud.addLists(this, ['channels/shop/campaigns/lists/import/price/specs']);
      this.resetImportData();
    }

    get specsForImportDataHeaders() {
      const specsForImportData = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-price-specs')(this) || [];
      return R.pipe(R.pluck('_data'), R.pluck('headerValue'), R.reject(_nventor.default.isNilOrEmpty))(specsForImportData);
    }

    get specsForImportDataValues() {
      const specsForImportData = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-price-specs')(this) || [];
      return R.pipe(R.pluck('_data'), R.pluck('value'), R.reject(_nventor.default.isNilOrEmpty))(specsForImportData);
    }

    resetImportData() {
      this.importData = '';
      this.successful = [];
      this.importedSheetHeaders = [];
      this.hasUnsuccessful = false;
      this.unsuccessfulImportData = [];
      this.isImported = false;
    }

    zipHeadersWithRowData(headers, row) {
      const specsForImportData = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-price-specs')(this) || [];
      const importDataHeaders = R.pluck('value')(headers);
      const rowData = {};

      _nventor.default.mapIndexed((header, index) => {
        if (!R.isEmpty(header)) {
          let value = row[index];
          const spec = R.find(R.pathEq(['_data', 'value'], header))(specsForImportData);

          if (spec?._data?.transform) {
            value = spec._data?.transform(value, row, header, this.intl);
          }

          rowData[header] = value;
        }
      })(importDataHeaders);

      return rowData;
    }

    getSheetData(sheetObj) {
      const sheetData = R.propOr([], 'data', sheetObj);
      const sheetHeaders = R.propOr([], 'headers', sheetObj);
      return R.pipe(R.map(row => {
        const rowData = this.zipHeadersWithRowData(sheetHeaders, row);
        return rowData;
      }))(sheetData);
    }

    *importDataTask() {
      const filteredWorkbook = this.filteredWorkbook;
      const specsForImportData = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-price-specs')(this) || [];
      let sheetHeaders = [];
      const importData = R.pipe(R.mapObjIndexed(sheetObj => {
        if (RA.isNilOrEmpty(sheetHeaders)) {
          sheetHeaders = R.propOr([], 'headers', sheetObj);
        }

        return this.getSheetData(sheetObj);
      }), R.values, R.flatten)(filteredWorkbook);
      sheetHeaders = R.pipe(R.map(header => {
        const spec = R.find(R.pathEq(['_data', 'value'], header.value))(specsForImportData);

        if (spec) {
          header.label = spec?._data?.label;
          return header;
        }

        return false;
      }), R.reject(R.equals(false)))(sheetHeaders);
      this.importedSheetHeaders = sheetHeaders;
      const groupedImportData = R.pipe(R.map(row => {
        const index = row?.index || '';
        row.indexHead = R.pipe(index => {
          if (!R.is(String, index)) {
            return R.toString(index);
          }

          return index;
        }, R.split('.'), R.head)(index);
        row.isMain = !R.includes('.')(index);
        return row;
      }), R.groupBy(R.prop('indexHead')), R.values)(importData);
      let fullResponse = {
        successful: [],
        unsuccessful: []
      };
      const q = [];
      const path = 'api/protected/channels/shop/campaigns/details/import';
      R.pipe(R.splitEvery(50), R.map(importDataChunk => {
        q.push(this.queueUpdateFromImportTask.perform({
          path,
          data: importDataChunk
        }));
      }))(groupedImportData);
      yield (0, _emberConcurrency.all)(q).then(responses => {
        R.forEach(response => {
          const successful = R.pathOr([], ['data', 'successful'])(response);
          const unsuccessful = R.pathOr([], ['data', 'unsuccessful'])(response);
          fullResponse.successful = R.concat(successful, fullResponse.successful || []);
          fullResponse.unsuccessful = R.concat(unsuccessful, fullResponse.unsuccessful || []);
        })(responses);
      });
      const specsForImportDataValues = this.specsForImportDataValues;

      try {
        this.isImported = true;
        const unsuccessful = R.pipe(R.pathOr([], ['unsuccessful']), R.sortWith([R.ascend(R.prop('index'))]), R.map(row => {
          row.cells = R.values(R.pickAll(specsForImportDataValues, row));
          return row;
        }), R.values)(fullResponse);

        if (unsuccessful.length > 0) {
          this.hasUnsuccessful = true;
          this.unsuccessfulImportData = unsuccessful;
        }

        const successful = R.pipe(R.pathOr([], ['successful']), R.sortWith([R.ascend(R.prop('index'))]), R.map(resp => {
          const row = R.propOr({}, 'model')(resp);
          row.cells = R.values(R.pickAll(specsForImportDataValues, row));
          return row;
        }), R.values)(fullResponse);
        this.successful = successful;

        if (successful.length > 0) {
          this.hasSuccessful = true;
          this.successfulImportData = successful;
        }
      } catch (err) {
        this.isImported = false;
        this.crud.setValidationErrors('errors', err, this);
      }
    }

    queueUpdateFromImportTask(_ref) {
      var _this = this;

      let {
        path,
        data
      } = _ref;
      return function* () {
        const payload = {
          groupedBatch: data,
          master: _this.args.model.getData('_key')
        };
        return yield _this.server.callJobs('PATCH', path, payload);
      }();
    }

    get importParamValues() {
      const specs = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-price-specs')(this) || [];
      const values = R.pipe(R.map(R.path(['_data', 'value'])), R.reject(RA.isNilOrEmpty))(specs);
      return values;
    }

    get toDisplayImportData() {
      if (!this.isImported) {
        return this.importData || [];
      }

      return this.unsuccessfulImportData;
    }

    get successfulCount() {
      const data = this.successful || [];
      return data.length;
    }

    get unsuccessfulCount() {
      const data = this.unsuccessfulImportData || [];
      return data.length;
    }

    get sheetNames() {
      const workbook = this.workbook || {};
      const sheetNames = R.keys(workbook) || [];
      return R.map(sheetName => {
        return Ember.Object.create({
          value: sheetName,
          label: sheetName
        });
      })(sheetNames);
    }

    get workbook() {
      const sheets = this.sheets || {};
      const name = R.pathOr([], [0, 'specsForImportDataHeaders'])(this);
      return R.mapObjIndexed(sheetData => {
        const sheetObject = Ember.Object.create();
        sheetObject.set('headers', this.matchDataWithHeaders(sheetData));
        sheetData = R.reject(row => {
          if (R.includes(name, row)) {
            return true;
          }

          return false;
        })(sheetData);
        sheetObject.set('data', sheetData);
        return sheetObject;
      })(sheets);
    }

    get filteredWorkbook() {
      const sheets = this.workbook || {};
      const selectedSheets = this.selectedSheets || [];
      const allowedSheets = {};
      R.mapObjIndexed((sheetObject, sheetName) => {
        if (R.includes(sheetName, selectedSheets)) {
          allowedSheets[sheetName] = sheetObject;
          return sheetObject;
        }
      })(sheets);
      return allowedSheets;
    }

    matchDataWithHeaders(data) {
      if (data.length > 0) {
        const importDataHeaders = R.head(data);
        const allowedHeaders = R_.dotPath('crud.lists.channels-shop-campaigns-lists-import-price-specs')(this) || [];
        return R.map(importDataHeader => {
          importDataHeader = R.trim(`${importDataHeader}`);
          const found = R.find(R.pathEq(['_data', 'headerValue'], importDataHeader))(allowedHeaders);
          const value = R.pathOr('', ['_data', 'value'], found);
          return Ember.Object.create({
            value
          });
        })(importDataHeaders);
      }

      return false;
    }

    showImportModal(sheets) {
      this.sheets = sheets;
      const sheetNames = this.sheetNames || [];
      const selectedSheets = R.map(sheetName => {
        return sheetName.value;
      }, sheetNames);
      this.selectedSheets = selectedSheets;
      this.isShowImportModal = true;
    }

    closeImportModal() {
      const hasSuccessful = R.pipe(R.propOr([], 'successful'), RA.isNotNilOrEmpty)(this);

      if (this.isShowImportModal) {
        this.isShowImportModal = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "excel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "export", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hasUnsuccessful", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hasSuccessful", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isImported", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isPaidImported", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isShowImportModal", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "sheets", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "selectedSheets", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "showImportPaidModal", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "successful", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "successfulImportData", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "templateFilename", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "importData", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "importedSheetHeaders", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "unsuccessful", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "unsuccessfulImportData", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "resetImportData", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "resetImportData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "zipHeadersWithRowData", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "zipHeadersWithRowData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "importDataTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "importDataTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "queueUpdateFromImportTask", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "queueUpdateFromImportTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showImportModal", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "showImportModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeImportModal", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "closeImportModal"), _class.prototype)), _class));
  _exports.default = ChannelsShopItemsLimitsImportBtnComponent;
});