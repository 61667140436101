define("client/pods/components/files/uploadcare-widget/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z29ba8SW",
    "block": "{\"symbols\":[],\"statements\":[[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[28,\"not\",[[23,0,[\"didFetchSecureToken\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"input\",false],[12,\"role\",\"uploadcare-uploader\"],[12,\"name\",[22,\"name\"]],[12,\"data-public-key\",[22,\"publicKey\"]],[12,\"data-multiple\",[22,\"multiple\"]],[12,\"data-multiple-max\",[22,\"multipleMax\"]],[12,\"data-multiple-min\",[22,\"multipleMin\"]],[12,\"data-images-only\",[22,\"imagesOnly\"]],[12,\"data-preview-step\",\"false\"],[12,\"data-crop\",[22,\"crop\"]],[12,\"data-image-shrink\",[22,\"imageShrink\"]],[12,\"data-clearable\",[22,\"clearable\"]],[12,\"data-secure-signature\",[23,0,[\"uploadcare\",\"secureSignature\"]]],[12,\"data-secure-expire\",[23,0,[\"uploadcare\",\"secureExpire\"]]],[12,\"data-tabs\",[22,\"tabs\"]],[12,\"type\",\"hidden\"],[3,\"did-insert\",[[23,0,[\"initWidget\"]]]],[8],[9],[0,\"\\n\\n  \"],[1,[28,\"elements/element-btn\",null,[[\"label\",\"translate\",\"icon\",\"onSubmit\"],[[24,[\"label\"]],[24,[\"translate\"]],[24,[\"icon\"]],[28,\"action\",[[23,0,[]],\"triggerUploadBtn\"],null]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/files/uploadcare-widget/template.hbs"
    }
  });

  _exports.default = _default;
});