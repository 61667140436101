define("client/pods/components/dispatch/import-btn/import-dispatched/component", ["exports", "ember-concurrency", "client/mixins/crud", "client/mixins/date", "client/mixins/transporter", "client/utils/nventor", "ramda", "ramda-extension", "ramda-adjunct"], function (_exports, _emberConcurrency, _crud, _date, _transporter, _nventor, R, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, _date.default, _transporter.default, (_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    excel: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    tagName: 'span',
    showImportModal: false,
    showImportPaidModal: false,
    isImported: false,
    isPaidImported: false,
    templateFilename: '',
    sheets: null,
    successful: null,
    unsuccessful: null,
    nextDispatchProcessCode: 'dispatched',
    nextDispatchProcessLabel: 'dispatched',
    nextDispatchProcessLabelTranslate: true,
    isDispatched: true,
    autoShowPrintAllDialogDispatched: false,
    autoShowPrintAllDialog: true,

    init() {
      this._super(...arguments);

      this.set('importTemplate', [{
        headerValue: '收貨人',
        prop: 'contact',
        required: true
      }, {
        headerValue: '收貨人電話',
        prop: 'telephone',
        required: true
      }, {
        headerValue: '收貨人地址',
        prop: 'address',
        required: true
      }, {
        // headerValue: '付款方式',
        headerValue: this.intl.t('payment method'),
        prop: 'paymentMethodKey'
      }, {
        // headerValue: '總計',
        headerValue: this.intl.t('total'),
        prop: 'inclTotal'
      }, {
        // headerValue: '備註',
        headerValue: this.intl.t('notes'),
        prop: 'notes'
      }, {
        // headerValue: '參照編號',
        headerValue: this.intl.t('ref'),
        prop: 'ref'
      }, {
        headerValue: '#',
        prop: 'docNo'
      }, {
        headerValue: '託運單號',
        prop: 'waybill',
        required: true
      }, {
        headerValue: this.intl.t('transporter'),
        prop: 'transporterLabel',
        required: true
      }, {
        headerValue: this.intl.t('transporter account'),
        prop: 'transporterAccountNumber',
        required: true
      }]);
      this.crud.addLists(this, ['documents/lists/import/dispatched/specs']);
      const templateFilename = this.intl.t('import_transporter_template');
      this.set('templateFilename', templateFilename);
      this.set('selectedSheets', Ember.Object.create({
        sheets: []
      }));
      this.set('importDispatchDateZ', this.getStartOfDayZ());
      this.resetImportData();
    },

    specsForImportDataHeaders: Ember.computed('crud.lists.documents-lists-import-dispatched-specs.[]', function () {
      const specsForImportData = R_.dotPath('crud.lists.documents-lists-import-dispatched-specs')(this) || [];
      return R.pipe(R.pluck('_data'), R.pluck('headerValue'), R.reject(_nventor.default.isNilOrEmpty))(specsForImportData);
    }),
    specsForImportDataValues: Ember.computed('crud.lists.documents-lists-import-dispatched-specs.[]', function () {
      const specsForImportData = R_.dotPath('crud.lists.documents-lists-import-dispatched-specs')(this) || [];
      return R.pipe(R.pluck('_data'), R.pluck('value'), R.reject(_nventor.default.isNilOrEmpty))(specsForImportData);
    }),

    resetImportData() {
      this.set('importData', '');
      this.set('successful', []);
      this.set('hasUnsuccessful', false);
      this.set('unsuccessfulImportData', []);
      this.set('isImported', false);
    },

    zipHeadersWithRowData(headers, row) {
      // const importDataHeaderObjects = this.get('importDataHeaders')
      const importDataHeaders = R.pluck('value')(headers);
      const rowData = {};

      _nventor.default.mapIndexed((header, index) => {
        if (!R.isEmpty(header)) {
          rowData[header] = row[index];
        }
      })(importDataHeaders);

      return rowData;
    },

    getSheetData(importDispatchDateZ, sheetObj) {
      const sheetData = R.propOr([], 'data', sheetObj);
      const sheetHeaders = R.propOr([], 'headers', sheetObj);
      const importTransporter = this.importTransporter; // const importTransporterKey = this.get('importTransporterKey')

      const importTransporterAccountNumber = this.importTransporterAccountNumber;
      return R.pipe(R.map(row => {
        const rowData = this.zipHeadersWithRowData(sheetHeaders, row);

        if (importTransporter && RA.isNilOrEmpty(rowData.transporterLabel)) {
          rowData.transporterLabel = importTransporter;
        }

        if (importTransporterAccountNumber && RA.isNilOrEmpty(rowData.transporterAccountNumber)) {
          rowData.transporterAccountNumber = importTransporterAccountNumber;
        }

        rowData.dispatchProcess = 'dispatched';
        rowData.isDispatched = true;

        if (!rowData.dispatchDateZ) {
          rowData.dispatchDateZ = importDispatchDateZ;
        }

        return rowData;
      }))(sheetData);
    },

    importDataTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        isImportDocumentStatus = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const filteredWorkbook = _this.filteredWorkbook;
        const importTransporter = _this.importTransporter;
        const importTransporterAccountNumber = _this.importTransporterAccountNumber;
        let importDispatchDateZ = _this.importDispatchDateZ;
        importDispatchDateZ = _this.getStartOfDayZ(importDispatchDateZ);
        const importData = R.pipe(R.mapObjIndexed(sheetObj => {
          return _this.getSheetData(importDispatchDateZ, sheetObj);
        }), R.values, R.flatten)(filteredWorkbook);

        const {
          path,
          payload
        } = _this.getImportDataPayload({
          importTransporter,
          importTransporterAccountNumber,
          importDispatchDateZ,
          importData,
          isImportDocumentStatus
        });

        const specsForImportDataValues = _this.specsForImportDataValues;

        try {
          const response = yield _this.server.callJobs('POST', path, payload);

          _this.set('isImported', true);

          const successful = R.pathOr([], ['data', 'successful'], response);

          _this.set('successful', successful);

          const unsuccessful = R.pipe(R.pathOr([], ['data', 'unsuccessful']), R.map(row => {
            row.cells = R.values(R.pick(specsForImportDataValues, row));
            return row;
          }), R.values)(response); // const unsuccessfulImportData = this.matchUnsuccessful(importData, unsuccessful) || []

          if (unsuccessful.length > 0) {
            _this.set('hasUnsuccessful', true);

            _this.set('unsuccessfulImportData', unsuccessful);
          }
        } catch (err) {
          _this.set('isImported', false);

          _this.setValidationErrors('errors', err);
        }
      }();
    }).drop(),
    importParamValues: Ember.computed('crud.lists.documents-lists-import-dispatched-specs.[]', function () {
      const specs = R_.dotPath('crud.lists.documents-lists-import-dispatched-specs')(this) || [];
      const values = R.pipe(R.map(R.path(['_data', 'value'])), R.reject(RA.isNilOrEmpty))(specs);
      return values;
    }),
    // matchUnsuccessful (importData, unsuccessful) {
    //   const allowedspecs = this.get('importParamValues')
    //   unsuccessful = R.map(unsuccessfulDoc => {
    //     const toMatchData = {}
    //     R.mapObjIndexed((docData, key) => {
    //       if (R.includes(key, allowedspecs)) {
    //         toMatchData[key] = docData
    //       }
    //       return docData
    //     }, unsuccessfulDoc)
    //     return toMatchData
    //   }, unsuccessful)
    //   return R.filter(row => {
    //     const rowData = this.zipHeadersWithRowData(row)
    //     let isUnsuccessful = false
    //     R.forEach(toMatchData => {
    //       if (!isUnsuccessful) {
    //         isUnsuccessful = R.equals(rowData, toMatchData)
    //       }
    //     }, unsuccessful)
    //     return isUnsuccessful
    //   })(importData)
    // },
    // toDisplayImportData: computed('isImported', 'importData.[]', 'unsuccessfulImportData.[]', function () {
    //   if (!this.get('isImported')) {
    //     return this.get('importData') || []
    //   }
    //   return this.get('unsuccessfulImportData')
    // }),
    successfulCount: Ember.computed('successful.[]', function () {
      const data = this.successful || [];
      return data.length;
    }),
    unsuccessfulCount: Ember.computed('unsuccessfulImportData.[]', function () {
      const data = this.unsuccessfulImportData || [];
      return data.length;
    }),
    createImportTemplateTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      const templateFilename = this.templateFilename;
      const importTemplate = this.get('importTemplate');
      const importTemplateHeaders = R.pluck('headerValue')(importTemplate);
      const worksheets = [];
      worksheets.push({
        sheetName: '物流資料',
        data: [importTemplateHeaders]
      });
      yield this.excel.exportFile({
        worksheets,
        title: templateFilename
      });
      onCloseDialogAction();
    }),

    createWorkbook() {
      const sheets = this.sheets || {};
      const name = this.specsForImportDataHeaders[0];
      return R.mapObjIndexed(sheetData => {
        const sheetObject = Ember.Object.create();
        sheetObject.set('headers', this.matchDataWithHeaders(sheetData));
        sheetData = R.reject(row => {
          if (R.includes(name, row)) {
            return true;
          }

          return false;
        })(sheetData);
        sheetObject.set('data', sheetData);
        return sheetObject;
      })(sheets);
    },

    filteredWorkbook: Ember.computed('sheets.[]', 'workbook', 'selectedSheets.sheets.[]', function () {
      const sheets = this.workbook || {};
      const selectedSheets = this.get('selectedSheets.sheets') || [];
      const allowedSheets = {};
      R.mapObjIndexed((sheetObject, sheetName) => {
        if (R.includes(sheetName, selectedSheets)) {
          allowedSheets[sheetName] = sheetObject;
          return sheetObject;
        }
      })(sheets);
      return allowedSheets;
    }),

    matchDataWithHeaders(data) {
      if (data.length > 0) {
        const importDataHeaders = R.head(data);
        const allowedHeaders = R_.dotPath('crud.lists.documents-lists-import-dispatched-specs')(this) || [];
        return R.map(importDataHeader => {
          importDataHeader = R.trim(`${importDataHeader}`);
          const found = R.find(R.pathEq(['_data', 'headerValue'], importDataHeader))(allowedHeaders);
          const value = R.pathOr('', ['_data', 'value'], found);
          return Ember.Object.create({
            value
          });
        })(importDataHeaders);
      }

      return false;
    },

    actions: {
      resetImportData() {
        this.resetImportData();
      },

      showImportModal(sheets) {
        this.set('sheets', sheets);
        const sheetNames = R.pipe(R.keys, R.map(sheetName => {
          return Ember.Object.create({
            value: sheetName,
            label: sheetName
          });
        }))(sheets);
        this.set('sheetNames', sheetNames);
        const workbook = this.createWorkbook();
        this.set('workbook', workbook);
        const selectedSheets = this.get('selectedSheets.sheets');
        R.forEach(sheetName => {
          selectedSheets.pushObject(sheetName.value);
        }, sheetNames);
        this.set('showImportModal', true);
      },

      closeImportModal(_ref) {
        let {
          refresh = false
        } = _ref;
        const hasSuccessful = R.pipe(R.propOr([], 'successful'), RA.isNotNilOrEmpty)(this);

        if (refresh && this.isImported && hasSuccessful) {
          const importDispatchDateZ = this.importDispatchDateZ || this.getStartOfDayZ();
          let period = this.getPeriod(importDispatchDateZ);
          let isDispatched = this.isDispatched;

          if (this.isDispatched) {
            this?.onRefreshDispatched?.(period, isDispatched, this.autoShowPrintAllDialog);
          } else {
            period = false;
            isDispatched = false;
            const nextDispatchProcessModel = this.nextDispatchProcessModel;
            this?.onRefresh?.(nextDispatchProcessModel, period, isDispatched, this.autoShowPrintAllDialog);
          }
        }

        this.set('showImportModal', false);
        this?.onCloseDialogAction?.();
      },

      closeImportModalDispatched(_ref2) {
        let {
          refresh = false
        } = _ref2;
        const hasSuccessful = R.pipe(R.propOr([], 'successful'), RA.isNotNilOrEmpty)(this);

        if (refresh && this.isImported && hasSuccessful) {
          const importDispatchDateZ = this.importDispatchDateZ || this.getStartOfDayZ();
          const period = this.getPeriod(importDispatchDateZ);

          if (this.isDispatched) {
            this?.onRefreshDispatched?.(period, this.isDispatched, this.autoShowPrintAllDialogDispatched);
          }
        }

        this.set('showImportModal', false);
        this?.onCloseDialogAction?.();
      },

      setDispatchData(dispatchProcessModel) {
        this.set('isDispatched', false);

        if (dispatchProcessModel?._data?.isDispatched) {
          const todayZ = this.dateService.getStartOfDayZ();
          this.set('importDispatchDateZ', todayZ);
          this.set('isDispatched', true);
        }

        this.set('nextDispatchProcessLabel', dispatchProcessModel?._data?.name);
        this.set('nextDispatchProcessLabelTranslate', dispatchProcessModel?._data?.translate);
        this.set('nextDispatchProcessModel', dispatchProcessModel);
      }

    },

    getImportDataPayload() {
      let {
        importTransporter,
        importTransporterAccountNumber,
        importDispatchDateZ,
        importData,
        isImportDocumentStatus = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let path = 'api/protected/documents/sales/invoices/import/dispatched';
      const payload = {
        transporterLabel: importTransporter,
        transporterAccountNumber: importTransporterAccountNumber,
        dispatchDateZ: importDispatchDateZ,
        batch: importData
      };

      if (isImportDocumentStatus) {
        path = 'api/protected/documents/sales/invoices/import/status';
        payload.nextDispatchProcessCode = this.nextDispatchProcessCode;
        payload.nextDispatchProcessLabel = this.nextDispatchProcessLabel;
        payload.nextDispatchProcessLabelTranslate = this.nextDispatchProcessLabelTranslate;
      }

      return {
        path,
        payload
      };
    }

  }, (_applyDecoratedDescriptor(_obj, "getImportDataPayload", [_dec], Object.getOwnPropertyDescriptor(_obj, "getImportDataPayload"), _obj)), _obj)));

  _exports.default = _default;
});