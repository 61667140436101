define("client/pods/products/lists/attachments-types/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = [{
        value: this.constants.productAttachmentsTypes.video,
        label: 'video',
        translate: true
      }, {
        value: this.constants.productAttachmentsTypes.link,
        label: 'link',
        translate: true
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});