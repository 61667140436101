define("client/pods/components/transporters/seven-eleven/pickup/editor-extension/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RYZLnp9Z",
    "block": "{\"symbols\":[\"@tab\",\"@transporterModel\",\"@personDetails\",\"@personModel\",\"@upsertPersonTask\",\"@outerOnCloseDialogAction\",\"@isNewPerson\",\"@errors\",\"@isReadonly\"],\"statements\":[[5,\"extensions/apps/tw/seven-eleven/print-item-meta\",[],[[\"@tab\",\"@model\",\"@transporterKey\",\"@storeId\",\"@storeName\",\"@storeAddress\",\"@storeIdPlaceholder\",\"@storeNamePlaceholder\",\"@storeAddressPlaceholder\",\"@isUpdateReturnStore\",\"@onClearReturnShop\",\"@personDetails\",\"@personModel\",\"@upsertPersonTask\",\"@outerOnCloseDialogAction\",\"@isNewPerson\"],[[23,1,[]],[23,2,[]],[23,2,[\"_data\",\"_key\"]],[23,3,[\"storeId\",\"_data\",\"value\"]],[23,3,[\"storeName\",\"_data\",\"value\"]],[23,3,[\"storeAddress\",\"_data\",\"value\"]],\"same as drop off\",\"same as drop off\",\"same as drop off\",true,[23,0,[\"onClearReturnShop\"]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]]]]],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\"],[\"transporter package temperature\",[23,8,[]],\"transporterPackageTemperature\"]],{\"statements\":[[0,\"\\n  \"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@disabled\",\"@itemLabelKey\",\"@itemValueKey\",\"@list\",\"@translate\",\"@value\"],[true,[23,9,[]],\"_data.name\",\"_data.account\",[23,0,[\"transporterPackageTemperatureList\"]],true,[23,3,[\"transporterAccount\",\"_data\",\"value\"]]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/seven-eleven/pickup/editor-extension/template.hbs"
    }
  });

  _exports.default = _default;
});