define("client/pods/components/channels/shop/campaigns/campaigns-editor/settings-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p6qG42RK",
    "block": "{\"symbols\":[\"subTab\",\"&default\",\"@tab\",\"@model\",\"@isReadonly\",\"@isEditing\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"panel-grid__header is-sticky-row-auto\"],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTop\"]]]],[8],[0,\"\\n    \"],[5,\"menus/tab-menu-new\",[],[[\"@tab\",\"@tabs\",\"@menu\",\"@onSelect\"],[[23,3,[]],[23,3,[\"subTabs\"]],[23,0,[\"crud\",\"lists\",\"channels-shop-campaigns-lists-settings-editor-editor-sub-panels\"]],[28,\"fn\",[[23,0,[\"tabsManager\",\"displaySubPanelNew\"]],[23,3,[]]],null]]]],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[23,3,[\"subTabs\"]]]],{\"statements\":[[0,\"\\n      \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"isDisabled\",\"isDuplicate\",\"isCopy\",\"isEditing\",\"isReadonly\",\"isNew\",\"model\",\"tab\",\"parentTab\",\"resourceList\"],[[24,[\"isDisabled\"]],[24,[\"isDuplicate\"]],[24,[\"isCopy\"]],[23,6,[]],[23,5,[]],[24,[\"isNew\"]],[23,4,[]],[23,1,[]],[23,3,[]],[23,0,[\"crud\",\"lists\",\"channels-shop-campaigns-lists-settings-editor-editor-sub-panels\"]]]]],false],[0,\"\\n    \"]],\"parameters\":[1]}],[0,\"\\n  \"],[9],[0,\" \"],[2,\" end panel-grid__content  \"],[0,\"\\n\\n\"],[0,\"\\n\"],[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\" \"],[2,\" end grid container \"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/settings-editor/template.hbs"
    }
  });

  _exports.default = _default;
});