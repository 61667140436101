define("client/pods/components/reports/commissions/tab-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators"], function (_exports, _component, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ReportsCommissionsTabContainer = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class ReportsCommissionsTabContainer extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "applicationService", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "dataManager", _descriptor3, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor4, this);

      _initializerDefineProperty(this, "search", _descriptor5, this);

      _initializerDefineProperty(this, "date", _descriptor6, this);

      _initializerDefineProperty(this, "server", _descriptor7, this);

      _initializerDefineProperty(this, "filters", _descriptor8, this);

      _initializerDefineProperty(this, "resultsToggle", _descriptor9, this);

      _initializerDefineProperty(this, "yyyy", _descriptor10, this);

      _initializerDefineProperty(this, "mm", _descriptor11, this);

      _defineProperty(this, "adapterName", 'users/associates');

      _defineProperty(this, "filtersName", 'users/associates/filters');

      this.crud.addLists(this, ['users/lists/menu', 'commissions/lists/panels']);
      this.dash();
      this.yyyy = {
        value: '',
        label: ''
      };
      this.mm = {
        value: '',
        label: ''
      };
      const filters = this.search.setupFilters({
        adapterName: this.filtersName
      });
      this.filters = filters;
    }

    afterLoadLists() {
      this.loadTabTask.perform();
    }

    *loadTabTask() {
      const filters = this.filters;
      filters.setData('query', '');
      yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: filters
      });
      this.filters = filters;
      this.dash(filters.results);
      this.resultsToggle = true;
    }

    markAsPayoutsTask(_ref) {
      var _this = this;

      let {
        adapterName,
        model,
        onAfter
      } = _ref;
      return function* () {
        const dateZ = _this.date.getNowZ();

        const period = _this.date.getPeriod(dateZ);

        const payload = _this.dataManager.setAsRecord({
          adapterName,
          data: {
            salesPerson: model._data.salesPerson,
            salesPersonKey: model._data.salesPersonKey,
            amount: model._data.amount,
            comments: model._data.comments,
            ref: period.monthStart,
            dateZ: dateZ,
            mm: period.mm,
            yyyy: period.yyyy
          }
        });

        yield _this.crud.createRecordTask.perform({
          adapterName,
          model: payload,
          onAfter
        }); // yield this.server.call('POST', 'api/protected/reports/commissions/payouts', payload)
      }();
    }
    /**
     * display the user
     * @param  {object} user model
     */


    display(model) {
      const subTabOptions = {
        component: 'reports/commissions/panels-display',
        model: model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

    dash() {
      this.resultsToggle = false;
      const tab = this.args.tab;
      const subTabOptions = {
        component: 'users/panels-dash' // model: model

      };
      this.tabsManager.reuseSubTab(tab, subTabOptions);
    } // @TODO: NEED TO USE NGUARD TO RESTRICT CERTAIN ACTIONS


    onDash() {
      // should deselect results if any..
      // this.deselectResult();
      this.dash(); // search () {
    }

    onDisplay(model) {
      this.yyyy = {
        value: '',
        label: ''
      };
      this.mm = {
        value: '',
        label: ''
      };
      this.display(model);
    }

    onDisplayWithDate(model, yyyy, mm) {
      this.yyyy = yyyy;
      this.mm = mm;
      this.display(model);
    }

    displayPanel(resource) {
      this.yyyy = {
        value: '',
        label: ''
      };
      this.mm = {
        value: '',
        label: ''
      };
      const tab = this.args.tab;
      const subTabOptions = {
        component: resource.getData('component')
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

    displayPanelWithDate(resource, yyyy, mm) {
      this.yyyy = yyyy;
      this.mm = mm;
      const tab = this.args.tab;
      const subTabOptions = {
        component: resource.getData('component')
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dataManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "date", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "resultsToggle", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "yyyy", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "mm", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadTabTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadTabTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markAsPayoutsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "markAsPayoutsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDash", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onDash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDisplay", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onDisplay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDisplayWithDate", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onDisplayWithDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayPanel", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "displayPanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayPanelWithDate", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "displayPanelWithDate"), _class.prototype)), _class));
  _exports.default = ReportsCommissionsTabContainer;
});