define("client/pods/components/operator/change-logs/panels-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kSmayyzl",
    "block": "{\"symbols\":[\"@onCancel\",\"@onDash\",\"@tab\",\"@onAfterUpdateRecordTask\"],\"statements\":[[5,\"operator/change-logs/change-logs-editor\",[],[[\"@adapterName\",\"@isEditing\",\"@isRunning\",\"@model\",\"@onCancel\",\"@onDash\",\"@onSaveTask\",\"@tab\"],[[23,0,[\"adapterName\"]],true,[23,0,[\"crud\",\"updateRecordTask\",\"isRunning\"]],[23,0,[\"model\"]],[23,1,[]],[23,2,[]],[28,\"perform\",[[23,0,[\"crud\",\"updateRecordTask\"]],[28,\"hash\",null,[[\"adapterName\",\"model\",\"onAfter\",\"tab\"],[[23,0,[\"adapterName\"]],[23,0,[\"model\"]],[23,4,[]],[23,3,[]]]]]],null],[23,3,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/operator/change-logs/panels-edit/template.hbs"
    }
  });

  _exports.default = _default;
});