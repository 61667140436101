define("client/pods/components/dispatch/panels-display/list-summary/summary-item/summary-item-derivation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+2p5LqBF",
    "block": "{\"symbols\":[\"process\",\"@derivation\",\"@allowedDispatchProcessesCodes\",\"@hasMultipleProcesses\",\"@summaryData\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\"],[9],[0,\"\\n\\t\"],[7,\"td\",true],[10,\"class\",\"is-derivation\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"itemId\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[1,[23,2,[\"itemId\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\\t\"],[1,[28,\"tt\",[\"miscellaneous\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\t\"],[9],[0,\"\\n\\t\"],[7,\"td\",true],[10,\"class\",\"u-desc u-desc--mini\"],[8],[0,\"\\n\\t\\t\"],[1,[23,2,[\"itemCode\"]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,4,[]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\\t\\t\\t\"],[1,[28,\"number\",[[23,0,[\"totalQty\"]]],null],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\\n\"],[4,\"each\",[[23,5,[\"processes\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"contains\",[[23,1,[\"_data\",\"code\"]],[23,3,[]]],null]],null,{\"statements\":[[4,\"if\",[[28,\"ramda\",[\"pathOr\",\"\",[28,\"array\",[\"dispatchProcess\",[23,1,[\"_data\",\"code\"]]],null],[23,2,[]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[1,[28,\"number\",[[28,\"ramda\",[\"pathOr\",\"\",[28,\"array\",[\"dispatchProcess\",[23,1,[\"_data\",\"code\"]]],null],[23,2,[]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\\t\"],[7,\"td\",true],[10,\"class\",\"u-no-print\"],[8],[0,\"\\n\\t\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dispatch/panels-display/list-summary/summary-item/summary-item-derivation/template.hbs"
    }
  });

  _exports.default = _default;
});