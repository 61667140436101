define("client/pods/components/settings/settings-editor/3rd-party-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SettingsSettingsEditor3rdPartyEditorComponent extends Ember.Component {}

  _exports.default = SettingsSettingsEditor3rdPartyEditorComponent;
});