define("client/pods/components/contacts/contacts-editor/component", ["exports", "client/mixins/search-contacts", "client/mixins/editor", "client/mixins/date", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _searchContacts, _editor, _date, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_searchContacts.default, _editor.default, _date.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    admin: Ember.inject.service(),
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),
    contactsList: null,
    tagName: 'div',
    classNames: ['col-container'],
    disabled: false,
    translate: true,
    isEditing: false,
    disable: false,
    isRendered: false,
    formGroupclassNames: 'form-group__container--column-3',

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['admin/members/lists/plans', 'statuses', 'contacts/lists/taxMethods', 'contacts/lists/relationships', 'contacts/lists/detailTypes', 'contacts/lists/tags', 'contacts/lists/isTransporter', 'contacts/transporters', 'paymentMethods', 'paymentTerms', 'channels/shop/campaigns/lists/commission-types', 'channels/shop/campaigns/lists/commission-types', {
        name: 'users',
        appendPath: 'internal'
      }, 'transporter']);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('isRendered', true);
      this.setupTask.perform();
    },

    *setupTask() {
      if (this.settings.getProp('isBigOrdr')) {
        yield this.admin.getAllActivePodsTask.perform();
      }
    },

    setBigOrdrPlanFee(model, selected) {
      model.setData('bigOrdrPlanFee', selected?._data?.fee || '');
      model.setData('bigOrdrPlanFeeType', selected?._data?.feeType || '');
    },

    /**
     * only focus child components when component has finished rendering.
     * eg. when creating new contact it will autofocus on first input,
     * when editing, child components with autofocus
     * should not override first inputs autofocus
     */
    autofocusComponents: Ember.computed('isRendered', function () {
      if (this.isRendered) {
        return true;
      }

      return false;
    }),
    // determines if min form requirements are met
    isMinRequiredMet: Ember.computed('model._data.name', function () {
      const minRequired = ['name'];
      const model = this.model;
      let met = true;
      minRequired.forEach(function (required) {
        if (Ember.isEmpty(model.getData(required))) {
          met = false;
        }
      });
      return met;
    }),

    setAssociatesDefaults() {
      const model = this.model;

      if (RA.isNotNilOrEmpty(model)) {
        let selectedCommissionBaseCostMethod;
        let isUsingAverage;
        let isCalculatedByExclAmt;
        let isUsingSetChild;
        let hasAdjustedShippingFee;
        const settingsModel = model?.settings?.settingsModel || {};

        if (RA.isNotNilOrEmpty(settingsModel)) {
          selectedCommissionBaseCostMethod = R.pathOr('normalPercent', ['_data', 'selectedCommissionBaseCostMethod'], settingsModel);
          isUsingAverage = R.pathOr(true, ['_data', 'isCommissionsUsingAverage'], settingsModel);
          isCalculatedByExclAmt = R.pathOr(true, ['_data', 'isCommissionsCalculatedByExclAmt'], settingsModel);
          isUsingSetChild = R.pathOr(false, ['_data', 'isCommissionsUsingSetChild'], settingsModel);
          hasAdjustedShippingFee = R.pathOr(false, ['_data', 'isCommissionsHasAdjustedShippingFee'], settingsModel);
        }

        model.setData('selectedCommissionBaseCostMethod', selectedCommissionBaseCostMethod);
        model.setData('isCommissionsUsingAverage', isUsingAverage);
        model.setData('isCommissionsCalculatedByExclAmt', isCalculatedByExclAmt);
        model.setData('isCommissionsUsingSetChild', isUsingSetChild);
        model.setData('isCommissionsHasAdjustedShippingFee', hasAdjustedShippingFee);
        const supplierOption = R.pipe(R.pathOr({}, ['crud', 'lists', 'contacts-lists-relationships']), R.find(R.pathEq(['_data', 'value'], 'supplier')))(this);

        if (RA.isNotNilOrEmpty(supplierOption)) {
          const relationships = model.getData('relationships') || [];
          const newRelationships = R.pipe(R.concat(['supplier']), R.uniq)(relationships);
          model.setData('relationships', newRelationships);
          this.addRelationship(supplierOption);
        }
      }
    },

    addRelationship(option) {
      const relationship = option.getData('value');
      const model = this.model;
      let type = 'sales';

      if (relationship === 'supplier') {
        type = 'purchases';
      }

      const settingsModel = this.settings.getModel();
      model.setData(`${type}PaymentMethodKey`, settingsModel.getData(`${type}PaymentMethodKey`, this.get('constants.paymentMethods.cash')));
      model.setData(`${type}TaxMethodKey`, this.get('constants.taxMethods.auto'));
    },

    removeRelationship(option) {
      const self = this;
      const relationship = option.getData('value');
      const model = this.model;
      let type = 'sales';

      if (relationship === 'supplier') {
        type = 'purchases';
        model.setData('isTransporter', self.get('constants.isTransporter.off'));
        model.setData('transporterAccounts', []);
        model.setData('defaultTransporterAccount', '');
      }

      model.setData(`${type}HasPaymentTerms`, '');
      model.setData(`${type}PaymentTerms`, '');
      model.setData(`${type}PaymentTermsKey`, '');
    },

    *onSaveTask(model) {
      return yield this.saveTask.perform(model);
    },

    toggleIsTransporter(model) {
      const isTransporter = model?._data?.isTransporter;

      if (!isTransporter) {
        model.setData('transporterKey', '');
        model.setData('transporterExtension', '');
      }
    },

    actions: {
      /**
       * pass model to outer component's save action
       * (do not handle saving here as different actions are required for create/update)
       */
      // submit () {
      //   const model = this.get('model')
      //   // should remove customer/supplier tax rates if not required
      //   this.removeNotRequired()
      //   this.get('onSubmit')(model)
      // },
      // cancel () {
      //   if (this.confirmClose()) {
      //     this.get('onCancel')()
      //   }
      // },

      /**
       * assign sales rep to contact
       */
      assignSalesPerson(person) {
        this.assignSalesPerson(person);
      },

      /**
       * unassign sales rep
       */
      unassignSalesPerson(person) {
        this.unassignSalesPerson(person);
      },

      /**
       * add detail to model based on detail type
       */
      addDetail(model, detailType) {
        model.addDetail({
          type: detailType.getData('value'),
          subType: detailType.getData('defaultSubType')
        });
      },

      /**
       * remove detail from model
       */
      removeDetail(model, detail
      /* , component */
      ) {
        model.removeDetail(detail);
      },

      /**
       * add person to model
       */
      addPerson(model
      /* , component */
      ) {
        model.addPerson();
      },

      /**
       * remove deparment from model
       */
      removePerson(model, person) {
        model.removePerson(person);
      },

      /**
       * add department to model
       */
      addDepartment(model
      /* , component */
      ) {
        model.addDepartment();
      },

      /**
       * remove deparment from model
       */
      removeDepartment(model, department) {
        model.removeDepartment(department);
      },

      // loadPaymentMethod(type, paymentMethod) {
      //
      //   if (paymentMethod) {
      //     // check paymentMethod selection to determine if has payment terms
      //     const hasPaymentTerms = paymentMethod.getData('hasPaymentTerms');
      //
      //     this.get('model').setData(`${type}HasPaymentTerms`, hasPaymentTerms);
      //   }
      // },
      //
      // loadPaymentTerm(type, paymentTerm) {
      //
      //   this.get('model').setData(`${type}PaymentTerms`, paymentTerm.getData('label'));
      // },
      //
      // // searchContact(relationship, e, value) {
      // //
      // //   /* jshint unused: false */
      // //
      // //   this.searchContact(relationship, value);
      // // },
      //
      // loadAccount(type, contact) {
      //   // @TODO TEST
      //
      //   const key = contact.getData('_key');
      //   const  model = this.get('model');
      //   model.setData(`${type}AccountKey`, key);
      //
      //   model.setData(`${type}PaymentMethodKey`, contact.getData(`${type}PaymentMethodKey`));
      //   model.setData(`${type}HasPaymentTerms`, contact.getData(`${type}HasPaymentTerms`));
      //   model.setData(`${type}PaymentTermsKey`, contact.getData(`${type}PaymentTermsKey`));
      //   model.setData(`${type}PaymentTerms`, contact.getData(`${type}paymentTerms`));
      // },
      //
      // unloadAccount(type) {
      //
      //   const model = this.get('model');
      //   model.setData(`${type}AccountKey`, '');
      // },
      //
      // loadTransporter(contact) {
      //   // @TODO TEST
      //
      //   const key = contact.getData('_key');
      //   const  model = this.get('model');
      //   model.setData('transporterKey', key);
      // },
      //
      // unloadTransporter() {
      //
      //   const model = this.get('model');
      //   model.setData('transporterKey', '');
      // },
      setTransporterAccountsList() {
        const model = this.model;
        const accounts = model.getData('transporterAccounts');

        if (!accounts) {
          model.setData('transporterAccounts', []);
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "setupTask"), _obj), _applyDecoratedDescriptor(_obj, "setBigOrdrPlanFee", [_dec], Object.getOwnPropertyDescriptor(_obj, "setBigOrdrPlanFee"), _obj), _applyDecoratedDescriptor(_obj, "setAssociatesDefaults", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setAssociatesDefaults"), _obj), _applyDecoratedDescriptor(_obj, "addRelationship", [_dec3], Object.getOwnPropertyDescriptor(_obj, "addRelationship"), _obj), _applyDecoratedDescriptor(_obj, "removeRelationship", [_dec4], Object.getOwnPropertyDescriptor(_obj, "removeRelationship"), _obj), _applyDecoratedDescriptor(_obj, "onSaveTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "onSaveTask"), _obj), _applyDecoratedDescriptor(_obj, "toggleIsTransporter", [_dec5], Object.getOwnPropertyDescriptor(_obj, "toggleIsTransporter"), _obj)), _obj)));

  _exports.default = _default;
});