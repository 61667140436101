define("client/pods/components/transporters/hct/pickup/editor-extension/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pmh1JiGG",
    "block": "{\"symbols\":[\"@errors\",\"@transporterModel\",\"@isReadonly\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\"],[\"transporter account\",[23,1,[]],\"transporterAccountNumber\"]],{\"statements\":[[0,\"\\n  \"],[5,\"lists/list-dropdown\",[],[[\"@disabled\",\"@isBtn\",\"@itemLabelKey\",\"@itemValueKey\",\"@list\",\"@readonly\",\"@translate\",\"@value\"],[[22,\"isDisabled\"],true,\"_data.name\",\"_data.account\",[23,2,[\"_data\",\"transporterAccounts\"]],true,false,[23,0,[\"transporterAccountNumber\",\"_data\",\"value\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\"],[\"transporter package temperature\",[23,1,[]],\"transporterPackageTemperature\"]],{\"statements\":[[0,\"\\n  \"],[5,\"lists/list-dropdown\",[],[[\"@isBtn\",\"@disabled\",\"@itemLabelKey\",\"@itemValueKey\",\"@list\",\"@onSelect\",\"@translate\",\"@value\"],[true,[23,3,[]],\"_data.name\",\"_data.account\",[23,0,[\"crud\",\"lists\",\"extensions-apps-tw-t-cat-lists-thermosphere\"]],[23,0,[\"updateTransporterAccount\"]],true,[23,0,[\"transporterPackageTemperature\",\"_data\",\"value\"]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/hct/pickup/editor-extension/template.hbs"
    }
  });

  _exports.default = _default;
});