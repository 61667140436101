define("client/pods/components/layouts/sidebar/contents/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RdwsZ1h/",
    "block": "{\"symbols\":[\"sidebarOptions\",\"@tab\",\"@sidebarComponent\",\"@components\"],\"statements\":[[4,\"each\",[[23,4,[]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,3,[\"selected\"]],[23,1,[\"component\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tab\",\"sidebarOptions\"],[[23,2,[]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/layouts/sidebar/contents/template.hbs"
    }
  });

  _exports.default = _default;
});