define("client/pods/components/helpers/drop-container/component", ["exports", "client/mixins/droppable"], function (_exports, _droppable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // this is DEPRECATED.
  // use the new way
  // helpers/dragAndDrop/DragContainer and helpers/dragAndDrop//DropContainer should be deprecated
  // @deprecated
  var _default = Ember.Component.extend(_droppable.default, {
    tag: 'div',
    classNames: ['drop-container'],
    classNameBindings: ['dragPosition', 'isActive:is-active']
  });

  _exports.default = _default;
});