define("client/pods/components/elements/element-toggle-wrapper/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * A container to wrap any element into a toggle.
   * Purpose is to add a toggle on/off icon on left / right (default),
   * and allow element to be toggleable.
   *
   * this component should look exactly like the accordian-btn, but toggle logic is dictated by outer component
   */
  let ElementsElementToggleWrapper = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class ElementsElementToggleWrapper extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "toggleOnIconClassName", _descriptor, this);

      _initializerDefineProperty(this, "toggleOffIconClassName", _descriptor2, this);

      _initializerDefineProperty(this, "toggleOnIconRightClassName", _descriptor3, this);

      _initializerDefineProperty(this, "toggleOffIconRightClassName", _descriptor4, this);

      _defineProperty(this, "hasToggleIcon", RA.defaultWhen(RA.isNilOrEmpty, false, this.args.hasToggleIcon));

      _defineProperty(this, "hasToggleIconRight", RA.defaultWhen(RA.isNilOrEmpty, true, this.args.hasToggleIconRight));
    }

    get isToggler() {
      if (this.args.disabled) {
        return false;
      }

      return RA.defaultWhen(RA.isNilOrEmpty, true, this.args.isToggler);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "toggleOnIconClassName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, 'fas fa-angle-down', this.args.toggleOnIconClassName);
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toggleOffIconClassName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, 'fas fa-angle-right', this.args.toggleOffIconClassName);
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toggleOnIconRightClassName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, 'fas fa-angle-down', this.args.toggleOnIconRightClassName);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "toggleOffIconRightClassName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return RA.defaultWhen(RA.isNilOrEmpty, 'fas fa-angle-right', this.args.toggleOffIconRightClassName);
    }
  })), _class));
  _exports.default = ElementsElementToggleWrapper;
});