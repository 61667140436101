define("client/pods/grapesjs/plugins/strip", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = editor => {
    const bigordrStrip = 'bigordr-strip';
    const bigordrStripBlockId = `${bigordrStrip}-block`; // Inner Components

    const bigordrStripImage = 'bigordr-strip-image';
    const {
      DomComponents,
      BlockManager
    } = editor;
    const options = R.pathOr({}, ['Config', 'pluginsOpts', bigordrStrip])(editor);
    const bigordrStripImageOptions = R.pathOr({}, [bigordrStripImage])(options);
    const margin0 = 'margin-0';
    const {
      intl = {
        t: txt => txt
      },
      isReadonly,

      /** @type {Categories} */
      categories
    } = options;
    const {
      onDbClick = args => args
    } = bigordrStripImageOptions;
    const blockLabel = intl.t(bigordrStrip);

    const onImageSelect = (component, imgType, imageUrl, mimeType) => {
      component.addStyle({
        'background-image': `url(${imageUrl})`
      });
    };

    DomComponents.addType(bigordrStripImage, {
      model: {
        defaults: {
          imgType: 'full-width-image',
          name: intl.t(bigordrStripImage),
          style: {
            'background-attachment': 'scroll',
            'background-image': 'url(https://picsum.photos/512)',
            'background-position': 'center center',
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'max-height': '100%',
            'min-height': '200px',
            'object-fit': 'cover',
            'flex-shrink': '1',
            height: 'auto',
            width: '100%'
          },
          removable: true,
          copyable: false,
          draggable: false,
          stylable: true,
          droppable: false,
          selectable: true,
          traits: []
        }
      },
      view: {
        // Add easily component specific listeners with `events`
        // Being component specific (eg. you can't attach here listeners to window)
        // you don't need to care about removing them when the component is removed,
        // they will be managed automatically by the editor
        events: {
          dblclick: 'onDbClick'
        },

        onDbClick(event) {
          var _this = this;

          const targetType = R.pathOr('', ['target', 'dataset', 'gjsType'])(event);

          if (onDbClick && !isReadonly && targetType !== 'text' && targetType !== 'bigordr-button') {
            const imgType = '';
            const imagesManager = {
              onImageSelect: function () {
                for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
                  args[_key] = arguments[_key];
                }

                return onImageSelect(_this.model, ...args);
              }
            };
            const allowSrcSet = false;
            onDbClick(imgType, imagesManager, allowSrcSet);
          }
        }

      }
    });
    DomComponents.addType(bigordrStrip, {
      model: {
        defaults: {
          tagName: 'div',
          name: intl.t(bigordrStrip),
          classes: ['bigordr-strip'],
          components: [{
            type: 'row',
            style: {
              padding: '0px 0px 0px 0px'
            },
            selectable: false,
            droppable: false,
            draggable: false,
            components: [{
              type: bigordrStripImage,
              traits: [{
                type: 'bigordr-animation-trait',
                name: 'animationSelector',
                label: intl.t('select animation')
              }, {
                type: 'bigordr-animation-iteration-trait',
                name: 'animationIterationSelector',
                label: intl.t('select iteration count')
              }, {
                type: 'bigordr-animation-duration-trait',
                name: 'animationDuration',
                label: intl.t('enter duration (sec)')
              }],
              classes: ['bigordr-strip-image']
            }, {
              type: 'cell',
              selectable: false,
              components: [{
                tagName: 'section',
                name: intl.t('text-block'),
                style: {
                  padding: '10px'
                },
                components: [{
                  type: 'text',
                  classes: ['bigordr-title', margin0],
                  name: intl.t('title'),
                  style: {
                    padding: '10px'
                  },
                  tagName: 'h1',
                  content: 'Insert title here',
                  traits: [{
                    type: 'bigordr-animation-text-trait',
                    name: 'animationSelector',
                    label: intl.t('select animation')
                  }, {
                    type: 'bigordr-animation-iteration-trait',
                    name: 'animationIterationSelector',
                    label: intl.t('select iteration count')
                  }, {
                    type: 'bigordr-animation-duration-trait',
                    name: 'animationDuration',
                    label: intl.t('enter duration (sec)')
                  }]
                }, {
                  type: 'text',
                  style: {
                    padding: '10px'
                  },
                  tagName: 'p',
                  content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce lobortis ullamcorper lorem. Donec tempor lectus sapien, eu consequat dolor vehicula eu. Vestibulum non felis blandit, condimentum mi ac, pulvinar dolor. Nullam eu mi sodales, tempus eros in, blandit urna. Suspendisse potenti. Etiam sit amet dignissim ex. Maecenas elit quam, tempus quis consectetur sed, malesuada eu tortor. In faucibus, quam at efficitur aliquam, sem tortor feugiat velit, in hendrerit ipsum enim sit amet tortor. Quisque a lacus sed tortor rutrum facilisis ac eget magna. In gravida consequat justo ut eleifend. Vivamus dapibus rhoncus eros, et vestibulum eros fringilla ac. Maecenas porttitor est nec tempor mattis.',
                  traits: [{
                    type: 'bigordr-animation-trait',
                    name: 'animationSelector',
                    label: intl.t('select animation')
                  }, {
                    type: 'bigordr-animation-iteration-trait',
                    name: 'animationIterationSelector',
                    label: intl.t('select iteration count')
                  }, {
                    type: 'bigordr-animation-duration-trait',
                    name: 'animationDuration',
                    label: intl.t('enter duration (sec)')
                  }],
                  classes: [margin0]
                }],
                traits: []
              }],
              traits: []
            }, {
              type: bigordrStripImage,
              traits: [{
                type: 'bigordr-animation-trait',
                name: 'animationSelector',
                label: intl.t('select animation')
              }, {
                type: 'bigordr-animation-iteration-trait',
                name: 'animationIterationSelector',
                label: intl.t('select iteration count')
              }, {
                type: 'bigordr-animation-duration-trait',
                name: 'animationDuration',
                label: intl.t('enter duration (sec)')
              }],
              classes: ['bigordr-strip-image']
            }],
            traits: []
          }],
          traits: []
        }
      }
    });
    BlockManager.add(bigordrStripBlockId, {
      label: blockLabel,
      category: categories.complex,
      content: {
        type: bigordrStrip
      },
      render: _ref => {
        let {
          model
        } = _ref;
        return `
    <div class="gjs-block__media">
    <svg width="100%" height="100%" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
        <g transform="matrix(1,0,0,1,-3.32468,1.7053e-13)">
            <g>
                <g transform="matrix(1,0,0,1,-3.28194,-1.08002e-12)">
                    <g transform="matrix(1.37733,0,0,1.65228,-180.565,-864.206)">
                        <path d="M207.809,832.911L800.909,832.911" style="fill:none;stroke:black;stroke-width:29.59px;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,118.44,399.43)">
                        <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                            <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;"/>
                        </g>
                        <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                            <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;"/>
                        </g>
                    </g>
                    <g transform="matrix(-1,-1.22465e-16,1.22465e-16,-1,918.773,624.57)">
                        <g transform="matrix(0.0811978,0.0811978,-0.0650785,0.0650785,826.292,-8.88271)">
                            <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;"/>
                        </g>
                        <g transform="matrix(-0.0811978,0.0811978,-0.0650785,-0.0650785,910.413,149.901)">
                            <path d="M207.809,832.911L828.197,832.911" style="fill:none;stroke:black;stroke-width:432.45px;stroke-linecap:square;"/>
                        </g>
                    </g>
                </g>
                <g transform="matrix(1,0,0,1,1.86342,37.2616)">
                    <g transform="matrix(1.00794,0,0,0.792537,12.4421,44.5258)">
                        <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:white;stroke:black;stroke-width:38.6px;stroke-linecap:square;stroke-linejoin:miter;"/>
                    </g>
                    <g transform="matrix(0.314179,0,0,0.783256,138.804,45.509)">
                        <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:rgb(209,209,209);"/>
                    </g>
                    <g transform="matrix(0.314179,0,0,0.783256,576.983,42.2419)">
                        <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:rgb(209,209,209);"/>
                    </g>
                    <g transform="matrix(1.00794,0,0,0.792537,12.4421,44.5258)">
                        <rect x="203.744" y="249.501" width="586.657" height="586.657" style="fill:none;stroke:black;stroke-width:38.6px;stroke-linecap:square;stroke-linejoin:miter;"/>
                    </g>
                </g>
            </g>
        </g>
        <g transform="matrix(0.399314,0,0,0.377203,340.227,332.928)">
            <text x="260.627px" y="673.073px" style="font-family:'Helvetica-Bold', 'Helvetica';font-weight:700;font-size:551.145px;">T</text>
        </g>
    </svg>
    <div class="block-label">${model.get('label')}</div>
    </div>`;
      }
    });
  };

  _exports.default = _default;
});