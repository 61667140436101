define("client/pods/components/bsp/virtual-account-editor/component", ["exports", "ramda", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor", "client/pods/options/model"], function (_exports, R, _component, _emberConcurrencyDecorators, _nventor, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // @TODO: Future backend validation
  let BspVirtualAccountEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class BspVirtualAccountEditorComponent extends _component.default {
    // @service tabsManager
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "modelProxy", _descriptor2, this);

      _initializerDefineProperty(this, "virtualAccountErrors", _descriptor3, this);

      _initializerDefineProperty(this, "hashKeysErrors", _descriptor4, this);

      _defineProperty(this, "adapterName", this.args?.customAdapterName || this.args?.adapterName || 'settings');

      const isEditing = this?.args?.isEditing;
      const model = this.args?.model || {};
      const tab = this.args?.tab || {};
      this.tab = tab;
      this.modelProxy = _nventor.default.createModel(_model.default, {
        enabled: false,
        isDefault: false,
        merchantId: '',
        hash: {}
      });

      if (isEditing) {
        const parentModel = R.pathOr(null, ['args', 'parentModel'])(this);
        const twPaymentsBsp = R.pathOr({}, ['_data', 'tw_payments_bsp'])(parentModel);
        const virtualAccounts = R.pathOr([], ['virtualAccounts'])(twPaymentsBsp);
        const data = R.pipe(R.pathOr({}, ['_data']), R.clone)(model);
        const currentEditModelIndex = R.findIndex(R.pathEq(['_data', 'merchantId'], data.merchantId))(virtualAccounts);
        this.modelProxy.set('_data', data);
        this.modelProxy.set('index', currentEditModelIndex);
      }
    }

    get hasErrors() {
      if (this.virtualAccountErrors.length !== 0 || this.hashKeysErrors.length !== 0) {
        return true;
      }

      return false;
    } // @TODO: should all use backend validation


    validateVirtualAccount() {
      // Uniq names
      const currentMerchantId = R.pathOr('', ['modelProxy', '_data', 'merchantId'])(this);

      if (!currentMerchantId) {
        this.virtualAccountErrors = [{
          type: 'isRequired',
          message: 'is required',
          path: ['merchantId'],
          context: {
            key: 'merchantId'
          },
          isError: true
        }];
      } else if (currentMerchantId?.length !== 10) {
        this.virtualAccountErrors = [{
          type: 'incorrectLength',
          message: 'incorrect length',
          path: ['merchantId'],
          context: {
            key: 'merchantId'
          },
          isError: true
        }];
      }

      const hash = R.pathOr({}, ['modelProxy', '_data', 'hash'])(this);
      const HASH_KEYS = ['A1', 'A2', 'B1', 'B2'];
      HASH_KEYS.forEach(key => {
        if (!hash?.[key]) {
          this.hashKeysErrors.push({
            type: 'isRequired',
            message: 'is required',
            path: [`hash.${key}`],
            context: {
              key: `hash.${key}`
            },
            isError: true
          });
        } else if (hash[key].length !== 16) {
          this.hashKeysErrors.push({
            type: 'incorrctLength',
            message: 'incorrect length',
            path: [`hash.${key}`],
            context: {
              key: `hash.${key}`
            },
            isError: true
          });
        }
      });

      if (this.hasErrors) {
        throw new Error('Frontend Validation Error: BspVirtualAccountEditor Error');
      }
    }

    isMerchantIdUniq() {
      // Uniq names
      const virtualAccounts = R.pathOr([], ['args', 'parentModel', '_data', 'tw_payments_bsp', 'virtualAccounts'])(this);
      const currentMerchantId = R.pathOr('', ['modelProxy', '_data', 'merchantId'])(this);
      const virtualAccountMerchantIds = R.pipe(R.map(R.pathOr('', ['_data', 'merchantId'])))(virtualAccounts);
      const duplicateMerchantId = virtualAccountMerchantIds?.find(id => id === currentMerchantId);

      if (duplicateMerchantId) {
        this.virtualAccountErrors = [{
          type: 'isUnique',
          message: 'not unique',
          path: ['merchantId'],
          context: {
            key: 'merchantId'
          },
          isError: true
        }];
        throw new Error('MerchantId Already in Used!!!');
      }
    }

    updateVirtualAccountsTask() {
      var _this = this;

      let {
        remove = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const parentModel = R.pathOr(null, ['args', 'parentModel'])(_this);

        try {
          if (parentModel) {
            const twPaymentsBsp = R.pathOr({}, ['_data', 'tw_payments_bsp'])(parentModel);
            const virtualAccounts = R.pathOr([], ['virtualAccounts'])(twPaymentsBsp);
            _this.hashKeysErrors = [];
            _this.virtualAccountErrors = [];

            if (_this?.args?.isEditing) {
              const index = _this.modelProxy.index;
              virtualAccounts.removeAt(index);

              if (!remove) {
                virtualAccounts.insertAt(index, _this.modelProxy);

                _this.validateVirtualAccount();

                _this.isMerchantIdUniq();
              }
            } else {
              _this.validateVirtualAccount();

              const isFirstVirtualAccount = R.length(virtualAccounts) === 0;

              if (isFirstVirtualAccount) {
                const merchantId = _this?.modelProxy?._data?.merchantId;
                parentModel.set('_data.tw_payments_bsp.defaultVirtualAccount', merchantId);

                _this.modelProxy.setData('enabled', true);

                _this.modelProxy.setData('isDefault', true);
              }

              virtualAccounts.addObject(_this.modelProxy);
            }

            parentModel.setData('tw_payments_bsp.virtualAccounts', virtualAccounts);
          }

          _this.onCloseDialogAction();
        } catch (error) {
          console.log(error.message);
        }
      }();
    }

    *cancelEdittingTask() {
      this.onCloseDialogAction();
    }

    onCloseDialogAction() {
      const toggleShowEditor = R.pathOr(null, ['args', 'toggleShowEditor'])(this);
      const onCloseDialogAction = R.pathOr(null, ['args', 'onCloseDialogAction'])(this);
      const onCloseDialogActionListSelector = R.pathOr(null, ['args', 'addItemOptions', 'onCloseDialogActionListSelector'])(this);
      const virtualAccounts = R.pathOr(null, ['args', 'parentModel', '_data', 'tw_payments_bsp', 'virtualAccounts'])(this);
      const isFirstVirtualAccount = R.length(virtualAccounts) === 1;

      if (isFirstVirtualAccount) {
        return onCloseDialogActionListSelector();
      }

      if (onCloseDialogAction) {
        return onCloseDialogAction();
      }

      if (toggleShowEditor) {
        return toggleShowEditor(false);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modelProxy", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "virtualAccountErrors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hashKeysErrors", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateVirtualAccountsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateVirtualAccountsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelEdittingTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEdittingTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCloseDialogAction", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "onCloseDialogAction"), _class.prototype)), _class));
  _exports.default = BspVirtualAccountEditorComponent;
});