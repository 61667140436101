define("client/pods/components/settings/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dx2tU1Op",
    "block": "{\"symbols\":[\"subTab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\n\"],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__content\"],[8],[0,\"\\n    \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,0,[\"fetchAndDisplayTask\",\"isRunning\"]]]],{\"statements\":[[0,\"\\n      \"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[23,0,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"documentationResource\",\"isWizard\",\"model\",\"onCancel\",\"onDisplay\",\"onEdit\",\"tab\",\"tabParent\",\"wizardData\"],[\"settings\",[23,0,[\"isWizard\"]],[23,1,[\"model\"]],[23,0,[\"display\"]],[23,0,[\"display\"]],[23,0,[\"edit\"]],[23,1,[]],[23,0,[\"tab\"]],[28,\"hash\",null,[[\"isWizard\",\"onWizard\",\"onWizardCancel\",\"onWizardDone\",\"onWizardNext\",\"stepsList\",\"target\"],[[23,0,[\"isWizard\"]],[23,0,[\"wizard\"]],[23,0,[\"edit\"]],[23,0,[\"display\"]],[23,0,[\"wizardNext\"]],[23,0,[\"crud\",\"lists\",\"settings-wizard\"]],[23,1,[\"target\"]]]]]]]],false],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/settings/tab-container/template.hbs"
    }
  });

  _exports.default = _default;
});