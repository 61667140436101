define("client/pods/components/channels/shop/campaigns/campaigns-editor/automations-editor/when-editor/item-value/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    tagName: '',
    autofocus: false,

    init() {
      this._super(...arguments);

      this.filters = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          onlyForCampaignKey: this?.master?._data?.shopKey || '',
          resultsProperty: 'productsList'
        }
      });
    },

    isBetweenAutomation: Ember.computed('automationModel._data.criteria', function () {
      const automationModel = this.get('automationModel');
      const criteria = automationModel.getData('criteria');

      if (criteria === 'isBetween') {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});