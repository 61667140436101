define("client/pods/components/documents/bulk/insert-helper/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DocumentsBulkInsertHelperComponent = (_class = class DocumentsBulkInsertHelperComponent extends _component.default {
    get bulkInsertInstructionsLabel() {
      const bulkInsertInstructions = this.args.bulkInsertInstructions || {};
      const fields = R.pipe(R.propOr([], 'fields'), R.map(field => {
        return `[${field}]`;
      }))(bulkInsertInstructions);
      const separator = bulkInsertInstructions.separator || '=';
      return R.join(` ${separator} `)(fields);
    }

    *bulkInsertDetailsTask(onCloseDialogAction, bulkInsertInstructions) {
      yield this.args.onBulkInsertDetailsTask.perform(bulkInsertInstructions);
      onCloseDialogAction();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "bulkInsertDetailsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "bulkInsertDetailsTask"), _class.prototype)), _class);
  _exports.default = DocumentsBulkInsertHelperComponent;
});