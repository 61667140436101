define("client/pods/updates/service", ["exports", "ramda", "client/config/environment"], function (_exports, R, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Service.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    hasNewVersionAlertMessage: false,
    auth: Ember.inject.service(),
    server: Ember.inject.service(),
    crud: Ember.inject.service(),
    listsIsLoaded: false,

    init() {
      this._super(...arguments);

      this.set('env', _environment.default);
      this.set('forceLogout', false);

      if (this.auth.isLoggedIn) {
        this.crud.addLists(this, ['application/change-logs']);
      }
    },

    hasNewVersion: Ember.computed('hasNewVersionAlertMessage', 'crud.lists.application-change-logs.{[],synced}', function () {
      if (this.hasNewVersionAlertMessage) {
        return true;
      }

      const changeLogs = this.crud?.lists?.['application-change-logs'] || [];
      const clientVersionNumber = this.versionNumber;
      return R.reduce((acc, changeLog) => {
        if (acc === true) {
          return true;
        }

        return this._hasNewerVersionNumber({
          current: clientVersionNumber,
          latest: changeLog?._data?.versionNumber
        });
      }, false)(changeLogs);
    }),

    checkIfIsNewerThanCurrentVersion(toCheck) {
      const toCheckNumber = this.getAsVersionNumber(toCheck);
      return this._hasNewerVersionNumber({
        current: this.versionNumber,
        latest: toCheckNumber
      });
    },

    _hasNewerVersionNumber(_ref) {
      let {
        current,
        latest
      } = _ref;
      current = parseInt(current) || 0;
      latest = parseInt(latest) || 0;

      if (current < latest) {
        return true;
      }
    },

    newVersionAlert(data) {
      if (data.debug) {
        debugger; //eslint-disable-line
      }

      if (data) {
        const currentClientVersionNumber = this.versionNumber;

        const isUpdateRequired = this._hasNewerVersionNumber({
          current: currentClientVersionNumber,
          latest: data?.versionNumber
        });

        if (isUpdateRequired) {
          this.set('hasNewVersionAlertMessage', true);
        }
      }

      return false;
    },

    // checkForReSyncData (socketId, wasDisconnected) {
    //   this.updateCacheDataTask.perform(socketId, wasDisconnected)
    // },
    // updateCacheDataTask: task(function * (socketId, wasDisconnected) {
    //   this.set('isReSyncing', true)
    //   let isStale = true
    //   try {
    //     const response = yield this.server.call('GET', 'api/protected/app/resync', {
    //       id: socketId,
    //       timestampZ: wasDisconnected
    //     })
    //     const currentState = R.propOr({}, 'data', response)
    //     isStale = currentState.isStale
    //   } catch (e) {
    //     isStale = true
    //   }
    //   if (isStale) {
    //     this.set('forceLogout', true)
    //   } else {
    //     this.set('forceLogout', false)
    //   }
    //   this.set('isReSyncing', false)
    //   return isStale
    // }).drop(),
    version: Ember.computed('env.version', function () {
      return _environment.default.version || '';
    }),

    getAsVersionNumber() {
      let version = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      return R.pipe(R.split('.'), R.map(number => {
        let leadingZeros = [0, 0, 0];
        const numberOfZeros = leadingZeros.length - number.length;
        leadingZeros = R.take(numberOfZeros)(leadingZeros);
        leadingZeros.push(number);
        return R.join('', leadingZeros);
      }), R.join(''))(version);
    },

    versionNumber: Ember.computed('env.versionNumber', function () {
      return this.getAsVersionNumber(_environment.default.version);
    }),

    reload() {
      window.location.reload();
    }

  }, (_applyDecoratedDescriptor(_obj, "checkIfIsNewerThanCurrentVersion", [_dec], Object.getOwnPropertyDescriptor(_obj, "checkIfIsNewerThanCurrentVersion"), _obj), _applyDecoratedDescriptor(_obj, "reload", [_dec2], Object.getOwnPropertyDescriptor(_obj, "reload"), _obj)), _obj)));

  _exports.default = _default;
});