define("client/pods/channels/shop/campaigns/details/search-results-item/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChannelsShopCampaignsDetailsSearchResultsItemComponent extends _component.default {}

  _exports.default = ChannelsShopCampaignsDetailsSearchResultsItemComponent;
});