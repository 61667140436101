define("client/pods/components/products/products-editor/customizations-editor/component", ["exports", "client/mixins/errors", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _errors, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_errors.default, {
    init() {
      this._super(...arguments);

      this.set('productToBeLinked', []);

      if (this.isEditing || this.isNew) {
        const model = this.model;
        const customizations = model.getData('customizations') || [];
        R.forEach(customization => {
          const options = customization.getData('options') || [];
          R.forEach(option => {
            if (!option._data.optionUid) {
              const optionUid = _nventor.default.random.alphaNum(10);

              option.setData('optionUid', optionUid);
            }
          })(options);
        })(customizations);

        if (RA.isNilOrEmpty(customizations)) {
          model.addCustomization();
        }
      }
    },

    actions: {
      addCustomization() {
        const model = this.model;

        if (this.isEditing && RA.isNotNilOrEmpty(this.dirtyClone)) {
          this.dirtyClone.addCustomization();
        } else {
          model.addCustomization();
        }
      },

      removeCustomization(model, customization) {
        const msg = this.intl.t('are you sure you want to remove');

        if (window.confirm(msg)) {
          model.removeCustomization(customization);
        }
      }

    }
  });

  _exports.default = _default;
});