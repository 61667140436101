define("client/helpers/isSameAsPreviousValue", ["exports", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params, hash) {
      const currentValue = params[0];
      const array = hash.array || [];
      const index = hash.index;
      const path = hash.path;

      if (RA.isNilOrEmpty(array)) {
        return false;
      }

      const previousIndex = Number(index) - 1;

      if (previousIndex < 0) {
        return false;
      }

      let previousValue = R.path([previousIndex])(array);

      if (path) {
        previousValue = R_.dotPath(path)(previousValue);
      }

      if (currentValue === previousValue) {
        return true;
      }

      return false;
    }

  });

  _exports.default = _default;
});