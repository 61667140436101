define("client/pods/components/menus/tab-menu-new/tab-item/component", ["exports", "ramda", "@glimmer/component"], function (_exports, R, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MenusTabMenuNewTabItemComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember.computed('args.subTabs.@each.isActive'), _dec6 = Ember._action, (_class = class MenusTabMenuNewTabItemComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "tabsManager", _descriptor, this);

      _initializerDefineProperty(this, "menuLabelKey", _descriptor2, this);

      _initializerDefineProperty(this, "classNames", _descriptor3, this);

      _initializerDefineProperty(this, "translate", _descriptor4, this);
    }

    get isActive() {
      const subTabs = this.args.subTabs || [];
      const menu = this.args.menu || [];
      const currentMenuItem = this.args.item;
      return this.tabsManager.isActiveMenuByActiveTab(subTabs, menu, currentMenuItem);
    }

    get item() {
      return this.args?.item || null;
    }

    get label() {
      return this.item.get?.(this.menuLabelKey);
    }

    get itemComponent() {
      return this.item?._data?.component || this.item?.component || '';
    }

    get itemValue() {
      return this.item?._data?.value || this.item?.value || '';
    }

    get itemLabel() {
      return this.item?._data?.label || this.item?.label || '';
    } // get currentActive () {
    //   if (this.args?.isCurrentActive) {
    //     const currentActive = this.args.isCurrentActive
    //     if (currentActive?.label) {
    //       return currentActive.label === this.itemLabel
    //     }
    //     if (currentActive?.value) {
    //       return currentActive.value === this.itemValue
    //     }
    //     return currentActive.component === this.itemComponent
    //   }
    //   return false
    // }


    get subTab() {
      const subTabs = this.args?.subTabs || [];
      const subTab = R.find(R.propEq('component', this.itemComponent))(subTabs);
      return subTab;
    }

    onClick() {
      if (this.args?.onSelect) {
        this.args.onSelect(this.item);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "menuLabelKey", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args?.menuLabelKey || '_data.label';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "classNames", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args?.classNames || 'u-tab-menu__item';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "translate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "isActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class));
  _exports.default = MenusTabMenuNewTabItemComponent;
});