define("client/pods/modals/service", ["exports", "client/config/environment", "ramda", "ramda-adjunct"], function (_exports, _environment, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let ModalsService = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class ModalsService extends Ember.Service {
    setupModal(_ref) {
      let {
        id,
        headerHeight = 46,
        footerHeight = 61,
        modalContainerClassNames = '',
        modalDialogZIndex,
        modalOverLayZIndex,
        modalDialogZIndexOnFullScreen,
        modalOverLayZIndexOnFullscreen,
        isSidebar,
        hasExtraSidebar,
        sidebarModalMaxWidth,
        isMobile,
        moveToModalSection = true
      } = _ref;
      let modalDialog = document.getElementById(id);
      const overlay = document.getElementById(`overlay-${id}`);

      if (RA.isNilOrEmpty(modalDialog)) {
        return;
      }

      const parentElement = this._getParentElement({
        modalDialog,
        modalContainerClassNames
      });

      const parentElementBoundingClientRect = parentElement.getBoundingClientRect(); // need to initialize in the beginning

      const parentElementComputedStyle = window.getComputedStyle(parentElement);
      const marginRight = Number(parentElementComputedStyle.marginRight) || 0;
      parentElementBoundingClientRect.rightWithoutMarginRight = Number(parentElementBoundingClientRect?.right || 0) - marginRight; // const parentElementBoundingClientRect

      const modalDialogBoundingClientRect = modalDialog.getBoundingClientRect();

      const positionYData = this._getPositionYData({
        parentElementBoundingClientRect,
        modalDialogBoundingClientRect,
        modalDialog
      });

      const positionXData = this._getPositionXData({
        parentElementBoundingClientRect,
        modalDialogBoundingClientRect
      });

      modalDialog = this._setPositionY({
        modalDialog,
        parentElementBoundingClientRect,
        positionYData,
        headerHeight,
        footerHeight,
        isSidebar,
        hasExtraSidebar,
        sidebarModalMaxWidth,
        isMobile
      });
      modalDialog = this._setPositionX({
        modalDialog,
        parentElement,
        parentElementBoundingClientRect,
        positionYData,
        positionXData,
        headerHeight,
        isSidebar,
        modalContainerClassNames
      });

      if (moveToModalSection) {
        modalDialog.style.visibility = 'hidden';

        const modalSection = this._getModalContainerSection(modalDialog);

        if (overlay) {
          modalSection.appendChild(overlay);
        }

        modalSection.appendChild(modalDialog);
        modalDialog.style.visibility = '';
      }
    }

    _getCurrentTab(element) {
      return element.closest('.tab-parent');
    }

    _getModalContainerSection(element) {
      const currentTab = this._getCurrentTab(element);

      let modalSection = document.querySelector('.global-modals');

      if (!currentTab) {
        return modalSection;
      }

      let modalContainerSelector = '.tab-modals-container';
      const isFullscreen = currentTab.querySelector('.is-fullscreen');

      if (isFullscreen) {
        modalContainerSelector = '.tab-fullscreen-modals-container';
        const fullScreenModalContainerSection = currentTab.querySelector(modalContainerSelector);

        if (!fullScreenModalContainerSection) {
          const newDiv = document.createElement('div');
          newDiv.classList.add('tab-fullscreen-modals-container');
          isFullscreen.append(newDiv);
          return newDiv;
        }
      }

      modalSection = currentTab.querySelector(modalContainerSelector);

      if (!modalSection) {
        const newDiv = document.createElement('div');
        newDiv.classList.add('tab-modals-container');
        currentTab.append(newDiv);
        modalSection = newDiv;
      }

      return modalSection;
    } // @action
    // setupOverlay (
    //   {
    //     modalOverLayZIndex,
    //     modalOverLayZIndexOnFullscreen
    //   },
    //   element
    // ) {
    //   element.classList.add('tab-grid__modal-overlay', 'modal-overlay')
    //   // const currentTab = this._getCurrentTab(element)
    //   const modalSection = this._getModalContainerSection(element)
    //   modalSection.appendChild(element)
    //   // if (currentTab) {
    //   //   const modalSection = currentTab.lastElementChild
    //   //   const hasModalFullscreen = currentTab.querySelector('.tab-grid__modal--fullscreen')
    //   //   const hasPosFullscreen = currentTab.querySelector('.pos-view--fullscreen')
    //   //   const hasModalSidebar = currentTab.lastElementChild.querySelector('.modal-sidebar')
    //   //   const onSidebar = element.closest('.app-grid__sidebar')
    //   //   // if (hasModalFullscreen || hasPosFullscreen || onSidebar || hasModalSidebar) {
    //   //   //   element.style.zIndex = modalOverLayZIndexOnFullscreen
    //   //   // } else {
    //   //   //   element.style.zIndex = modalOverLayZIndex
    //   //   // }
    //   //   modalSection.appendChild(element)
    //   // } else {
    //   //   element.style.zIndex = modalOverLayZIndexOnFullscreen
    //   //   const modalSection = document.querySelector('.global-modals.no-tab')
    //   //   if (modalSection) {
    //   //     modalSection.appendChild(element)
    //   //   }
    //   // }
    //   // app-grid__content
    //   document.querySelector('.modal-overlay').addEventListener('wheel', function (e) {
    //     e.preventDefault()
    //   }, { passive: false })
    // }


    closeDialog(_ref2) {
      let {
        component,
        prop
      } = _ref2;
      Ember.set(component, prop, false);
    } // this should be deprecated
    // @deprecated


    closeOverlay(_ref3) {
      let {
        component,
        isShowOverlay,
        onToggleModalDialog,
        onToggle,
        onClose
      } = _ref3;

      if (isShowOverlay) {
        if (onToggleModalDialog) {
          onToggleModalDialog();
        }

        if (onToggle) {
          onToggle();
        }

        if (onClose) {
          onClose();
        }
      }
    }

    _getParentElement(_ref4) {
      let {
        modalContainerClassNames,
        modalDialog
      } = _ref4;
      const isListDropdown = R.includes('is-list-dropdown', modalContainerClassNames);
      const isListBtn = R.includes('is-list-btn', modalContainerClassNames);
      const isListSearcher = R.includes('is-list-searcher', modalContainerClassNames);
      const isTooltip = R.includes('is-tooltip', modalContainerClassNames);
      let parentElement = modalDialog.parentElement;

      if (isListBtn || isListSearcher) {
        let parentElementBtn = parentElement;

        while (parentElementBtn) {
          if (parentElementBtn.classList.contains('button')) {
            break;
          }

          parentElementBtn = parentElementBtn.previousElementSibling;
        }

        parentElement = parentElementBtn;

        if (RA.isNilOrEmpty(parentElement)) {
          parentElement = modalDialog.parentElement;
        }
      }

      if (isListDropdown) {
        let hasListDropdown = false;

        if (RA.isNotNilOrEmpty(parentElement)) {
          hasListDropdown = parentElement?.classList?.contains('list-dropdown');
        }

        if (!hasListDropdown) {
          parentElement = modalDialog.closest('.list-dropdown').querySelector('button');
        }

        if (RA.isNilOrEmpty(parentElement)) {
          parentElement = modalDialog.closest('.list-dropdown').querySelector('span');
        }

        if (RA.isNilOrEmpty(parentElement)) {
          parentElement = modalDialog.closest('.list-dropdown');
        }

        if (RA.isNilOrEmpty(parentElement)) {
          parentElement = modalDialog.previousElementSibling;
        }
      }

      if (isTooltip) {
        parentElement = modalDialog.closest('.info-tooltip-container'); // parentElement = modalDialog.closest('.info-tooltip')

        if (RA.isNilOrEmpty(parentElement)) {
          parentElement = modalDialog.parentElement.parentElement;
        }
      } // prevent modalDialog without parentElement


      if (RA.isNilOrEmpty(parentElement)) {
        parentElement = modalDialog.parentElement;
      }

      return parentElement;
    }

    _getPositionYData(_ref5) {
      let {
        parentElementBoundingClientRect,
        modalDialogBoundingClientRect
      } = _ref5;
      const maxTop = 85;
      const maxBottom = 85;
      const parentElementTop = parentElementBoundingClientRect.top;
      const parentElementBottom = parentElementBoundingClientRect.bottom; // const modalDialogBoundingClientRect = modalDialog.getBoundingClientRect()

      const modalDialogHeight = modalDialogBoundingClientRect.height;
      const screenHeight = document.documentElement.clientHeight;
      const screenHeightWithoutFooter = screenHeight - 60;
      const topSpace = parentElementTop - maxTop; // 75px navbar

      const bottomSpace = screenHeightWithoutFooter - parentElementBottom;
      let top = parentElementBottom + 5;

      if (bottomSpace >= modalDialogHeight) {
        return {
          top
        };
      }

      let bottom = screenHeight - parentElementTop + 5;

      if (topSpace >= modalDialogHeight) {
        return {
          bottom
        };
      }

      if (bottomSpace > topSpace && bottomSpace > 200) {
        // render on bottom
        bottom = maxBottom;
        return {
          top,
          bottom
        };
      } // render on top


      top = maxTop;

      if (topSpace > 200) {
        // topSpace > bottomSpace
        return {
          top,
          bottom
        };
      }

      return {
        top,
        bottom,
        maxHeight: screenHeightWithoutFooter - maxBottom
      };
    }

    _getPositionXData(_ref6) {
      let {
        parentElementBoundingClientRect,
        modalDialogBoundingClientRect
      } = _ref6;
      const parentElementLeft = parentElementBoundingClientRect.left;
      const parentElementRight = parentElementBoundingClientRect.rightWithoutMarginRight;
      const screenWidth = document.documentElement.clientWidth - 10;
      const modalDialogWidth = modalDialogBoundingClientRect.width; // const leftSpace = pscreenWidth - parentElementLeft

      const rightSpace = screenWidth - parentElementLeft - 10;

      if (modalDialogWidth >= screenWidth) {
        return {
          left: 10,
          right: 10
        };
      }

      if (rightSpace >= modalDialogWidth) {
        // has space on the right, from parentElementLeft
        return {
          left: parentElementLeft
        };
      }

      let rightPos = parentElementRight;

      if (rightPos >= screenWidth) {
        // parent element right is out of window
        rightPos = screenWidth;
      } // check if has available space


      const newLeft = rightPos - modalDialogWidth;

      if (newLeft < 0) {
        return {
          left: 10,
          right: 10
        };
      } // has available space


      return {
        right: screenWidth - rightPos
      };
    }

    _setPositionY(_ref7) {
      let {
        modalDialog,
        parentElementBoundingClientRect,
        positionYData,
        headerHeight = 46,
        footerHeight = 61,
        isSidebar,
        hasExtraSidebar,
        sidebarModalMaxWidth,
        isMobile
      } = _ref7;
      const screenWidth = document.documentElement.clientWidth;
      const screenHeight = document.documentElement.clientHeight;
      const modalContainerContent = modalDialog.querySelector('.modal-container__content');

      if (!modalContainerContent) {
        return modalDialog;
      }

      let maxHeight;
      let maxWidth; // @TODO: this does not belong here? should side bar be a general or specific solution?
      // ie. should size be set on each side bar? then modal does not need to adjust width. only check if it exceeds max width
      // the contents should be what determines width

      if (isSidebar) {
        maxHeight = screenHeight - headerHeight;
        const defaultsidebarModalMaxWidth = screenWidth * 0.98;
        sidebarModalMaxWidth = sidebarModalMaxWidth || defaultsidebarModalMaxWidth;
        const sidebarModalFullSize = screenWidth; // - (screenWidth * 0.01)

        maxWidth = sidebarModalFullSize;

        if (maxWidth > sidebarModalMaxWidth) {
          maxWidth = sidebarModalMaxWidth;
        }

        if (isMobile) {
          maxWidth = sidebarModalFullSize;
        } // modalContainerContent.style.width = `${maxWidth}px`


        modalContainerContent.style.height = `${maxHeight}px`;
        modalContainerContent.style.maxHeight = `${maxHeight}px`;
        modalContainerContent.style.maxWidth = `${maxWidth}px`;
      } else {
        modalContainerContent.style.height = '100%';

        if (RA.isNotNil(positionYData.maxHeight)) {
          // modalContainerContent.style.height = `${positionYData.maxHeight}px`
          modalDialog.style.height = `${positionYData.maxHeight}px`;
        }

        if (RA.isNotNil(positionYData.top)) {
          // modalContainerContent.style.top = `${positionYData.top}px`
          modalDialog.style.top = `${positionYData.top}px`;
        }

        if (RA.isNotNil(positionYData.bottom)) {
          // modalContainerContent.style.bottom = `${positionYData.bottom}px`
          modalDialog.style.bottom = `${positionYData.bottom}px`;
        }
      }

      return modalDialog;
    }

    _setPositionX(_ref8) {
      let {
        modalDialog,
        parentElement,
        parentElementBoundingClientRect,
        positionYData,
        positionXData,
        headerHeight,
        isSidebar,
        modalContainerClassNames = ''
      } = _ref8;
      modalDialog.style.position = 'fixed';
      isSidebar = isSidebar || false;
      const isOversized = modalDialog?.isOversized || false;

      if (isSidebar) {
        modalDialog.style.top = `${headerHeight}px`;
        modalDialog.style.right = '0px';
        setTimeout(() => {
          // remove slide-in-right after animation
          modalDialog.classList.remove('slide-in-right');
        }, 1000);
      } else if (isOversized) {
        modalDialog.style.top = `calc(50% + ${headerHeight}px)`;
        modalDialog.style.left = '50%'; // modalDialog.style.transform = `translate(-50%, calc(-50% - ${headerHeight / 2}px))`
      } else {
        if (RA.isNotNil(positionXData.right)) {
          modalDialog.style.right = `${positionXData.right}px`;
        }

        if (RA.isNotNil(positionXData.left)) {
          modalDialog.style.left = `${positionXData.left}px`;
        }
      }

      return modalDialog;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "setupModal", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "setupModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeDialog", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "closeDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeOverlay", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "closeOverlay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_getParentElement", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_getParentElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_getPositionYData", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_getPositionYData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_getPositionXData", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "_getPositionXData"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_setPositionY", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "_setPositionY"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_setPositionX", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "_setPositionX"), _class.prototype)), _class));
  _exports.default = ModalsService;
});