define("client/pods/flows/lists/events/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let {
        data = {}
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data = [{
        //   value: 'menu',
        //   label: 'menu item',
        //   category: 'bot'
        // }, {
        value: 'view',
        label: 'page view',
        category: 'page',
        hasCriterias: false
      }, {
        //   value: 'click',
        //   label: 'button click',
        //   category: 'page'
        // }, {
        value: 'signup',
        label: 'signup',
        category: 'event',
        hasCriterias: false
      }, {
        //   value: 'login',  //welcoming people back
        //   label: 'login',
        //   category: 'event'
        // }, {
        // // @TODO: add retargeting options. eg. if added to cart,
        // // @TODO: then come back after x days, give discount
        //   value: 'addToCart',
        //   label: 'add to cart',
        //   category: 'event'
        // }, {
        //   value: 'checkoutBefore',
        //   label: 'before checkout',
        //   category: 'event'
        // }, {
        value: 'checkoutAfter',
        label: 'after checkout',
        category: 'event',
        hasCriterias: true // }, {
        //   value: 'botOnly',
        //   label: 'bot only',

      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});