define("client/pods/dispatch/lists/processes/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        name: '',
        code: '',
        defaultExportCustomSheets: [],
        showProductSetWithChoices: false
      };
    },

    populate(data, attrs) {
      data.defaultExportCustomSheets = this._transformDefaultExportCustomSheets(data);

      if (!R.path(['defaultExportSheets'], data)) {
        data.defaultExportSheets = R.pathOr([], ['defaultExportSheetsKeyList'], data);
      }

      this._super(data, attrs);
    },

    isSettingsAllowed: Ember.computed('_data.{_key,isEditable}', function () {
      if (this?._data?._key || this?._data?.isEditable) {
        return true;
      }

      return false;
    }),

    _transformDefaultExportCustomSheets(data) {
      return R.pipe(R.propOr([], 'defaultExportCustomSheets'), R.map(defaultExportCustomSheet => {
        return this.dataManager.setAsRecord({
          adapterName: 'dispatch/lists/processes/custom-worksheets',
          data: defaultExportCustomSheet
        });
      }))(data);
    }

  });

  _exports.default = _default;
});