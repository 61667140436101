define("client/pods/components/operator/change-logs/panels-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    adapterName: 'operator/change-logs',

    init() {
      this.setup();

      this._super(...arguments);
    },

    setup() {
      const tab = this.tab;
      const model = tab.model;
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model,
        tab
      });
      this.set('dirty', dirty);
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, dirty.getData('name'));
    }

  });

  _exports.default = _default;
});