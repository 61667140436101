define("client/pods/components/filters/save-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t7ADZKry",
    "block": "{\"symbols\":[\"onCloseDialogAction\",\"@label\",\"@filters\",\"@tab\"],\"statements\":[[5,\"elements/element-btn-with-modal-dialog\",[],[[\"@label\",\"@icon\",\"@tooltipLabel\",\"@modalDialogTitle\",\"@modalSize\",\"@closeOnOverlayClick\",\"@onSubmit\"],[[23,0,[\"label\"]],\"far fa-star\",[28,\"if\",[[23,2,[]],\"\",\"save this search\"],null],\"save this search\",\"small\",true,[28,\"fn\",[[23,0,[\"reset\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-section\",[[3,\"will-destroy\",[[23,0,[\"willDestroyElement\"]]]]],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@label\",\"@model\",\"@tab\"],[\"label\",\"filters\",\"label\",[23,3,[]],[23,4,[]]]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/element-input\",[],[[\"@autofocus\",\"@value\"],[true,[23,3,[\"_data\",\"label\"]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n  \"],[5,\"layouts/panels/panel-footer\",[],[[],[]],{\"statements\":[[0,\"\\n    \"],[5,\"layouts/footer-btns\",[],[[],[]],{\"statements\":[[0,\"\\n      \"],[5,\"layouts/btns-group\",[],[[\"@classNames\"],[\"is-right\"]],{\"statements\":[[0,\"\\n        \"],[5,\"elements/btn-cancel\",[],[[\"@isRunning\",\"@onSubmit\"],[[23,0,[\"search\",\"saveFiltersTask\",\"isRunning\"]],[23,1,[]]]]],[0,\"\\n        \"],[5,\"elements/btn-save\",[],[[\"@disabled\",\"@isRunning\",\"@onSubmit\"],[[28,\"if\",[[23,3,[\"_data\",\"label\"]],false,true],null],[23,0,[\"search\",\"saveFiltersTask\",\"isRunning\"]],[28,\"perform\",[[23,0,[\"search\",\"saveFiltersTask\"]],[28,\"hash\",null,[[\"filters\",\"onCloseDialogAction\"],[[23,3,[]],[23,1,[]]]]]],null]]]],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/filters/save-btn/template.hbs"
    }
  });

  _exports.default = _default;
});