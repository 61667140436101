define("client/pods/components/dispatch/panels-display/list-summary/summary-item/component", ["exports", "ramda", "client/mixins/big", "client/utils/nventor"], function (_exports, R, _big, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_big.default, (_dec = Ember._action, (_obj = {
    tagName: '',
    selectedProcesses: null,

    init() {
      this._super(...arguments);

      let showDerivations = false;
      const dispatchSummaryType = this?.settings?.settingsModel?._data?.dispatchSummaryType || 'collapseUnderProductName';

      if (dispatchSummaryType === 'expandToShowAliases') {
        showDerivations = true;
      }

      this.set('showDerivations', showDerivations);
    },

    product: Ember.computed('productsList.[]', 'item.itemKey', function () {
      const productsList = this.productsList || [];
      const itemKey = this.get('item.itemKey');
      return R.find(R.pathEq(['inventory', '_data', 'itemKey'], itemKey), productsList);
    }),
    showDerivationBtn: Ember.computed('derivationsProxy.[]', function () {
      const derivations = this?.derivationsProxy || [];
      const itemId = this?.item?.itemId || '';
      const derivationsItemIds = R.pluck('itemId')(derivations);

      if (R.all(R.equals(itemId))(derivationsItemIds)) {
        return false;
      }

      return true;
    }),
    derivationsProxy: Ember.computed('item.derivations.[]', function () {
      const derivations = this?.item?.derivations || [];
      const groupedDerivationsByItemId = R.pipe(R.groupBy(R.prop('itemId')), R.map(derivations => {
        const dispatchProcesses = R.pluck('dispatchProcess')(derivations);
        const firstDerivation = R.pipe(_nventor.default.safeHeadOr({}), R.clone)(derivations);
        const newDispatchProcess = {};
        R.forEach(dispatchProcess => {
          newDispatchProcess[dispatchProcess.name] = dispatchProcess.qty;
        })(dispatchProcesses);
        firstDerivation.dispatchProcess = newDispatchProcess;
        firstDerivation.totalQty = R.pipe(R.pluck('totalQty'), R.sum)(derivations);
        firstDerivation.totalReturnQty = R.pipe(R.pluck('totalReturnQty'), R.sum)(derivations);
        return firstDerivation;
      }), R.values)(derivations);
      return groupedDerivationsByItemId;
    }),
    totalQty: Ember.computed('item', 'product', 'allowedDispatchProcessesCodes.[]', function () {
      const item = this.item;
      const product = this.product;
      const allowedDispatchProcessesCodes = this.allowedDispatchProcessesCodes || [];
      const qtys = R.pipe(R.pluck('qty'), R.mapObjIndexed((qty, dispatchProcessCode) => {
        if (R.includes(dispatchProcessCode, allowedDispatchProcessesCodes)) {
          return qty;
        }

        return 0;
      }), R.values, R.reject(R.isNil))(item);
      const sumBig = this.sumArray(qtys);
      const roundingInventoryQty = this.settings.getProp('roundingInventoryQty', 0, product);
      return sumBig.toFixed(roundingInventoryQty);
    }),

    toggleDerivation() {
      const showDerivations = this.showDerivations;
      this.set('showDerivations', R.not(showDerivations));
    }

  }, (_applyDecoratedDescriptor(_obj, "toggleDerivation", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleDerivation"), _obj)), _obj)));

  _exports.default = _default;
});