define("client/pods/grapesjs/plugins/tabs/components/TabContents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (dc, _ref) => {
    let {
      intl = {
        t: txt => txt
      },
      ...config
    } = _ref;
    dc.addType(config.typeTabContents, {
      model: {
        defaults: {
          name: intl.t('tab contents'),
          draggable: false,
          droppable: false,
          copyable: false,
          removable: false,
          classes: config.classTabContents,
          traits: [],
          ...config.tabContentsProps
        }
      }
    });
  };

  _exports.default = _default;
});