define("client/pods/components/elements/element-toggle-btn/component", ["exports", "client/mixins/has-dropdown", "ramda-adjunct"], function (_exports, _hasDropdown, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @deprecated
   * use ElementTogglerContainer or ElementBtn isToggler=true
   */
  var _default = Ember.Component.extend(_hasDropdown.default, {
    tagName: 'span',
    attributeBindings: ['disabled', 'tabindex'],
    tabindex: 0,
    translate: true,
    classNames: ['element-toggle-btn-container'],
    classNameBindings: ['isBtn:button', 'isBtn:button__toggle', 'disabled', 'toggleIsOn:on'],
    isBtn: true,
    iconClassName: null,
    // defaultToggleState: null, //determine if toggleIsOn should be true/false on init
    toggleIsOn: false,
    // property to toggle
    delegateToggle: false,
    // if true, let toggleAction perform actual toggling on/off
    confirmMsg: null,
    hasToggleAbility: Ember.computed('hasToggle', function () {
      return RA.defaultWhen(RA.isNilOrEmpty, true)(this.hasToggle);
    }),
    submit: function () {
      if (!this.hasToggleAbility) {
        return;
      }

      if (this.disabled) {
        return;
      }

      let confirmed = true;
      const confirmMsg = this.confirmMsg;

      if (confirmMsg) {
        confirmed = window.confirm(confirmMsg);
      }

      if (confirmed) {
        if (!this.delegateToggle) {
          this.toggleProperty('toggleIsOn');
        }

        if (this.onSubmit) {
          this.onSubmit(this);
        }
      }
    },
    click: function (e) {
      this.submit(e);
    },
    keyUp: function (e) {
      if (e.which === 13) {
        this.submit(e);
      }
    }
  });

  _exports.default = _default;
});