define("client/pods/components/channels/shop/delivery-date-options/pickup-date-options-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lI1PaM2O",
    "block": "{\"symbols\":[\"@model\",\"@errors\",\"@isReadonly\",\"@addItemComponent\",\"@customAdapterName\",\"@isEditing\",\"@addItemOptions\",\"@isNew\",\"@onAddItem\",\"@onAfterAddItem\",\"@onCloseDialogAction\",\"@parentModel\",\"@tab\"],\"statements\":[[5,\"channels/shop/delivery-date-options/info-editor\",[],[[\"@dispatchType\",\"@model\",\"@errors\",\"@isReadonly\",\"@subtitle\",\"@allowTodayLabel\",\"@latestShippingTimeLabel\",\"@latestShippingTimeDescription\",\"@minDayLabel\",\"@maxDayLabel\",\"@disallowedDispatchDaysLabel\",\"@addItemComponent\",\"@customAdapterName\",\"@isEditing\",\"@addItemOptions\",\"@isNew\",\"@onAddItem\",\"@onAfterAddItem\",\"@onCloseDialogAction\",\"@parentModel\",\"@tab\"],[\"pickupLocations\",[23,1,[]],[23,2,[]],[23,3,[]],\"pickup date subtitle\",\"allow today pickup\",\"latest pickup time\",\"latest pickup time description\",\"delivery min days\",\"delivery max days\",\"select store closed day\",[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,12,[]],[23,13,[]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/delivery-date-options/pickup-date-options-editor/template.hbs"
    }
  });

  _exports.default = _default;
});