define("client/mixins/sales-persons", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * helper methods for setting up lists for tabs and panels
   */
  var _default = Ember.Mixin.create((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    init() {
      this._super(...arguments);

      this.setData('salesPersons', []);
    },

    /**
     * transform salesPersons array into array of models
     */
    transformSalesPersons(rawData) {
      const self = this;

      if (R.is(Array, rawData.salesPersons)) {
        const transformed = rawData.salesPersons.map(function (data) {
          return self._createSalesPersonModel(data);
        });
        rawData.salesPersons = transformed;
      }
    },

    _createSalesPersonModel(data) {
      // returned from api
      // data = {
      //   _key: 12321312,
      //   name: 'darwin'
      // }
      // looks like this in db
      // data = {
      //   _key: 12321312,
      //   _id: members/12321312
      //   name: 'darwin'
      // }
      if (!data._id) {
        // this is for backwards compatibility
        data._id = `members/${data._key}`;
      }

      return this.dataManager.setAsRecord({
        adapterName: 'users',
        data: data
      });
    },

    addSalesPerson(personModel) {
      const data = personModel.serialize();
      const salesPersons = this.getOrSetData('salesPersons', []);
      const hasPerson = salesPersons.findBy('_data._key', data._key);

      if (!hasPerson) {
        const salesPerson = this._createSalesPersonModel(data);

        this.getData('salesPersons').pushObject(salesPerson);
      }
    },

    removeSalesPerson(salesPerson) {
      const salesPersons = this.getData('salesPersons');
      salesPersons.removeObject(salesPerson);
    }

  }, (_applyDecoratedDescriptor(_obj, "addSalesPerson", [_dec], Object.getOwnPropertyDescriptor(_obj, "addSalesPerson"), _obj), _applyDecoratedDescriptor(_obj, "removeSalesPerson", [_dec2], Object.getOwnPropertyDescriptor(_obj, "removeSalesPerson"), _obj)), _obj)));

  _exports.default = _default;
});