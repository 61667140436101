define("client/pods/components/products/products-editor/variants-editor/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/errors", "client/utils/nventor"], function (_exports, R, RA, _errors, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_errors.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    dataManager: Ember.inject.service(),
    productToBeLinked: Ember.A([]),
    adapterName: 'products',
    hasPressedDone: false,

    init() {
      this._super(...arguments);

      this.set('productToBeLinked', []);

      if (this.isEditing || this.isNew) {
        const model = this.model;
        model.setData('productsToBeUnlinked', []);
        const variants = model.getData('variants') || [];
        R.forEach(variant => {
          const options = variant.getData('options') || [];
          R.forEach(option => {
            if (!option._data.optionUid) {
              const optionUid = _nventor.default.random.alphaNum(10);

              option.setData('optionUid', optionUid); // this is for legacy purposes. for when variant children were created without a optionUid

              option.set('noInitialOptionUid', true); // false until saved
            }
          })(options);
        })(variants);
        const originalModel = this.originalModel;
        model.updateVariantChildrenList({
          originalModel,
          setInitialValue: this.isEditing
        });

        if (RA.isNilOrEmpty(variants)) {
          model.addVariant();
        }

        if (this.isNew) {
          const originalVariants = model.getData('variants') || [];

          if (RA.isNotNilOrEmpty(originalVariants)) {
            const originalVariantsClone = R.map(variant => {
              return this.dataManager.copyRecord('products/variants', variant);
            })(originalVariants);
            this.set('originalVariants', originalVariantsClone);
          }
        }
      }
    },

    isReadonlyAndAvailable: Ember.computed('model._data.hasVariants', 'isReadonly', function () {
      if (!this.get('model._data.hasVariants')) {
        return true;
      }

      return R.propOr(false, 'isReadonly')(this);
    }),
    showCancelVariants: Ember.computed('hasPressedDone', 'isNew', function () {
      const isNew = this.isNew;

      if (!isNew) {
        return true;
      }

      if (this.hasPressedDone) {
        return true;
      }

      return false;
    }),

    updateVariantChildrenList(_ref) {
      let {
        autoFill,
        autoFillCommand,
        isNew,
        onAfter
      } = _ref;
      const model = this.model;
      const originalModel = this.originalModel || {};

      if (RA.isNilOrEmpty(isNew)) {
        isNew = R.pathOr(false, ['args', 'isNew'])(this);
      }

      if (RA.isNotNilOrEmpty(model)) {
        // clearing name using autofillcommand clear will only clear the active variantChildren
        // productToBeLinked needs to filter out any possible product that was added on the same time editing (maybe the user was adding new variantChild then changes mind and clear all)
        if (R.equals(autoFill, 'name'), R.equals(autoFillCommand, 'clear')) {
          // PUT all active variantChild with keys to productsToBeUnlinked
          const productsToBeUnlinked = model.getData('productsToBeUnlinked') || [];
          R.pipe(R.filter(variantChild => RA.isNotNilOrEmpty(variantChild._data._key)), R.forEach(variantChild => {
            const variantChildCopy = this.dataManager.copyRecord('products/variants/children', variantChild);
            productsToBeUnlinked.pushObject(variantChildCopy);
          }))(this._createVariantChildrenActiveProxy); // filter all active variantChild from productToBeLinked

          const inactiveKeys = R.map(R.pathOr('', ['_data', '_key']))(this._createVariantChildrenInactiveProxy);
          let productToBeLinked = this?.productToBeLinked || [];
          productToBeLinked = R.filter(product => R.includes(product._data._key, inactiveKeys))(productToBeLinked);
          this.set('productToBeLinked', productToBeLinked);
        }

        model.updateVariantChildrenList({
          originalModel,
          autoFill,
          autoFillCommand,
          isNew,
          onAfter
        });
      }
    },

    activeVariantChildren: Ember.computed('model._data.variantChildren.{[],@each.synced}', function () {
      const variantChildren = this?.model?._data?.variantChildren || [];
      return R.filter(variantChild => {
        const status = variantChild?._data?.status || 'active';
        return R.equals(status, 'active');
      })(variantChildren);
    }),
    inactiveVariantChildren: Ember.computed('model._data.variantChildren.{[],@each.synced}', '_createVariantChildrenInactiveProxy.[]', function () {
      const variantChildren = this?.model?._data?.variantChildren || [];
      return R.filter(variantChild => {
        const status = variantChild?._data?.status || 'active';
        const permanentlyUnavailable = R.pathOr(false, ['_data', 'permanentlyUnavailable'])(variantChild);

        if (R.equals(status, 'inactive') && !permanentlyUnavailable) {
          return true;
        }

        return false;
      })(variantChildren);
    }),
    permanentlyUnavailableVariantChildren: Ember.computed('model._data.variantChildren.{[],@each.synced}', '_createVariantChildrenInactiveProxy.[]', function () {
      const variantChildren = this?.model?._data?.variantChildren || [];
      return R.filter(variantChild => {
        const permanentlyUnavailable = R.pathOr(false, ['_data', 'permanentlyUnavailable'])(variantChild);

        if (permanentlyUnavailable) {
          return true;
        }

        return false;
      })(variantChildren);
    }),
    _createVariantChildrenActiveProxy: Ember.computed('model._data._createVariantChildren.{[],@each.synced}', function () {
      const _createVariantChildren = this?.model?._data?._createVariantChildren || [];

      return R.filter(variantChild => {
        const status = variantChild?._data?.status || 'active';
        return R.equals(status, 'active');
      })(_createVariantChildren);
    }),
    _createVariantChildrenInactiveProxy: Ember.computed('model._data._createVariantChildren.{[],@each.synced}', function () {
      const _createVariantChildren = this?.model?._data?._createVariantChildren || [];

      return R.reject(variantChild => {
        const status = variantChild?._data?.status || 'active';
        return R.equals(status, 'active');
      })(_createVariantChildren);
    }),

    cancelVariantsDirty() {
      this.set('isEditingVariants', false);
      this.set('isDoneEditingVariants', true);

      if (this.isNew) {
        const originalVariants = this.originalVariants || [];
        const model = this?.model || {};
        model.setData('variants', originalVariants);
        this.set('originalVariants', null);
      }

      if (!this.isEditing) {
        return;
      }

      this.crud.cancelEditing({
        adapterName: 'products',
        dirty: this.dirtyClone
      });
      this.set('dirtyClone', null);
    },

    setupVariantsDirty(model) {
      if (this.isEditing) {
        const dirtyClone = this.crud.setupDirty({
          adapterName: 'products',
          model
        });
        this.set('dirtyClone', dirtyClone);
      }

      if (this.isNew) {
        const originalVariants = model.getData('variants') || [];

        if (RA.isNotNilOrEmpty(originalVariants)) {
          const originalVariantsClone = R.map(variant => {
            return this.dataManager.copyRecord('products/variants', variant);
          })(originalVariants);
          this.set('originalVariants', originalVariantsClone);
        }
      }

      this.set('isEditingVariants', true);
      this.set('isDoneEditingVariants', false);
    },

    doneEditingVariants(model) {
      const originalModel = this.originalModel;

      if (this.isEditing) {
        model.setData('variants', this?.dirtyClone?._data?.variants || []);
      }

      model.filterEmptyVariants();
      model.updateVariantChildrenList({
        originalModel,
        setInitialValue: false,
        isNew: this.isNew
      });
      this.set('hasPressedDone', true);
      this.set('isEditingVariants', false);
      this.set('isDoneEditingVariants', true);
      this.set('dirtyClone', null);
    },

    variantOptionsCanBeSaved: Ember.computed('model._data.variants.{[],@each.synced}', 'dirtyClone._data.variants.{[],@each.synced}', 'isEditing', function () {
      let isAllowed = true;
      let variantDataToCheck = this?.model?._data?.variants || [];

      if (this.isEditing) {
        variantDataToCheck = this?.dirtyClone?._data?.variants || [];
      }

      R.forEach(variant => {
        const name = variant.getData('name');

        if (RA.isNilOrEmpty(name)) {
          const options = variant?._data?.options || [];
          R.forEach(option => {
            const optionName = option?._data?.name || '';

            if (RA.isNotNilOrEmpty(optionName)) {
              isAllowed = false;
            }
          })(options);
        }
      })(variantDataToCheck);
      return isAllowed;
    }),
    actions: {
      addVariant() {
        const model = this.model;

        if (this.isEditing && RA.isNotNilOrEmpty(this.dirtyClone)) {
          this.dirtyClone.addVariant();
        } else {
          model.addVariant();
        }
      },

      removeVariant(model, variant) {
        const msg = this.intl.t('are you sure you want to remove');

        if (window.confirm(msg)) {
          model.removeVariant(variant);
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "updateVariantChildrenList", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateVariantChildrenList"), _obj), _applyDecoratedDescriptor(_obj, "cancelVariantsDirty", [_dec2], Object.getOwnPropertyDescriptor(_obj, "cancelVariantsDirty"), _obj), _applyDecoratedDescriptor(_obj, "setupVariantsDirty", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setupVariantsDirty"), _obj), _applyDecoratedDescriptor(_obj, "doneEditingVariants", [_dec4], Object.getOwnPropertyDescriptor(_obj, "doneEditingVariants"), _obj)), _obj)));

  _exports.default = _default;
});