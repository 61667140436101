define("client/pods/components/flows/table-view/data-detail-row/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // import * as R_ from 'ramda-extension'
  let FlowsTableViewDataDetailRowComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, (_class = class FlowsTableViewDataDetailRowComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "isForm", _descriptor2, this);
    }

    get flowPayloadSteps() {
      const tableViewOptions = this.args.tableViewOptions || {};
      const flow = tableViewOptions?.flow;
      const flowType = flow?._data?.type;

      if (flowType === 'form') {
        this.isForm = true;
        const formSteps = R.pipe(R.pathOr([], ['_data', 'formSteps']), R.reject(R.pathEq(['_data', 'key'], '')))(flow);
        const steps = R.pipe(R.map(R.pathOr([], ['_data', 'steps'])), R.flatten)(formSteps);
        return steps;
      }

      const steps = R.pipe(R.pathOr([], ['_data', 'steps']), R.reject(R.pathEq(['_data', 'key'], '')))(flow); // @TODO: DARWIN what is this for???
      // const replySteps = R.filter(R.pathEq(['_data', 'hasReply'], true))(steps)
      // const gameSteps = R.filter(R.pathEq(['_data', 'actionType'], 'games'))(steps)
      // const allSteps = R.concat(replySteps)(gameSteps)
      // return allSteps

      return steps;
    }

    *customSearchTask(step, filters, value) {
      const key = step?._data?.key || '';

      if (key) {
        const customFilter = filters.getData('custom') || {};
        customFilter[key] = value;
        filters.setData('custom', customFilter);
      }

      yield this.args.onSearchTask.perform({
        adapterName: 'flows/data',
        component: this,
        filters
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isForm", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "customSearchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "customSearchTask"), _class.prototype)), _class));
  _exports.default = FlowsTableViewDataDetailRowComponent;
});