define("client/pods/components/menus/progress-bar/step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ShjCKD5q",
    "block": "{\"symbols\":[\"@itemClassNames\",\"@index\"],\"statements\":[[7,\"li\",true],[11,\"class\",[29,[\"progress-step \",[23,0,[\"stepStatus\"]],\" \",[28,\"if\",[[23,1,[]],[23,1,[]]],null]]]],[8],[0,\"\\n  \"],[7,\"span\",true],[8],[0,\"\\n    \"],[1,[28,\"concat\",[[28,\"tt\",[\"step\"],null],[28,\"sum\",[[23,2,[]],1],null]],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/menus/progress-bar/step/template.hbs"
    }
  });

  _exports.default = _default;
});