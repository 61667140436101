define("client/pods/documents/model", ["exports", "client/pods/base/model", "client/mixins/sales-persons", "client/pods/documents/mixins/linking", "client/pods/documents/mixins/details", "client/pods/documents/mixins/calculations", "client/pods/documents/mixins/contacts", "client/mixins/date", "ramda", "ramda-adjunct", "client/config/environment", "client/utils/nventor", "client/constants/index"], function (_exports, _model, _salesPersons, _linking, _details, _calculations, _contacts, _date, R, RA, _environment, _nventor, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend(_salesPersons.default, _linking.default, _date.default, _details.default, _calculations.default, _contacts.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    documents: Ember.inject.service(),
    eInvoicesService: Ember.inject.service('extensions/apps/tw/e-invoices'),
    unroundedCurrency: 8,
    selectedLinkFromDocKeys: null,
    showHCTModal: false,
    print: true,
    printWaybill: true,
    printAsPackingList: false,

    init() {
      this._super(...arguments);

      this.set('selectedLinkFromDocKeys', []); // used for when copied to dirty.

      this.set('_attrs', ['eInvoicesCollection']);
      this.set('mergeDetailsProps', ['fromMaster', 'exclPrice', 'itemCode', 'itemKey', 'ref', 'taxRate', 'unitKey', 'source', 'sourceKey']);
      this.set('linkTemp', _model.default.create());
      this.set('linkAttrs', [{
        label: 'payment method',
        param: 'paymentMethod',
        // this corresponds to _link_paymentMethod method in linking mixin
        component: 'documents/links-conflict/payment-method-conflict',
        group: [// 'isConsignment',
        'paymentMethodKey', 'paymentTerms', 'paymentTermsKey']
      }, {
        param: 'paymentDueDateZ',
        label: 'payment due date',
        component: 'documents/links-conflict/payment-due-conflict'
      }, 'account', {
        param: 'shop',
        // this corresponds to _link_shop method in linking mixin
        label: 'shop',
        group: ['source', 'sourceKey'],
        component: 'documents/links-conflict/shop-conflict'
      }, {
        param: 'taxMethodKey',
        label: 'tax method',
        translate: true,
        translatePrefix: 'tax_'
      }, {
        param: 'ref',
        label: 'reference'
      }, {
        param: 'taxNumber',
        label: 'tax number'
      }, // {
      //   param: 'discount',
      //   group: ['discountType', 'discountAmount', 'discountRate'],
      //   component: 'documents/links-conflict/discount-conflict'
      // },
      'address', {
        param: 'transporter',
        // this corresponds to _link_transporter method in linking mixin
        label: 'transporter',
        group: ['transporterLabel', 'transporterKey'],
        component: 'documents/links-conflict/transporter-conflict',
        excludeForDocName: 'returns'
      }, {
        param: 'notes',
        component: 'documents/links-conflict/notes-conflict'
      }]);
      this.set('adapters', {
        eInvoicesCollection: 'extensions/apps/tw/e-invoices/collection'
      });
      this.set('sortArrayInstructions', [{
        direction: 'ascend',
        prop: '_data.index'
      }, {
        direction: 'ascend',
        prop: '_data.childIndex'
      }, {
        direction: 'ascend',
        prop: '_data.grandChildIndex'
      }]);
    },

    defaults() {
      return {
        rewardData: {},
        currency: 'NTD',
        timestampZ_printed: '',
        printBatchId: '',
        isPrinted: false,
        printHistory: [],
        createRefundWorkflow: false,
        createRefundWorkflowAmount: '0',
        detailsInventoryData: [],
        contactRelationship: this._getDefaultContactRelationship(),
        storeLocationData: {
          label: '',
          address: ''
        },
        twEInvoiceCarrierType: ''
      };
    },

    newBlank(_ref) {
      let {
        docType,
        docName,
        paymentMethodsList = [],
        paymentTermsList = [],
        paymentMethodKey = '',
        paymentTerms = '',
        isFromShortcut = false,
        isFromDocument = false,
        isDuplicateDocumentShortcut = false,
        contact = '',
        contactKey = '',
        currency = '',
        paymentDueDateZ = '',
        person = '',
        ref = '',
        salesPersons = [],
        duplicateData = {},
        contactsModel,
        tabParent = {},
        twEInvoicePrint
      } = _ref;
      const todayZ = this.getStartOfDayZ();
      const settingsModel = this.settings.getModel();
      this.setData('docType', docType);
      this.setData('docName', docName);
      this.setData('taxMethodKey', settingsModel.getData(`${docType}TaxMethodKey`, this.get('constants.taxMethods.exclusive')));

      if (docType === 'sales' && docName === 'invoices') {
        this.setData('autoPrintInvoices', settingsModel.getData('autoPrintInvoices'));
      }

      if (paymentMethodKey === '') {
        if (docName === 'consignments' || docName === 'consignments-returns') {
          paymentMethodKey = this.get('constants.paymentMethods.consignment');
        } else {
          paymentMethodKey = settingsModel.getData(`${docType}PaymentMethodKey`, this.get('constants.paymentMethods.cash'));
        }
      }

      const isPos = R.propEq('isPos', true)(tabParent);

      if (isPos) {
        // Set Payment Method to Cash for POS
        paymentMethodKey = this.get('constants.paymentMethods.cash');
        this.setData('taxMethodKey', this.get('constants.taxMethods.inclusive'));
        const hasTwEInvoicePrint = RA.isNotNilOrEmpty(twEInvoicePrint);

        if (hasTwEInvoicePrint) {
          this.setData('twEInvoicePrint', twEInvoicePrint);
        } else {
          this.setData('twEInvoicePrint', true);
        }

        this.setData('isPos', true);
      }

      const paymentMethod = paymentMethodsList.findBy('_data.value', paymentMethodKey);
      this.loadPaymentMethod({
        paymentMethod,
        paymentMethodKey
      });

      if (paymentTerms === '') {
        const paymentTermsKey = settingsModel.getData(`${docType}PaymentTermsKey`);
        paymentTerms = paymentTermsList.findBy('_data._key', paymentTermsKey);
        this.loadPaymentTerms({
          paymentTerms,
          paymentTermsKey
        });
      } else {
        this.setData('paymentTerms', paymentTerms);
      }

      this.setData('discountType', settingsModel.getData('discountType', this.get('constants.discountTypes.rate')));
      this.setData('discountMethod', this.get('constants.discountMethods.onTotal'));
      this.setData('dateZ', todayZ);
      this.setData('roundingCurrency', settingsModel.getData('roundingCurrency'));
      this.setData('unroundedCurrency', settingsModel.getData('unroundedCurrency'));
      this.setData('roundingInventoryQty', settingsModel.getData('roundingInventoryQty'));
      this.setData('roundingInventoryPrice', settingsModel.getData('roundingInventoryPrice'));

      if (docName === 'quotes') {
        // @TODO: validity is currently set at 1 month from date. should get from settings
        const validityDate = this.getMoment(todayZ).add(1, 'months');
        this.setData('validityDateZ', validityDate.toISOString());
      }

      if (docName === 'orders') {
        this.setData('deliveryDateOption', this.get('constants.deliveryDateOptions.single'));
        this.setData('deliveryDateZ', todayZ);
      }

      if (this.hasPaymentReminders) {
        this.setData('paymentRemindersAutoEmail', settingsModel.getData('paymentRemindersAutoEmail'));
      } // if (isFromShortcut) {
      //   this.setData('contact', contact)
      //   this.setData('contactKey', contactKey)
      //   // this.setData('createdDateZ', createdDateZ)
      //   this.setData('currency', currency)
      //   // this.setData('dateZ', dateZ)
      //   // this.setData('docNo', docNo)
      //   this.setData('paymentDueDateZ', paymentDueDateZ)
      //   this.setData('person', person)
      //   this.setData('ref', ref)
      //   this.setData('salesPersons', salesPersons)
      //   // this.setData('status', status)
      // }
      // @TODO: refactor


      if (isDuplicateDocumentShortcut) {
        this.set('_data', duplicateData);
        const details = this.get('_data.details'); // used to remove linking property
        // remove if linking is needed for duplicate document

        R.forEach(detail => {
          detail.setData('ref', '');
          detail.setData('fromRef', '');
          detail.setData('fromDocKey', '');
          detail.setData('fromDocType', '');
          detail.setData('fromDocName', '');
          detail.setData('fromDocNo', '');
          detail.setData('fromMaster', '');
          detail.setData('fromDetailKey', '');
        })(details);
        this.setData('docNo', '');
        this.setData('ref', '');
        this.setData('_id', '');
        this.setData('_key', '');
        this.setData('_rev', '');
        this.setData('externalId', '');
        this.setData('externalUrl', '');
        this.setData('dispatchStatus', '');
        this.setData('isDispatch', '');
        this.setData('dispatchProcess', '');
        this.setData('paymentDate', '');
      } // load contact to fill default telephone, address, etc


      if (RA.isNotNilOrEmpty(contactsModel)) {
        this.loadContact({
          docType,
          contact: contactsModel,
          paymentMethodsList,
          paymentTermsList
        });
      } // if (docType === 'sales' && docName === 'invoices') {
      //   this.setData('autoPrintInvoices', settingsModel.getData('autoPrintInvoices'))
      // }

    },

    hasNotifications: Ember.computed('notifications.[]', function () {
      return R.pipe(R.pathOr([], ['notifications']), R.propSatisfies(R.gte(R.__, 1), 'length'))(this);
    }),
    validTaxNumber: Ember.computed('_data.{companyName,twEInvoiceCarrierType,taxNumber}', function () {
      const isB2B = R.pathEq(['_data', 'twEInvoiceCarrierType'], 'b2b')(this);

      if (!isB2B) {
        return true;
      }

      const validTaxNumber = this._data?.taxNumber?.length === 8;

      if (!validTaxNumber) {
        return false;
      }

      const validCompanyName = this._data?.companyName?.length >= 4;

      if (!validCompanyName) {
        return false;
      }

      return true;
    }),
    docAdapterName: Ember.computed('_data.{docType,docName}', function () {
      const docType = this.get('_data.docType');
      const docName = this.get('_data.docName');
      return `documents/${docType}/${docName}`;
    }),
    title: Ember.computed('_data.{docType,docName}', function () {
      const docType = this.get('_data.docType');
      const docName = this.get('_data.docName');
      return `${docType}-${docName}`;
    }),
    hasRewardEventForOrder: Ember.computed('_data.{rewardData._key,rewardPointApplied,fetchedContactRewardData.memberLevelId,fetchedContactRewardData._key}', 'rewardEventForOrder.rewardsEventId', function () {
      const rewardKey = this._data?.rewardData?._key;
      const memberLevelIdOrKey = this._data?.fetchedContactRewardData?.memberLevelId || this._data?.fetchedContactRewardData?._key;
      const rewardEventForOrder = this.rewardEventForOrder?.rewardsEventId;
      const rewardPointApplied = this._data?.rewardPointApplied;

      if (!rewardKey) {
        return false;
      }

      if (memberLevelIdOrKey) {
        return true;
      }

      if (rewardEventForOrder) {
        return true;
      }

      if (rewardPointApplied) {
        return true;
      }

      return false;
    }),

    _getDefaultContactRelationship() {
      const docType = this?._data?.docType || '';
      const isContactRelationshipAllowed = this.isContactRelationshipAllowed;

      if (isContactRelationshipAllowed) {
        if (R.equals(docType, 'sales')) {
          return 'customer';
        } else {
          return 'supplier';
        }
      }

      return '';
    },

    latestWaybillStatus: Ember.computed('_data.transporterWaybillRecords.[]', function () {
      return R.pathOr({}, ['_data', 'transporterWaybillRecords', 0, 'shippingWaybillStatuses', 0])(this);
    }),
    latestWaybillStatusMessage: Ember.computed('latestWaybillStatus.message', function () {
      return R.pathOr('', ['latestWaybillStatus', 'message'])(this);
    }),
    waybillsStatusesCount: Ember.computed('_data.transporterWaybillRecords.[]', function () {
      return R.pathOr('', ['_data', 'transporterWaybillRecords', 0, 'shippingWaybillStatuses', 'length'])(this);
    }),
    isContactRelationshipAllowed: Ember.computed('_data.docName', function () {
      const docName = this?._data?.docName || '';
      const allowedDocName = ['invoices', 'returns'];

      if (R.includes(docName, allowedDocName)) {
        return true;
      }

      return false;
    }),

    /**
     * create model when dataManager is not available. this is when in public viewing mode
     */
    _createModelForPublicMode(data) {
      return Ember.Object.create({
        _data: data
      });
    },

    docStatus: Ember.computed('_data.{status,dispatchProcess}', 'hasDispatchProcess', function () {
      const status = this.getData('status');
      let docStatus = status;

      if (this.get('_data.isMerged')) {
        return 'merged_document';
      }

      if (!this.hasDispatchProcess) {
        return docStatus;
      }

      const dispatchProcess = this.getData('dispatchProcess');

      if (status === this.get('constants.documentsStatus.final')) {
        docStatus = dispatchProcess;
      }

      if (!docStatus) {
        docStatus = this.get('constants.dispatchProcess.unprepared');
      }

      return docStatus;
    }),
    statusClass: Ember.computed('_data.{status,dispatchProcess}', 'hasDispatchProcess', function () {
      const status = this.get('_data.status');
      const dispatchProcess = this.get('_data.dispatchProcess');

      if (this.isMerged) {
        return 'is-danger';
      }

      if (status === this.get('constants.documentsStatus.draft')) {
        return 'is-warning';
      }

      if (status === this.get('constants.documentsStatus.void')) {
        return 'is-danger';
      }

      if (!this.hasDispatchProcess) {
        return 'is-success';
      }

      if (dispatchProcess === this.get('constants.dispatchProcess.awaitingPayment') && status === this.get('constants.documentsStatus.final')) {
        return 'is-dark';
      }

      if (dispatchProcess === this.get('constants.dispatchProcess.awaitingRecurringPayment') && status === this.get('constants.documentsStatus.final')) {
        return 'is-dark';
      }

      if (dispatchProcess === this.get('constants.dispatchProcess.dispatched') && status === this.get('constants.documentsStatus.final')) {
        return 'is-success';
      }

      if (dispatchProcess === this.get('constants.dispatchProcess.unprepared') || dispatchProcess === '') {
        return 'is-info';
      }

      if (dispatchProcess === this.get('constants.dispatchProcess.outOfStock') || dispatchProcess === '') {
        return 'is-black';
      }

      return 'is-dark';
    }),
    // dispatchStatusClass: computed('_data.dispatchStatus', function () {
    //   const dispatchStatus = this.get('_data.dispatchStatus')
    //   if (dispatchStatus === this.get('constants.dispatchStatus.onHold')) {
    //     return 'is-dark'
    //   }
    //   if (dispatchStatus === this.get('constants.dispatchStatus.undispatched') || !dispatchStatus) {
    //     return 'is-info'
    //   }
    //   if (dispatchStatus === this.get('constants.dispatchStatus.dispatched')) {
    //     return 'is-success'
    //   }
    // }),
    allowSplit: Ember.computed('_data.{isDispatched,details.[],groupId}', function () {
      if (this.get('_data.isDispatched')) {
        return false;
      }

      const details = this.get('_data.details') || [];

      if (details.length < 1) {
        return false;
      }

      if (details.length === 1) {
        const detail = details.get('firstObject');

        if (detail?._data?.qty) {
          const qty = parseFloat(detail.getData('qty')) || 0;

          if (qty <= 1) {
            return false;
          }
        } else {
          return false;
        }
      }

      const groupId = this?._data?.groupId || '';

      if (RA.isNotNilOrEmpty(groupId)) {
        return false;
      }

      return true;
    }),
    hasPreOrderDetails: Ember.computed('_data.{status,details.[]}', 'hasPreOrderDetailsCount', function () {
      if (this?._data?.status !== 'final') {
        return false;
      }

      const preOrderDetailsCount = this.hasPreOrderDetailsCount || 0;
      return R.gt(preOrderDetailsCount, 0);
    }),
    hasPreOrderDetailsCount: Ember.computed('_data.{status,details.[]}', function () {
      if (this?._data?.status !== 'final') {
        return false;
      }

      return R.pipe(R.pathOr([], ['_data', 'details']), R.filter(detail => {
        return R.pathEq(['_data', 'productStatus'], 'preOrder')(detail);
      }), R.length)(this);
    }),
    allowPrintWaybill: Ember.computed('_data.{transporterExtension,status}', function () {
      const transporterExtension = this.getData('transporterExtension');
      const hasAllowedTransporterExtension = R.anyPass([R.equals(this.get('constants.transporterExtensions.hct')), R.equals(this.get('constants.transporterExtensions.tCat')), R.equals(this.get('constants.transporterExtensions.sevenEleven'))])(transporterExtension);
      const status = this.getData('status');
      const isFinal = status === this.get('constants.documentsStatus.final');

      if (hasAllowedTransporterExtension && isFinal) {
        return true;
      }

      return false;
    }),
    isTransporterTCat: Ember.computed('_data.transporterExtension', function () {
      const tCat = this.get('constants.transporterExtensions.tCat');
      return R.pathEq(['_data', 'transporterExtension'], tCat)(this);
    }),
    isTransporterHct: Ember.computed('_data.transporterExtension', function () {
      const hct = this.get('constants.transporterExtensions.hct');
      return R.pathEq(['_data', 'transporterExtension'], hct)(this);
    }),
    isTransporterSevenEleven: Ember.computed('_data.transporterExtension', function () {
      const sevenEleven = this.get('constants.transporterExtensions.sevenEleven');
      return R.pathEq(['_data', 'transporterExtension'], sevenEleven)(this);
    }),
    isDispatched: Ember.computed('synced', '_data.{isDispatched,status}', function () {
      const isDispatched = this.getData('isDispatched');
      const status = this.getData('status');

      if (R.equals(isDispatched, true) && R.equals(status, 'final')) {
        return true;
      }

      return false;
    }),
    notDispatched: Ember.computed('isDispatched', function () {
      return !this.isDispatched;
    }),
    allowTransporterQuickChange: Ember.computed('notDispatched', 'isDispatchedBeforeToday', 'isTransporterSevenEleven', function () {
      if (this.notDispatched) {
        return true;
      } else if (!this.isDispatchedBeforeToday) {
        return true;
      } else if (this.isTransporterSevenEleven) {
        return true;
      }

      return false;
    }),
    hasEInvoiceInfoChanged: Ember.computed('_data.{email,taxNumber,address,status}', 'requirePrintEInvoice', function () {
      return Math.random();
    }),
    hasEInvoices: Ember.computed('synced', 'eInvoicesCollection.{[],synced}', function () {
      const invoices = this.get('eInvoicesCollection._data.invoices') || [];

      if (invoices.length > 0) {
        return true;
      }

      return false;
    }),
    successfulEInvoices: Ember.computed('synced', 'eInvoicesCollection.{[],synced}', function () {
      const invoices = this.get('eInvoicesCollection._data.invoices') || [];
      return R.filter(R.pathEq(['_data', 'status'], 'successful'), invoices);
    }),
    successfulEInvoiceNumbers: Ember.computed('successfulEInvoices.[]', function () {
      const successfulEInvoices = this.successfulEInvoices || [];
      return R.pipe(R.map(R.path(['_data', 'number'])), R.reject(RA.isNilOrEmpty), R.join(', '))(successfulEInvoices);
    }),
    lastSuccessfulEInvoice: Ember.computed('synced', 'eInvoicesCollection.{[],synced}', function () {
      const invoices = this.get('eInvoicesCollection._data.invoices') || [];
      const successful = R.filter(R.pathEq(['_data', 'status'], 'successful'), invoices);

      if (successful.length > 0) {
        return R.head(successful);
      }

      return false;
    }),
    lastSuccessfulEInvoiceNumber: Ember.computed('synced', 'lastSuccessfulEInvoice._data.number', function () {
      return R.pathOr(false, ['lastSuccessfulEInvoice', '_data', 'number'])(this);
    }),
    paymentLink: Ember.computed('_data.{externalUrl,email,ref,isRecurringChild,isRecurringMaster,shopUrl}', function () {
      const isRecurringOrder = this._data?.isRecurringChild || this._data?.isRecurringMaster || false;
      const shopUrl = this._data?.shopUrl || '';

      if (isRecurringOrder && shopUrl) {
        const shopUrl = this._data?.shopUrl || '';
        const email = this._data?.email || '';
        const ref = this._data?.ref || '';
        return `${_environment.default.APP.bigordrHost}/shop/${shopUrl}/orders/status/${email}/${ref}`;
      }

      const externalUrl = this?._data?.externalUrl;

      if (externalUrl) {
        return `${_environment.default.APP.bigordrHost}/erp/payments/card/doc/${externalUrl}`;
      }

      return '';
    }),
    paymentLinkPos: Ember.computed('paymentLink', function () {
      if (this.paymentLink) {
        return `${this.paymentLink}?isPos=true`;
      }

      return this.paymentLink;
    }),
    refOrDocNo: Ember.computed('_data.{ref,docNo,sendRefAsDocNo}', function () {
      const sendRefAsDocNo = this.get('_data.sendRefAsDocNo');
      const ref = this.get('_data.ref');

      if (sendRefAsDocNo && ref) {
        return ref;
      }

      return this.get('_data.docNo');
    }),
    isDispatchedBeforeToday: Ember.computed('_data.{dispatchStatus,dispatchDateZ}', function () {
      const dispatchStatus = this.getData('dispatchStatus');
      const dispatchDateZ = this.getData('dispatchDateZ');

      if (this.isBeforeToday(dispatchDateZ) && dispatchStatus === this.get('constants.dispatchStatus.dispatched')) {
        return true;
      }

      return false;
    }),
    // @TODO use new isComplete computed in base. simply set requiredAttrs in model. still need to work out how details will also work
    // @TODO simply add _data.details.@each.isComplete? and then for details also set requiredAttrs
    isComplete: Ember.computed('_data.{contact,dateZ,details.@each.hasIncomplete}', 'incompleteItems.[]', function () {
      // @NOTE: overwrites base.isComplete
      const self = this;
      let hasIncomplete = false;

      if (!self.getData('contact')) {
        hasIncomplete = true;
      }

      if (!self.getData('dateZ')) {
        hasIncomplete = true;
      }

      const details = self.getData('details') || [];
      let detailsIncomplete = 0;
      details.forEach(function (detail) {
        if (detail.get('hasIncomplete')) {
          detailsIncomplete++;
        }
      });

      if (details.length === 1 && detailsIncomplete === 1) {
        hasIncomplete = true;
      }

      if (detailsIncomplete > 1) {
        hasIncomplete = true;
      }

      self.addToIncomplete('info', hasIncomplete);
      const incompleteItems = this.incompleteItems;

      if (incompleteItems.length > 0) {
        hasIncomplete = true;
      }

      if (hasIncomplete) {
        return false;
      }

      return true;
    }),
    isDispatchableToday: Ember.computed('_data.{status,dispatchProcess,dateZ}', function () {
      if (this.getData('isDispatched') && this.getData('status') === this.get('constants.documentsStatus.final')) {
        return true;
      }

      const dateZ = this.getData('dateZ');

      if (this.isSameOrBefore(dateZ)) {
        return true;
      }

      return false;
    }),
    isMissingHCTDataClassNames: Ember.computed('_data.{address,status,isDispatched,transporterExtension,transporterRegionIsOuter}', 'IsOuter', 'sevenElevenWaybillNotificationModel', 'hasAddressChangeRequired', function () {
      let classNames = '';
      const transporterExtension = this.getData('transporterExtension');

      if (transporterExtension === 'hct') {
        if (this.isOuterRegion) {
          classNames = 'is-warning';
        }
      }

      if (this.isTransporterSevenEleven) {
        if (this.hasAddressChangeRequired) {
          classNames = 'is-danger';
        }
      }

      return classNames;
    }),
    hasAddressChangeRequired: Ember.computed('latestWaybillStatus.status', function () {
      if (this.latestWaybillStatus.status === 'addressChangeRequired') {
        return true;
      }

      return false;
    }),
    isOuterRegion: Ember.computed('_data.transporterWaybillRecord', '_data.transporterWaybillRecords.length', function () {
      const latestShippingWaybill = R.pipe(R.pathOr([], ['_data', 'transporterWaybillRecords']), R.sortWith([R.descend(R.prop('timestampZ_latest'))]), R.pathOr({}, [0]))(this);
      return R.pathEq(['transporterRegionIsOuter'], true)(latestShippingWaybill);
    }),
    hasDispatchToday: Ember.computed('_data.{expectedDispatchDateZ,isDispatched}', function () {
      const expectedDispatchDateZ = this.getData('expectedDispatchDateZ');
      const isDispatched = this.getData('isDispatched');

      if (this.dateService.isSameOrBefore(expectedDispatchDateZ) && !isDispatched) {
        return true;
      }

      return false;
    }),

    /**
     * custom populate function.
     * will create child detail models
     * @param {object/json} data - model data
     */
    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let attrs = arguments.length > 1 ? arguments[1] : undefined;
      const self = this;
      data = data || {};
      const docType = data?.docType;
      const docName = data?.docName;

      if (docType && docName) {
        this.set('adapterName', `documents/${docType}/${docName}`);
      }

      const notifications = self._transformNotifications(data);

      self.set('notifications', notifications);
      const detailsRequiredAttrs = self.get('detailsRequiredAttrs'); // create models for each detail

      if (data.details) {
        data.details = data.details.map(detailData => {
          const commissionBaseCost = detailData.commissionBaseCost || '';

          if (RA.isNilOrEmpty(detailData.commissionBaseCostFinal)) {
            detailData.commissionBaseCostFinal = commissionBaseCost;
          }

          if (this.dataManager) {
            const detailModel = this.dataManager.setAsRecord({
              adapterName: 'documents/details',
              data: detailData
            });
            detailModel.set('requiredAttrs', detailsRequiredAttrs);
            return detailModel;
          } else {
            return this._createModelForPublicMode(detailData);
          }
        });
      }

      if (data.eInvoices) {
        const eInvoicesCollection = self.transformEInvoices(data);
        this.set('eInvoicesCollection', eInvoicesCollection);
      }

      self.transformSalesPersons(data);
      const returnsDetails = self.transformReturnsDetails(data);
      self.set('returnsDetails', returnsDetails);

      const returnDocs = self._transformReturnsDoc({
        returnsDetails
      });

      self.set('returnDocs', returnDocs);
      data.paymentData = self._transformPaymentData(data);
      data.groups = self._transformGroups(data);
      data = self._transformCreditCardRefundsSchedules(data); // populate docName info
      // self.overwriteData(data)

      this._super(data, attrs);
    },

    _transformNotifications(data) {
      const notifications = R.pipe(R.propOr([], 'notifications'), R.map(notification => {
        return this.dataManager.setAsRecord({
          adapterName: 'notifications',
          data: notification
        });
      }))(data);
      delete data.notifications;
      return notifications;
    },

    _transformGroups(data) {
      const groups = R.propOr([], 'groups')(data);
      return R.map(docData => {
        const docModel = this.dataManager.setAsRecord({
          adapterName: `documents/${docData.docType}/${docData.docName}`,
          data: docData
        });
        const ref = docModel?._data?.ref || '';
        const newDetails = R.pipe(R.pathOr([], ['_data', 'details']), R.map(detail => {
          detail.set('isGroupChild', true);
          detail.set('docRef', ref);
          return detail;
        }))(docModel) || [];
        const newReturnsDetails = R.pipe(R.pathOr([], ['returnsDetails']), R.map(detail => {
          detail.set('isGroupChild', true);
          detail.set('docRef', ref);
          return detail;
        }))(docModel) || [];
        docModel.setData('details', newDetails);
        docModel.setData('returnsDetails', newReturnsDetails);
        return docModel;
      })(groups);
    },

    _transformPaymentData(data) {
      return R.pathOr({}, ['payments', 0])(data);
    },

    transformEInvoices(data) {
      const eInvoicesCollection = data.eInvoices || [];
      delete data.eInvoices;
      const collectionData = {
        master: data._key,
        invoices: eInvoicesCollection
      };

      if (this.dataManager) {
        return this.dataManager.setAsRecord({
          adapterName: this.get('adapters.eInvoicesCollection'),
          data: collectionData
        });
      } else {
        return this._createModelForPublicMode(collectionData);
      }
    },

    transformReturnsDetails(data) {
      const returns = data.returns || [];
      delete data.returns;
      let returnsDetailsModel = [];

      if (this.dataManager) {
        let adapterName;

        if (data.docType === 'sales') {
          adapterName = 'documents/sales/returns/details';
        } else {
          adapterName = 'documents/purchases/returns/details';
        }

        returnsDetailsModel = R.map(returnDetailData => {
          returnDetailData.docData = returnDetailData.doc;
          return this.dataManager.setPartialRecord({
            adapterName,
            data: returnDetailData
          });
        })(returns);
      } else {
        returnsDetailsModel = R.map(returnDetailData => {
          return this._createModelForPublicMode(returnDetailData);
        })(returns);
      }

      return R.map(detail => {
        detail.set('isReturnDetail', true);
        return detail;
      })(returnsDetailsModel);
    },

    _transformReturnsDoc(_ref2) {
      let {
        returnsDetails
      } = _ref2;

      if (RA.isNilOrEmpty(returnsDetails)) {
        return [];
      }

      return R.pipe(R.groupBy(R.path(['_data', 'docData', 'docNo'])), R.values, R.map(group => {
        const docData = R.pathOr({}, ['0', '_data', 'docData'])(group);
        return {
          docData: this.dataManager.setPartialRecord({
            adapterName: 'documents/sales/returns',
            data: docData
          }),
          details: group
        };
      }))(returnsDetails);
    },

    getGroupedDocumentsPaymentMethodKeys(_ref3) {
      let {
        document,
        hasGroups = true,
        hasPaymentLastFiveDigits = false,
        isJoinResults = true,
        isTranslate = true
      } = _ref3;
      const paymentMethodKey = document.getData('paymentMethodKey');
      const paymentLastFiveDigits = document.getData('paymentLastFiveDigits') || '';
      let translatedPaymentMethodKey = '';

      if (isTranslate) {
        if (RA.isNotNilOrEmpty(paymentLastFiveDigits) && hasPaymentLastFiveDigits) {
          translatedPaymentMethodKey = `${this.intl.t(paymentMethodKey)} (${this.intl.t('account last 5 digits')}: ${paymentLastFiveDigits})`;
        } else {
          translatedPaymentMethodKey = this.intl.t(paymentMethodKey);
        }
      } else {
        translatedPaymentMethodKey = paymentMethodKey;
      }

      let paymentMethodKeys = [translatedPaymentMethodKey];

      if (document && hasGroups) {
        const groups = document.getData('groups') || [];
        R.forEach(group => {
          const groupPaymentMethodKey = group.getData('paymentMethodKey');
          const groupPaymentLastFiveDigits = group.getData('paymentLastFiveDigits') || '';
          let groupTranslatedPaymentMethodKey = '';

          if (isTranslate) {
            if (RA.isNotNilOrEmpty(groupPaymentLastFiveDigits) && hasPaymentLastFiveDigits) {
              groupTranslatedPaymentMethodKey = `${this.intl.t(groupPaymentMethodKey)} (${this.intl.t('account last 5 digits')}: ${groupPaymentLastFiveDigits})`;
            } else {
              groupTranslatedPaymentMethodKey = this.intl.t(groupPaymentMethodKey);
            }
          } else {
            groupTranslatedPaymentMethodKey = groupPaymentMethodKey;
          }

          paymentMethodKeys.push(groupTranslatedPaymentMethodKey);
        })(groups);
      }

      paymentMethodKeys = R.pipe(R.reject(RA.isNilOrEmpty), R.uniq)(paymentMethodKeys);

      if (isJoinResults) {
        return R.join(', ', paymentMethodKeys);
      } else {
        return paymentMethodKeys;
      }
    },

    getGroupedDocumentsTotal(_ref4) {
      let {
        document,
        hasGroups = true
      } = _ref4;
      const roundingCurrency = document.getData('roundingCurrency') || 0;
      const paymentMethodKey = document.getData('paymentMethodKey');
      let exclTotal = document.getDataBig('exclTotal');
      let taxTotal = document.getDataBig('taxTotal');
      let inclTotal = document.getDataBig('inclTotal');
      let rewardPointEarned = document.getDataBig('rewardPointEarned');
      let returnRewardAdjustmentPoints = document.getDataBig('returnRewardAdjustmentPoints');
      let returnRewardAdjustmentAmount = document.getDataBig('returnRewardAdjustmentAmount');
      let cashCollectionInclTotal = this.big.newBig(0);

      if (paymentMethodKey === 'cash') {
        cashCollectionInclTotal = document.getDataBig('cashCollectionInclTotal');
      }

      if (document && hasGroups) {
        const groups = document.getData('groups') || [];
        R.forEach(group => {
          const groupExclTotal = group.getData('exclTotal') || 0;
          const groupTaxTotal = group.getData('taxTotal') || 0;
          const groupInclTotal = group.getData('inclTotal') || 0;
          const groupRewardPointEarned = group.getData('rewardPointEarned') || 0;
          const groupReturnRewardAdjustmentPoints = group.getData('returnRewardAdjustmentPoints') || 0;
          const groupReturnRewardAdjustmentAmount = group.getData('returnRewardAdjustmentAmount') || 0;
          const groupCashCollectionInclTotal = group.getData('cashCollectionInclTotal') || 0;
          const groupPaymentMethodKey = group.getData('paymentMethodKey');
          exclTotal = exclTotal.add(groupExclTotal);
          taxTotal = taxTotal.add(groupTaxTotal);
          inclTotal = inclTotal.add(groupInclTotal);
          rewardPointEarned = rewardPointEarned.add(groupRewardPointEarned);
          returnRewardAdjustmentPoints = returnRewardAdjustmentPoints.add(groupReturnRewardAdjustmentPoints);
          returnRewardAdjustmentAmount = returnRewardAdjustmentAmount.add(groupReturnRewardAdjustmentAmount);

          if (groupPaymentMethodKey === 'cash') {
            cashCollectionInclTotal = cashCollectionInclTotal.add(groupCashCollectionInclTotal);
          }
        })(groups);
      }

      return {
        exclTotal: exclTotal.toFixed(roundingCurrency),
        taxTotal: taxTotal.toFixed(roundingCurrency),
        inclTotal: inclTotal.toFixed(roundingCurrency),
        cashCollectionInclTotal: cashCollectionInclTotal.toFixed(0),
        rewardPointEarned: rewardPointEarned.toFixed(0),
        returnRewardAdjustmentPoints: returnRewardAdjustmentPoints.toFixed(0),
        returnRewardAdjustmentAmount: returnRewardAdjustmentAmount.toFixed(roundingCurrency)
      };
    },

    // sortedDetailsNetReturns: computed('sortedDetailsWithDiscountAndReward.[]', 'returnsDetails.[]', function () {
    //   const returnsDetails = this.returnsDetails || []
    //   console.log('====returnsDetails======')
    //   console.log(returnsDetails)
    //   console.log('====sortedDetailsWithDiscountAndReward======')
    //   const sortedDetailsWithDiscountAndReward = this.sortedDetailsWithDiscountAndReward || []
    //   console.log(this.sortedDetailsWithDiscountAndReward)
    //   if (RA.isNotNilOrEmpty(returnsDetails)) {
    //   }
    //   return sortedDetailsWithDiscountAndReward
    // }),

    /**
     * returns true if discount is a %
     * @type {Boolean}
     */
    isDiscountRate: Ember.computed('_data.discountType', function () {
      const discType = this.getData('discountType');

      if (discType === this.get('constants.discountTypes.rate')) {
        return true;
      }

      return false;
    }),
    isTaxExclusive: Ember.computed('_data.taxMethodKey', function () {
      const taxMethodKey = this.getData('taxMethodKey'); // no taxMethodKey (none) is also treated as exclusive

      if (taxMethodKey === this.get('constants.taxMethods.exclusive') || taxMethodKey === this.get('constants.taxMethods.none')) {
        return true;
      }

      return false;
    }),
    isPurchases: Ember.computed('_data.docType', function () {
      if (this.get('_data.docType') === 'purchases') {
        return true;
      }

      return false;
    }),
    isSales: Ember.computed('_data.docType', function () {
      if (this.get('_data.docType') === 'sales') {
        return true;
      }

      return false;
    }),
    isCashBeforeDelivery: Ember.computed('_data.{docType,docName}', function () {
      if (this.get('_data.paymentMethodKey') === this.get('constants.paymentMethods.cashBeforeDelivery')) {
        return true;
      }

      return false;
    }),
    isCashOnDelivery: Ember.computed('_data.{docType,docName}', function () {
      if (this.get('_data.paymentMethodKey') === this.get('constants.paymentMethods.cash')) {
        return true;
      }

      return false;
    }),
    isSalesReturns: Ember.computed('_data.{docType,docName}', function () {
      if (this.get('_data.docType') === 'sales' && this.get('_data.docName') === 'returns') {
        return true;
      }

      return false;
    }),
    isSalesInvoices: Ember.computed('_data.{docType,docName}', function () {
      if (this.get('_data.docType') === 'sales' && this.get('_data.docName') === 'invoices') {
        return true;
      }

      return false;
    }),
    hasDispatchProcess: Ember.computed('_data.{docType,docName,paymentMethodKey}', function () {
      const docType = this.get('_data.docType');
      const docName = this.get('_data.docName');
      const paymentMethodKey = this.get('_data.paymentMethodKey');

      if (docType === 'sales' && (docName === 'invoices' || docName === 'consignments')) {
        if (docName === 'invoices' && paymentMethodKey === _index.default.paymentMethods.consignment) {
          return false;
        }

        return true;
      }

      return false;
    }),
    dispatchProcess: Ember.computed('_data.dispatchProcess', function () {
      if (this.get('_data.dispatchProcess') === '') {
        return 'unprepared';
      }

      return this.get('_data.dispatchProcess');
    }),
    hasTaxMethod: Ember.computed('_data.taxMethodKey', function () {
      const taxMethodKey = this.getData('taxMethodKey'); // no taxMethodKey (none) is also treated as exclusive

      if (taxMethodKey === this.get('constants.taxMethods.none') || taxMethodKey === '') {
        return false;
      }

      return true;
    }),
    hasRewards: Ember.computed('_data.rewardInclTotal', function () {
      const rewardInclTotal = parseInt(this._data.rewardInclTotal) || 0;

      if (rewardInclTotal > 0) {
        return true;
      }

      return false;
    }),
    hasDiscount: Ember.computed('_data.discInclTotal', function () {
      const discInclTotal = this.get('_data.discInclTotal') || 0;

      if (discInclTotal > 0) {
        return true;
      }

      return false;
    }),
    hasDiscountRate: Ember.computed('_data.discountRate', function () {
      if (!this.hasDiscount) {
        return false;
      }

      const discInclTotal = this.getData('discountRate') || 0;

      if (discInclTotal > 0) {
        return true;
      }

      return false;
    }),
    hasDiscountAmount: Ember.computed('hasDiscountRate', '_data.{discountType,discountAmount}', function () {
      if (!this.hasDiscount) {
        return false;
      }

      const discountType = this.getData('discountType') || '';

      if (discountType === this.get('constants.discountTypes.amount') && this.getData('discountAmount')) {
        return true;
      }

      return false;
    }),
    calculatedDiscExclTotal: Ember.computed('hasDiscountAmount', '_data.{discExclTotal,discInclTotal,details.[]}', function () {
      if (this.hasDiscountAmount) {
        if (this.getData('discExclTotal')) {
          return this.getData('discExclTotal');
        }

        const details = this.getData('details') || [];
        const roundingCurrency = this.getData('roundingCurrency') || 0;
        return this.big.sumPropAndRound(roundingCurrency, '_data.allocatedExclDiscountUnrounded', details);
      }

      return '';
    }),
    hasReturnsDetails: Ember.computed('returnsDetails.[]', 'synced', function () {
      const returnsDetails = this.returnsDetails || [];

      if (RA.isNilOrEmpty(returnsDetails)) {
        return false;
      }

      return true;
    }),
    returnsInclTotal: Ember.computed('returnsDetails.[]', 'synced', function () {
      let returnsDetails = this.returnsDetails || [];

      if (RA.isNilOrEmpty(returnsDetails)) {
        return 0;
      }

      returnsDetails = R.reject(R.pathEq(['_data', 'isSetChild'], true))(returnsDetails);
      const roundingCurrency = this.getData('roundingCurrency') || 0;
      return this.big.sumPropAndRound(roundingCurrency, '_data.postDiscInclAmt', returnsDetails);
    }),
    netInclTotal: Ember.computed('returnsInclTotal', 'returnsDetails.[]', 'synced', function () {
      let returnsDetails = this.returnsDetails || [];

      if (RA.isNilOrEmpty(returnsDetails)) {
        return 0;
      }

      returnsDetails = R.reject(R.pathEq(['_data', 'isSetChild'], true))(returnsDetails);
      return this._calculateNetInclTotal(returnsDetails);
    }),
    allowDuplicating: Ember.computed('_data.{rewardPointApplied,rewardPointEarned}', function () {
      if (this?._data?.rewardPointApplied || this?._data?.rewardPointEarned) {
        return false;
      }

      return true;
    }),

    _calculateNetInclTotal(returnsDetails) {
      const roundingCurrency = this.getData('roundingCurrency') || 0;
      const returnsInclTotal = this.big.sumPropAndRound(roundingCurrency, '_data.postDiscInclAmt', returnsDetails);
      const inclTotal = this.getData('inclTotal') || 0;
      return this.big.newBig(inclTotal).minus(returnsInclTotal).toFixed(roundingCurrency);
    },

    hasPaymentReminders: Ember.computed('_data.{docType,docName,paymentMethodKey,paymentCreditCardTransaction}', function () {
      const docType = this.getData('docType');
      const docName = this.getData('docName');

      if (docType === 'sales') {
        if (docName === 'quotes' || docName === 'invoices' || docName === 'orders') {
          if (this.getData('paymentMethodKey') === this.constants.paymentMethods.cashBeforeDelivery) {
            return true;
          }
        }
      }

      return false;
    }),
    hasDispatchStatus: Ember.computed('_data.{status,docType,docName}', function () {
      const docType = this.getData('docType');
      const docName = this.getData('docName');
      const status = this.getData('status');

      if (docType === 'sales' && (docName === 'invoices' || docName === 'consignments') && status === 'final') {
        return true;
      }

      return false;
    }),
    isDraft: Ember.computed('_data.status', function () {
      const status = this.getData('status');

      if (status === this.get('constants.documentsStatus.draft')) {
        return true;
      }

      return false;
    }),
    isConsignment: Ember.computed('_data.docName', function () {
      const docName = this.getData('docName');

      if (docName === 'consignments') {
        return true;
      }

      return false;
    }),
    requirePrintEInvoice: Ember.computed('_data.allowPrintInvoice', 'eInvoicesCollection._data.invoices.[]', function () {
      const settings = this.settings;
      const eInvoices = this.get('eInvoicesCollection._data.invoices') || [];
      const hasTwEInvoices = settings.hasTwEInvoices(this);

      if (hasTwEInvoices) {
        const successfulInvoices = R.filter(R.pathEq(['_data', 'status'], 'successful'), eInvoices);

        if (R.isEmpty(successfulInvoices)) {
          return true;
        }
      }

      return false;
    }),
    allowDispatchProcesses: Ember.computed('_data.{dispatchDateZ,timestampZ_latest,status}', function () {
      if (this.hasDispatchStatus) {
        const status = this.get('_data.status');

        if (status === this.get('constants.documentsStatus.void')) {
          return false;
        }

        const isDispatched = this.get('_data.isDispatched');
        const dispatchDateZ = this.get('_data.dispatchDateZ');
        const timestampZ = this.get('_data.timestampZ_latest');
        let beforeSevenDays = this.dateService.isBeforeNumberOfDays(7, dispatchDateZ);

        if (isDispatched && beforeSevenDays && this.dateService.isBeforeNumberOfDays(7, timestampZ)) {
          return false;
        }

        return true;
      }

      return false;
    }),
    allowUndoVoid: Ember.computed('_data.{isVoid,paymentCreditCardStatus}', function () {
      if (this.getData('paymentCreditCardStatus') === this.constants.paymentCreditCardStatus.refunded) {
        return false;
      }

      if (this.getData('isVoid') === true) {
        return true;
      }

      return false;
    }),
    hasBirthdayNotification: Ember.computed('_data.birthday', function () {
      const birthday = this?._data?.birthday || '';

      if (birthday) {
        const todayZ = this.dateService.getStartOfDayZ();
        const day = this.dateService.getMoment(birthday).date();
        const month = this.dateService.getMoment(birthday).month();
        const year = this.dateService.getMoment(todayZ).year();
        const birthdayInCurrentYear = this.getStartOfDayZ(`${month + 1}-${day}-${year}`);
        const dateDiff = this.dateService.getDateDiff(birthdayInCurrentYear, todayZ, 'days') || 0;

        if (Math.abs(dateDiff) <= 15) {
          return true;
        }
      }

      return false;
    }),

    loadPaymentMethod() {
      let {
        paymentMethod,
        paymentMethodKey
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (paymentMethodKey != null) {
        this.setData('paymentMethodKey', paymentMethodKey);
      }

      const hasPaymentTerms = paymentMethod.getData('hasPaymentTerms') || false;
      this.setData('paymentMethod', paymentMethod.getData('label'));
      this.setData('hasPaymentTerms', hasPaymentTerms);
      const paymentMethodIsNotDefault = R.pipe(R.hasPath(['_data', 'isNotEditable']), R.not)(paymentMethod);

      if (paymentMethodIsNotDefault) {
        const paymentMethodCustomLabel = paymentMethod.getData('customLabel');
        this.setData('paymentMethodCustomLabel', paymentMethodCustomLabel);
      } else {
        this.setData('paymentMethodCustomLabel', '');
        delete this._data.paymentMethodCustomLabel;
        this.updateSynced();
      }

      this.loadPaymentTerms();
    },

    loadPaymentTerms() {
      let {
        paymentTerms,
        paymentTermsKey
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      // if (!paymentTerms) {
      //   return false
      // }
      const hasPaymentTerms = this.getData('hasPaymentTerms');

      if (!hasPaymentTerms) {
        this.setData('paymentTermsKey', '');
        this.setData('paymentTermsMonths', '');
        this.setData('paymentTermsDays', '');
        this.setData('paymentTermsFrom', '');
        this.setData('paymentTermsEnd', '');
        return false;
      }

      if (paymentTermsKey != null) {
        this.setData('paymentTermsKey', paymentTermsKey);
      }

      paymentTermsKey = this.getData('paymentTermsKey');

      if (!paymentTermsKey) {
        this.setData('paymentTermsMonths', '0');
        this.setData('paymentTermsDays', '0');
        this.setData('paymentTermsFrom', this.get('constants.paymentTermsFrom.invoiceMonth'));
        this.setData('paymentTermsEnd', this.get('constants.paymentTermsEnd.exactDate'));
        return false;
      }

      if (RA.isNotNilOrEmpty(paymentTerms)) {
        this.setData('paymentTerms', paymentTerms.getData('label'));
        this.setData('paymentTermsMonths', paymentTerms.getData('months'));
        this.setData('paymentTermsDays', paymentTerms.getData('days'));
        this.setData('paymentTermsFrom', paymentTerms.getData('from'));
        this.setData('paymentTermsEnd', paymentTerms.getData('end'));
      }

      return true;
    },

    hasDuplicateProduct(product) {
      const details = this.getData('details');
      const found = details.filterBy('_data.itemKey', product.getData('_key'));

      if (found.length > 1) {
        return true;
      }

      return false;
    },

    loadProduct(taxRate, detail, product, historyData) {
      detail.setData('isSet', product.getData('isSet'));
      detail.setData('unit', product.getData('unit'));
      detail.setData('unitKey', product.getData('unitKey'));
      detail.setData('item', product.getData('name'));
      detail.setData('invoiceDescription', product.getData('invoiceDescription'));
      detail.setData('roundingInventoryQty', product.getData('roundingInventoryQty', this.settings.getProp('roundingInventoryQty')));
      detail.setData('roundingInventoryPrice', product.getData('roundingInventoryPrice', this.settings.getProp('roundingInventoryPrice')));
      detail.setData('taxRate', taxRate);
      const options = {
        defaultDetailPrice: detail?._data?.price
      };
      this.setDetailPrice(detail, historyData, options);
    },

    setDetailPrice(detail, historyData) {
      let {
        defaultDetailPrice = ''
      } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let price = defaultDetailPrice;
      const isTaxExclusive = this.isTaxExclusive;

      if (!price) {
        if (isTaxExclusive) {
          price = historyData.exclPrice;
        } else {
          price = historyData.inclPrice;
        }
      }

      if (!price) {
        const docType = this.get('_data.docType');
        let method = 'Excl';

        if (!isTaxExclusive) {
          method = 'Incl';
        }

        price = R.propOr('', `${docType}${method}Price`, historyData);
      }

      price = this._setPreBulkDiscountPrice({
        price,
        historyData
      });
      detail.setData('price', price);
      this.setProductCommissionsBaseCost(detail, historyData);
      this.sumTotals();
    },

    _setPreBulkDiscountPrice(_ref5) {
      let {
        price,
        historyData
      } = _ref5;
      const roundingCurrency = this.getData('roundingCurrency');
      const bulkDiscountAmt = R.propOr(0, 'bulkDiscountAmt')(historyData);

      if (R.gt(bulkDiscountAmt, 0)) {
        price = this.big.newBig(price).plus(bulkDiscountAmt).toFixed(roundingCurrency);
      }

      return price;
    },

    /**
     * determine if there is a single discount or has multiple discounts (line by line)
     */
    hasSingleDiscount: Ember.computed('_data.discountMethod', function () {
      const discountMethod = this.get('_data.discountMethod');

      if (discountMethod === this.get('constants.discountMethods.onTotal')) {
        return true;
      }

      return false;
    }),
    hasLineByLineDiscount: Ember.computed('_data.discountMethod', function () {
      return !this.hasSingleDiscount;
    }),

    setProductCommissionsBaseCost(detail, historyData) {
      const hasSalesPersons = this.hasSalesPersons;

      if (hasSalesPersons) {
        // @TODO: should check the sales person from linking doc and to linking doc.
        // @TODO: commission may be different from sales persons
        const commissionBaseCost = R.propOr(0, 'commissionBaseCost', historyData);

        if (commissionBaseCost !== 0 && commissionBaseCost !== '0') {
          // set base cost only if its non 0
          detail.setData('commissionBaseCost', commissionBaseCost);
        }
      }
    },

    hasSalesPersons: Ember.computed('_data.salesPersons.[]', function () {
      const salesPersons = this.getData('salesPersons') || [];

      if (R.isEmpty(salesPersons)) {
        return false;
      }

      return salesPersons;
    }),
    isCreditCardPayment: Ember.computed('_data.paymentMethodKey', function () {
      if (this._data.paymentMethodKey === this.constants.paymentMethods.creditCard) {
        return true;
      }

      return false;
    }),
    isLinePayPayment: Ember.computed('_data.paymentMethodKey', function () {
      if (this._data.paymentMethodKey === this.constants.paymentMethods.linePay) {
        return true;
      }

      return false;
    }),
    isIntegratedCreditCardProvider: Ember.computed('_data.paymentCreditCardProvider', function () {
      if (this._data.paymentCreditCardProvider === 'tapPay') {
        return true;
      }

      if (this._data.paymentCreditCardProvider === 'ecPay') {
        return true;
      }

      return false;
    }),
    allowReturns: Ember.computed('_data.{docType,docName}', function () {
      // @TODO: allow other docs at a later stage
      if (this._data.docType !== 'sales' || this._data.docName !== 'invoices') {
        return false;
      }

      if (this._data.isVoid) {
        return false;
      }

      if (this._data.status !== this.constants.documentsStatus.final) {
        return false;
      }

      if (this._data.paymentMethodKey === this.constants.paymentMethods.creditCard && this._data.paymentCreditCardStatus !== this.constants.paymentCreditCardStatus.approved) {
        return false;
      }

      return true;
    }),
    allowDiscounts: Ember.computed('_data.{docType,docName}', function () {
      // @TODO: allow other docs at a later stage
      if (R.includes(this._data.docName)(['returns', 'consignments', 'consignments-returns'])) {
        return false;
      }

      return true;
    }),
    allowCreditCardRefunds: Ember.computed('_data.{docType,docName,paymentMethodKey}', function () {
      if (!this._data.docType === 'sales' || !this._data.docName === 'returns') {
        return false;
      }

      if (this._data.isVoid) {
        return false;
      }

      if (this._data.paymentMethodKey === this.constants.paymentMethods.creditCard) {
        return true;
      }

      if (this._data.paymentMethodKey === this.constants.paymentMethods.linePay) {
        return true;
      } // if (!this._data.paymentCreditCardTransactionCode) {
      //   return false
      // }


      return false;
    }),
    isAllowPartialCreditCardRefund: Ember.computed('_data.{disallowCreditCardPartialRefunds,paymentCreditCardTransactionTimestampZ,paymentCreditCardTransactionCode}', 'synced', function () {
      let allowCreditCardRefund = false;

      if (this?._data?.disallowCreditCardPartialRefunds) {
        return false;
      }

      const transactionDateZ = this.getData('paymentCreditCardTransactionTimestampZ');

      if (transactionDateZ) {
        const fourMonthAgo = this.dateService.getMoment().subtract(4, 'months');
        allowCreditCardRefund = fourMonthAgo.isBefore(transactionDateZ);
      }

      return allowCreditCardRefund;
    }),
    detailsWithDiscountAndReward: Ember.computed('{_data.details.@each.synced,synced,hasDiscountAmount,hasRewards,calculatedDiscExclTotal,_data.discInclTotal,_data.rewardPointApplied,_data.rewardExclTotal,_data.rewardInclTotal}', function () {
      const originalDetails = this.getData('details') || [];
      return this.addDiscountAndRewardToDetails(originalDetails);
    }),
    sortedDetailsWithDiscountAndReward: Ember.computed('{sorted.@each.synced,synced,hasDiscountAmount,hasRewards,calculatedDiscExclTotal,_data.discInclTotal,_data.rewardPointApplied,_data.rewardExclTotal,_data.rewardInclTotal}', function () {
      let originalDetails = this.get('sorted') || [];
      let lastSetId = '';
      originalDetails = R.map(detail => {
        if (detail?._data?.setId && detail?._data?.setId !== lastSetId) {
          detail.set('isFirstSetChoiceItem', true);
          lastSetId = detail._data.setId;
        }

        return detail;
      })(originalDetails);
      return this.addDiscountAndRewardToDetails(originalDetails);
    }),

    addDiscountAndRewardToDetails(originalDetails) {
      const details = R.concat(originalDetails, []);
      const hasDiscount = this.get('hasDiscount') || false;
      const hasRewards = this.get('hasRewards') || false;
      const hasNoDiscountDetail = R.pipe(R.find(R.propEq(['_data', 'item'], this.intl.t('discount amount'))), RA.isNilOrEmpty())(details);
      const hasNoRewardDetail = R.pipe(R.find(R.propEq(['_data', 'item'], `${this.intl.t('points spent')} (${this.intl.t('points', {
        points: this.getData('rewardPointApplied')
      })}): $ ${this.getData('rewardInclTotal') || 0}`)), RA.isNilOrEmpty())(details);
      const roundingCurrency = this.getData('roundingCurrency') || 0;
      let discountDetail = {};

      if (hasDiscount && hasNoDiscountDetail) {
        const inclAmt = this.getData('discInclTotal') || 0;
        const exclAmt = this.get('calculatedDiscExclTotal') || this.getData('discExclTotal') || 0;
        const discountTaxAmount = this.big.newBig(inclAmt).minus(exclAmt).toFixed(roundingCurrency);
        let item = `${this.intl.t('discount')}: $ ${this.intl.formatNumber(this.getData('discountAmount'))}`;

        if (this.get('hasDiscountRate')) {
          item = `${this.intl.t('discount')}: ${this.getData('discountRate')}%`;
        }

        discountDetail = this.dataManager.setAsRecord({
          adapterName: 'documents/details',
          data: {
            item,
            exclAmt: R.negate(exclAmt),
            taxAmt: R.negate(discountTaxAmount),
            inclAmt: R.negate(inclAmt),
            hasReturns: false,
            netReturnsQty: 1
          }
        });
        discountDetail.set('isAdditionalDetail', true);
        details.pushObject(discountDetail);
      }

      let rewardDetail = {};

      if (hasRewards && hasNoRewardDetail) {
        const inclAmt = this.getData('rewardInclTotal') || 0;
        const exclAmt = this.getData('rewardExclTotal') || 0;
        const rewardTaxAmount = this.big.newBig(inclAmt).minus(exclAmt).toFixed(roundingCurrency);
        rewardDetail = this.dataManager.setAsRecord({
          adapterName: 'documents/details',
          data: {
            item: `${this.intl.t('points spent')} (${this.intl.t('points', {
              points: this.getData('rewardPointApplied')
            })}): $ ${inclAmt}`,
            exclAmt: R.negate(exclAmt),
            taxAmt: R.negate(rewardTaxAmount),
            inclAmt: R.negate(inclAmt),
            hasReturns: false,
            netReturnsQty: 1
          }
        });
        rewardDetail.set('isAdditionalDetail', true);
        details.pushObject(rewardDetail);
      }

      return details;
    },

    hasTaxRoundingDifference: Ember.computed('detailsWithDiscountAndReward', function () {
      const details = this.get('detailsWithDiscountAndReward');
      const roundingCurrency = this.getData('roundingCurrency') || 0;
      const exclTotal = this.getData('exclTotal');
      const calculatedExclTotal = this.big.sumPropAndRound(roundingCurrency, '_data.exclAmt', details);

      if (exclTotal !== calculatedExclTotal) {
        return true;
      }

      return false;
    }),
    calculatedExclTotal: Ember.computed('detailsWithDiscountAndReward', function () {
      const details = this.get('detailsWithDiscountAndReward');
      const roundingCurrency = this.getData('roundingCurrency') || 0;
      return this.big.sumPropAndRound(roundingCurrency, '_data.exclAmt', details);
    }),
    calculatedTaxTotal: Ember.computed('detailsWithDiscountAndReward', function () {
      const details = this.get('detailsWithDiscountAndReward');
      const roundingCurrency = this.getData('roundingCurrency') || 0;
      return this.big.sumPropAndRound(roundingCurrency, '_data.taxAmt', details);
    }),
    firstSelectedLinkFromDocKey: Ember.computed('synced', '_data.details.[]', 'selectedLinkFromDocKeys.[]', function () {
      const selected = this.selectedLinkFromDocKeys || [];

      if (RA.isNilOrEmpty(selected)) {
        const details = this._data.details || [];
        return R.pathOr('', [0, '_data', 'fromMaster'])(details);
      }

      return _nventor.default.safeHeadOr('')(selected);
    }),
    detailQtyGroupedByLinkedList: Ember.computed('_data.details[]', 'synced', function () {
      const details = this._data.details || [];
      return R.pipe(R.filter(detail => {
        const fromDocName = R.pathOr('', ['_data', 'fromDocName'])(detail);
        let pendingId = '';

        if (RA.isNotNilOrEmpty(fromDocName)) {
          pendingId = R.pathOr('', ['_data', `${fromDocName}PendingId`])(detail);

          if (RA.isNilOrEmpty(pendingId) && R.equals(fromDocName, 'invoices')) {
            pendingId = R.pathOr('', ['_data', 'fromDetailKey'])(detail);
          }
        }

        if (RA.isNotNilOrEmpty(pendingId)) {
          return true;
        }

        return false;
      }), R.groupBy(detail => {
        const fromDocName = R.pathOr('', ['_data', 'fromDocName'])(detail);

        if (R.equals(fromDocName, 'invoices')) {
          return R.path(['_data', 'fromDetailKey'])(detail);
        }

        return R.path(['_data', `${fromDocName}PendingId`])(detail);
      }), R.map(group => {
        const maxQty = R.pathOr('', [0, '_data', 'maxQtyWhenLinked'])(group);
        const groupQtys = R.pipe(R.map(detail => {
          const qty = R.pathOr('0', ['_data', 'qty'])(detail);
          return this.big.newBig(qty).toFixed(0);
        }), x => {
          return this.big.sumArray(x).toFixed(0);
        })(group);
        const fromDocName = R.pathOr('', [0, '_data', 'fromDocName'])(group);
        let isExceedMaxQty = false;
        let isQtyFullCapacity = false;

        if (RA.isNotNilOrEmpty(maxQty)) {
          isExceedMaxQty = this.big.newBig(groupQtys).gt(maxQty);
          isQtyFullCapacity = this.big.newBig(groupQtys).gte(maxQty);
        }

        return {
          type: fromDocName,
          isExceedMaxQty,
          isQtyFullCapacity
        };
      }))(details);
    }),
    qtyWhenLinkedExceedAllowedQty: Ember.computed('_data.details.@each.synced', 'detailQtyGroupedByLinkedList', 'synced', function () {
      if (this?._data?.docName !== 'returns') {
        return false;
      }

      if (this?._data?.docName !== 'consignment-returns') {
        return false;
      }

      const details = this._data.details || [];
      const detailQtyWhenLinkedExceedAllowedQtyList = R.map(R.propOr(false, 'detailQtyWhenLinkedExceedAllowedQty'))(details);
      const qtyWhenLinkedExceedAllowedQty = R.any(R.equals(true))(detailQtyWhenLinkedExceedAllowedQtyList); // check for if there's multiple row of detail with same link and if the sum qty exceed allowed

      const detailQtyGroupedByLinkedList = this.detailQtyGroupedByLinkedList;
      const detailQtyGroupedByLinkedExceedAllowedQty = R.pipe(R.values, R.reject(R.propEq('type', 'orders')), R.pluck('isExceedMaxQty'), R.any(R.equals(true)))(detailQtyGroupedByLinkedList);
      return detailQtyGroupedByLinkedExceedAllowedQty || qtyWhenLinkedExceedAllowedQty;
    }),
    qtyHaveValueBelowOne: Ember.computed('_data.docName', '_data.details.@each.synced', 'synced', function () {
      const details = this?._data?.details || [];
      const detailQtyBigList = R.pipe(R.map(detail => {
        const detailItem = R.pathOr('', ['_data', 'item'])(detail);
        const detailItemKey = R.pathOr('', ['_data', 'itemKey'])(detail); // const detailItemCode = R.pathOr('', ['_data', 'itemCode'])(detail)

        if (RA.isNotNilOrEmpty(detailItem) || RA.isNotNilOrEmpty(detailItemKey)) {
          const detailQty = R.pathOr('', ['_data', 'qty'])(detail);
          return this.big.newBig(detailQty);
        }

        return false;
      }), R.reject(R.equals(false)))(details);
      let isAnyQtyBelowOne = R.any(R.gte(0))(detailQtyBigList);
      const docName = this?._data?.docName || '';
      const isReturnDocs = R.includes(docName, ['consignments-returns', 'returns']);
      const hasMoreThanOneDetail = R.gt(R.length(detailQtyBigList), 1);

      if (isReturnDocs && hasMoreThanOneDetail) {
        isAnyQtyBelowOne = R.any(R.gt(0))(detailQtyBigList);
      }

      const isTotalDetailQtyZeroOrBelow = R.gte(0, R.sum(detailQtyBigList));
      const hasMoreThanZeroDetail = R.gt(R.length(detailQtyBigList), 0);

      if (isTotalDetailQtyZeroOrBelow && hasMoreThanZeroDetail) {
        return true;
      }

      if (isAnyQtyBelowOne) {
        return true;
      }

      return false;
    }),
    haveMultiplePendingId: Ember.computed('detailQtyGroupedByLinkedList', 'synced', function () {
      const detailQtyGroupedByLinkedList = this.detailQtyGroupedByLinkedList || {};
      const pendingIdListLength = R.pipe(R.values, R.pluck('type'), R.uniq, R.length)(detailQtyGroupedByLinkedList);

      if (R.gt(pendingIdListLength, 1)) {
        return true;
      }

      return false;
    }),
    totalItems: Ember.computed('_data.details.[]', function () {
      const salesInvoicesDetails = this.getData('details') || [];
      const salesInvoicesTotalItems = R.pipe(R.reject(R.anyPass([R.pathEq(['_data', 'isSet'], true), R.pathEq(['_data', 'type'], 'paymentFee'), R.pathEq(['_data', 'type'], 'shippingFee')])), R.map(R.pathOr(0, ['_data', 'qty'])), R.sum)(salesInvoicesDetails) || 0;
      let salesReturnsTotalItems = 0;
      const returnsDetails = this.get('returnsDetails') || [];

      if (RA.isNotNilOrEmpty(returnsDetails)) {
        salesReturnsTotalItems = R.pipe(R.reject(R.anyPass([R.pathEq(['_data', 'isSet'], true), R.pathEq(['_data', 'type'], 'paymentFee'), R.pathEq(['_data', 'type'], 'shippingFee')])), R.map(R.pathOr(0, ['_data', 'qty'])), R.sum)(returnsDetails) || 0;
      }

      return R.subtract(salesInvoicesTotalItems, salesReturnsTotalItems);
    }),
    hasExpiredWaybill: Ember.computed('_data.{transporterPrintHistory,waybill}', function () {
      const waybill = this._data.waybill;

      if (!waybill) {
        return false;
      }

      const transporterPrintHistory = this?._data?.transporterPrintHistory || [];
      const lastPrintTimestamp = R.pipe(R.find(R.propEq('waybill', waybill)), R.prop('timestampZ_printed'))(transporterPrintHistory);

      if (lastPrintTimestamp) {
        const maxAllowDays = 7;
        let date = this.dateService.getMoment(lastPrintTimestamp);
        const todayZ = this.dateService.getStartOfDayZ();
        const days = this.dateService.getDateDiff(date, todayZ, 'days');
        return days >= maxAllowDays;
      }

      return true;
    }),
    allowUpdateWaybillData: Ember.computed('_data.{transporterPrintHistory,waybill}', function () {
      const transporterWaybillRecord = this?._data?.transporterWaybillRecord;

      if (!transporterWaybillRecord) {
        return false;
      }

      if (this.hasExpiredWaybill) {
        return false;
      }

      return true;
    }),
    selectedZone: Ember.computed('_data.{shippingFeeData.combinedZones.[],transporterKey}', function () {
      const transporterKey = this?._data?.transporterKey;
      const combinedZones = this?._data?.shippingFeeData?.combinedZones || [];
      return R.find(R.propEq('transporterKey', transporterKey))(combinedZones);
    }),

    updateAddressWithAdvanceAddress() {
      const addressData = this?._data?.addressData || {};
      const isConvenienceStore = this?._data?.isConvenienceStore || false;
      let prefix = '';

      if (isConvenienceStore) {
        prefix = `[${this.intl.t(addressData.brand)}](${addressData.storeId}${addressData.storeName})`;
      }

      const postCode = addressData?.postCode || '';
      const region = addressData?.region || '';
      const district = addressData?.district || '';
      const street = addressData?.street || '';
      const address = `${prefix}${postCode}${region}${district}${street}`;

      if (address && addressData?.street) {
        this.setData('address', address);
      }
    },

    // hasAutoCancelInvoice: computed('lastSuccessfulEInvoice._data.number', function () {
    //   const hasAutoCancelInvoice = R.pathOr(false, ['lastSuccessfulEInvoice', '_data', 'number'], this)
    //   return hasAutoCancelInvoice
    // })
    // creditCardRefunds: computed('_data.creditCardRefunds.[]', function () {
    //   const creditCardRefunds = this._data.creditCardRefunds || []
    //   if (RA.isNilOrEmpty(creditCardRefunds)) {
    //     return ''
    //   }
    //   return this._data.creditCardRefunds
    // })
    _transformCreditCardRefundsSchedules(data) {
      this.set('creditCardRefundsSchedules', data?.creditCardRefundsSchedules);
      return R.omit(['creditCardRefundsSchedules'])(data);
    },

    toggleDiscountType(item) {
      const discType = item.getData('value');

      if (discType === this.get('constants.discountTypes.rate')) {
        const currentDiscValue = this.getData('discountAmount') || this.getData('discountRate');
        this.setData('discountRate', currentDiscValue);
        this.setData('discountAmount', '');
      } else {
        const currentDiscValue = this.getData('discountRate') || this.getData('discountAmount');
        this.setData('discountAmount', currentDiscValue);
        this.setData('discountRate', '');
      }

      this.sumTotals();
    },

    setShop(shopModel) {
      const hasShop = RA.isNotNilOrEmpty(shopModel);

      if (!hasShop) {
        return;
      }

      const key = shopModel?._data?._key;
      const name = shopModel?._data?.name;
      this.set('_data.source', name);
      this.set('_data.sourceKey', key);
    },

    toggleShowQtyWarningMsg() {
      this.set('showQtyWarningMsg', true);
    },

    onSetTwEInvoiceCarrierType() {
      const twEInvoiceCarrierType = this._data?.twEInvoiceCarrierType;

      if (twEInvoiceCarrierType === 'b2b') {} else {}

      this.set('_data.twEInvoiceCarrierId', '');
      this.set('_data.companyName', '');
      this.set('_data.taxNumber', '');
      const synced = Math.random();
      this.set('synced', synced);
    },

    setupTaxDetails() {
      let companyDetails = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.eInvoicesService.setupTaxDetails(this, companyDetails);
    },

    setTransporterData(transporterModel, senderModel) {
      if (!transporterModel && !senderModel) {
        return;
      } // NOTE: When transporter sender has been set, it will auto select the data on document.Otherwise, it will be empty.


      const transporterData = senderModel?.getTransporterData() || {};

      for (const transporterDataKey in transporterData) {
        const value = transporterData[transporterDataKey];
        this.set(`_data.${transporterDataKey}`, value);
      }
    },

    translateAccountName: Ember.computed('_data.transporterExtension', function () {
      // @TODO: this is difficult to maintain,
      // there are multiple versions
      return R.anyPass([R.pathEq(['model', '_data', 'transporterExtension'], 't-cat'), R.pathEq(['model', '_data', 'transporterExtension'], 'seven-eleven')])(this);
    })
  }, (_applyDecoratedDescriptor(_obj, "updateAddressWithAdvanceAddress", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateAddressWithAdvanceAddress"), _obj), _applyDecoratedDescriptor(_obj, "toggleDiscountType", [_dec2], Object.getOwnPropertyDescriptor(_obj, "toggleDiscountType"), _obj), _applyDecoratedDescriptor(_obj, "setShop", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setShop"), _obj), _applyDecoratedDescriptor(_obj, "toggleShowQtyWarningMsg", [_dec4], Object.getOwnPropertyDescriptor(_obj, "toggleShowQtyWarningMsg"), _obj), _applyDecoratedDescriptor(_obj, "onSetTwEInvoiceCarrierType", [_dec5], Object.getOwnPropertyDescriptor(_obj, "onSetTwEInvoiceCarrierType"), _obj), _applyDecoratedDescriptor(_obj, "setupTaxDetails", [_dec6], Object.getOwnPropertyDescriptor(_obj, "setupTaxDetails"), _obj), _applyDecoratedDescriptor(_obj, "setTransporterData", [_dec7], Object.getOwnPropertyDescriptor(_obj, "setTransporterData"), _obj)), _obj)));

  _exports.default = _default;
});