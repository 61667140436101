define("client/pods/components/documents/panel-new/component", ["exports", "client/mixins/date", "client/mixins/settings", "client/mixins/crud", "client/pods/components/documents/mixins/pre-submit", "client/pods/components/documents/mixins/details", "ember-concurrency", "ramda-extension", "ramda", "ramda-adjunct"], function (_exports, _date, _settings, _crud, _preSubmit, _details, _emberConcurrency, R_, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, _date.default, _details.default, _settings.default, _preSubmit.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_obj = {
    contactsService: Ember.inject.service('contacts'),
    constants: Ember.inject.service(),
    crud: Ember.inject.service(),
    server: Ember.inject.service(),
    documentsService: Ember.inject.service('documents'),
    productsService: Ember.inject.service('products'),
    printerService: Ember.inject.service('printer'),
    tabsManager: Ember.inject.service(),
    selectedProducts: Ember.A([]),
    areProductsFullScreen: true,
    posFullscreenMode: false,

    init() {
      this.crud.addLists(this, ['taxes', 'paymentMethods', 'paymentTerms']);

      this._super(...arguments);

      this.set('dontFetchRegionCode', this?.get('tab.config.dontFetchRegionCode'));
      this?.tabsManager?.setHeader(this?.tabParent, this?.intl?.t('new'));
    },

    qrCodeData: Ember.computed('model.paymentLink', function () {
      const paymentLink = this?.model?.paymentLink;

      if (paymentLink) {
        return `${paymentLink}?isPos=true`;
      }

      return '';
    }),
    preSaveModalSaveLabel: Ember.computed('showPreSaveModal', 'shouldVerifyPayment', 'model.paymentLink', 'model._data.paymentCreditCardStatus', function () {
      let label = 'payout';
      const hasApproveStatus = this.model?._data?.paymentCreditCardStatus === this?.constants?.paymentCreditCardStatus?.approved;

      if (this?.shouldVerifyPayment) {
        label = 'awaiting payment';
      } else if (hasApproveStatus) {
        label = 'paid';
      }

      return label;
    }),
    panelNewClassNames: Ember.computed('tabParent.isPos', 'areProductsFullScreen', 'posFullscreenMode', function () {
      const isPos = R.pathEq(['tabParent', 'isPos'], true)(this);
      const classNames = [];

      if (isPos) {
        classNames.push('pos-grid');

        if (this.areProductsFullScreen) {
          classNames.push('pos-view');
        }

        if (this.posFullscreenMode) {
          classNames.push('pos-view--fullscreen');
          classNames.push('is-fullscreen');
        }
      }

      return classNames.join(' ');
    }),
    panelNewEditorMainClassNames: Ember.computed('tabParent.isPos', 'areProductsFullScreen', function () {
      const isPos = R.pathEq(['tabParent', 'isPos'], true)(this);
      const classNames = [];

      if (isPos && this.areProductsFullScreen) {
        classNames.push('panel-new-editor-main-pos-view');
      }

      return classNames.join(' ');
    }),
    shouldVerifyPaymentLinePayOrCreditCard: Ember.computed('tabParent.isPos', 'model._data.paymentMethodKey', function () {
      const isPaymentMethodCreditCard = this?.model?._data?.paymentMethodKey === this?.constants?.paymentMethods?.creditCard;
      const isPaymentMethodLinePay = this?.model?._data?.paymentMethodKey === this?.constants?.paymentMethods?.linePay;
      return isPaymentMethodCreditCard || isPaymentMethodLinePay;
    }),
    loadDataTask: (0, _emberConcurrency.task)(function* () {
      const adapterName = this.get('tab.config.adapterName');
      this.set('adapterName', adapterName);
      const model = this.setupNewRecord();
      this.set('model', model);
      const docType = this.get('tab.config.docType');
      const docName = this.get('tab.config.docName');
      let contactsModel; // @TODO: remove this

      const isFromShortcut = this.get('tab.isFromShortcut') || false;
      const isFromPendingShortcut = this.get('tab.isFromPendingShortcut') || false;
      const isFromContactShortcut = this.get('tab.isFromContactShortcut') || false;
      const isFromDocument = this.get('tab.isFromDocument') || false;
      const isDuplicateDocumentShortcut = this.get('tab.isDuplicateDocumentShortcut') || false;
      const isFromOutstandingQty = this.get('tab.isFromOutstandingQty') || false;
      const isFromToInvoiceQty = this.get('tab.isFromToInvoiceQty') || false;
      const isLinkingNeeded = this.get('tab.isLinkingNeeded') || false;
      this.set('isFromShortcut', isFromShortcut);
      this.set('isFromPendingShortcut', isFromPendingShortcut);
      this.set('isFromDocument', isFromDocument);
      this.set('isLinkingNeeded', isLinkingNeeded);
      let orderDoc = {};
      const taxesList = this?.crud?.lists?.taxes || [];
      const taxRate = this.settings.getDefaultTaxRate(taxesList);
      let orderDetailDoc = {
        taxRate
      };

      if (isFromOutstandingQty) {
        orderDoc = this.get('tab.pendingDoc.order') || {};
        orderDetailDoc = this.get('tab.pendingDoc.detail') || {};
        const outstandingQty = this.get('tab.pendingDoc.outstandingQty') || '';
        Ember.set(orderDetailDoc, 'qty', outstandingQty);
      }

      if (isFromToInvoiceQty) {
        // just get first detail for now... first in first out, just sort pending order detail by oldest
        const pendingDocData = this.get('tab.pendingDoc._data.details') || [];
        const pendingOrderDetail = R.head(pendingDocData) || {};
        orderDoc = pendingOrderDetail?.order || {};
        orderDetailDoc = pendingOrderDetail?.detail || {};
        let toInvoiceQty = this.get('tab.pendingDoc._data.toInvoiceQty') || '';

        if (R_.isNegative(toInvoiceQty)) {
          toInvoiceQty = R.negate(toInvoiceQty);
        }

        Ember.set(orderDetailDoc, 'qty', toInvoiceQty);
      } // @TODO: refactor


      if (isFromContactShortcut) {
        orderDoc.contact = this.get('tab.pendingDoc._data.name') || '';
        orderDoc.contactKey = this.get('tab.pendingDoc._data._key') || '';
        contactsModel = this.get('tab.pendingDoc');
      }

      if (isFromPendingShortcut) {
        contactsModel = yield this.findTask.perform({
          adapterName: 'contacts',
          appendPath: `/${orderDoc.contactKey}`
        });
      }

      if (isFromDocument || isDuplicateDocumentShortcut) {
        orderDoc.duplicateData = this.get('tab.pendingDoc._data') || {};
        const contactKey = this.get('tab.pendingDoc._data.contactKey');
        contactsModel = yield this.findTask.perform({
          adapterName: 'contacts',
          appendPath: `/${contactKey}`
        });
      } // refactor above ^^^


      const tab = this.tab;
      const loadInstructions = this.tabsManager.getLoadInstructionsAndClear(tab);
      const contactKey = loadInstructions?._data?.contactKey;

      if (contactKey) {
        // fetch contact
        contactsModel = yield this.findTask.perform({
          adapterName: 'contacts',
          appendPath: `/${contactKey}`
        });
      }

      const twEInvoicePrint = R.pathEq(['posOptions', 'enableAutoIssueEInvoice'], true)(this);
      const defaultData = R.mergeRight({
        docType,
        docName,
        paymentMethodsList: R_.dotPath('crud.lists.paymentMethods')(this),
        paymentTermsList: R_.dotPath('crud.lists.paymentTerms')(this),
        isFromShortcut,
        isFromDocument,
        isDuplicateDocumentShortcut,
        contactsModel,
        tabParent: this.tabParent,
        twEInvoicePrint
      }, orderDoc);
      model.newBlank(defaultData);
      model.addDetail(orderDetailDoc);
      this.setShop();
      this.addNewDetail(model);
      this.resetProductsQty();
    }),

    resetProductsQty() {
      const products = this?.posOptions?.products || [];
      R.forEach(product => {
        product.set('_data.qty', 0);
      })(products);
    },

    afterLoadLists() {
      this._super(...arguments);

      this.loadDataTask.perform();
    },

    showInventoryNotificationTask: (0, _emberConcurrency.task)(function* (model) {
      if (RA.isNotNilOrEmpty(model)) {
        const docType = model?._data?.docType || '';
        const docName = model?._data?.docName || '';
        const allowedDocName = ['invoices', 'consignments'];

        if (R.equals(docType, 'purchases') && R.includes(docName, allowedDocName)) {
          const details = model?._data?.details || [];

          if (RA.isNotNilOrEmpty(details)) {
            const q = [];
            R.forEach(detail => {
              const itemKey = detail?._data?.itemKey || '';
              q.push(this.server.call('GET', `api/protected/products/${itemKey}`));
            })(details);
            let detailsInventoryData = yield (0, _emberConcurrency.all)(q);
            detailsInventoryData = R.map(detail => {
              if (detail?.data) {
                return detail.data;
              }

              return {};
            })(detailsInventoryData);
            model.set('detailsInventoryData', detailsInventoryData);
          }
        }
      }

      if (this.onDisplay) {
        this.onDisplay(model);
      }

      return model;
    }),
    onAfterSaveTask: (0, _emberConcurrency.task)(function* (model) {
      const isPos = R.pathEq(['tabParent', 'isPos'], true)(this);

      if (isPos) {
        if (this.shouldVerifyPaymentLinePayOrCreditCard) {
          return model;
        }

        return this.onNew();
      }

      return this.showInventoryNotificationTask.perform(...arguments);
    }),
    onAddItemToCartTask: (0, _emberConcurrency.task)(function* (item) {
      if (item.isDocumentDetail) {
        try {
          return this.incrementQty(item);
        } catch (error) {}
      }

      let documentsDetails = item?._data;
      const docSalesDetails = R.pathOr([], ['model', '_data', 'details'])(this);
      let documentDetailModel = R.pipe(R.filter(R.hasPath(['_data', 'item'])), R.find(R.anyPass([R.pathEq(['_data', 'item'], documentsDetails?.item), R.pathEq(['_data', 'item'], documentsDetails?.name)])))(docSalesDetails);
      let product = item?.product;
      const isProduct = R.propEq('adapterName', 'products')(item);

      if (isProduct) {
        product = item;
        documentsDetails = R.mergeRight(documentsDetails, {
          index: R.length(docSalesDetails),
          itemKey: documentsDetails._key,
          itemCode: documentsDetails.code,
          item: documentsDetails.name
        });
      }

      let foundDetail = true;

      if (!documentDetailModel) {
        documentDetailModel = this.model.addPosDetail(documentsDetails);
        foundDetail = false;
      } // this.updateIndexes(documentDetailModel)


      const taxRate = this.productsService.getTaxRate(this?.model.getData('docType'), R_.dotPath('crud.lists.taxes')(this), product);
      const itemKey = product.getData('_key');
      const {
        preferredHistoryData
      } = yield this.productsService.fetchDocHistoryTask.perform(this.model, 'contact', product, itemKey);
      this.model.loadProduct(taxRate, documentDetailModel, product, preferredHistoryData);
      this.incrementQty(documentDetailModel);

      if (!foundDetail) {
        this.addNewDetail(this.model);
      }

      this.updateIndexes(documentDetailModel);
    }),
    onRemoveItemFromCartTask: (0, _emberConcurrency.task)(function* (detail) {
      this.decrementQty(detail);
      this.updateIndexes(detail);
    }),
    issueAndPrintTask: (0, _emberConcurrency.task)(function* () {
      const onlinePrinters = yield this.printerService.fetchOnlinePrintersTask.perform();
      const selectedPrinter = R.path([0, '_data', 'printerId'])(onlinePrinters);
      const component = this;

      const onCloseDialogAction = () => this.set('showPreSaveModal', false);

      const document = this?.model;
      const custom = {};
      yield this.printerService.issueAndPrintTask.perform(component, onCloseDialogAction, document, custom, selectedPrinter);
    }),
    onSavePreSaveModalTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        preSubmitAction = fn => fn
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        if (_this.shouldVerifyPayment) {
          // Print eInvoice
          return yield _this.issueAndPrintTask.perform();
        }

        preSubmitAction = _this.preSubmitAction || preSubmitAction;
        const result = yield preSubmitAction();

        _this.set('model', result); // Show QRCode when pay by TapPay and has pay by link


        if (_this.shouldVerifyPaymentLinePayOrCreditCard) {
          _this.set('shouldVerifyPayment', _this.shouldVerifyPaymentLinePayOrCreditCard);

          return;
        } // @TODO: DO SOME STUFF HERE


        _this.set('showPreSaveModal', false);
      }();
    }),
    verifyPaymentStatusTask: (0, _emberConcurrency.task)(function* (paymentStatus) {
      const isPos = R.pathEq(['tabParent', 'isPos'], true)(this);

      if (!isPos) {
        return;
      }

      const hasApproveStatus = paymentStatus === this?.constants?.paymentCreditCardStatus?.approved;

      if (hasApproveStatus) {
        const twEInvoicePrint = R.pathEq(['model', '_data', 'twEInvoicePrint'], true)(this);

        if (twEInvoicePrint) {
          yield this.issueAndPrintTask.perform();
        }

        this.set('showPreSaveModal', false);
        return this.onNew();
      }
    }),

    toggleProductsFullScreen() {
      this.set('areProductsFullScreen', !this.areProductsFullScreen);
    },

    updateIndexes() {
      const details = this?.model?._data?.details || [];
      const lastIndex = details.length;
      let index = 1;
      R.forEach(detail => {
        if (!detail?._data?.item) {
          detail.setData('index', lastIndex);
        } else {
          detail.setData('index', index);
          index += 1;
        }

        return detail;
      })(details);
    },

    incrementQty(detail) {
      const qty = detail?.getData('qty') || 0;
      const newQty = parseInt(qty, 10) + 1;
      detail.set('_data.qty', newQty);
      this.sumTotals();
      return true;
    },

    decrementQty(detail) {
      if (RA.isNotNilOrEmpty(detail)) {
        const qty = detail?.getData('qty') || 0;

        if (qty > 0) {
          const newQty = parseInt(qty, 10) - 1;
          detail?.set('_data.qty', newQty);

          if (newQty === 0) {
            const details = this?.model?._data?.details || [];
            details.removeObject(detail);
          }

          this.sumTotals();
          return true;
        }
      }

      return false;
    },

    loadPaymentMethod(paymentMethod) {
      this.model.loadPaymentMethod({
        paymentMethod
      });
    },

    onPreSubmitPos(model, submit) {
      const isPos = R.pathEq(['tabParent', 'isPos'], true)(this);

      const preSubmitAction = () => this.preSubmit(model, submit);

      if (!isPos) {
        return preSubmitAction();
      } // When POS show POS modal confirmation


      this.set('showPreSaveModal', true);
      this.set('preSubmitAction', preSubmitAction);
    },

    sumTotals() {
      this.model.sumTotals();
    },

    toggleDiscountType(item) {
      const discType = item?.getData('value');
      const model = this?.model;

      if (discType === this?.get('constants.discountTypes.rate')) {
        const currentDiscValue = model?.getData('discountAmount') || model?.getData('discountRate');
        model?.setData('discountRate', currentDiscValue);
        model?.setData('discountAmount', '');
      } else {
        const currentDiscValue = model?.getData('discountRate') || model?.getData('discountAmount');
        model?.setData('discountAmount', currentDiscValue);
        model?.setData('discountRate', '');
      }

      this.sumTotals();
    },

    setShop() {
      // Set Shop if Pos
      const isPos = R.pathEq(['tabParent', 'isPos'], true)(this);

      if (isPos && this.model) {
        const shop = this.posOptions?.shop;
        this.model.setShop(shop);
      }
    },

    toggleAutoIssueEInvoices() {
      const isPos = R.pathEq(['tabParent', 'isPos'], true)(this);

      if (!isPos) {
        return;
      }

      const twEInvoicePrint = R.pathEq(['posOptions', 'enableAutoIssueEInvoice'], true)(this);
      this.model.set('_data.twEInvoicePrint', twEInvoicePrint);
    },

    togglePosFullscreenMode() {
      this.set('posFullscreenMode', !this.posFullscreenMode);
    }

  }, (_applyDecoratedDescriptor(_obj, "toggleProductsFullScreen", [_dec], Object.getOwnPropertyDescriptor(_obj, "toggleProductsFullScreen"), _obj), _applyDecoratedDescriptor(_obj, "updateIndexes", [_dec2], Object.getOwnPropertyDescriptor(_obj, "updateIndexes"), _obj), _applyDecoratedDescriptor(_obj, "incrementQty", [_dec3], Object.getOwnPropertyDescriptor(_obj, "incrementQty"), _obj), _applyDecoratedDescriptor(_obj, "decrementQty", [_dec4], Object.getOwnPropertyDescriptor(_obj, "decrementQty"), _obj), _applyDecoratedDescriptor(_obj, "loadPaymentMethod", [_dec5], Object.getOwnPropertyDescriptor(_obj, "loadPaymentMethod"), _obj), _applyDecoratedDescriptor(_obj, "onPreSubmitPos", [_dec6], Object.getOwnPropertyDescriptor(_obj, "onPreSubmitPos"), _obj), _applyDecoratedDescriptor(_obj, "sumTotals", [_dec7], Object.getOwnPropertyDescriptor(_obj, "sumTotals"), _obj), _applyDecoratedDescriptor(_obj, "toggleDiscountType", [_dec8], Object.getOwnPropertyDescriptor(_obj, "toggleDiscountType"), _obj), _applyDecoratedDescriptor(_obj, "setShop", [_dec9], Object.getOwnPropertyDescriptor(_obj, "setShop"), _obj), _applyDecoratedDescriptor(_obj, "toggleAutoIssueEInvoices", [_dec10], Object.getOwnPropertyDescriptor(_obj, "toggleAutoIssueEInvoices"), _obj), _applyDecoratedDescriptor(_obj, "togglePosFullscreenMode", [_dec11], Object.getOwnPropertyDescriptor(_obj, "togglePosFullscreenMode"), _obj)), _obj)));

  _exports.default = _default;
});