define("client/pods/components/contacts/transporters-accounts-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['extensions/apps/tw/t-cat/lists/thermosphere', 'extensions/apps/tw/payuni-seven-eleven/accounts', 'extensions/apps/tw/payuni-seven-eleven/type']);
      const self = this;
      let isEditing = this.isEditing;

      if (isEditing) {
        const original = self.get('model');
        const dirty = this.dataManager.setAsRecord({
          adapterName: 'base',
          data: original.serialize()
        });
        self.set('model', dirty);
        self.set('original', original);
      } else {
        const model = this.dataManager.setAsRecord({
          adapterName: 'base',
          data: {
            accountType: ''
          }
        });
        self.set('model', model);
      }
    },

    isMinRequiredMet: Ember.computed('model._data.{account,name}', function () {
      const minRequired = ['account', 'name'];
      const model = this.model;
      let met = true;
      minRequired.forEach(function (required) {
        if (Ember.isEmpty(model.getData(required))) {
          met = false;
        }
      });
      return met;
    }),

    create() {
      const self = this;
      const model = self.get('model');
      self.get('onAddItem')(model);
      self.get('onCloseDialogAction')();
    },

    update() {
      const self = this;
      const dirty = self.get('model');
      const original = self.get('original');
      original.populate(dirty.serialize());
      self.get('onUpdateItem')(original);
    },

    delete() {
      const self = this;
      const intl = self.get('intl');
      const msg = intl.t('are you sure you want to delete');

      if (window.confirm(msg)) {
        const model = self.get('original');
        self.get('onRemoveItem')(model);
      }
    },

    actions: {
      submit() {
        if (this.isEditing) {
          this.update();
        } else {
          this.create();
        }
      },

      delete() {
        this.delete();
      },

      cancel() {
        if (this.isEditing) {
          this.set('showEditor', false);
        } else {
          this.onCloseDialogAction();
        }
      }

    }
  });

  _exports.default = _default;
});