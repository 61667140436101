define("client/pods/documents/lists/import/dispatched/specs/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    async findAll() {
      return [{
        headerValue: '',
        value: '',
        label: 'none'
      }, {
        headerValue: '收貨人',
        value: 'contact',
        label: 'contact',
        required: true
      }, {
        headerValue: '收貨人電話',
        value: 'telephone',
        label: 'telephone',
        required: true
      }, {
        headerValue: '收貨人地址',
        value: 'address',
        label: 'address',
        required: true
      }, {
        headerValue: '託運單號',
        value: 'waybill',
        label: 'waybill',
        required: true
      }, {
        headerValue: this.intl.t('payment method'),
        value: 'paymentMethodKey',
        label: this.intl.t('payment method'),
        required: true,
        translate: false
      }, {
        headerValue: this.intl.t('total'),
        value: 'inclTotal',
        label: this.intl.t('total'),
        required: true,
        translate: false
      }, {
        headerValue: this.intl.t('notes'),
        value: 'notes',
        label: this.intl.t('notes'),
        required: true,
        translate: false
      }, {
        // headerValue: '參照編號',
        headerValue: this.intl.t('ref'),
        value: 'ref',
        prop: 'ref',
        label: this.intl.t('ref'),
        translate: false
      }, {
        headerValue: '#',
        value: 'docNo',
        prop: 'docNo',
        label: '#',
        translate: false
      }, {
        headerValue: this.intl.t('transporter'),
        value: 'transporterLabel',
        prop: 'transporterLabel',
        label: this.intl.t('transporter'),
        translate: false
      }, {
        headerValue: this.intl.t('transporter account'),
        value: 'transporterAccountNumber',
        prop: 'transporterAccountNumber',
        label: this.intl.t('transporter account'),
        translate: false // }, {
        //   headerValue: '件數',
        //   value: 'packages',
        //   label: 'packages'
        // }, {
        //   headerValue: '託運單號',
        //   value: 'dispatchDateZ',
        //   label: 'dispatchDateZ'

      }];
    }

  });

  _exports.default = _default;
});