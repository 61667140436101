define("client/pods/filters/model", ["exports", "ramda", "ramda-extension", "client/pods/base/model", "client/utils/nventor", "client/pods/filters/sort/model"], function (_exports, R, R_, _model, _nventor, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        page: 1,
        count: 20
      };
    },

    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.transformSort(data);
      return this._super(...arguments);
    },

    transformSort(rawData) {
      const sort = R.propOr([], 'sort')(rawData);

      if (!R.isEmpty(sort)) {
        rawData.sort = R.map(sortData => _nventor.default.createModel(_model2.default, sortData))(sort);
      }

      return rawData;
    },

    // const localecompare = R.curry((getter, a, b) => {
    //   const aV = getter(a)
    //   const bV = getter(b)
    //   return bV.localeCompare(aV)
    // })
    // const alice = {
    //   name: 'alice',
    //   age: 40
    // };
    // const bob = {
    //   name: 'bob',
    //   age: 30
    // };
    // const clara = {
    //   name: 'Blara',
    //   age: 30
    // };
    // const people = [clara, bob, alice];
    // const ageNameSort = R.sortWith([
    //   R.descend(R.prop('age')),
    //   lc(R.prop('name'))
    // ]);
    // ageNameSort(people); //=> [alice, clara, bob]
    getRamdaSortWithArray() {
      let propPrefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '_data.';
      const sortArray = this.getData('sort') || [];
      return R.pipe(R.map(sortByObject => {
        const sortBy = sortByObject?._data?.by;

        if (!sortBy) {
          return false;
        }

        const prop = `${propPrefix}${sortBy}`;

        if (R.toLower(sortByObject?._data?.direction || '') === 'desc') {
          return _nventor.default.sortTextAndNumericDescendingWithGetter(R_.dotPath(prop));
        }

        return _nventor.default.sortTextAndNumericAscendingWithGetter(R_.dotPath(prop));
      }), R.reject(R.equals(false)))(sortArray);
    },

    addSort(by, direction) {
      let {
        toNumber = false,
        paramPrefix = '',
        keepParamPrefix = false
      } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this._setSortBy('add', by, direction, {
        toNumber,
        paramPrefix,
        keepParamPrefix
      });
    },

    replaceSort(by, direction) {
      let {
        toNumber = false,
        paramPrefix = '',
        keepParamPrefix = false
      } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this._setSortBy('replace', by, direction, {
        toNumber,
        paramPrefix,
        keepParamPrefix
      });
    },

    _setSortBy(method, by, direction, _ref) {
      let {
        toNumber,
        paramPrefix,
        keepParamPrefix
      } = _ref;
      // this.set('direction', direction)
      let sortByObject = this.getSortByObject(by);
      let sort = [];

      if (method === 'add') {
        sort = this.getData('sort') || [];

        if (sortByObject) {
          if (direction) {
            sortByObject.setData('direction', direction);
            sortByObject.setData('toNumber', toNumber);
            sortByObject.setData('paramPrefix', paramPrefix);
            sortByObject.setData('keepParamPrefix', keepParamPrefix);
          } else {
            sort.removeObject(sortByObject);
          }
        } else {
          sortByObject = _nventor.default.createModel(_model2.default, {
            by,
            direction,
            toNumber,
            paramPrefix,
            keepParamPrefix
          });
        }
      } else {
        sortByObject = _nventor.default.createModel(_model2.default, {
          by,
          direction,
          toNumber,
          paramPrefix,
          keepParamPrefix
        });
      }

      if (direction) {
        sort.pushObject(sortByObject);
      }

      return this.setData('sort', sort);
    },

    getSortByObject(by) {
      const sort = this.getData('sort') || [];
      const found = R.find(R.pathEq(['_data', 'by'], by))(sort);

      if (found) {
        return found;
      }

      return '';
    },

    getPropLength(prop) {
      const value = this.get(prop) || [];
      return value.length;
    },

    statusesCount: Ember.computed('_data.statuses.[]', function () {
      return this.getPropLength('_data.statuses');
    }),
    hasNewSearchResults: Ember.computed('fullCount', 'previousQueries.[]', 'currentQuerySubmitted', function () {
      const currentQuerySubmitted = this.currentQuerySubmitted || '';

      if (!currentQuerySubmitted) {
        return false;
      }

      const previousQueries = this.previousQueries || [];
      const lastQuery = R.pathOr({}, [0])(previousQueries);

      if (!lastQuery) {
        return false;
      }

      if (lastQuery !== currentQuerySubmitted) {
        return false;
      }

      const fullCount = Number(this.fullCount) || 0;

      if (fullCount > 0) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});