define("client/pods/components/documentations/sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c7GVSjHQ",
    "block": "{\"symbols\":[\"documentation\",\"onCloseDialogAction\",\"@tab\",\"@sidebarOptions\",\"&attrs\"],\"statements\":[[5,\"layouts/sidebar/content\",[[12,\"class\",\"u-container\"],[13,5]],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,0,[\"allowedEdit\"]]],null,{\"statements\":[[0,\"    \"],[5,\"elements/element-btn-with-modal-dialog\",[],[[\"@icon\",\"@label\",\"@closeOnOverlayClick\"],[\"fas fa-plus\",\"add help\",false]],{\"statements\":[[0,\"\\n      \"],[5,\"documentations/documentation-edit-modal\",[],[[\"@isHelp\",\"@tab\",\"@documentationResource\",\"@onCloseDialogAction\",\"@index\"],[true,[23,3,[]],[23,4,[\"documentationHelp\",\"documentationResource\"]],[23,2,[]],[23,0,[\"helpDocumentations\",\"length\"]]]]],[0,\"\\n    \"]],\"parameters\":[2]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"accordians-list\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"helpDocumentations\"]]],null,{\"statements\":[[0,\"      \"],[5,\"accordion-help-section\",[],[[\"@expanded\",\"@label\",\"@translate\",\"@tab\",\"@documentationResource\",\"@formInitData\"],[[23,1,[\"expanded\"]],[23,1,[\"_data\",\"documentationId\"]],false,[23,3,[]],[23,1,[\"_data\",\"documentationResource\"]],[28,\"hash\",null,[[\"documentationId\"],[[23,1,[\"_data\",\"documentationId\"]]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/documentations/sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});