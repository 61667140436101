define("client/pods/search/service", ["exports", "ramda", "ramda-extension", "ember-concurrency-decorators"], function (_exports, R, R_, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SearchService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class SearchService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "users", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);
    }

    // props.dynamicFilterKeys - eg. campaign master must never be saved, but must be used for every load from saved. this way filters can be saved and re-used across multiple campaigns
    setupFilters(_ref) {
      let {
        adapterName,
        altStorageProp = false,
        rememberMe = false,
        rememberProps = [],
        defaultFiltersData = {},
        resetDefaults = {},
        onAfter,
        props = {},
        debug = false
      } = _ref;

      if (debug) {
        debugger; //eslint-disable-line
      }

      const storageProp = altStorageProp || adapterName;

      if (rememberMe === true) {
        defaultFiltersData = this.getFromStorage(storageProp) || defaultFiltersData;
      }

      defaultFiltersData.filtersAdapterName = adapterName;
      defaultFiltersData.storageProp = storageProp;
      defaultFiltersData.rememberMe = rememberMe;
      defaultFiltersData.rememberProps = rememberProps;
      const userKey = this.users.getUserKey();
      defaultFiltersData.userKey = userKey;

      if (!defaultFiltersData.menuId) {
        defaultFiltersData.menuId = adapterName;
      }

      const filters = this.dataManager.setAsRecord({
        debug,
        adapterName,
        data: defaultFiltersData
      });
      R_.mapKeysWithValue((key, value) => {
        filters.set(key, value);
      })(props);
      filters.set('resetDefaults', resetDefaults);
      return filters;
    }

    copyFilters(_ref2) {
      let {
        filters,
        adapterName,
        altStorageProp = false,
        rememberMe = false,
        lastSearchSettingsProperty = 'lastSearchSettings',
        defaultFiltersData
      } = _ref2;
      let toCopyDefaultFiltersData = filters.serialize();
      toCopyDefaultFiltersData = R.mergeRight(toCopyDefaultFiltersData, defaultFiltersData);
      adapterName = adapterName || defaultFiltersData.filtersAdapterName;
      const copy = this.setupFilters({
        adapterName,
        altStorageProp,
        rememberMe,
        defaultFiltersData: toCopyDefaultFiltersData
      });
      copy.set('lastSearchSettingsProperty', lastSearchSettingsProperty);
      return copy;
    }

    getFromStorage(storageProp) {
      storageProp = R.replace('/', '__')(storageProp);
      return this.storage.get(`settings__${storageProp}__filters`) || false;
    }

    setToStorage(filters) {
      let rememberProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      let storageProp = filters.getData('storageProp');
      storageProp = R.replace('/', '__')(storageProp);
      rememberProps = rememberProps || filters.getData('rememberProps') || [];
      let filterData = filters.serialize();

      if (!R.isEmpty(rememberProps)) {
        filterData = R.pick(rememberProps)(filterData);
      }

      this.storage.set(`settings__${storageProp}__filters`, filterData);
      return filters;
    }

    loadFromSavedFilters(_ref3) {
      let {
        currentFilters,
        savedFilter,
        onAfter
      } = _ref3;
      return function* () {
        const currentFiltersSerialized = currentFilters.serialize();
        const savedFilterSerialized = savedFilter.serialize();
        const savedFilterData = savedFilterSerialized.data || {}; // keep dynamic filter values from being overritten

        const dynamicFilterKeys = currentFilters?.dynamicFilterKeys || [];
        R.forEach(dynamicKey => {
          const dynamicValue = R.prop(dynamicKey)(currentFiltersSerialized);

          if (!R.isNil(dynamicValue)) {
            savedFilterData[dynamicKey] = dynamicValue;
          }
        })(dynamicFilterKeys);
        currentFilters.set('_data', savedFilterData);
        yield onAfter(currentFilters);
      }();
    }

    saveFiltersTask(_ref4) {
      var _this = this;

      let {
        filters,
        onCloseDialogAction
      } = _ref4;
      return function* () {
        // const userMenu = this.userMenu || []
        // const lastIndex = userMenu.length || 0
        // filters.setData('index', 0)
        const dynamicFilterKeys = filters?.dynamicFilterKeys || [];
        let filtersData = filters.serialize();
        filtersData = R.omit(dynamicFilterKeys)(filtersData); // @NOTE: always set to page 1.
        // if need to set a different page, should add input in save filter modal

        filtersData.page = 1;

        const model = _this.crud.setupNewRecord({
          adapterName: filters.adapterName,
          data: {
            menuId: filtersData?.menuId,
            userKey: filtersData?.userKey,
            label: filtersData?.label,
            data: filtersData
          }
        });

        yield _this.crud.createRecordTask.perform({
          adapterName: 'users/personal-filters',
          model
        });

        if (onCloseDialogAction) {
          onCloseDialogAction();
        }
      }();
    }

    *removeSavedFiltersTask(savedFilter) {
      yield this.crud.removeRecordTask.perform({
        adapterName: 'users/personal-filters',
        model: savedFilter,
        noConfirm: false
      });
    }

    getSavedFiltersTask(_ref5) {
      var _this2 = this;

      let {
        menuId
      } = _ref5;
      return function* () {
        const personalFilters = _this2.setupFilters({
          adapterName: 'users/personal-filters/filters',
          defaultFiltersData: {
            sort: [{
              by: 'index',
              direction: 'ASC'
            }],
            menuId
          }
        });

        _this2.set('personalFilters', personalFilters);

        const personalSavedFilters = yield _this2.crud.findTask.perform({
          adapterName: 'users/personal-filters/collection',
          filters: personalFilters
        });
        return personalSavedFilters;
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "users", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loadFromSavedFilters", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadFromSavedFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveFiltersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveFiltersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSavedFiltersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "removeSavedFiltersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getSavedFiltersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getSavedFiltersTask"), _class.prototype)), _class));
  _exports.default = SearchService;
});