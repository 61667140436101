define("client/pods/components/wizards/wizard-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WizardsWizardContainerCompenent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('users'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = class WizardsWizardContainerCompenent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);

      _initializerDefineProperty(this, "usersService", _descriptor4, this);

      _initializerDefineProperty(this, "userMenu", _descriptor5, this);

      _initializerDefineProperty(this, "userKey", _descriptor6, this);

      this.crud.addLists(this, []);
      this.setupTask.perform();
    }

    *setupTask() {
      const model = this.args.model;
      const tab = this.args.tab;

      if (!this.isWizard) {
        return;
      }

      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model,
        isNew: this.isNew,
        tab
      });
      Ember.set(this, 'dirty', dirty);
      this.getCustomizedUserMenuTask.perform();
    }

    afterLoadLists() {
      // @todo: add isWizrd
      const stepsList = this.stepsList;

      const initialPanel = _nventor.default.safeHeadOr({}, stepsList);

      this.tabsManager.displaySubPanel(this, initialPanel);
    }

    get wizardData() {
      return R.pathOr(null, ['wizardData'])(this.args);
    }

    get isWizard() {
      return R.pathOr(false, ['isWizard'])(this.args);
    }

    get isNew() {
      const model = this.args.model;

      const _key = R.pathOr('', ['_data', 'key'])(model);

      if (_key) {
        return false;
      }

      return true;
    }

    get adapterName() {
      return this.target;
    }

    get target() {
      const wizardData = this.wizardData;

      if (!wizardData) {
        return R.pathOr('', ['target'])(this.args);
      }

      return wizardData?.target || '';
    }

    get stepsList() {
      const wizardData = this.wizardData;

      if (!wizardData) {
        return R.pathOr([], ['stepsList'])(this.args);
      }

      return wizardData?.stepsList || [];
    }

    get resourceList() {
      return R.map(stepObj => stepObj?._data || {})(this.stepsList);
    }

    get isRunning() {
      const creating = this.crud.createRecordTask.isRunning;
      const updating = this.crud.updateRecordTask.isRunning;
      const replacing = this.crud.replaceRecordTask.isRunning;
      const finding = this.crud.findTask.isRunning;
      return creating || updating || replacing || finding;
    }

    get currentStepIndex() {
      const tab = this.args.tab;
      const subTab = this.tabsManager.getActiveSubTab(tab);

      if (!subTab?.value) {
        return -1;
      }

      const stepsList = this.stepsList;
      return R.findIndex(R.pathEq(['_data', 'value'], subTab.value))(stepsList);
    }

    get previousStep() {
      const stepsList = this.stepsList;
      const subTabIndex = this.currentStepIndex;

      if (subTabIndex === -1 || subTabIndex === 0) {
        return false;
      }

      return stepsList[subTabIndex - 1];
    }

    get nextStep() {
      const stepsList = this.stepsList;
      const subTabIndex = this.currentStepIndex;

      if (subTabIndex === -1 || subTabIndex === stepsList.length - 1) {
        return false;
      }

      return stepsList[subTabIndex + 1];
    }

    get currentStep() {
      const stepsList = this.stepsList;
      const subTabIndex = this.currentStepIndex;

      if (subTabIndex === -1) {
        return false;
      }

      return stepsList[subTabIndex];
    }

    get entities() {
      return R.pathOr(false, ['_data', 'entities'])(this.currentStep);
    }

    get updateProps() {
      return R.pathOr(false, ['_data', 'updateProps'])(this.currentStep);
    }

    previous() {
      const previousStep = this.previousStep;

      if (previousStep) {
        this.tabsManager.displaySubPanel(this, previousStep);
      }
    }

    async next() {
      const dirty = this.dirty;
      const nextStep = this.nextStep;

      if (nextStep) {
        const tab = this.args.tab;
        const currentStep = this.currentStep;
        const updateProps = R.pathOr([], ['_data', 'updateProps'])(currentStep);
        const onWizardNew = this.wizardData?.onWizardNew || this.args.onWizardNew;

        if (!this.previousStep && this.isNew && onWizardNew) {
          const saved = await onWizardNew(dirty, {
            tabOptions: {
              tab
            }
          });

          const _dirty = this.crud.setupDirty({
            adapterName: this.adapterName,
            model: saved,
            tab
          });

          Ember.set(this, 'dirty', _dirty);
        } else {
          const onNext = this.wizardData?.onWizardNext || this.args.onWizardNext;

          if (onNext) {
            await onNext(dirty, {
              updateProps,
              tabOptions: {
                tab
              }
            });
          }
        }

        this.tabsManager.displaySubPanel(this, nextStep);
      } else {
        await this.saveTask.perform();
        const onDone = this.wizardData?.onWizardDone || this.args.onWizardDone;

        if (onDone) {
          onDone(dirty);
        }
      }
    }

    async cancel() {
      const model = await this.crud.findTask.perform({
        adapterName: this.adapterName
      });
      const onCancel = this.wizardData?.onWizardCancel || this.args.onWizardCancel;

      if (onCancel) {
        onCancel(model, {
          tabOptions: {}
        });
      }
    }

    *getCustomizedUserMenuTask() {
      const userKey = this.usersService.getUserKey();
      this.userKey = userKey;
      const menuId = 'application/lists/wizards';
      const userMenuFilters = this.search.setupFilters({
        adapterName: 'members-menu/filters',
        defaultFiltersData: {
          sort: [{
            by: 'index',
            direction: 'ASC'
          }],
          userKey,
          menuId,
          target: this.target
        }
      });
      const menu = yield this.crud.findTask.perform({
        adapterName: 'wizards',
        filters: userMenuFilters
      });
      this.userMenu = menu;
    }

    *saveTask() {
      const menu = this.userMenu;
      const collection = R.pathOr([], ['_data', 'collection'])(menu);
      const targetCollection = R.find(R.pathEq(['_data', 'target'], this.target))(collection);

      if (!targetCollection) {
        return;
      }

      const dirty = this.crud.setupDirty({
        adapterName: 'members-menu',
        model: targetCollection
      });
      dirty.setData('isFinished', true);
      yield this.crud.updateRecordTask.perform({
        adapterName: 'members-menu/collection',
        model: dirty
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "usersService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userMenu", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "userKey", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "previous", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "previous"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "next", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "next"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCustomizedUserMenuTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getCustomizedUserMenuTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "saveTask"), _class.prototype)), _class));
  _exports.default = WizardsWizardContainerCompenent;
});