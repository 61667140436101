define("client/pods/components/dispatch/panels-display/print-all-dialog/component", ["exports", "ramda", "ember-concurrency", "ramda-extension", "ramda-adjunct"], function (_exports, R, _emberConcurrency, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    constants: Ember.inject.service(),
    constantsService: Ember.inject.service('constants'),
    tagName: 'span',
    attributeBindings: ['attr:data-trigger-id'],
    attr: '',
    hasPrices: true,
    hasGroups: true,
    hasReturnsDetails: true,
    nextDispatchProcessCode: '',
    isPrinted: false,
    hidePrinted: 'hidePrintedAll',
    markAsPrintedOnClose: true,
    waybillsHtml: false,
    printType: '',
    lastPrintTimestamp: '',
    batchStatus: null,
    waybillsPerPage: 4,
    groupDocumentsByTransporter: false,

    init() {
      this.crud.addLists(this, ['documents/lists/hide-printed', 'documents/lists/print', 'documents/lists/print/unit', 'documents/lists/print/product-code', 'extensions/apps/tw/t-cat/lists/waybill-print-type']);

      this._super(...arguments);

      const subPanels = this.printSubPanels || [];

      if (subPanels.length > 0) {
        this.tabsManager.displaySubPanel(this, subPanels.get('firstObject'));
      }

      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      this.set('dispatchProcesses', dispatchProcesses);
      this.set('isPrinted', false);
      this.set('errors', []);
      const printImagesOnDocuments = this?.settings?.settingsModel?._data?.printImagesOnDocuments || false;

      if (printImagesOnDocuments) {
        this.set('hasImages', true);
      }

      const groupDocumentsByTransporter = this?.settings?.settingsModel?._data?.groupDocumentsByTransporter || false;

      if (groupDocumentsByTransporter) {
        this.set('groupDocumentsByTransporter', true);
      }
    },

    afterLoadLists() {
      const hidePrinted = R.pathOr([], ['crud', 'lists', 'documents-lists-hide-printed', 0, '_data', 'value'])(this);
      this.set('hidePrinted', hidePrinted);
    },

    setupTask: (0, _emberConcurrency.task)(function* (onFetchUnpaginatedData) {
      this.set('isPrinted', false);
      this.set('markAsPrintedOnClose', true);
      this.set('errors', []);
      return onFetchUnpaginatedData({
        hidePrinted: this.hidePrinted
      });
    }).drop(),
    printBadge: Ember.computed('toPrintList.[]', 'hasSelected', function () {
      if (this.hasSelected > 0) {
        const models = this.toPrintList || [];
        return models.length;
      }

      return this.intl.t('all');
    }),
    printContentResults: Ember.computed('toPrintList.[]', 'toPrintListTCat.[]', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', function () {
      const activeSubTab = R.pipe(R.pathOr([], ['tab', 'subTabsArray']), R.find(R.propEq('isActive', true)))(this);
      let provider = R.propOr('', 'value')(activeSubTab);
      const isHct = R.pipe(R.propOr('', 'label'), R.includes(this.constantsService.transporterExtensions.hct))(activeSubTab);

      if (isHct) {
        provider = this.constantsService.transporterExtensions.hct;
      }

      const allResultsWhen = ['pickingList', 'deliveryNotes', 'hctSummary'];
      const shouldShowAllResults = R.includes(provider)(allResultsWhen);

      if (shouldShowAllResults) {
        return this.toPrintModelsList;
      }

      return R.pipe(R.propOr([], 'toPrintModelsList'), R.filter(R.pathEq(['_data', 'transporterExtension'], provider)))(this);
    }),
    printContentListToPrint: Ember.computed('groupDocumentsByTransporter', 'toPrintList.[]', 'toPrintListTCat.[]', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', function () {
      const activeSubTab = R.pipe(R.pathOr([], ['tab', 'subTabsArray']), R.find(R.propEq('isActive', true)))(this);
      let provider = R.propOr('', 'value')(activeSubTab);
      const isHct = R.pipe(R.propOr('', 'label'), R.includes(this.constantsService.transporterExtensions.hct))(activeSubTab);

      if (isHct) {
        provider = this.constantsService.transporterExtensions.hct;
      }

      const allResultsWhen = ['pickingList', 'deliveryNotes', 'hctSummary'];
      const shouldShowAllResults = R.includes(provider)(allResultsWhen);

      if (shouldShowAllResults) {
        // groupDocumentsByTransporter
        if (!this.groupDocumentsByTransporter) {
          return this.toPrintList;
        }

        const transporterExtensionOrderSet = R.pipe(R.pathOr([], ['printSubPanels']), R.reduce((acc, subPanel) => {
          const transporterExtension = subPanel?._data?.transporterExtension;

          if (!transporterExtension) {
            return acc;
          }

          acc.add(transporterExtension);
          return acc;
        }, new Set()))(this);
        return R.pipe(R.propOr([], 'toPrintList'), R.groupBy(R.path(['model', '_data', 'transporterExtension'])), R.toPairs, // Convert to array of [key, value] pairs
        R.sortBy(R.compose(R.indexOf(R.__, Array.from(transporterExtensionOrderSet)), // Get index from the Set
        R.head // Extract the key from the [key, value] pair
        )), R.fromPairs, R.values, R.flatten)(this);
      }

      return R.pipe(R.propOr([], 'toPrintList'), R.filter(R.anyPass([R.pathEq(['model', '_data', 'transporterExtension'], provider), R.pathEq(['model', '_data', 'transporterExtension'], provider)])))(this);
    }),
    printCount: Ember.computed('printContentResults', 'toPrintList.[]', 'toPrintListTCat.[]', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', function () {
      return R.length(this.printContentResults);
      const activeSubTab = R.pipe(R.pathOr([], ['tab', 'subTabsArray']), R.find(R.propEq('isActive', true)))(this);
      const provider = R.propOr('', 'value')(activeSubTab);

      if (provider === this.constants.transporterExtensions.tCat) {
        return R.length(this.toPrintListTCat);
      } else if (provider === this.constants.transporterExtensions.sevenEleven) {
        return R.pipe(R.propOr([], 'toPrintList'), R.filter(R.pathEq(['model', '_data', 'transporterExtension'], this.constants.transporterExtensions.sevenEleven)), R.length)(this);
      }

      return R.length(this.toPrintList);
    }),
    printListWithTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'printContentResults.@each._data.transporterWaybillRecord', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', function () {
      return R.pipe(R.propOr([], 'printContentResults'), R.reject(R.pipe(R.path(['_data', 'transporterWaybillRecord']), RA.isNilOrEmpty)))(this);
    }),
    printListCountTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'printListWithTransporterWaybillRecord.@each._data.transporterWaybillRecord', 'printContentResults', function () {
      return R.length(this.printListWithTransporterWaybillRecord);
    }),
    missingTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'printContentResults.@each._data.transporterWaybillRecord', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', function () {
      return R.pipe(R.propOr([], 'printContentResults'), R.filter(R.pipe(R.path(['_data', 'transporterWaybillRecord']), RA.isNilOrEmpty)))(this);
    }),
    printCountMissingTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'missingTransporterWaybillRecord.@each._data.transporterWaybillRecord', 'printContentResults', function () {
      return R.length(this.missingTransporterWaybillRecord);
    }),
    showMarkAsPrintedBtn: Ember.computed('isDispatched', 'isPrinted', 'dispatchProcess._data.allowPrintAllWaybills', function () {
      const allowPrintAllWaybills = R.pathEq(['dispatchProcess', '_data', 'allowPrintAllWaybills'], true)(this);

      if (allowPrintAllWaybills) {
        return this.isPrinted;
      }

      return this.isDispatched && this.isPrinted;
    }),
    printSubPanels: Ember.computed('isDispatched', 'crud.lists.dispatch-lists-print-all-sub-panels.[]', 'dispatchProcess._data.allowPrintAllWaybills', function () {
      const subPanels = R_.dotPath('crud.lists.dispatch-lists-print-all-sub-panels')(this) || [];
      const isDispatched = this.isDispatched;
      const allowPrintAllWaybills = R.pathEq(['dispatchProcess', '_data', 'allowPrintAllWaybills'], true)(this);
      const resultsGroupByTransporterExtension = R.pipe(R.pathOr([], ['printContentResults']), R.groupBy(R.path(['_data', 'transporterExtension'])))(this);

      if (isDispatched || allowPrintAllWaybills) {
        // "dispatch/lists/print-all-sub-panels"
        return R.map(subPanel => {
          const transporterExtension = R.pathOr('', ['_data', 'transporterExtension'])(subPanel);
          const documentsCount = R.pipe(R.pathOr([], [transporterExtension]), R.length)(resultsGroupByTransporterExtension);

          if (documentsCount === 0) {
            return subPanel;
          }

          const data = subPanel.serialize();
          data.badge = documentsCount;
          return this.crud.setupNewRecord({
            adapterName: 'dispatch/lists/print-all-sub-panels',
            data
          });
        })(subPanels);
      }

      return subPanels.filter(model => {
        const allowedPanels = ['pickingList', 'deliveryNotes'];
        return allowedPanels.includes(model?._data?.value);
      });
    }),
    updateBtnLabel: Ember.computed('nextDispatchProcessCode', function () {
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      let label = this.intl.t('update status');

      if (nextDispatchProcessCode) {
        const dispatchService = this.dispatch;
        const dispatchProcesses = this.dispatchProcesses || [];
        const nextDispatchProcess = dispatchService.findDispatchProcess(dispatchProcesses, nextDispatchProcessCode);

        if (nextDispatchProcess) {
          let name = nextDispatchProcess.getData('name');

          if (nextDispatchProcess.getData('translate')) {
            name = this.intl.t(name);
          }

          label = `${label}: ${name}`;
        }
      }

      return label;
    }),
    isDisableSetNextDispatchProcess: Ember.computed('nextDispatchProcessCode', 'dispatchProcess', function () {
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      const dispatchProcess = this.dispatchProcess;

      if (nextDispatchProcessCode && dispatchProcess.getData('code') !== nextDispatchProcessCode) {
        return false;
      }

      return true;
    }),
    disablePrintBtn: Ember.computed('toPrintList.@each.model._data.{waybill,transporterWaybillRecord}', 'printListWithTransporterWaybillRecord', 'printListCountTransporterWaybillRecord', 'lastPrintTimestamp', function () {
      // Should only allow to print if all documents contains waybills
      const allDocHaveWaybill = R.pipe(R.propOr([], 'toPrintList'), R.map(R.pathOr('', ['model', '_data', 'waybill'])), R.reject(RA.isNilOrEmpty), R.allPass([RA.isNotNilOrEmpty]))(this);

      if (allDocHaveWaybill) {
        return false;
      }

      return true;
    }),
    printCountOuterRegion: Ember.computed('toPrintListOuterRegion', function () {
      const toPrintListOuterRegion = R.pipe(R.prop('toPrintListOuterRegion'), R.length)(this);
      return toPrintListOuterRegion;
    }),
    toPrintListOuterRegion: Ember.computed('toPrintList.@each.model._data.transporterRegionIsOuter', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', 'lastPrintTimestamp', function () {
      const printCountOuterRegion = R.pipe(R.prop('toPrintList'), R.filter(R.pathEq(['model', '_data', 'transporterRegionIsOuter'], true)))(this);
      return printCountOuterRegion;
    }),
    setNextDispatchProcessTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      // const models = this.toPrintModelsList
      const dispatchService = this.dispatch;
      const filters = this.filters;
      const dispatchProcesses = this.dispatchProcesses || [];
      const dispatchProcess = this.dispatchProcess;
      const nextDispatchProcessCode = this.nextDispatchProcessCode;

      if (dispatchProcess.getData('code') !== nextDispatchProcessCode) {
        const nextDispatchProcess = dispatchService.findDispatchProcess(dispatchProcesses, nextDispatchProcessCode);

        if (nextDispatchProcess) {
          const filtersData = filters.serialize();
          const defaultFiltersData = R.mergeRight(filtersData, {
            matches: [],
            count: 5000,
            dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
            dispatchProcess: dispatchProcess.getData('code'),
            sort: filters.getData('sort')
          });

          if (this.period) {
            defaultFiltersData.dispatchDateZ = this.period.date;
          } else {
            defaultFiltersData.dispatchDateZ = '';
          }

          const unpaginatedFilters = this.search.setupFilters({
            adapterName: 'documents/dispatch/filters',
            defaultFiltersData
          });
          const results = yield this.get('dispatch.updateEntireDispatchProcessTask').perform(unpaginatedFilters, nextDispatchProcess);
          this.onRefresh(nextDispatchProcess);
          onCloseDialogAction();
          return results;
        }

        onCloseDialogAction();
      }
    }).drop(),
    selectSubTabTask: (0, _emberConcurrency.task)(function* (subTab, a, b) {
      if (subTab.getData('value') === 'hctSummary') {
        // fetch all unpaginated
        const allResults = yield this.onFetchUnpaginatedData({
          setResults: false,
          hidePrinted: false
        });
        const allUnpaginated = R.prop('docs')(allResults) || [];
        this.set('allUnpaginated', allUnpaginated);
      }

      return this.tabsManager.displaySubPanel(this, subTab);
    }),
    generateWaybillsTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        onPrintDialogAction,
        subTab,
        isNewWaybill = false,
        onlyFetch = false,
        onlyGenerate = false,
        onlyMissingTransporterWaybillRecord = false,
        onlyWithTransporterWaybillRecord = false,
        waybillsPerPage
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return function* () {
        const isTCat = R.pipe(R.prop('value'), R.includes(_this.constantsService.transporterExtensions.tCat))(subTab);
        let provider = R.propOr('', 'value')(subTab);
        const isHct = R.pipe(R.propOr('', 'label'), R.includes(_this.constantsService.transporterExtensions.hct))(subTab);

        if (isHct) {
          provider = _this.constantsService.transporterExtensions.hct;
        }

        const isAllowTransporter = R.anyPass([R.includes(_this.constantsService.transporterExtensions.tCat), R.includes(_this.constantsService.transporterExtensions.hct), R.includes(_this.constantsService.transporterExtensions.sevenEleven), R.includes(_this.constantsService.transporterExtensions.payuniSevenEleven)])(provider);
        let documents = _this.printContentResults;

        if (isTCat) {
          return _this.dispatch.tCatGenerateAndDownloadWaybillTask.perform({
            component: _this,
            documents,
            printType: _this.printType
          });
        } else if (isAllowTransporter) {
          if (onlyMissingTransporterWaybillRecord) {
            documents = _this.missingTransporterWaybillRecord;
          } else if (onlyWithTransporterWaybillRecord) {
            documents = _this.printListWithTransporterWaybillRecord;
          }

          const response = yield _this.dispatch.generateAndDownloadWaybillsTask.perform({
            component: _this,
            transporterExtension: provider,
            documents,
            isNewWaybill,
            onlyFetch,
            onlyGenerate,
            waybillsPerPage
          });
          const transporterExtensionsToDisplayAsIframe = [_this.constants.transporterExtensions.sevenEleven, _this.constants.transporterExtensions.payuniSevenEleven];

          if (transporterExtensionsToDisplayAsIframe.includes(provider)) {
            _this.set('waybillsHtml', response);

            return;
          }

          _this.set('batchStatus', response);

          const lastPrintTimestamp = new Date().getTime();

          _this.set('lastPrintTimestamp', lastPrintTimestamp);

          return response;
        }

        if (subTab.value !== 'hctSummary') {
          _this.set('isPrinted', true);
        }

        if (onPrintDialogAction) {
          onPrintDialogAction(subTab);
        }
      }();
    }),
    fetchWaybillRecordTask: (0, _emberConcurrency.task)(function* (subTab) {
      let documents = this.availableToReDownloadDocuments;
      let provider = R.propOr('', 'value')(subTab);
      const waybillsPerPage = this.waybillsPerPage;
      const response = yield this.dispatch.hctFetchWaybillRecordTask.perform({
        component: this,
        transporterExtension: provider,
        documents,
        waybillsPerPage
      });
      const transporterExtensionsToDisplayAsIframe = [this.constants.transporterExtensions.sevenEleven, this.constants.transporterExtensions.payuniSevenEleven];

      if (transporterExtensionsToDisplayAsIframe.includes(provider)) {
        this.set('waybillsHtml', response);
        return;
      }

      this.set('batchStatus', response);
      const lastPrintTimestamp = new Date().getTime();
      this.set('lastPrintTimestamp', lastPrintTimestamp);
      return response;
    }),

    resetBatchStatus() {
      this.set('batchStatus', null);
    },

    setWaybillsPerPage(model) {
      const waybillsPerPage = R.pathOr(4, ['_data', 'value'])(model);
      this.set('waybillsPerPage', waybillsPerPage);
    },

    fetchUnPaginatedDataTask: (0, _emberConcurrency.task)(function* (onFetchUnPaginatedDataTask, model) {
      return yield onFetchUnPaginatedDataTask.perform({
        hidePrinted: model?._data?.value
      });
    }),
    actions: {
      setPrintType(printType) {
        this.printType = R.pathOr(printType, ['_data', 'value'])(printType);
      },

      print(onPrintDialogAction, subTab) {
        let {
          runPrintCommand = false
        } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        if (runPrintCommand) {
          const waybillIframe = document.querySelector('#waybillIframe');

          if (!waybillIframe) {
            return;
          }

          waybillIframe.contentWindow.focus();
          waybillIframe.contentWindow.print();
          return;
        }

        let provider = R.propOr('', 'value')(subTab);

        if (subTab.value !== 'hctSummary') {
          this.set('isPrinted', true);
        }

        const allowedGenerateWaybills = [this.constantsService.transporterExtensions.tCat, this.constantsService.transporterExtensions.sevenEleven, this.constantsService.transporterExtensions.payuniSevenEleven];
        const isAllowToGenerateWaybills = allowedGenerateWaybills.includes(subTab.value);

        if (isAllowToGenerateWaybills) {
          const waybillsPerPage = this.waybillsPerPage;
          return this.generateWaybillsTask.perform({
            onPrintDialogAction,
            subTab,
            isNewWaybill: true,
            waybillsPerPage
          });
        }

        onPrintDialogAction(subTab);
      }

    },
    deliveryNotePrintFormat: Ember.computed('settings.settingsModel._data.deliveryNotePrintFormat', function () {
      return this?.settings?.settingsModel?._data?.deliveryNotePrintFormat || 'default';
    }),
    showUnit: Ember.computed('settings.settingsModel._data.showUnit', function () {
      return R.pathOr('showUnitBelowItems', ['settings', 'settingsModel', '_data', 'showUnit'])(this);
    }),
    showProductCode: Ember.computed('settings.settingsModel._data.showProductCode', function () {
      return R.pathOr('showCodeBelowItems', ['settings', 'settingsModel', '_data', 'showProductCode'])(this);
    }),
    isDeliveryNotesSubTab: Ember.computed('tab.subTabs.@each.isActive', function () {
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);

      if (activeSubTab.value === 'deliveryNotes') {
        return true;
      }

      return false;
    }),
    availableToReDownloadDocuments: Ember.computed('printContentResults.length', function () {
      return R.pipe(R.pathOr([], ['printContentResults']), R.filter(R.pipe(R.path(['_data', 'waybill']), RA.isNotNilOrEmpty)))(this);
    })
  }, (_applyDecoratedDescriptor(_obj, "resetBatchStatus", [_dec], Object.getOwnPropertyDescriptor(_obj, "resetBatchStatus"), _obj), _applyDecoratedDescriptor(_obj, "setWaybillsPerPage", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setWaybillsPerPage"), _obj)), _obj)));

  _exports.default = _default;
});