define("client/pods/components/websites/websites-editor/templates/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ember-concurrency", "ramda", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, _emberConcurrency, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let WebsitesWebsitesEditorTemplatesComponent = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class WebsitesWebsitesEditorTemplatesComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "applicationService", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "search", _descriptor3, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor4, this);

      _initializerDefineProperty(this, "publicResults", _descriptor5, this);

      _initializerDefineProperty(this, "privateResults", _descriptor6, this);

      _initializerDefineProperty(this, "templates", _descriptor7, this);

      _initializerDefineProperty(this, "query", _descriptor8, this);

      this.crud.addLists(this, ['websites/lists/templates']);
      this.publicFilters = this.search.setupFilters({
        adapterName: 'websites/filters',
        defaultFiltersData: {
          resultsProperty: 'publicResults',
          match: ['name'],
          hasTemplate: true,
          scope: 'public'
        }
      });
      this.privateFilters = this.search.setupFilters({
        adapterName: 'websites/filters',
        defaultFiltersData: {
          resultsProperty: 'privateResults',
          hasTemplate: true,
          match: ['name'],
          scope: 'private'
        }
      });
    }

    get publicTemplates() {
      return R.filter(R.pathEq(['_data', 'isPublicTemplate'], true))(this.publicResults);
    }

    get privateTemplates() {
      return R.reject(R.pathEq(['_data', 'isPublicTemplate'], true))(this.privateResults);
    }

    get hasResults() {
      return !(R.isEmpty(this.publicTemplates) && R.isEmpty(this.privateTemplates));
    }

    get subPanels() {
      const subPanels = this.crud.lists['websites-lists-templates'] || [];

      if (!R.isEmpty(subPanels)) {
        return R.map(panel => {
          if (panel._data.value === 'privateTemplates') {
            panel.setData('badge', this.privateTemplates.length);
          }

          if (panel._data.value === 'publicTemplates') {
            panel.setData('badge', this.publicTemplates.length);
          }

          return panel;
        }, subPanels);
      }

      return subPanels;
    }

    *searchTask(query) {
      const q = [];
      q.push(this.searchWebsiteTemplatesTask.perform({
        query,
        filters: this.publicFilters
      }));
      q.push(this.searchWebsiteTemplatesTask.perform({
        query,
        filters: this.privateFilters
      }));
      yield (0, _emberConcurrency.all)(q);
    }

    searchWebsiteTemplatesTask(_ref) {
      var _this = this;

      let {
        adapterName = 'websites/pages',
        query,
        filters
      } = _ref;
      return function* () {
        const resultsProperty = R.pathOr('', ['_data', 'resultsProperty'])(filters);
        filters.setData('query', query);
        _this[resultsProperty] = yield _this.crud.searchRecordsConcurrentlyTask.perform({
          adapterName,
          filters,
          query
        });
        return _this[resultsProperty];
      }();
    }

    afterLoadLists() {
      this.searchTask.perform();
    }

    insertTemplate(onCloseDialogAction, pageData) {
      let templateImages = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      const onInsertTemplate = R.pathOr(null, ['onInsertTemplate'])(this.args);

      if (onInsertTemplate) {
        onInsertTemplate(pageData, templateImages);
      }

      onCloseDialogAction();
    }

    replaceTemplate(onCloseDialogAction, pageData) {
      let templateImages = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      const onReplaceTemplate = R.pathOr(null, ['onReplaceTemplate'])(this.args);

      if (onReplaceTemplate) {
        onReplaceTemplate(pageData, templateImages);
      }

      onCloseDialogAction();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "publicResults", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "privateResults", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "templates", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.query || '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchWebsiteTemplatesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchWebsiteTemplatesTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterLoadLists", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "afterLoadLists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "insertTemplate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "insertTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "replaceTemplate", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "replaceTemplate"), _class.prototype)), _class));
  _exports.default = WebsitesWebsitesEditorTemplatesComponent;
});