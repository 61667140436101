define("client/pods/documents/batch/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/documents/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/documents/batch',
    modelObj: _model.default,

    /**
     * update dispatch status in a single batch
     * @param  {array} batch array of dirty models
     * @return {promise}
     */
    udpateDispatchBatch(_ref) {
      let {
        batch,
        appendPath,
        serverType
      } = _ref;
      const path = this.getPath('resourcePath', appendPath);
      batch = batch.map(dirty => {
        const docType = dirty.getData('docType');
        const docName = dirty.getData('docName');
        const adapterName = `documents/${docType}/${docName}`;
        return this.dataManager.getAdapter(adapterName).serialize(dirty);
      });
      const payload = {
        batch
      };
      return this.server.call('PATCH', path, payload, null, {
        serverType
      });
    }

  });

  _exports.default = _default;
});