define("client/pods/components/products/products-editor/customizations-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UuYb5ECf",
    "block": "{\"symbols\":[\"customization\",\"index\",\"@tab\",\"@isEditing\",\"@isNew\",\"@model\",\"@isReadonly\"],\"statements\":[[5,\"forms/form-section\",[],[[\"@context\",\"@documentationResource\"],[\"customizations\",\"products\"]],{\"statements\":[[0,\"\\n\\t\"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@documentationResource\",\"@label\",\"@model\",\"@tab\"],[\"hasCustomizations\",\"products\",\"enable customizations\",[23,6,[]],[23,3,[]]]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"elements/element-toggler\",[],[[\"@disabled\",\"@toggleOffValue\",\"@toggleOnValue\",\"@toggleValue\"],[[23,7,[]],false,true,[23,6,[\"_data\",\"hasCustomizations\"]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[4,\"if\",[[23,6,[\"_data\",\"hasCustomizations\"]]],null,{\"statements\":[[4,\"each\",[[23,6,[\"_data\",\"customizations\"]]],null,{\"statements\":[[0,\"      \"],[7,\"table\",true],[10,\"class\",\"table-form\"],[8],[0,\"\\n        \"],[5,\"products/products-editor/customizations-editor/customization-editor\",[],[[\"@tab\",\"@errors\",\"@errorIndex\",\"@isReadonly\",\"@isEditing\",\"@isNew\",\"@model\",\"@autoAddCustomizationOption\",\"@onRemoveCustomization\",\"@customization\"],[[23,3,[]],[22,\"errors\"],[23,2,[]],[23,0,[\"isReadonly\"]],[23,4,[]],[23,5,[]],[23,6,[]],true,[28,\"action\",[[23,0,[]],\"removeCustomization\"],null],[23,1,[]]]]],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"unless\",[[23,0,[\"isReadonly\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-section\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[5,\"elements/element-btn\",[],[[\"@icon\",\"@tooltipLabel\",\"@disabled\",\"@onSubmit\"],[\"fas fa-plus\",\"add new customization\",[23,0,[\"isReadonly\"]],[28,\"action\",[[23,0,[]],\"addCustomization\"],null]]]],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/products-editor/customizations-editor/template.hbs"
    }
  });

  _exports.default = _default;
});