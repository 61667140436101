define("client/pods/grapesjs/plugins/slider-native/components/NavDot", ["exports", "client/pods/grapesjs/plugins/slider-native/utils", "client/pods/grapesjs/plugins/slider-native/constants"], function (_exports, _utils, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = function _default(dc) {
    let config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const defaultType = dc.getType('default');
    const defaultModel = defaultType.model;
    const {
      dotName
    } = _constants.default;
    const classId = dotName;
    const type = dotName;
    const {
      intl
    } = config;
    dc.addType(type, {
      model: defaultModel.extend({
        defaults: { ...defaultModel.prototype.defaults,
          name: intl.t('nav dot'),
          copyable: 0,
          draggable: false,
          selectable: false,
          droppable: false,
          classes: ['lory-dot', 'lory-dot-color']
        },

        init() {
          this.get('classes').pluck('name').indexOf(classId) < 0 && this.addClass(classId);
        }

      }, {
        isComponent(el) {
          if ((0, _utils.elHasClass)(el, classId)) return {
            type
          };
        }

      }),
      view: { ...defaultType.view
      }
    });
  };

  _exports.default = _default;
});