define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-footer-btns/component", ["exports", "client/config/environment", "ramda", "ramda-adjunct", "ramda-extension", "ember-concurrency-decorators"], function (_exports, _environment, R, RA, R_, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    settingsService: Ember.inject.service('settings'),
    dateService: Ember.inject.service('date'),
    server: Ember.inject.service(),
    intl: Ember.inject.service(),
    selectedStatusKey: null,
    selectedTagKeys: null,
    untagSelectedTagKeys: false,

    init() {
      this.set('config', _environment.default);
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
      this.crud.addLists(this, ['dispatch/lists/processes', 'users/lists/menu', 'periods/months', 'periods/years']);

      this._super(...arguments);

      const period = this.dateService.getPeriod();
      const dateStart = this.dateService.formatDateForUrl(`${period.yyyy}-${period.mm}-01`);
      const dateEnd = this.dateService.formatDateForUrl(this.dateService.getMonthEnd(period.yyyy, period.mm));
      this.setNewPeriod({
        dateStart,
        dateEnd
      });
    },

    setNewPeriod(period) {
      const {
        dateStart,
        dateEnd
      } = period;
      this.set('period', period);
      this.set('dateStart', dateStart);
      this.set('dateEnd', dateEnd);
    },

    isDispatchedDispatchProcess: Ember.computed('crud.lists.dispatch-lists-processes.[]', function () {
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      return R.find(R.pathEq(['_data', 'code'], 'dispatched'), dispatchProcesses);
    }),
    emptyCampaignDetailModel: Ember.computed('selectedItems.@each.synced', function () {
      return this.dataManager.setAsRecord({
        adapterName: 'channels/shop/campaigns/details',
        data: {}
      });
    }),
    archiveButtonLabel: Ember.computed('model._data.status', function () {
      const status = this.model.getData('status');

      if (status === 'archived') {
        return 'unarchive';
      }

      return 'archive';
    }),

    *updateChildrenUsingMasterTask(model) {
      const msg = this.intl.t('are you sure you want to copy from master to children');
      const confirmed = window.confirm(msg);

      if (confirmed) {
        if (!this.settingsService.getProp('features.allowMasterChildCampaigns')) {
          return false;
        }

        const isMaster = model?._data?.isMaster;
        let master;

        if (isMaster) {
          master = model?._data?._key;
        } else {
          master = model?._data?.childOfKey;
        }

        if (!master) {
          const errorMsg = this.intl.t('no master campaign found');
          alert(errorMsg);
          return false;
        }

        const response = yield this.server.call('PATCH', `api/protected/channels/shop/campaigns/master/${master}`, {});
        const syncMsg = this.intl.t('campaigns_are_syncing');
        alert(syncMsg);
      }
    },

    *updateArchiveCampaignTask() {
      const model = this.model || {};
      const status = model.getData('status');
      let msg = this.intl.t('are you sure you want to archive');

      if (status === 'archived') {
        msg = this.intl.t('are you sure you want to unarchive');
      }

      const confirmed = window.confirm(msg);

      if (confirmed) {
        const dirty = this.crud.setupDirty({
          adapterName: 'channels/shop/campaigns',
          model
        });

        if (status === 'archived') {
          dirty.setData('status', 'inactive');
        } else {
          dirty.setData('status', 'archived');
        }

        yield this.crud.updateRecordTask.perform({
          adapterName: 'channels/shop/campaigns',
          model: dirty,
          hasUpdateProps: true
        });
      }
    },

    reCacheAllAndReloadCampaignDetailsTask(_ref) {
      var _this = this;

      let {
        model,
        onAfter
      } = _ref;
      return function* () {
        const campaignKey = model.getData('_key');
        const data = {
          campaignKey,
          details: [],
          refetchActivityFromDb: true
        }; // call the refetchActivityFromDb function

        const activity = yield _this.server.call('GET', 'api/protected/channels/shop/campaigns/details/refetchActivity', data);

        if (onAfter) {
          onAfter();
        }
      }();
    },

    *onDuplicateRecordTask(onCloseDialogAction, dirty) {
      const saved = yield this.duplicateRecordTask.perform({
        adapterName: 'channels/shop/campaigns',
        model: dirty
      });
      const duplicateKey = saved?._data?._key;

      if (duplicateKey) {
        const tabOptions = {
          component: 'channels/shop/campaigns/tab-container',
          title: 'campaigns',
          loadInstructions: {
            find: {
              id: duplicateKey
            }
          }
        };
        this.tabsManager.openTab('left', tabOptions);
        onCloseDialogAction();
      }
    },

    *batchUpdateProductStatusSchedulesTask() {
      const campaignModel = this.model;
      const campaignDetailModel = this.emptyCampaignDetailModel || {};
      const productSchedulesModel = campaignDetailModel.getData('productSchedules') || [];
      const productSchedules = R.pipe(R.map(schedule => {
        return R.propOr({}, '_data')(schedule);
      }), R.reject(RA.isNilOrEmpty))(productSchedulesModel);
      let selectedItems = this.selectedItems || [];

      if (RA.isNotNilOrEmpty(selectedItems)) {
        selectedItems = R.map(item => {
          return item.serialize();
        })(selectedItems);
      }

      const saved = yield this.server.call('PATCH', `api/protected/channels/shop/campaigns/details/master/${campaignModel.getData('_key')}/product-schedules`, {
        hasProductSchedules: campaignDetailModel.getData('hasProductSchedules') || false,
        productSchedules,
        batch: selectedItems
      });

      if (saved.success) {
        this.resetSelectedStatusKeyAndSelectedItems();
      }
    },

    resetSelectedStatusKey() {
      this.set('selectedStatusKey', null);
    },

    resetSelectedStatusKeyAndSelectedItems() {
      this.set('selectedStatusKey', null);
      this.onResetSelectedItems();
    },

    resetSelectedTagKeys() {
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
    },

    resetSelectedTagKeysAndSelectedItems() {
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
      this.onResetSelectedItems();
    },

    resetSelectedTagKeysAndStatusKeyAndSelectedItems() {
      this.set('selectedStatusKey', null);
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
      this.onResetSelectedItems();
    }

  }, (_applyDecoratedDescriptor(_obj, "updateChildrenUsingMasterTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_obj, "updateChildrenUsingMasterTask"), _obj), _applyDecoratedDescriptor(_obj, "updateArchiveCampaignTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_obj, "updateArchiveCampaignTask"), _obj), _applyDecoratedDescriptor(_obj, "reCacheAllAndReloadCampaignDetailsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "reCacheAllAndReloadCampaignDetailsTask"), _obj), _applyDecoratedDescriptor(_obj, "onDuplicateRecordTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "onDuplicateRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "batchUpdateProductStatusSchedulesTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_obj, "batchUpdateProductStatusSchedulesTask"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedStatusKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "resetSelectedStatusKey"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedStatusKeyAndSelectedItems", [_dec2], Object.getOwnPropertyDescriptor(_obj, "resetSelectedStatusKeyAndSelectedItems"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedTagKeys", [_dec3], Object.getOwnPropertyDescriptor(_obj, "resetSelectedTagKeys"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedTagKeysAndSelectedItems", [_dec4], Object.getOwnPropertyDescriptor(_obj, "resetSelectedTagKeysAndSelectedItems"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedTagKeysAndStatusKeyAndSelectedItems", [_dec5], Object.getOwnPropertyDescriptor(_obj, "resetSelectedTagKeysAndStatusKeyAndSelectedItems"), _obj)), _obj)));

  _exports.default = _default;
});