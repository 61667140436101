define("client/pods/flows/form-steps/model", ["exports", "ramda", "client/pods/base/model", "client/utils/nventor"], function (_exports, R, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        steps: [],
        index: 0,
        nextButtonLabel: 'next page',
        previousButtonLabel: 'previous page'
      };
    },

    populate(data, attrs) {
      data.steps = this._transformSteps(data.steps || []);

      this._super(data, attrs);
    },

    _transformSteps(steps) {
      return steps.map(stepData => {
        return this.dataManager.setAsRecord({
          adapterName: 'flows/steps',
          data: stepData
        });
      });
    }

  });

  _exports.default = _default;
});