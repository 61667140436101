define("client/pods/grapesjs/plugins/button", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = editor => {
    const BIGORDR_BUTTON = 'bigordr-button';
    const options = R.pathOr({}, ['Config', 'pluginsOpts', BIGORDR_BUTTON])(editor);
    const {
      intl = {
        t(txt) {
          return txt;
        }

      },

      /** @type {Categories} */
      categories,
      onDbClick,
      onDrop,
      isReadonly
    } = options;
    const {
      DomComponents,
      BlockManager
    } = editor;
    DomComponents.addType('button', {
      model: {
        defaults: {
          tagName: 'a',
          stylable: false,
          classes: ['button'],
          draggable: '.bigordr-cell, [data-gjs-type="text-block"]',
          traits: []
        },

        init() {
          const icon = this.get('icon');

          if (icon) {
            this.setIcon(icon);
          }
        },

        setIcon(icon) {
          const classes = this.getClasses();
          classes.insertAt(0, 'button');
          this.setClass(classes);
          this.set('content', '');
          this.components({
            tagName: 'span',
            classes: ['icon'],
            components: [{
              tagName: 'i',
              classes: icon
            }]
          });
        }

      }
    });
    DomComponents.addType(BIGORDR_BUTTON, {
      extend: 'button',
      model: {
        defaults: {
          content: 'Button',
          name: intl.t('button'),
          classes: ['button'],
          attributes: {
            'data-gjs-button-theme': 'button',
            target: '_blank',
            rel: 'noreferrer noopener'
          },
          traits: [],
          toolbar: [{
            attributes: {
              class: 'fas fa-pencil-alt'
            },

            command(editor) {
              const el = editor.getSelected().getEl();

              if (onDbClick) {
                onDbClick(el);
              }
            }

          }, {
            attributes: {
              class: 'fas fa-level-up-alt'
            },
            command: 'select-parent'
          }, {
            attributes: {
              class: 'fas fa-arrows-alt'
            },
            command: 'tlb-move'
          }, {
            attributes: {
              class: 'far fa-clone'
            },
            command: 'tlb-clone'
          }, {
            attributes: {
              class: 'fas fa-trash'
            },
            command: 'tlb-delete'
          }] // As by default, traits are binded to attributes, so to define
          // their initial value we can use attributes

        },

        init() {
          this.on('change:attributes', this.handleTypeChange);
        },

        handleTypeChange(model) {
          let attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
          const {
            content,
            href = ''
          } = attributes;

          if (content) {
            this.set('content', content);
          }

          if (href && !R.includes('https://')(href)) {
            const validHref = R.pipe(R.unless(R.includes('https://'), R.pipe(R.split('//'), R.takeLast(1), R.prepend('https://'), R.join(''))))(href);
            const previousAttributes = model.getAttributes();
            const updatedAttributes = R.mergeRight(previousAttributes, {
              href: validHref
            });
            model.setAttributes(updatedAttributes);
          }
        }

      },
      view: {
        events: {
          dblclick: 'onDbClick'
        },

        onDbClick() {
          if (onDbClick && !isReadonly) {
            onDbClick(this.model);
          }
        },

        // On init you can create listeners, like in the model, or start some other
        // function at the beginning
        init(_ref) {
          let {
            model
          } = _ref;
          const attributes = model.getAttributes();
          attributes.content = model.get('content');
          model.setAttributes(attributes);
        }

      }
    });
    BlockManager.add('bigordr-button-block', {
      label: intl.t('button'),
      category: categories.basic,
      content: {
        type: BIGORDR_BUTTON
      },
      render: _ref2 => {
        let {
          model
        } = _ref2;
        return `
    <div class="gjs-block__media">
      <svg width='100%' height='100%' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:square;stroke-miterlimit:1.5;'>
        <g transform='matrix(1,0,0,1.20897,0,-211.8)'>
            <path d='M853.168,517.228C853.168,483.819 820.375,456.694 779.984,456.694L244.016,456.694C203.625,456.694 170.832,483.819 170.832,517.228L170.832,638.296C170.832,671.706 203.625,698.83 244.016,698.83L779.984,698.83C820.375,698.83 853.168,671.706 853.168,638.296L853.168,517.228Z' style='fill:white;stroke:black;stroke-width:31.55px;'/>
        </g>
        <g transform='matrix(1,0,0,1,42.6175,-287.434)'>
            <g transform='matrix(160,0,0,160,220.906,830.15)'>
                <path d='M0.353,-0.544C0.423,-0.544 0.477,-0.519 0.517,-0.468C0.556,-0.418 0.576,-0.353 0.576,-0.273C0.576,-0.19 0.557,-0.122 0.518,-0.067C0.479,-0.013 0.424,0.014 0.354,0.014C0.31,0.014 0.275,0.005 0.248,-0.012C0.232,-0.023 0.215,-0.041 0.196,-0.067L0.196,0L0.06,0L0.06,-0.719L0.199,-0.719L0.199,-0.463C0.216,-0.488 0.236,-0.507 0.257,-0.52C0.282,-0.536 0.314,-0.544 0.353,-0.544ZM0.317,-0.101C0.353,-0.101 0.381,-0.115 0.4,-0.144C0.42,-0.173 0.43,-0.211 0.43,-0.258C0.43,-0.296 0.425,-0.327 0.416,-0.352C0.397,-0.399 0.363,-0.422 0.313,-0.422C0.263,-0.422 0.228,-0.399 0.209,-0.354C0.199,-0.329 0.194,-0.298 0.194,-0.259C0.194,-0.213 0.204,-0.175 0.225,-0.146C0.245,-0.116 0.276,-0.101 0.317,-0.101Z' style='fill-rule:nonzero;'/>
            </g>
            <g transform='matrix(160,0,0,160,318.64,830.15)'>
                <path d='M0.204,-0.532L0.204,-0.211C0.204,-0.181 0.207,-0.158 0.214,-0.143C0.227,-0.116 0.252,-0.103 0.289,-0.103C0.337,-0.103 0.369,-0.122 0.387,-0.16C0.396,-0.181 0.4,-0.208 0.4,-0.243L0.4,-0.532L0.542,-0.532L0.542,0L0.406,0L0.406,-0.075C0.405,-0.074 0.402,-0.069 0.396,-0.061C0.391,-0.052 0.385,-0.045 0.378,-0.039C0.356,-0.02 0.335,-0.006 0.315,0.001C0.294,0.008 0.271,0.012 0.243,0.012C0.164,0.012 0.111,-0.017 0.084,-0.073C0.069,-0.104 0.061,-0.151 0.061,-0.211L0.061,-0.532L0.204,-0.532Z' style='fill-rule:nonzero;'/>
            </g>
            <g transform='matrix(160,0,0,160,416.375,830.15)'>
                <path d='M0.01,-0.428L0.01,-0.527L0.084,-0.527L0.084,-0.676L0.222,-0.676L0.222,-0.527L0.309,-0.527L0.309,-0.428L0.222,-0.428L0.222,-0.147C0.222,-0.125 0.225,-0.112 0.23,-0.106C0.236,-0.101 0.253,-0.098 0.281,-0.098C0.285,-0.098 0.29,-0.098 0.295,-0.098C0.299,-0.099 0.304,-0.099 0.309,-0.099L0.309,0.005L0.243,0.007C0.177,0.01 0.132,-0.002 0.108,-0.027C0.092,-0.043 0.084,-0.067 0.084,-0.101L0.084,-0.428L0.01,-0.428Z' style='fill-rule:nonzero;'/>
            </g>
            <g transform='matrix(160,0,0,160,469.656,830.15)'>
                <path d='M0.01,-0.428L0.01,-0.527L0.084,-0.527L0.084,-0.676L0.222,-0.676L0.222,-0.527L0.309,-0.527L0.309,-0.428L0.222,-0.428L0.222,-0.147C0.222,-0.125 0.225,-0.112 0.23,-0.106C0.236,-0.101 0.253,-0.098 0.281,-0.098C0.285,-0.098 0.29,-0.098 0.295,-0.098C0.299,-0.099 0.304,-0.099 0.309,-0.099L0.309,0.005L0.243,0.007C0.177,0.01 0.132,-0.002 0.108,-0.027C0.092,-0.043 0.084,-0.067 0.084,-0.101L0.084,-0.428L0.01,-0.428Z' style='fill-rule:nonzero;'/>
            </g>
            <g transform='matrix(160,0,0,160,522.937,830.15)'>
                <path d='M0.304,-0.098C0.344,-0.098 0.376,-0.113 0.397,-0.142C0.419,-0.171 0.43,-0.212 0.43,-0.265C0.43,-0.319 0.419,-0.36 0.397,-0.388C0.376,-0.417 0.344,-0.432 0.304,-0.432C0.263,-0.432 0.232,-0.417 0.21,-0.388C0.188,-0.36 0.177,-0.319 0.177,-0.265C0.177,-0.212 0.188,-0.171 0.21,-0.142C0.232,-0.113 0.263,-0.098 0.304,-0.098ZM0.576,-0.265C0.576,-0.187 0.554,-0.12 0.509,-0.065C0.464,-0.009 0.396,0.019 0.304,0.019C0.213,0.019 0.145,-0.009 0.1,-0.065C0.055,-0.12 0.032,-0.187 0.032,-0.265C0.032,-0.342 0.055,-0.409 0.1,-0.465C0.145,-0.521 0.213,-0.549 0.304,-0.549C0.396,-0.549 0.464,-0.521 0.509,-0.465C0.554,-0.409 0.576,-0.342 0.576,-0.265Z' style='fill-rule:nonzero;'/>
            </g>
            <g transform='matrix(160,0,0,160,620.672,830.15)'>
                <path d='M0.36,-0.545C0.415,-0.545 0.46,-0.531 0.495,-0.502C0.53,-0.473 0.547,-0.425 0.547,-0.358L0.547,0L0.405,0L0.405,-0.324C0.405,-0.352 0.401,-0.373 0.394,-0.388C0.38,-0.416 0.354,-0.429 0.315,-0.429C0.268,-0.429 0.236,-0.409 0.218,-0.369C0.209,-0.348 0.205,-0.321 0.205,-0.288L0.205,0L0.066,0L0.066,-0.531L0.2,-0.531L0.2,-0.454C0.218,-0.481 0.235,-0.501 0.251,-0.513C0.28,-0.534 0.316,-0.545 0.36,-0.545Z' style='fill-rule:nonzero;'/>
            </g>
        </g>
    </svg>
    <div class="block-label">${model.get('label')}</div>
    </div>
  `;
      }
    });
    editor.on('block:drag:stop', component => {
      if (component && component.is(BIGORDR_BUTTON)) {
        editor.select(component);

        if (onDrop) {
          onDrop(component.getEl());
        }
      }
    });
  };

  _exports.default = _default;
});