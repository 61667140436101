define("client/pods/components/channels/shop/items/item-price/item-price-old/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6slDEXsC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[\"store-item__price \",[28,\"if\",[[24,[\"hasStrikeThrough\"]],\"store-item__price--old\"],null]]]],[8],[1,[28,\"currency\",[[24,[\"oldPrice\"]]],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/items/item-price/item-price-old/template.hbs"
    }
  });

  _exports.default = _default;
});