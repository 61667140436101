define("client/pods/components/wizards/wizard-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PXuPLuug",
    "block": "{\"symbols\":[\"&default\",\"@tab\",\"@isWizard\"],\"statements\":[[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"\\n  \"],[14,1,[[23,0,[]]]],[0,\"\\n\\n  \"],[5,\"wizards/wizard-btns\",[],[[\"@isWizard\",\"@tab\",\"@wizardContainerComponent\"],[true,[23,2,[]],[23,0,[]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[14,1,[[23,0,[]]]],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/wizards/wizard-container/template.hbs"
    }
  });

  _exports.default = _default;
});