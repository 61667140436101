define("client/pods/components/contacts/panels-display/sub-panels-activity/component", ["exports", "ramda", "client/mixins/date", "client/mixins/crud", "client/mixins/doc-status"], function (_exports, R, _date, _crud, _docStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _date.default, _docStatus.default, {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const docType = this.get('tab.docType');
      this.set('docType', docType);
      this.crud.addLists(this, ['dispatch/lists/processes', 'documents/lists/types/sales', 'documents/lists/types/purchases']);
    },

    docTypesList: Ember.computed('docType', 'crud.lists.{documents-lists-types-sales.[],documents-lists-types-purchases.[]}', 'model._data.relationships.[]', function () {
      const docType = this.docType;
      const docTypesList = this.crud.lists[`documents-lists-types-${docType}`] || [];
      const relationships = this?.model?._data?.relationships || [];
      return R.pipe(R.map(resourceData => {
        const docType = resourceData.getData('docType');
        const docName = resourceData.getData('docName');

        if (R.equals(R.length(relationships), 1)) {
          const relationship = R.head(relationships);
          const allowedDocName = ['invoices', 'returns'];

          if (R.equals(relationship, 'customer') && R.equals(docType, 'purchases') && !R.includes(docName, allowedDocName)) {
            return false;
          }

          if (R.equals(relationship, 'supplier') && R.equals(docType, 'sales') && !R.includes(docName, allowedDocName)) {
            return false;
          }
        }

        resourceData.setData('tabType', 'replaceSubTab');
        resourceData.setData('id', `${docType}-${docName}`);
        resourceData.setData('component', 'contacts/panels-display/sub-panels-activity/sub-panels-activity-doc');
        return resourceData;
      }), R.reject(R.equals(false)))(docTypesList);
    })
  });

  _exports.default = _default;
});