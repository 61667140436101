define("client/pods/components/updates/update-notify-btn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    updates: Ember.inject.service(),

    init() {
      this._super(...arguments);

      const version = this.updates.get('version');
      this.set('version', version);
    },

    actions: {
      broadcastNewVersion(onCloseDialogAction) {
        const version = this.version;
        this.socket.send('broadcastNewVersion', {
          version,
          host: window.location.hostname
        });
        onCloseDialogAction();
      }

    }
  });

  _exports.default = _default;
});