define("client/pods/contacts/persons/model", ["exports", "client/pods/base/model", "client/mixins/contacts-is-parent", "client/mixins/contacts-details", "client/utils/nventor", "ramda", "ramda-adjunct"], function (_exports, _model, _contactsIsParent, _contactsDetails, _nventor, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_contactsIsParent.default, _contactsDetails.default, {
    defaults() {
      return {
        personId: _nventor.default.random.alphaNum(8)
      };
    },

    populate(data, attrs) {
      this._super(...arguments);
    },

    setTransporterKeyToDetailsModel() {
      const details = this?._data?.details || [];
      const isPickupLocation = this?._data?.type === 'pickupLocation';

      if (!isPickupLocation) {
        return details;
      }

      const updatedDetails = details.map(detailModel => {
        const hasTransporterKey = R.pipe(R.prop('transporterKey'), RA.isNotNilOrEmpty)(detailModel);

        if (hasTransporterKey) {
          return detailModel;
        }

        const transporterKeyHash = {
          address: 'transporterSenderAddress',
          telephone: 'transporterSenderTelephone',
          transporterPackageTemperature: 'transporterAccount'
        };
        const subType = detailModel?._data?.subType;
        const transporterKey = transporterKeyHash[subType];

        if (transporterKey) {
          detailModel.setData('transporterKey', transporterKey);
        }

        return detailModel;
      });
      this.setData('details', updatedDetails);
    },

    componentPath: 'contacts/persons/editor-person',
    component: Ember.computed('componentPath', function () {
      return this.componentPath;
    }),
    label: Ember.computed('_data.{name,label}', function () {
      const label = this?._data?.label;

      if (label) {
        return `${label} (${this?._data?.name})`;
      }

      return this?._data?.name;
    }),
    defaultForDocs: Ember.computed('_data.{isDefault,isDefaultFor,isDefaultForDocs.[]}', function () {
      if (this.get('_data.isDefault')) {
        if (this.get('_data.isDefaultFor') === 'all') {
          return ['all'];
        }

        return this.get('_data.isDefaultForDocs') || [];
      }

      return [];
    }),

    /**
     * Computes the convenience store data based on the details data.
     * READ ONLY
     *
     * @returns {Object} The computed convenience store data.
     */
    convenienceStoreData: Ember.computed('_data.details.[]', function () {
      const data = R.pipe(R.pathOr([], ['_data', 'details']), R.map(detailModel => {
        const sevenEleven = ['storeId', 'storeName', 'storeAddress'];
        const subType = detailModel?._data?.subType;
        const isSevenElevenDetail = sevenEleven.includes(subType);

        if (isSevenElevenDetail) {
          return {
            [subType]: detailModel._data.value
          };
        }
      }), R.reject(RA.isNilOrEmpty))(this);
      return Object.assign({}, ...data);
    }),

    tCatPersonDetails() {
      return [{
        customLabel: 'sender telephone',
        subType: 'telephone',
        value: '',
        transporterKey: 'transporterSenderTelephone'
      }, {
        customLabel: 'sender address',
        subType: 'address',
        value: '',
        transporterKey: 'transporterSenderAddress'
      }, {
        customLabel: 'preferred delivery time',
        icon: 'fas fa-clock',
        subType: 'deliveryTime',
        value: 'anytime',
        translate: true,
        transporterKey: 'transporterDeliveryTime'
      }, {
        customLabel: 'transporter account',
        icon: 'fas fa-thermometer-half',
        subType: 'transporterAccount',
        value: 'unrefrigerated',
        translate: true,
        transporterKey: 'transporterAccount'
      }, {
        customLabel: 'package size',
        icon: 'fas fa-ruler-combined',
        subType: 'packageSize',
        value: '60cm',
        transporterKey: 'transporterPackageSize'
      }, {
        customLabel: 'product type',
        icon: 'fas fa-cube',
        subType: 'packageType',
        value: 'specialty and local good',
        translate: true,
        transporterKey: 'transporterPackageType'
      }];
    },

    hctPersonDetails() {
      return [{
        customLabel: 'sender telephone',
        subType: 'telephone',
        value: '',
        transporterKey: 'transporterSenderTelephone'
      }, {
        customLabel: 'sender address',
        subType: 'address',
        value: '',
        transporterKey: 'transporterSenderAddress'
      }, {
        customLabel: 'transporter account',
        icon: 'fas fa-user-circle',
        subType: 'transporterAccount',
        value: '',
        translate: false,
        transporterKey: 'transporterAccount'
      }];
    },

    sevenElevenPersonDetails() {
      return [{
        customLabel: 'sender telephone',
        subType: 'telephone',
        value: '',
        transporterKey: 'transporterSenderTelephone'
      }, {
        customLabel: 'return storeId',
        icon: 'fas fa-user-circle',
        subType: 'storeId',
        value: ''
      }, {
        customLabel: 'return storeName',
        icon: 'fas fa-user-circle',
        subType: 'storeName',
        value: ''
      }, {
        customLabel: 'return storeAddress',
        icon: 'fas fa-user-circle',
        subType: 'storeAddress',
        value: ''
      }, {
        // customLabel: 'transporter package temperature',
        icon: 'fas fa-thermometer-half',
        subType: 'transporterAccount',
        value: 'unrefrigerated',
        translate: true,
        transporterKey: 'transporterAccount'
      }];
    },

    payuniSevenElevenPersonDetails() {
      return [{
        customLabel: 'sender telephone',
        subType: 'telephone',
        value: '',
        transporterKey: 'transporterSenderTelephone'
      }, {
        customLabel: 'transporter type',
        icon: 'fa-solid fa-people-carry-box',
        subType: 'transporterType',
        value: 'B2C',
        translate: false,
        transporterKey: 'transporterType'
      }, {
        customLabel: 'transporter account',
        icon: 'fas fa-thermometer-half',
        subType: 'transporterAccount',
        value: 'unrefrigerated',
        translate: true,
        transporterKey: 'transporterAccount'
      }];
    },

    /**
     *
     * @param {Object} options
     * @param {string} options.transporterExtension
     * @param {string} options.transporterSenderTelephone
     */
    setupPerson(_ref) {
      let {
        transporterExtension,
        transporterSenderTelephone = ''
      } = _ref;
      const personDefaultDetailsByTransporterAccounts = {
        [this.constants.transporterExtensions.hct]: this.hctPersonDetails(),
        [this.constants.transporterExtensions.tCat]: this.tCatPersonDetails(),
        [this.constants.transporterExtensions.sevenEleven]: this.sevenElevenPersonDetails(),
        [this.constants.transporterExtensions.payuniSevenEleven]: this.payuniSevenElevenPersonDetails()
      };
      R.pipe(R.pathOr([], [transporterExtension]), R.forEach(detailData => {
        const detailDataDefault = R.mergeRight({
          type: 'pickupLocation',
          isDefault: true,
          translate: false
        }, detailData);

        if (transporterSenderTelephone && detailDataDefault.transporterKey === 'transporterSenderTelephone') {
          detailDataDefault.value = transporterSenderTelephone;
        }

        this.addDetail(detailDataDefault);
      }))(personDefaultDetailsByTransporterAccounts);
    },

    clearConvenienceStoreData() {
      const details = R.pipe(R.pathOr([], ['_data', 'details']), R.map(detailModel => {
        const sevenEleven = ['storeId', 'storeName', 'storeAddress'];
        const subType = detailModel?._data?.subType;
        const isSevenElevenDetail = sevenEleven.includes(subType);

        if (isSevenElevenDetail) {
          return R.set(R.lensPath(['_data', 'value']), '')(detailModel);
        }

        return detailModel;
      }), R.reject(RA.isNilOrEmpty))(this);
      this.set('_data.details', details);
    },

    getTransporterData() {
      const transporterSenderName = this._data.name;
      const transporterData = R.pipe(R.pathOr([], ['_data', 'details']), R.filter(R.hasPath(['_data', 'transporterKey'])), R.reduce((obj, model) => {
        const transporterKey = R.pathOr('', ['_data', 'transporterKey'])(model);
        obj[transporterKey] = R.pathOr('', ['_data', 'value'])(model);
        return obj;
      }, {}))(this);
      return R.mergeRight({
        transporterSenderName
      }, transporterData);
    }

  });

  _exports.default = _default;
});