define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-faqs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7PScRqih",
    "block": "{\"symbols\":[\"@headerBottom\",\"@isDisabled\",\"@isReadonly\",\"@lists\",\"@model\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\"],\"statements\":[[5,\"channels/shop/campaigns/campaigns-editor/faqs-editor\",[],[[\"@headerBottom\",\"@isDisabled\",\"@isReadonly\",\"@lists\",\"@model\",\"@parentTab\",\"@resourceList\",\"@tab\",\"@tabContentOnly\"],[[23,1,[]],[23,2,[]],[23,3,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/panels-display/sub-panels-faqs/template.hbs"
    }
  });

  _exports.default = _default;
});