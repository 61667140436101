define("client/pods/components/home/wizard-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let HomeWizardContainerComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('users'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class HomeWizardContainerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "search", _descriptor2, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);

      _initializerDefineProperty(this, "usersService", _descriptor4, this);

      _initializerDefineProperty(this, "wizard", _descriptor5, this);

      _initializerDefineProperty(this, "userMenu", _descriptor6, this);

      _initializerDefineProperty(this, "isToggleOn", _descriptor7, this);

      _initializerDefineProperty(this, "showType", _descriptor8, this);

      _defineProperty(this, "pinType", 'wizard');

      this.crud.addLists(this, ['application/lists/wizards']);
    }

    afterLoadLists() {
      this.setupTask.perform();
    }

    get wizardsList() {
      const allowed = this.crud.lists['application-lists-wizards'] || [];
      return allowed;
    }

    get pinList() {
      const showType = this.showType;

      if (showType === 'isFinished') {
        return this.finishedItems;
      }

      if (showType === 'unfinished') {
        return this.unfinishedItems;
      }

      return this.userColletion;
    }

    get userColletion() {
      return R.pathOr([], ['userMenu', '_data', 'collection'])(this);
    }

    get isOn() {
      if (this.isToggleOn) {
        return true;
      }

      if (this.unFinishedItemsCount > 0) {
        return true;
      }

      return false;
    }

    get isOnClassName() {
      if (this.isOn) {
        return 'on';
      }

      return 'off';
    }

    get unfinishedItemsCount() {
      return this.unfinishedItems.length;
    }

    get finishedItems() {
      const userColletion = this.userColletion;
      return userColletion.filter(uc => R.pathEq(['_data', 'isFinished'], true)(uc));
    }

    get unfinishedItems() {
      const userColletion = this.userColletion;
      return userColletion.filter(uc => R.pathEq(['_data', 'isFinished'], false)(uc));
    }

    toggle() {
      this.isToggleOn = !this.isToggleOn;
    }

    setShowType(showType) {
      this.showType = showType;
      this.isToggleOn = true;
    }

    *setupTask() {
      yield this.getCustomizedUserMenuTask.perform();
    }

    *getCustomizedUserMenuTask() {
      const userKey = this.usersService.getUserKey();
      const menuId = 'application/lists/wizards';
      const userMenuFilters = this.search.setupFilters({
        adapterName: 'members-menu/filters',
        defaultFiltersData: {
          sort: [{
            by: 'index',
            direction: 'ASC'
          }],
          userKey,
          menuId
        }
      });
      const userMenu = yield this.crud.findTask.perform({
        adapterName: 'wizards',
        filters: userMenuFilters
      });
      this.userMenu = userMenu;
      const allowedMenu = this.wizardsList || [];
      const userCollection = R.pathOr([], ['_data', 'collection'])(userMenu);
      yield R.forEach(menu => {
        const foundCollection = R.find(R.pathEq(['_data', 'target'], menu._data.target))(userCollection);

        if (foundCollection) {
          return;
        }

        menu.setData('userKey', userKey);
        menu.setData('menuId', menuId);
        const hideForOldUsers = menu.getData('hideForOldUsers');
        menu.setData('isFinished', !!hideForOldUsers);
        return this.crud.createRecordTask.perform({
          adapterName: 'members-menu',
          model: menu
        });
      })(allowedMenu);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "usersService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "wizard", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "userMenu", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isToggleOn", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showType", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setShowType", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setShowType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCustomizedUserMenuTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "getCustomizedUserMenuTask"), _class.prototype)), _class));
  _exports.default = HomeWizardContainerComponent;
});