define("client/helpers/tab-copy", ["exports", "ramda", "ramda-extension"], function (_exports, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    tabsManager: Ember.inject.service(),

    compute(params
    /* , hash */
    ) {
      const tab = params[0] || [];
      return this.tabsManager.copyTab({
        tab
      });
    }

  });

  _exports.default = _default;
});