define("client/pods/components/transporters/waybill-display/list/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TransportersWaybillDisplayListComponent extends _component.default {
    get transporterWaybillRecords() {
      const shippingWaybill = this.args.shippingWaybill;
      const hasShippingWaybills = RA.isNotNilOrEmpty(shippingWaybill);

      if (hasShippingWaybills) {
        return RA.ensureArray(shippingWaybill);
      }

      return this.args.model?._data?.transporterWaybillRecords || [];
    }

    get isPickingList() {
      let tab = R.pathOr('', ['args', 'tab', 'value'])(this);
      return R.includes('pickingList')(tab);
    }

  }

  _exports.default = TransportersWaybillDisplayListComponent;
});