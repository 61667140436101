define("client/pods/components/ledgers/accounts/aging/panels-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d6Z+kya/",
    "block": "{\"symbols\":[\"@tab\",\"@currentPeriod\",\"@paymentDueDateZ\",\"@onReloadStatementOnDueDateChangeTask\",\"@onRunningTask\",\"@filters\",\"@docType\"],\"statements\":[[0,\"\\n\"],[5,\"forms/form-section\",[],[[\"@title\"],[[23,1,[\"label\"]]]],{\"statements\":[[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"row-container u-header-1\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"tt\",[[23,0,[\"monthName\"]]],null],false],[0,\" \"],[1,[23,2,[\"yyyy\"]],false],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[5,\"forms/form-input-row\",[],[[\"@label\"],[\"payment due date\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"elements/date-input\",[],[[\"@target\",\"@model\",\"@date\",\"@onSelectDate\",\"@isRunning\"],[\"paymentDueDateZ\",[23,0,[]],[23,3,[]],[28,\"perform\",[[23,4,[]]],null],[23,5,[]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,5,[]]]],{\"statements\":[[0,\"\\n\\t\\t\"],[5,\"layouts/table-view\",[],[[\"@tableClassNames\",\"@tab\",\"@detailsRowComponent\",\"@filters\",\"@results\",\"@onReloadSearchRecordsTask\",\"@onPaginationTask\",\"@paginationIsFooter\",\"@tableViewOptions\"],[\"even-rows-bg\",[23,1,[]],\"ledgers/accounts/aging/panels-display/table-view/detail-row\",[23,6,[]],[23,6,[\"results\"]],[23,0,[\"crud\",\"reloadSearchRecordsTask\"]],[23,0,[\"crud\",\"paginateSearchRecordsTask\"]],false,[28,\"hash\",null,[[\"docType\",\"currentPeriod\",\"paymentDueDateZ\"],[[23,7,[]],[23,0,[\"currentPeriod\"]],[24,[\"paymentDueDateZ\"]]]]]]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/ledgers/accounts/aging/panels-display/template.hbs"
    }
  });

  _exports.default = _default;
});