define("client/pods/components/dispatch/panels-display/list-summary/summary-item/summary-item-derivation/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DispatchPanelsDisplayListSummarySummaryItemSummaryItemDerivation = (_dec = Ember.inject.service('settings'), _dec2 = Ember.inject.service('big'), (_class = class DispatchPanelsDisplayListSummarySummaryItemSummaryItemDerivation extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "tagName", '');

      _defineProperty(this, "selectedProcesses", null);

      _initializerDefineProperty(this, "settingsService", _descriptor, this);

      _initializerDefineProperty(this, "bigService", _descriptor2, this);
    }

    get totalQty() {
      const derivation = this.args.derivation;
      const allowedDispatchProcessesCodes = this.args.allowedDispatchProcessesCodes || [];
      const qtys = R.pipe(R.propOr({}, 'dispatchProcess'), R.mapObjIndexed((qty, dispatchProcessCode) => {
        if (R.includes(dispatchProcessCode, allowedDispatchProcessesCodes)) {
          return qty;
        }

        return 0;
      }), R.values)(derivation);
      const sumBig = this.bigService.sumArray(qtys);
      const roundingInventoryQty = this.settingsService?.settingsModel?._data?.roundingInventoryQty;
      return sumBig.toFixed(roundingInventoryQty);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bigService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DispatchPanelsDisplayListSummarySummaryItemSummaryItemDerivation;
});