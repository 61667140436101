define("client/pods/components/transporters/seven-eleven-waybill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vfiYm4Fp",
    "block": "{\"symbols\":[\"@errors\",\"@isReadonly\",\"@tab\",\"@onSetWaybillsPerPage\",\"@results\",\"@waybillsHtml\"],\"statements\":[[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"waybills per page\",[23,1,[]],\"sevenEleven.waybillsPerPage\",\"sevenElevenPayOnCheckoutShopId\"]],{\"statements\":[[0,\"\\n  \"],[5,\"lists/list-dropdown\",[],[[\"@disabled\",\"@list\",\"@isBtn\",\"@itemLabelKey\",\"@itemValueKey\",\"@readonly\",\"@tab\",\"@translate\",\"@value\",\"@isRunning\",\"@onSelect\"],[[23,2,[]],[23,0,[\"crud\",\"lists\",\"extensions-apps-tw-seven-eleven-lists-waybill-print-per-page\"]],true,\"_data.label\",\"_data.value\",[23,2,[]],[23,3,[]],false,[23,0,[\"waybillsPerPage\"]],[23,0,[\"fetchTransporter\",\"isRunning\"]],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"div\",false],[12,\"class\",\"notification-item\"],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"fetchTransporter\"]],[23,5,[]]],null]]],[8],[0,\"\\n  \"],[5,\"elements/element-label\",[],[[\"@icon\",\"@label\"],[\"fas fa-info-circle\",\"printing mixing waybills will cause the waybills to be group by payment type\"]]],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"transporters/iframe-waybills\",[],[[\"@waybillsHtml\"],[[23,6,[]]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/seven-eleven-waybill/template.hbs"
    }
  });

  _exports.default = _default;
});