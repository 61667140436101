define("client/pods/components/settings/settings-editor/component", ["exports", "ramda-extension", "client/config/environment", "client/utils/nventor", "client/mixins/transporter"], function (_exports, R_, _environment, _nventor, _transporter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_transporter.default, (_dec = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    transporter: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    translate: true,
    hctModel: false,
    headerBottom: null,

    didInsertElement() {
      this.crud.addLists(this, ['channels/shop/campaigns/statuses', 'channels/shop/campaigns/lists/productsOverview', 'channels/shop/campaigns/lists/productsImagesRatio', 'channels/shop/campaigns/lists/productsImagesSize', 'channels/shop/campaigns/lists/productsNameFontSize', 'channels/shop/campaigns/lists/productsNameLineCount', 'channels/shop/campaigns/lists/variantSelectorStyle', 'channels/shop/campaigns/lists/cartBtnStyle', 'discounts/types', 'facebook/pages', 'facebook/pixels', 'google/tracking', 'paymentMethods', 'paymentMethods/shop', 'paymentTerms', 'products/lists/tags', 'products/lists/types', 'products/lists/tracking', 'products/lists/onSaleTagDisplayPosition', 'products/lists/onSaleTagDisplayStyle', 'settings/editorSubPanels', 'settings/rounding', 'settings/imagesHoverFilter', 'settings/imagesHoverTransform', 'shipping/feeType', 'taxes', 'taxMethods', 'taxMethods/filters', 'toggles/onOff', 'units/dimensions', 'units/weight', {
        name: 'workflows/lists/items/activities/templates',
        appendPath: 'authorization'
      }]);

      if (this.settings.getProp('features.extensions.tw_invoices') || this.settings.getProp('features.extensions.tw_eInvoices')) {
        this.crud.addLists(this, ['extensions/apps/tw/invoicing']);
      }

      if (this.settings.getProp('features.sales.documents')) {
        this.crud.addLists(this, ['documents/sales/terms']);
      }

      if (this.settings.getProp('features.purchases.documents')) {
        this.crud.addLists(this, ['documents/purchases/terms']);
      }

      this._super(...arguments);

      this.set('config', _environment.default);
      this.set('editingPanels', []);
    },

    afterLoadLists() {
      this._super(...arguments);

      if (!this.isWizard) {
        const initialPanel = this.tabsManager.getLoadInstructions(this.tab) || _nventor.default.safeHeadOr({}, this.subPanelsList);

        this.tabsManager.displaySubPanel(this, initialPanel);
      }
    },

    subPanelsList: Ember.computed('crud.lists.settings-editorSubPanels.[]', function () {
      const editorSubPanels = R_.dotPath('crud.lists.settings-editorSubPanels')(this) || []; // only returns the ones with components

      return R.filter(subPanel => !!R.pathOr(null, ['_data', 'component'])(subPanel))(editorSubPanels);
    }),
    isReadonly: Ember.computed('isEditing', 'saveTask.isRunning', 'crud.saveTask.isRunning', function () {
      if (this.get('crud.saveTask.isRunning')) {
        return true;
      }

      if (this.isEditing === true) {
        return false;
      }

      return true;
    }),
    isDisabled: Ember.computed('isEditing', function () {
      if (this.isEditing === true) {
        return false;
      }

      return true;
    }),

    editPanel() {
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);
      const tabOptions = {
        loadInstructions: {
          component: activeSubTab.get?.('component')
        }
      };
      this.onEdit(this.model, {
        tabOptions
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "editPanel", [_dec], Object.getOwnPropertyDescriptor(_obj, "editPanel"), _obj)), _obj)));

  _exports.default = _default;
});