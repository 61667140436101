define("client/pods/components/emails/campaigns/panels-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ncSHlfwE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid panel-grid--has-header\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[1,[28,\"layouts/table-view\",null,[[\"detailsRowComponent\",\"results\",\"filters\",\"onSearchTask\",\"onPaginationTask\",\"onReloadSearchRecordsTask\",\"onEdit\",\"tableViewOptions\"],[\"emails/campaigns/table-view/detail-row\",[24,[\"results\"]],[24,[\"filters\"]],[24,[\"searchAdvancedTask\"]],[24,[\"paginateSearchRecordsTask\"]],[23,0,[\"crud\",\"reloadSearchRecordsTask\"]],[24,[\"onEdit\"]],[24,[\"tableViewOptions\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/emails/campaigns/panels-activity/template.hbs"
    }
  });

  _exports.default = _default;
});