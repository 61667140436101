define("client/pods/components/contacts/panels-display/sub-panels-details/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'contacts',
    editAction: 'edit',
    updateAction: 'update',
    dashAction: 'dash',
    showDetailEditor: false,
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),
    init: function () {
      this._super(...arguments);

      this.crud.addLists(this, ['taxes', 'statuses', 'contacts/lists/relationships', 'contacts/lists/detailTypes', 'contacts/lists/panels', 'channels/shop/campaigns/lists/commission-types']);
    },
    // lowestVariantOldPriceRange: computed('_data.{variantChildren.[],price,priceDiscounted}', function () {
    //   const variantChildren = this.get('_data.variantChildren') || []
    actions: {
      setAssociatesDefaults() {
        const model = this.model;
        model.setData('selectedCommissionBaseCostMethod', 'normalPercent');
        model.setData('isCommissionsUsingAverage', true);
        model.setData('isCommissionsCalculatedByExclAmt', true);
        model.setData('isCommissionsUsingSetChild', false);
        model.setData('isCommissionsHasAdjustedShippingFee', false);
      }

    }
  });

  _exports.default = _default;
});