define("client/pods/components/contacts/persons/pickup-location/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Hm/n2ke",
    "block": "{\"symbols\":[\"detailModel\",\"@model\"],\"statements\":[[4,\"if\",[[23,2,[\"_data\",\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"strong\",true],[8],[0,\"\\n      \"],[1,[28,\"tt\",[\"label\"],null],false],[0,\": \"],[1,[23,2,[\"_data\",\"label\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[8],[0,\"\\n  \"],[1,[28,\"tt\",[\"sender name\"],null],false],[0,\": \"],[1,[23,2,[\"_data\",\"name\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"each\",[[23,2,[\"_data\",\"details\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"_data\",\"value\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[28,\"tt\",[[23,1,[\"label\"]]],null],false],[0,\":\\n\"],[4,\"if\",[[23,1,[\"_data\",\"translate\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"tt\",[[23,1,[\"_data\",\"value\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,1,[\"_data\",\"value\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/contacts/persons/pickup-location/template.hbs"
    }
  });

  _exports.default = _default;
});