define("client/pods/components/elements/tag-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eB4zPlx1",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"@isRunning\",\"@onRemove\",\"@label\",\"&attrs\",\"@onSubmit\",\"@truncateClassName\",\"@labelClassNames\",\"@model\"],\"statements\":[[7,\"span\",false],[12,\"class\",\"element-tag-btn button\"],[13,5],[3,\"did-insert\",[[28,\"fn\",[[23,0,[\"didInsert\"]]],null]]],[8],[0,\"\\n  \"],[5,\"helpers/is-loading\",[],[[\"@isRunning\"],[[23,2,[]]]],{\"statements\":[[0,\"\\n    \"],[7,\"span\",false],[12,\"class\",[29,[\"element-tag-btn__label \",[28,\"if\",[[23,8,[]],[23,8,[]],\"\"],null],\" \",[28,\"if\",[[23,7,[]],[23,7,[]],\"\"],null],\" \",[28,\"if\",[[23,6,[]],\"tag__label--pointer\",\"\"],null]]]],[3,\"on\",[\"click\",[28,\"fn\",[[23,0,[\"submit\"]],[23,9,[]]],null]]],[8],[0,\"\\n\"],[4,\"if\",[[23,0,[\"hasTruncated\"]]],null,{\"statements\":[[0,\"        \"],[5,\"helpers/info-tooltip\",[],[[\"@hasInfoTooltip\",\"@clickToView\",\"@label\",\"@translate\"],[true,false,[23,4,[]],false]],{\"statements\":[[0,\"\\n          \"],[1,[23,4,[]],false],[0,\"\\n        \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,4,[]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,3,[]]],null,{\"statements\":[[0,\"      \"],[5,\"elements/element-pushbtn\",[],[[\"@classNames\",\"@isRunning\",\"@onSubmit\"],[\"u-close-btn\",[23,2,[]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/tag-btn/template.hbs"
    }
  });

  _exports.default = _default;
});