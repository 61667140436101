define("client/pods/websites/pages/model", ["exports", "ramda", "client/pods/base/model", "client/utils/nventor", "client/mixins/templates"], function (_exports, R, _model, _nventor, _templates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const WebsitesPagesBaseModel = _model.default.extend(_templates.default, (_dec = Ember._action, (_obj = {
    defaults() {
      return {
        css: '',
        hasTemplate: false,
        html: '',
        pageDataString: '{"html":"","css":"* { box-sizing: border-box; } body {margin: 0;}","assets":[],"styles":[],"components":[]}',
        products: [],
        pageSchedules: [],
        showOnMenu: true,
        showOnFooter: false,
        showOnFilters: false,
        templateImages: [],
        type: this.get('constants.websitePageTypes.page'),
        homePageRedirect: false,
        showBigOrdrStory: false,
        flowForms: [],
        facebookPixelKeys: [],
        googleTrackingKeys: [],
        openGraph: {}
      };
    },

    populate(data, attrs) {
      data.pageSchedules = this._transformSchedules({
        data,
        schedulesProp: 'pageSchedules'
      });

      this._super(data, attrs);
    },

    _transformSchedules(_ref) {
      let {
        data,
        schedulesProp
      } = _ref;
      const schedules = R.propOr([], schedulesProp)(data);
      return R.map(schedule => {
        return this.dataManager.setAsRecord({
          adapterName: 'status-schedules',
          data: schedule
        });
      })(schedules);
    },

    addSchedule(_ref2) {
      let {
        schedule = {},
        schedulesProp = ''
      } = _ref2;

      if (schedulesProp) {
        const schedules = this.getData(schedulesProp) || [];
        const scheduleModel = this.dataManager.setAsRecord({
          adapterName: 'status-schedules',
          data: schedule
        });
        schedules.pushObject(scheduleModel);
        this.setData(schedulesProp, schedules);
      }
    },

    statusClass: Ember.computed('_data.status', function () {
      // @TODO: needs to schedules into account
      const status = this.getData('status');

      if (status === this.constants.status.active) {
        return 'is-primary';
      }

      return 'is-danger';
    }),
    isPage: Ember.computed('_data.type', function () {
      if (this.get('_data.type') === this.get('constants.websitePageTypes.page')) {
        return true;
      }

      return false;
    }),
    isCategory: Ember.computed('_data.type', function () {
      if (this.get('_data.type') === this.get('constants.websitePageTypes.category')) {
        return true;
      }

      return false;
    }),
    isLink: Ember.computed('_data.type', function () {
      if (this.get('_data.type') === this.get('constants.websitePageTypes.link')) {
        return true;
      }

      return false;
    }),
    isProductLink: Ember.computed('isLink', '_data.linkType', function () {
      const linkType = this.get('_data.linkType');

      if (this.isLink && linkType === this.get('constants.websitePageLinkTypes.page')) {
        return true;
      }

      return false;
    }),
    allowWebBuilder: Ember.computed('model.{isPage,isLink,isCategory}', function () {
      if (this.get('model.isLink')) {
        return false;
      }

      return true;
    }),
    previewUrl: Ember.computed('_data.{_key,_rev,version}', 'synced', function () {
      const pageKey = this.get('_data._key');
      const version = this.get('_data.version');
      const previewHostingUrl = this.get('settings.config.APP.previewHostingUrl');
      const podKey = this.settings.getPodKey();
      return `${previewHostingUrl}/${podKey}/${pageKey}/${version}-preview.html`;
    }),
    isParent: Ember.computed('sub.[]', 'synced', function () {
      const sub = this.sub || [];

      if (R.isEmpty(sub)) {
        return false;
      }

      return true;
    }),
    lastParentSegment: Ember.computed('_data.parent', function () {
      const allParentKeys = this.get('_data.parent') || '';
      return R.pipe(R.split('/'), _nventor.default.safeLast)(allParentKeys);
    }),
    pageDataJson: Ember.computed('_data.pageDataString', function () {
      const pageDataString = this.get('_data.pageDataString');

      try {
        return JSON.parse(pageDataString);
      } catch (e) {
        return '';
      }
    }),
    templateFromPageDataString: Ember.computed('_data.pageDataString', function () {
      const pageDataString = this.get('_data.pageDataString');

      try {
        const pageData = JSON.parse(pageDataString);
        return R.propOr('', 'html', pageData);
      } catch (e) {
        return '';
      }
    }),
    pageName: Ember.computed('_data.{name,pageName,_rev}', 'synced', function () {
      const pageData = R.pathOr({}, ['_data'])(this);
      const pageName = R.prop('pageName')(pageData);

      if (pageData.isHome) {
        return pageName || this.intl.t('home page');
      }

      if (pageData.isDynamicPage) {
        return pageName || this.intl.t(pageData.name);
      }

      return R.propOr('no-name', 'name')(pageData);
    }),
    pageUrl: Ember.computed('_data.url', function () {
      const url = this?._data?.url || '';

      if (R.startsWith('http')(url)) {
        return url;
      }

      if (!url) {
        return '';
      }

      return `/${url}`;
    }),
    showOnLabels: Ember.computed('_data.{showOnMenu,showOnFilters,showOnFooter}', function () {
      const showOnMenu = this?._data?.showOnMenu;
      const showOnFilters = this?._data?.showOnFilters;
      const showOnFooter = this?._data?.showOnFooter;
      const showOnLabels = [];
      showOnLabels.push({
        isOn: showOnMenu,
        label: this.intl.t('webpage_show_on_menu_short')
      });
      showOnLabels.push({
        isOn: showOnFilters,
        label: this.intl.t('webpage_show_on_filters_short')
      });
      showOnLabels.push({
        isOn: showOnFooter,
        label: this.intl.t('webpage_show_on_footer_short')
      });
      return showOnLabels;
    }),
    activePageSchedule: Ember.computed('_data.pageSchedules.@each.isActive', function () {
      const pageSchedules = this.getData('pageSchedules') || [];
      return R.find(R.propEq('isActive', true))(pageSchedules);
    }),
    nextPageSchedule: Ember.computed('_data.pageSchedules.@each.isActiveNext', function () {
      const pageSchedules = this.getData('pageSchedules') || [];
      return R.find(R.propEq('isActiveNext', true))(pageSchedules);
    }),

    onSelectType() {
      const type = this.get('_data.type');

      if (type === this.constants.websitePageTypes.page) {
        this.setData('linkType', '');
        this.setData('hasTemplate', true);
        this.setData('showOnFilters', false);
        this.setData('linkType', '');
        this.setData('target', '');
      } else if (type === this.constants.websitePageTypes.link) {
        this.setData('showBreadcrumb', false);
        this.setData('linkType', this.get('constants.websitePageLinkTypes.url'));
        this.setData('target', '_self');
        this.setData('hasTemplate', false);
        this.setData('showOnFilters', false);
      } else if (type === this.constants.websitePageTypes.category) {
        this.setData('hasTemplate', false);
        this.setData('showOnFilters', true);
        this.setData('showOnFooter', false);
        this.setData('linkType', '');
        this.setData('target', '');
      }
    },

    serialize(model) {
      const data = this._super(model); // dont allow '__' in url


      let url = data?.url || '';

      if (url) {
        url = R.replace(/__/g, '')(url);
        url = R.replace(/--/g, '')(url);
        data.url = url;
      }

      return data;
    }

  }, (_applyDecoratedDescriptor(_obj, "onSelectType", [_dec], Object.getOwnPropertyDescriptor(_obj, "onSelectType"), _obj)), _obj)));

  let WebsitesPagesModel = (_dec2 = Ember._tracked, (_class = class WebsitesPagesModel extends WebsitesPagesBaseModel {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "_data", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WebsitesPagesModel;
});