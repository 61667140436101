define("client/pods/contacts/lists/menu/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Ember from 'ember';
  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let {
        data = {},
        appendPath,
        serverType
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const customData = {
        list: [{
          resource: 'api/protected/contacts/menu/actions/dash',
          icon: 'fas fa-table',
          component: 'contacts/panels-dash',
          label: 'overview',
          action: 'dash',
          tabType: 'replaceSubTab'
        }, {
          resource: 'api/protected/contacts/menu/actions/dash',
          icon: 'fas fa-poll',
          component: 'contacts/panels-report',
          label: 'report',
          action: 'report',
          tabType: 'replaceSubTab'
        }, {
          resource: 'api/protected/contacts/menu/actions/dash',
          icon: 'fas fa-users',
          component: 'contacts/groups/panels-dash',
          label: 'contacts_groups',
          action: 'groups',
          tabType: 'replaceSubTab'
        }, {
          resource: 'api/protected/contacts/menu/actions/new-customer',
          component: 'contacts/panels-new',
          icon: 'fas fa-plus',
          label: 'customer',
          action: 'new',
          relationships: ['customer'],
          tabType: 'replaceSubTab'
        }, {
          resource: 'api/protected/contacts/menu/actions/new-supplier',
          component: 'contacts/panels-new',
          icon: 'fas fa-plus',
          label: 'supplier',
          action: 'new',
          relationships: ['supplier'],
          tabType: 'replaceSubTab' // }, {
          //   resource: 'api/protected/contacts/menu/actions/import',
          //   component: 'importer/data-import',
          //   adapterName: 'contacts',
          //   label: 'import',
          //   action: 'import'

        }]
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});