define("client/pods/components/store-location/search-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KLaVXjPu",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"elements/element-input\",null,[[\"autofocus\",\"placeholder\",\"value\",\"isRunning\",\"isSearchBar\",\"onSubmit\"],[[24,[\"autofocus\"]],\"store locations\",[24,[\"filters\",\"_data\",\"query\"]],[28,\"or\",[[24,[\"searchTask\",\"isRunning\"]],[24,[\"listByTask\",\"isRunning\"]]],null],true,[28,\"perform\",[[24,[\"searchTask\"]],[24,[\"searchOptions\"]],[28,\"hash\",null,[[\"filters\"],[[24,[\"filters\"]]]]]],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"store-location/search-filters\",null,[[\"adapterName\",\"filters\",\"results\"],[[24,[\"adapterName\"]],[24,[\"filters\"]],[24,[\"results\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/store-location/search-bar/template.hbs"
    }
  });

  _exports.default = _default;
});