define("client/helpers/either", ["exports", "ramda-adjunct"], function (_exports, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params, hash) {
      const value = params[0];

      if (RA.isNotNilOrEmpty(value)) {
        return value;
      }

      const defaultValue = params[1];
      return defaultValue;
    }

  });

  _exports.default = _default;
});