define("client/pods/operator/change-logs/model", ["exports", "client/pods/base/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        version: '',
        deployed: false,
        forceUpdate: false,
        status: this.constants.publicStatus.private
      };
    },

    publicStatusClass: Ember.computed('_data.status', 'synced', function () {
      const status = this?._data?.status;

      if (status === this.constants.publicStatus.public) {
        return 'u-is-public';
      }

      return 'u-is-private';
    })
  });

  _exports.default = _default;
});