define("client/pods/components/channels/shop/campaigns/panels-display/sub-panels-activity/sub-panels-activity-doc/component", ["exports", "ramda", "client/mixins/doc-list-by", "client/mixins/crud", "client/mixins/doc-status", "ember-concurrency", "client/pods/documents/proxy/model", "client/utils/nventor"], function (_exports, R, _docListBy, _crud, _docStatus, _emberConcurrency, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _docListBy.default, _docStatus.default, {
    crud: Ember.inject.service(),
    filtersAdapterName: 'documents/dispatch/filters',
    page: 1,
    count: 20,
    isSearching: false,
    adapterName: Ember.computed('tab.{docType,docName}', function () {
      const docType = this.get('tab.docType');
      const docName = this.get('tab.docName');
      return `documents/${docType}/${docName}`;
    }),

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['documents/lists/filters/search', 'documents/lists/filters/statuses', 'documents/lists/filters/dispatchStatus', 'documents/lists/filters/sort', 'filters/count', 'filters/sortDirection']);
      const model = this.model;
      const sourceKey = model.getData('_key');
      const source = model.getData('name');
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName,
        defaultFiltersData: {
          source,
          sourceKey,
          query: '',
          includeIsPos: true
        }
      });
      this.set('filters', filters);
    },

    afterLoadLists() {
      this._super(...arguments);

      this.fetchCampaignActivityTask.perform();
    },

    fetchCampaignActivityTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        match,
        documentStatus,
        dispatchStatus,
        notDispatchStatus,
        date,
        dateStart,
        dateEnd,
        query = '',
        isVoid
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let onHideDropdown = arguments.length > 1 ? arguments[1] : undefined;
      return function* () {
        const sourceKey = _this.model.getData('_key');

        const filters = _this.filters;

        const docType = _this.get('tab.docType');

        const docName = _this.get('tab.docName');

        const adapterName = `documents/${docType}/${docName}`;
        const results = yield _this.listByTask.perform({
          filters,
          adapterName,
          match,
          notDispatchStatus,
          query,
          isVoid,
          sourceKey,
          documentStatus,
          dispatchStatus,
          date,
          dateStart,
          dateEnd,
          showCampaignPeriodDataOnly: R.pathOr(false, ['model', '_data', 'showCampaignPeriodDataOnly'])(_this),
          campaignPeriodDateStart: R.pathOr('', ['model', '_data', 'dateStartZ'])(_this),
          campaignPeriodDateEnd: R.pathOr('', ['model', '_data', 'dateEndZ'])(_this)
        });

        _this.set('results', results);

        if (onHideDropdown) {
          onHideDropdown();
        }
      }();
    }),
    searchCampaignTask: (0, _emberConcurrency.task)(function* (query) {
      const filters = this.filters;
      const docType = this.get('tab.docType');
      const docName = this.get('tab.docName');

      if (query === '') {
        return yield this.fetchCampaignActivityTask.perform();
      }

      const results = yield this.searchInputTask.perform({
        adapterName: `documents/${docType}/${docName}`,
        filters
      }, query) || [];
      this.set('results', results);
      return results;
    }),
    allActivity: Ember.computed('listByResults.[]', function () {
      // const isSearching = this.get('isSearching')
      // if (isSearching) {
      const searchResults = this.listByResults || [];

      if (!Ember.isEmpty(searchResults)) {
        return searchResults;
      }

      return []; // }
      // return this.get('listByResults') || []
    }),
    resultsProxy: Ember.computed('results.[]', function () {
      const results = this.results || [];
      return R.map(result => _nventor.default.createModel(_model.default, {}, {
        model: result,
        isSelected: false,
        showDetails: false // @TODO: get this from settings

      }), results);
    }),
    actions: {
      listByDate(onHideDropdown, dateZ, date) {
        return this.fetchCampaignActivityTask.perform({
          date: this.getMoment(date).format('YYYY-MM-DD'),
          dateZ,
          resultsToggle: 'resultsToggle'
        }, onHideDropdown);
      }

    }
  });

  _exports.default = _default;
});