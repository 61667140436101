define("client/pods/components/notifications/bell-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nBqH6xZr",
    "block": "{\"symbols\":[\"@isNotificationsVisible\"],\"statements\":[[5,\"elements/element-btn\",[],[[\"@badge\",\"@icon\",\"@isToggler\",\"@hasToggleIconRight\",\"@onSubmit\",\"@toggleIsOn\"],[[23,0,[\"notificationsService\",\"unreadCount\"]],\"fas fa-bell\",true,true,[28,\"fn\",[[23,0,[\"notificationsService\",\"markAllBroadcastAlertsAsRead\"]]],null],[23,1,[]]]]],[0,\"\\n\\n\"],[5,\"updates/update-btn\",[],[[\"@isBtnOnly\"],[true]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/notifications/bell-button/template.hbs"
    }
  });

  _exports.default = _default;
});