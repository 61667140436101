define("client/pods/components/extensions/apps/tw/payuni-seven-eleven/credentials/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fj5ZR0Ny",
    "block": "{\"symbols\":[\"@errors\",\"@isReadonly\",\"@model\"],\"statements\":[[5,\"forms/form-section\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[0,\"  \"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"merchantId\",[23,1,[]],\"payuni.merchantId\",\"payuniSevenElevenMerchantId\"]],{\"statements\":[[0,\"\\n\\n    \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@translatedPlaceholder\",\"@value\",\"@autocomplete\"],[[23,2,[]],[28,\"tt\",[\"required\"],null],[23,3,[\"_data\",\"payuni\",\"merchantId\"]],\"off\"]]],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[0,\"  \"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"merchantKey\",[23,1,[]],\"payuni.merchantKey\",\"payuniSevenElevenMerchantKey\"]],{\"statements\":[[0,\"\\n\\n    \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@translatedPlaceholder\",\"@value\",\"@autocomplete\"],[[23,2,[]],[28,\"tt\",[\"required\"],null],[23,3,[\"_data\",\"payuni\",\"merchantKey\"]],\"off\"]]],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"],[0,\"  \"],[5,\"forms/form-input-row\",[],[[\"@label\",\"@errors\",\"@context\",\"@documentationResource\"],[\"merchantIv\",[23,1,[]],\"payuni.merchantIV\",\"payuniSevenElevenMerchantIv\"]],{\"statements\":[[0,\"\\n\\n    \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@translatedPlaceholder\",\"@value\",\"@autocomplete\"],[[23,2,[]],[28,\"tt\",[\"required\"],null],[23,3,[\"_data\",\"payuni\",\"merchantIV\"]],\"off\"]]],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/extensions/apps/tw/payuni-seven-eleven/credentials/template.hbs"
    }
  });

  _exports.default = _default;
});