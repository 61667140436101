define("client/pods/components/documents/table-view/doc-row/info-row/component", ["exports", "ramda", "ramda-adjunct", "ember-concurrency-decorators", "client/mixins/crud", "client/mixins/dispatch", "client/mixins/transporter", "client/utils/nventor"], function (_exports, R, RA, _emberConcurrencyDecorators, _crud, _dispatch, _transporter, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, _dispatch.default, _transporter.default, {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    big: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    tagName: '',
    showSplitMergePrintBtns: false,
    printType: '',
    isShowGroupedDocuments: false,
    allowCheckbox: Ember.computed('model._data.{status,dispatchProcess}', 'dispatchProcess', function () {
      const model = this.get('model');
      const currentDispatchProcess = this.get('dispatchProcess._data.code');
      const dispatchProcess = model.getData('dispatchProcess') || 'unprepared';

      if (dispatchProcess !== currentDispatchProcess && currentDispatchProcess !== 'all') {
        return false;
      }

      if (model.getData('status') === this.get('constants.documentsStatus.final')) {
        return true;
      }

      return false;
    }),
    rowClass: Ember.computed('rowIndex', 'modelProxy.showDetails', 'model._data.description', 'isShowGroupedDocuments', 'isMainDocument', function () {
      return ''; // const modelProxy = this.modelProxy
      // const model = this.model
      // const rowIndex = this.rowIndex || 0
      // const isShowGroupedDocuments = this.isShowGroupedDocuments || false
      // const isMainDocument = this.isMainDocument || false
      // const showDetails = modelProxy.get('showDetails')
      // const description = model.getData('description')
      // let classString = ''
      // if (nventor.isEven(rowIndex)) {
      //   classString += 'even'
      // } else {
      //   classString += 'odd'
      // }
      // if (showDetails) {
      //   classString += ' is-expanded'
      // } else {
      //   classString += ''
      // }
      // if (description) {
      //   classString += ' no-border-bottom'
      // } else {
      //   classString += ''
      // }
      // if (isShowGroupedDocuments && isMainDocument) {
      //   classString += ' u-table-border-top-red darker-background'
      // } else {
      //   classString += ''
      // }
      // return classString
    }),
    showAllDetails: Ember.computed('modelProxy.showDetails', 'isMainDocument', 'isShowGroupedDocuments', 'isMaster', function () {
      const showDetails = this?.modelProxy?.showDetails || false;
      const isMainDocument = this.isMainDocument || false;
      const isShowGroupedDocuments = this.isShowGroupedDocuments || false;
      const isMaster = this.isMaster || false;
      let display = false;

      if (isMainDocument) {
        if (isShowGroupedDocuments) {
          display = false;
        } else {
          display = true;
        }
      } else {
        if (isShowGroupedDocuments) {
          display = true;
        } else if (!isShowGroupedDocuments && isMaster) {
          display = false;
        } else if (!isShowGroupedDocuments && !isMaster) {
          display = true;
        }
      }

      return showDetails && display;
    }),
    hasDuplicateWaybill: Ember.computed('model._data.waybill', 'duplicateWaybillsDocNos.@each.selected', function () {
      const modelValue = this.get('model._data.waybill');
      const duplicates = this.duplicateWaybillsDocNos || [];
      const found = R.find(R.propEq('value', modelValue), duplicates);

      if (found) {
        return true;
      }

      return false;
    }),
    hasDuplicateContact: Ember.computed('model._data.address', 'duplicateAddressesDocNos.[]', function () {
      const model = this.get('model');
      const modelValue = model.getData('address');
      const duplicates = this.duplicateAddressesDocNos || [];
      const found = R.filter(R.allPass([R.propEq('value', modelValue), _nventor.default.allEqProps(['docType', 'docName'], model._data)]))(duplicates);

      if (!R.isEmpty(found)) {
        return true;
      }

      return false;
    }),
    groupedDocsInclTotal: Ember.computed('tableViewOptions.isDispatchTab', 'isMainDocument', 'model._data.{paymentMethodKey,cashCollectionInclTotal,inclTotal,roundingCurrency,groups.@each.synced}', function () {
      const isDispatchTab = this?.tableViewOptions?.isDispatchTab || false;
      const isMainDocument = this.isMainDocument || false;
      const model = this?.model || {};
      const groups = model.getData('groups') || [];
      const paymentMethodKey = model.getData('paymentMethodKey');
      let groupedDocsInclTotal = this.big.newBig(model.getData('inclTotal'));

      if (isDispatchTab && paymentMethodKey === 'cash') {
        groupedDocsInclTotal = this.big.newBig(model.getData('cashCollectionInclTotal'));
      }

      if (isMainDocument) {
        R.forEach(group => {
          const groupPaymentMethodKey = group.getData('paymentMethodKey');
          let groupInclTotal = this.big.newBig(group.getData('inclTotal'));

          if (isDispatchTab && groupPaymentMethodKey === 'cash') {
            groupInclTotal = this.big.newBig(group.getData('cashCollectionInclTotal'));
          }

          groupedDocsInclTotal = groupedDocsInclTotal.add(groupInclTotal);
        })(groups);
      }

      return groupedDocsInclTotal.toFixed(model.getData('roundingCurrency') || 0);
    }),
    hasReturnsDetails: Ember.computed('model._data.docName', function () {
      const docName = this.get('model._data.docName');

      if (docName === 'invoices') {
        return true;
      }

      return false;
    }),
    dispatchProcess: Ember.computed('tableViewOptions.dispatchProcesses', function () {
      return this.get('tableViewOptions.dispatchProcess');
    }),
    allowIndividualPrintWaybill: Ember.computed('tab.isDispatched', 'model.allowPrintWaybill', 'model._data.transporterExtension', 'tab.dispatchProcess._data.allowPrintAllWaybills', function () {
      const allowPrintAllWaybills = R.pathEq(['tab', 'dispatchProcess', '_data', 'allowPrintAllWaybills'], true)(this);
      const isDispatched = R.pathEq(['tab', 'isDispatched'], true)(this);
      const allowPrintWaybill = R.pathEq(['model', 'allowPrintWaybill'], true)(this);

      if (allowPrintWaybill && (allowPrintAllWaybills || isDispatched)) {
        return true;
      }

      return false;
    }),
    shouldAllowTransporterQuickChange: Ember.computed('tableViewOptions.allowDispatching', 'model.allowTransporterQuickChange', function () {
      const allowDispatching = R.pathEq(['tableViewOptions', 'allowDispatching'], true)(this);
      const allowTransporterQuickChange = R.pathEq(['model', 'allowTransporterQuickChange'], true)(this);

      if (allowDispatching && allowTransporterQuickChange) {
        return true;
      }

      return false;
    }),
    // @dropTask
    // * tCatSetDefaults () {
    //   const tCatModel = yield this.dispatch.tCatGetModel.perform()
    //   const printType = tCatModel._data.defaultTransporterWaybillPrintType
    //   this.set('printType', printType)
    // },
    actions: {
      select() {
        if (this.onMainAction) {
          this.onMainAction(...arguments);
        }
      },

      afterSplit() {
        const dispatchProcess = this.dispatchProcess;
        const onAfterSplit = this.get('tableViewOptions.onAfterSplit');
        onAfterSplit(dispatchProcess);
      } // afterMerge () {
      //   const dispatchProcess = this.dispatchProcess
      //   const onAfterMerge = this.get('tableViewOptions.onAfterMerge')
      //   onAfterMerge(dispatchProcess)
      // }


    }
  });

  _exports.default = _default;
});