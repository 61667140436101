define("client/pods/components/files/files-gallery-btn/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { inject as service } from '@ember/service'
  // import nventor from '../../../../utils/nventor'
  var _default = Ember.Component.extend({
    tagName: 'span',
    // uploadcare: service(),
    // resource: 'files',
    // resourceKey: 'files',
    // transforms: '/-/format/webp/-/quality/lightest/-/progressive/yes/-/resize/800x/',
    // host: 'https://ucarecdn.com/',
    fetchUploads: true,
    allowSetDefault: false,
    allowUploads: true,
    showDefaultImagePreview: false,
    isVisibleGallery: false
  });

  _exports.default = _default;
});