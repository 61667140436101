define("client/pods/components/extensions/apps/tw/payuni-seven-eleven/credentials/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ExtensionsAppsTwPayuniSevenElevenCredentialsComponent extends _component.default {}

  _exports.default = ExtensionsAppsTwPayuniSevenElevenCredentialsComponent;
});