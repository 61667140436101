define("client/pods/components/channels/shop/items/item-price/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wqxdyps4",
    "block": "{\"symbols\":[\"&default\",\"@item\"],\"statements\":[[7,\"span\",true],[10,\"class\",\"flex-container-row\"],[8],[0,\"\\n\"],[4,\"if\",[[23,2,[\"isOnSale\"]]],null,{\"statements\":[[4,\"unless\",[[28,\"or\",[[28,\"lte\",[[23,2,[\"oldPrice\"]],\"0\"],null],[28,\"eq\",[[23,2,[\"oldPrice\"]],[23,2,[\"currentPrice\"]]],null]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"channels/shop/items/item-price/item-price-old\",null,[[\"item\",\"hasStrikeThrough\"],[[23,2,[]],[23,0,[\"hasStrikeThrough\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"span\",true],[11,\"class\",[29,[\"store-item__price \",[28,\"if\",[[23,2,[\"isOnSale\"]],\"store-item__price--sale\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,2,[\"_data\",\"hasOnSaleSchedules\"]],[23,2,[\"activeOnSaleSchedule\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"currency\",[[23,2,[\"activeOnSaleSchedule\",\"_data\",\"priceDiscounted\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"channels/shop/items/item-price/item-price-current\",null,[[\"item\"],[[23,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/items/item-price/template.hbs"
    }
  });

  _exports.default = _default;
});