define("client/pods/components/products/panels-display/pages-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jK1kjVnz",
    "block": "{\"symbols\":[\"@resourceList\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n\\n\"],[0,\"\\n\"],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n    \"],[1,[28,\"products/products-editor/pages-editor\",null,[[\"tab\",\"model\",\"isReadonly\",\"isDisabled\",\"resourceList\"],[[24,[\"tab\"]],[24,[\"model\"]],[24,[\"isReadonly\"]],[24,[\"isDisabled\"]],[23,1,[]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-display/pages-display/template.hbs"
    }
  });

  _exports.default = _default;
});