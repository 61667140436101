define("client/pods/grapesjs/plugins/grid-images", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @project: bigordr-app
   * @file: grid-images
   * @author Nafis Rubio
   * @since 21/09/2020
   * @copyright (C) 2020 BigOrdr
   * @description: Images grid
   */
  var _default = editor => {
    const {
      DomComponents,
      BlockManager
    } = editor;
    const pluginName = 'bigordr-grid-images';
    const pluginBlockId = `${pluginName}-block`;
    const options = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const {
      intl,

      /** @type {Categories} */
      categories
    } = options;
    const blockLabel = intl.t(pluginName);
    const componentName = intl.t(pluginName);
    const margin0 = 'margin-0';

    function getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
    }

    DomComponents.addType('bigordr-grid-image', {
      extend: 'bigordr-strip-image',
      model: {
        defaults: {
          style: {
            'background-attachment': 'scroll',
            'background-image': `url(https://picsum.photos/512.webp?blur=2&random=${getRandomIntInclusive(1, 100)})`,
            'background-position': 'center center',
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'flex-grow': '1',
            'flex-basis': '25%',
            'min-height': '100%',
            'object-fit': 'cover',
            height: 'auto',
            width: '100%',
            display: 'flex',
            'justify-content': 'center',
            'flex-direction': 'column'
          },
          selectable: false,
          components: [{
            type: 'row',
            style: {
              padding: '0',
              'flex-grow': '1'
            },
            components: [{
              type: 'cell',
              style: {
                padding: '0',
                'text-align': 'center'
              },
              components: [{
                type: 'text',
                style: {
                  padding: '10px'
                },
                name: intl.t('title'),
                classes: ['bigordr-title', margin0],
                tagName: 'h1',
                content: 'Insert title here',
                traits: [{
                  type: 'bigordr-animation-text-trait',
                  name: 'animationSelector',
                  label: intl.t('select animation')
                }, {
                  type: 'bigordr-animation-iteration-trait',
                  name: 'animationIterationSelector',
                  label: intl.t('select iteration count')
                }, {
                  type: 'bigordr-animation-duration-trait',
                  name: 'animationDuration',
                  label: intl.t('enter duration (sec)')
                }]
              }],
              traits: []
            }],
            traits: []
          }, {
            type: 'row',
            style: {
              padding: '0'
            },
            components: [{
              type: 'cell',
              style: {
                padding: '0',
                'text-align': 'center'
              },
              components: [{
                type: 'bigordr-button',
                traits: []
              }],
              traits: []
            }],
            traits: []
          }],
          traits: [{
            type: 'bigordr-animation-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }]
        }
      }
    });
    DomComponents.addType(pluginName, {
      model: {
        defaults: {
          name: componentName,
          style: {
            padding: '0'
          },
          components: [{
            type: 'row',
            style: {
              padding: '0'
            },
            components: [{
              type: 'cell',
              style: {
                padding: '0',
                'flex-grow': '1',
                'flex-basis': '100%'
              },
              components: {
                type: 'bigordr-grid-image',
                traits: [{
                  type: 'bigordr-animation-trait',
                  name: 'animationSelector',
                  label: intl.t('select animation')
                }, {
                  type: 'bigordr-animation-iteration-trait',
                  name: 'animationIterationSelector',
                  label: intl.t('select iteration count')
                }, {
                  type: 'bigordr-animation-duration-trait',
                  name: 'animationDuration',
                  label: intl.t('enter duration (sec)')
                }]
              },
              traits: []
            }],
            traits: []
          }],
          traits: [{
            type: 'bigordr-animation-trait',
            name: 'animationSelector',
            label: intl.t('select animation')
          }, {
            type: 'bigordr-animation-iteration-trait',
            name: 'animationIterationSelector',
            label: intl.t('select iteration count')
          }, {
            type: 'bigordr-animation-duration-trait',
            name: 'animationDuration',
            label: intl.t('enter duration (sec)')
          }]
        }
      }
    });
    BlockManager.add(pluginBlockId, {
      label: blockLabel,
      category: categories.complex,
      content: {
        type: pluginName
      },
      render: _ref => {
        let {
          model
        } = _ref;
        return `
    <div class="gjs-block__media">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M 5 4 A 1.0001 1.0001 0 0 0 4 5 L 4 45 A 1.0001 1.0001 0 0 0 5 46 L 45 46 A 1.0001 1.0001 0 0 0 46 45 L 46 5 A 1.0001 1.0001 0 0 0 45 4 L 5 4 z M 6 6 L 44 6 L 44 10 L 6 10 L 6 6 z M 6 12 L 44 12 L 44 44 L 6 44 L 6 12 z M 10 15 A 1.0001 1.0001 0 0 0 9 16 L 9 20 A 1.0001 1.0001 0 0 0 10 21 L 40 21 A 1.0001 1.0001 0 0 0 41 20 L 41 16 A 1.0001 1.0001 0 0 0 40 15 L 10 15 z M 11 17 L 39 17 L 39 19 L 11 19 L 11 17 z M 11 23 C 9.9069372 23 9 23.906937 9 25 L 9 39 C 9 40.093063 9.9069372 41 11 41 L 22 41 C 23.093063 41 24 40.093063 24 39 L 24 25 C 24 23.906937 23.093063 23 22 23 L 11 23 z M 28 23 C 26.906937 23 26 23.906937 26 25 L 26 39 C 26 40.093063 26.906937 41 28 41 L 39 41 C 40.093063 41 41 40.093063 41 39 L 41 25 C 41 23.906937 40.093063 23 39 23 L 28 23 z M 11 25 L 22 25 L 22 39 L 11 39 L 11 25 z M 28 25 L 39 25 L 39 39 L 28 39 L 28 25 z"></path></svg> 
    <div class="block-label">${model.get('label')}</div>
    </div>
    `;
      }
    });
  };

  _exports.default = _default;
});