define("client/pods/components/helpers/multiline-truncate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c5ALIA+E",
    "block": "{\"symbols\":[\"isTooltipVisible\",\"@content\",\"@hasWrapper\",\"@contentDisplayClassName\"],\"statements\":[[5,\"helpers/info-tooltip\",[],[[\"@customBlock\",\"@clickToView\"],[true,[23,0,[\"clickToView\"]]]],{\"statements\":[[0,\"\\n  \"],[1,[23,0,[\"shortenedContent\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"hasTooltip\"]]],null,{\"statements\":[[4,\"helpers/info-tooltip-content\",null,[[\"closeOnOverlayClick\",\"hasModalOverlay\",\"isViewable\",\"hasContent\"],[true,false,[23,1,[]],[23,0,[\"hasTooltip\"]]]],{\"statements\":[[0,\"      \"],[5,\"helpers/content-display\",[],[[\"@content\",\"@hasWrapper\",\"@className\"],[[23,2,[]],[23,3,[]],[23,4,[]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/multiline-truncate/template.hbs"
    }
  });

  _exports.default = _default;
});