define("client/pods/components/documents/filters-advanced/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DocumentsFiltersAdvancedComponent = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service('products'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class DocumentsFiltersAdvancedComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "applicationService", _descriptor, this);

      _initializerDefineProperty(this, "productsService", _descriptor2, this);

      _initializerDefineProperty(this, "crud", _descriptor3, this);

      _initializerDefineProperty(this, "search", _descriptor4, this);

      _initializerDefineProperty(this, "campaignFilters", _descriptor5, this);

      _initializerDefineProperty(this, "shopNoneToggle", _descriptor6, this);

      this.setupTask.perform();
    }

    *setupTask() {
      this.crud.addLists(this, [// {
      //   name: 'taxes',
      //   appendPath: 'products'
      // },
      // 'filters/count',
      // 'products/brands',
      // 'products/lists/tags',
      // 'products/lists/display-tags',
      // 'products/lists/product-display-position',
      // 'products/lists/types',
      // 'products/lists/setTypes',
      // 'products/lists/tracking',
      // 'products/lists/invoiceDescriptions',
      // 'products/lists/editorSubPanels',
      // 'settings/roundingAuto',
      // 'units',
      // 'units/dimensions',
      // 'units/weight',
      // 'statuses',
      // 'products/lists/pageImportBtnSubPanels',
      // 'products/lists/sync',
      // 'contacts/transporters',
      'channels/shop/campaigns/lists/locations', 'dispatch/lists/processes', 'dispatch/lists/date-header', 'documents/lists/filters/dispatch-status', 'paymentMethods', {
        name: 'users',
        appendPath: 'internal'
      }]);
      const defaultFiltersData = {};
      const source = R.path(['args', 'filters', '_data', 'source'])(this);

      if (source) {
        defaultFiltersData.query = source;
      }

      const campaignFilters = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/filters',
        defaultFiltersData
      });
      this.campaignFilters = campaignFilters; // const recurringFilters = yield this.productsService.getRecurringFiltersTask.perform() || []
      // this.recurringFilters = recurringFilters
    }

    selectDispatchProcesses() {
      const filters = this.args.filters;
      const dispatchProcessesModels = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      const dispatchProcesses = filters.getData('dispatchProcesses');
      const dispatchProcessesCriterias = R.pipe(R.filter(dispatchProcess => {
        return R.includes(dispatchProcess.getData('code'), dispatchProcesses);
      }), R.map(R.path(['_data', 'criterias'])))(dispatchProcessesModels);
      filters.setData('multipleDispatchProcessesCriterias', dispatchProcessesCriterias);
    }

    selectItem(item, isSelected) {
      if (this?.args?.onSearchTask) {
        this.args.onSearchTask.perform();
      }
    }

    get showStatusTags() {
      // @TODO: move to filter model
      const statuses = this?.crud?.lists?.statuses || [];
      const selectedStatuses = this.args.filters?._data?.statuses || [];

      if (selectedStatuses.length === 0) {
        return false;
      }

      if (selectedStatuses.length === statuses.length) {
        return false;
      }

      return true;
    }

    get showResetFilter() {
      // @TODO: move to filter model
      const filtersData = this?.args?.filters?._data;

      if (RA.isNilOrEmpty(filtersData)) {
        return false;
      }

      const statuses = filtersData.statuses;
      const hasImages = filtersData.hasImages;
      const item = filtersData.item;
      const itemCode = filtersData.itemCode;
      const price = filtersData.price;
      const setTypes = filtersData.setTypes;
      const tags = filtersData.tags;

      if (statuses.length !== 5 || tags.length !== 0 || setTypes.length !== 0 || RA.isNilOrEmpty(hasImages) !== true || item !== '' || itemCode !== '' || price !== '') {
        return true;
      }

      return false;
    }

    *updateFiltersTask() {
      // @TODO: how can this be improved?
      const filters = this.args.filters || {};
      Ember.set(filters, '_data', filters?._data || {}); // reset data

      return yield this.args.onSearchTask.perform(...arguments);
    }

    *clearSelectedItemsTask() {
      if (this.args.onResetSearchRecordsTask) {
        const filters = this.args.filters;
        return yield this.args.onResetSearchRecordsTask.perform({
          filters
        });
      } // default action


      if (this?.crud?.reloadSearchRecordsTask) {
        const resetExcludes = this.args.tableViewOptions?.resetExcludes || [];
        yield this?.crud?.reloadSearchRecordsTask.perform({
          filters: this.args.filters,
          reset: true,
          resetExcludes
        });
        const filters = this.args.filters || {};
        Ember.set(filters, '_data', filters?._data || {}); // reset data
      }
    }

    get customPaymentMethods() {
      const paymentMethods = this?.crud?.lists?.paymentMethods || this?.crud?.lists['payment-methods'] || [];
      return R.reject(R.hasPath(['_data', 'isNotEditable']))(paymentMethods);
    }

    get defaultPaymentMethods() {
      const paymentMethods = this?.crud?.lists?.paymentMethods || this?.crud?.lists['payment-methods'] || [];
      return R.filter(R.hasPath(['_data', 'isNotEditable']))(paymentMethods);
    }

    loadShopSource(shop) {
      const filters = this.args.filters;
      filters.setData('sourceKey', shop.getData('_key'));

      if (this.args.onSearchTask) {
        this.args.onSearchTask.perform({
          filters
        });
      }
    }

    loadShopSourceWithNone() {
      const shopNoneToggle = this.shopNoneToggle;
      const filters = this.args.filters;

      if (shopNoneToggle) {
        filters.setData('sourceKey', 'none');
      } else {
        this.unloadShopSource();
      }

      if (this.args.onSearchTask) {
        this.args.onSearchTask.perform({
          filters
        });
      }
    }

    unloadShopSource() {
      const filters = this.args.filters;
      filters.setData('sourceKey', '');
      this.shopNoneToggle = false;

      if (this.args.onSearchTask) {
        this.args.onSearchTask.perform({
          filters
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "productsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "campaignFilters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "shopNoneToggle", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectDispatchProcesses", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "selectDispatchProcesses"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectItem", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "selectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFiltersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateFiltersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearSelectedItemsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "clearSelectedItemsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadShopSource", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "loadShopSource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadShopSourceWithNone", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "loadShopSourceWithNone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadShopSource", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "unloadShopSource"), _class.prototype)), _class));
  _exports.default = DocumentsFiltersAdvancedComponent;
});