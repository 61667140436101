define("client/pods/components/elements/element-select/select-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TM+1VJnY",
    "block": "{\"symbols\":[\"@optionValueKey\",\"@option\",\"@value\",\"@optionLabelKey\"],\"statements\":[[7,\"option\",true],[11,\"value\",[28,\"get\",[[23,2,[]],[23,1,[]]],null]],[11,\"selected\",[28,\"if\",[[28,\"eq\",[[28,\"get\",[[23,2,[]],[23,1,[]]],null],[23,3,[]]],null],\"selected\"],null]],[8],[0,\"\\n  \"],[1,[28,\"tt\",[[28,\"get\",[[23,2,[]],[23,4,[]]],null]],[[\"translate\"],[[23,0,[\"hasTranslate\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/element-select/select-option/template.hbs"
    }
  });

  _exports.default = _default;
});