define("client/pods/components/websites/websites-editor/component", ["exports", "client/config/environment", "client/mixins/transporter"], function (_exports, _environment, _transporter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transporter.default, {
    applicationService: Ember.inject.service('application'),
    settingsService: Ember.inject.service('settings'),
    crud: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    translate: true,
    showWizard: false,

    init() {
      this.crud.addLists(this, ['websites/lists/subPanels', 'websites/lists/editorSubPanels', 'statuses', 'toggles/auto', 'toggles/onOff']);

      this._super(...arguments);

      this.set('productsList', []);
      this.set('config', _environment.default);
      this.set('editingPanels', []);
      const wizardEnabled = this.settings.settingsModel.getData('wizardEnable') || false;
      this.set('showWizard', wizardEnabled);
      const isWizard = this.isWizard;
      this.set('isWizard', isWizard);
    },

    isReadonly: Ember.computed('isEditing', 'saveTask.isRunning', function () {
      if (this.get('saveTask.isRunning')) {
        return true;
      }

      if (this.isEditing === true) {
        return false;
      }

      return true;
    }),
    hasSalesPersons: Ember.computed('model._data.salesPersons.[]', function () {
      // @TODO TEST
      const salesPersons = this.get('model._data.salesPersons');

      if (Ember.isEmpty(salesPersons)) {
        return false;
      }

      return salesPersons;
    }),
    actions: {
      assignSalesPerson(person) {
        const model = this.model;
        model.addSalesPerson(person); // set commission default to

        model.setData('commissionMethod', this.get('constants.commissionMethod.percentagePrice'));
      },

      unassignSalesPerson(person) {
        const model = this.model;
        model.removeSalesPerson(person);
      },

      toggleCommissionMethod() {
        const model = this.model;
        model.setData('commissionFlat', '');
        model.setData('commissionPercentage', '');
      }

    }
  });

  _exports.default = _default;
});