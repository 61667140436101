define("client/pods/components/products/panels-edit/component", ["exports", "client/mixins/crud", "ramda-extension", "ramda-adjunct", "ramda", "client/utils/nventor"], function (_exports, _crud, R_, RA, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    settings: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    adapterName: 'products',

    init() {
      this.setup();

      this._super(...arguments);
    },

    setup() {
      this.crud.addLists(this, ['products/lists/displaySubPanels']);
      const tab = this.tab;
      const model = tab.model;
      const dirty = this.crud.setupDirty({
        adapterName: this.adapterName,
        model,
        tab
      });
      this.set('dirty', dirty);
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, dirty.getData('name'));
    },

    displaySubPanels: Ember.computed('crud.lists.products-lists-displaySubPanels.[]', 'model._data.setType', function () {
      let panels = R_.dotPath('crud.lists.products-lists-displaySubPanels')(this) || [];
      let removeAll = false;
      const setType = this.get('model._data.setType');

      if (_nventor.default.isNilOrEmpty(setType)) {
        removeAll = true;
      }

      if (setType !== 'isSet' && setType !== 'isSetWithChoices' && setType !== 'hasVariants') {
        removeAll = true;
      }

      if (setType === 'isSet' || removeAll) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
      }

      if (setType === 'isSetWithChoices' || removeAll) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
      }

      if (setType === 'hasVariants' || removeAll) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
      }

      return panels;
    }),

    _display(model) {
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);
      const subPanels = this.displaySubPanels;
      let subTabOptions = subPanels.findBy('_data.value', activeSubTab.get?.('value'));

      if (RA.isNilOrEmpty(subTabOptions)) {
        subTabOptions = subPanels.findBy('_data.editingComponent', activeSubTab.get?.('component')) || _nventor.default.safeHeadOr({}, subPanels);
      }

      const tabOptions = {
        loadInstructions: {
          component: subTabOptions.get?.('_data.component'),
          label: subTabOptions.get?.('_data.label'),
          value: subTabOptions.get?.('_data.value')
        }
      };
      return this.onDisplay(model, {
        tabOptions
      });
    },

    afterUpdateRecord(saved) {
      this._display(saved);
    },

    afterCancelEditing(original) {
      this._display(original);
    }

  }, (_applyDecoratedDescriptor(_obj, "afterUpdateRecord", [_dec], Object.getOwnPropertyDescriptor(_obj, "afterUpdateRecord"), _obj), _applyDecoratedDescriptor(_obj, "afterCancelEditing", [_dec2], Object.getOwnPropertyDescriptor(_obj, "afterCancelEditing"), _obj)), _obj)));

  _exports.default = _default;
});