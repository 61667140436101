define("client/pods/components/comments/rules-sidebar/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CommentsHelpComponent extends _component.default {
    get master() {
      const master = this.args.sidebarOptions?.master;

      if (master) {
        return master;
      }

      return this.args.sidebarOptions?.type;
    }

  }

  _exports.default = CommentsHelpComponent;
});