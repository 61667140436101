define("client/pods/components/contacts/panels-display/component", ["exports", "client/mixins/crud", "client/utils/nventor", "ramda", "ramda-extension"], function (_exports, _crud, _nventor, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    adapterName: 'contacts',
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),

    init() {
      this._super(...arguments);

      const model = this.get('tab.model');
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, model.getData('name'));
      this.crud.addLists(this, ['taxes', 'statuses', 'contacts/lists/relationships', 'contacts/lists/tags', 'contacts/lists/panels', 'contacts/lists/allowed-payment-method', 'dispatch/lists/processes', 'documents/lists/types/purchases', 'documents/lists/types/sales']);
    },

    panelsList: Ember.computed('model.{hasSales,hasPurchases}', 'crud.lists.contacts-lists-panels.[]', function () {
      let panels = [];
      let allowedPaymentMethodForStatement = [];
      const model = this.model;
      const salesPaymentMethodKey = model.getData('salesPaymentMethodKey');
      const purchasesPaymentMethodKey = model.getData('purchasesPaymentMethodKey');
      const isAssociates = model.getData('isAssociates'); // @TODO: Make easier to change in the future
      // @TODO: make statement for other payment method. ignore for now

      panels = R_.dotPath('crud.lists.contacts-lists-panels')(this) || [];
      allowedPaymentMethodForStatement = R.pipe(R_.dotPathOr([], 'crud.lists.contacts-lists-allowed-payment-method'), R.pluck('_data'))(this);

      if (!R.isEmpty(panels)) {
        if (!R.includes(salesPaymentMethodKey, allowedPaymentMethodForStatement)) {
          panels = R.reject(R.pathEq(['_data', 'label'], 'customer statements'))(panels);
        }

        if (!R.includes(purchasesPaymentMethodKey, allowedPaymentMethodForStatement) && !isAssociates) {
          panels = R.reject(R.pathEq(['_data', 'label'], 'supplier reconciliations'))(panels);
        }
      }

      return panels;
    }),

    /**
     * dynamically determine classname for status tag.
     * green if active, otherwise gray for inactive
     */
    statusTagClassName: Ember.computed('model._data.status', function () {
      const status = this.get('model._data.status');

      if (status === this.get('constants.status.active')) {
        return 'list-tags__item--active-status';
      } else {
        return 'list-tags__item--inactive-status';
      }
    }),

    editPanel() {
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);
      const tabOptions = {};

      if (activeSubTab?.component) {
        tabOptions.loadInstructions = {
          component: activeSubTab.get('component')
        };
      }

      this.onEdit(this.model, {
        tabOptions,
        tab
      });
    }

  }, (_applyDecoratedDescriptor(_obj, "editPanel", [_dec], Object.getOwnPropertyDescriptor(_obj, "editPanel"), _obj)), _obj)));

  _exports.default = _default;
});