define("client/pods/channels/shop/campaigns/details/filters/model", ["exports", "client/pods/filters/model", "client/utils/nventor", "client/pods/filters/sort/model", "client/constants"], function (_exports, _model, _nventor, _model2, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsDetailsFiltersModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class ChannelsShopCampaignsDetailsFiltersModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "results", _descriptor, this);

      _initializerDefineProperty(this, "_data", _descriptor2, this);
    }

    defaults() {
      const baseDefaults = super.defaults() || {};
      return { ...baseDefaults,
        tags: [],
        setTypes: [],
        recurringFilters: [],
        hasImages: '',
        price: '',
        item: '',
        itemCode: '',
        page: 1,
        count: 20,
        query: '',
        match: ['all'],
        status: [],
        shopProductsSort: 'recommended',
        statuses: [_constants.default.productsStatus.draft, _constants.default.productsStatus.soldOut, _constants.default.productsStatus.preOrder, _constants.default.productsStatus.inactive, _constants.default.productsStatus.active],
        sort: [_nventor.default.createModel(_model2.default, {
          by: 'index',
          direction: 'ASC'
        })]
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "results", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_data", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChannelsShopCampaignsDetailsFiltersModel;
});