define("client/pods/components/elements/btns-prev-next/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kINyXmkt",
    "block": "{\"symbols\":[\"&default\",\"@isRunning\",\"@onSubmitPrev\",\"@onSubmitNext\"],\"statements\":[[4,\"if\",[[23,0,[\"showBtns\"]]],null,{\"statements\":[[0,\"  \"],[5,\"layouts/btns-seperator\",[],[[],[]]],[0,\"\\n\\n  \"],[5,\"elements/btn-prev\",[],[[\"@classNames\",\"@label\",\"@tooltipLabel\",\"@icon\",\"@disabled\",\"@isRunning\",\"@onSubmit\"],[[23,0,[\"btnClassNamesPrev\"]],[23,0,[\"prevLabel\"]],[23,0,[\"tooltipLabelPrev\"]],[23,0,[\"iconPrev\"]],[23,0,[\"disabledPrev\"]],[23,2,[]],[23,3,[]]]]],[0,\"\\n\\n  \"],[5,\"elements/btn-next\",[],[[\"@classNames\",\"@label\",\"@tooltipLabel\",\"@icon\",\"@disabled\",\"@isRunning\",\"@onSubmit\"],[[23,0,[\"btnClassNamesNext\"]],[23,0,[\"nextLabel\"]],[23,0,[\"tooltipLabelNext\"]],[23,0,[\"iconNext\"]],[23,0,[\"disabledNext\"]],[23,2,[]],[23,4,[]]]]],[0,\"\\n\\n  \"],[5,\"layouts/btns-seperator\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/btns-prev-next/template.hbs"
    }
  });

  _exports.default = _default;
});