define("client/pods/components/emails/campaigns/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kuCmHRIS",
    "block": "{\"symbols\":[\"subTab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\n\\t\"],[7,\"div\",false],[12,\"class\",\"tab-grid__header is-sticky-row-auto\"],[3,\"did-insert\",[[23,0,[\"applicationService\",\"setStickyTop\"]]]],[8],[0,\"\\n\\t\\t\"],[7,\"div\",true],[10,\"class\",\"row-container flex-row-space-between u-full-width\"],[8],[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flex-row-space-around\"],[8],[0,\"\\n\"],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flex-container-row flex-container--align-items-center\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[1,[28,\"menus/nav-menu\",null,[[\"menu\",\"classNames\",\"onSelect\"],[[23,0,[\"crud\",\"lists\",\"emails-lists-campaigns-menu\"]],\"is-pulled-right\",[28,\"fn\",[[23,0,[\"tabsManager\",\"triggerMenuAction\"]],[23,0,[]]],null]]]],false],[0,\"\\n\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__content\"],[8],[0,\"\\n\\n\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tabParent\",\"tab\",\"onCancel\",\"onEdit\",\"onDash\"],[[24,[\"tab\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"cancel\"],null],[28,\"action\",[[23,0,[]],\"edit\"],null],[28,\"action\",[[23,0,[]],\"activity\"],null]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/emails/campaigns/tab-container/template.hbs"
    }
  });

  _exports.default = _default;
});