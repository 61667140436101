define("client/pods/comments/service", ["exports", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CommentsService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, (_class = class CommentsService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "queue", _descriptor2, this);
    }

    qFetch(_ref, cb) {
      let {
        data,
        master,
        related = [],
        type,
        metaData = {},
        excludeFromFilters = [],
        debug = false
      } = _ref;

      if (master) {
        data.master = master;
      }

      if (type) {
        data.type = type;
      }

      if (RA.isNotNilOrEmpty(metaData)) {
        data = R.mergeRight(data, metaData);
      }

      data = R.omit(excludeFromFilters)(data);
      data.related = related || [];
      this.queue.pushObject({
        data,
        cb
      });
      const count = this.queue.length;
      setTimeout(() => {
        if (count > 0 && count === this.queue.length) {
          return this._fetchAllCommentsTask.perform({
            batch: this.queue,
            count,
            debug
          });
        }

        console.log('---skipped----');
      }, 200);
    }

    _fetchAllCommentsTask(_ref2) {
      var _this = this;

      let {
        batch,
        count,
        debug = false
      } = _ref2;
      return function* () {
        console.log('---fetching----');
        const commentsCollection = yield _this.crud.searchRecordsTask.perform({
          method: 'POST',
          adapterName: 'comments/collection',
          appendPath: '/batch/master',
          data: {
            batch: R.pluck('data')(batch)
          }
        });

        if (debug) {
          debugger; //eslint-disable-line
        }

        _this.queue = R.drop(count)(_this.queue);
        const cbs = R.pluck('cb')(batch);
        R.forEach(cb => {
          cb(commentsCollection);
        })(cbs);
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "queue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "_fetchAllCommentsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "_fetchAllCommentsTask"), _class.prototype)), _class));
  _exports.default = CommentsService;
});