define("client/pods/drag-drop-sort/service", ["exports", "ramda-adjunct"], function (_exports, _ramdaAdjunct) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let DragDropSortService = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class DragDropSortService extends Ember.Service {
    constructor() {
      super(...arguments);

      _defineProperty(this, "indexProp", '_data.index');

      _defineProperty(this, "checkingIndexProp", '_data.index');
    }

    dragStartItem(component, detail) {
      component.dragDetail = detail;
    }

    dragOverItem(component, detail, dragItem) {
      dragItem = dragItem || component.dragDetail;
      console.log('--dragItem', dragItem);

      if (_ramdaAdjunct.default.isNilOrEmpty(dragItem)) {
        console.log('no drag item!!');
        return '';
      }

      const checkingIndexProp = this?.checkingIndexProp || this?.indexProp || '_data.index';
      console.log('checkingIndexProp', checkingIndexProp);
      const dragIndex = dragItem.get(checkingIndexProp);
      console.log('dragIndex', dragIndex);
      const onOverItemIndex = detail.get(checkingIndexProp);
      console.log('onOverItemIndex', onOverItemIndex); // not sure if dragIndex is undefined should it become 'insert-above'?

      if (dragIndex === onOverItemIndex) {
        console.log('none');
        component.dropPosition = '';
      } else if (dragIndex < onOverItemIndex) {
        console.log('-below');
        component.dropPosition = 'insert-below';
      } else {
        console.log('-above');
        component.dropPosition = 'insert-above';
      }

      return component.dropPosition || '';
    }

    dragOverItemHorizontally(component, detail, dragItem) {
      dragItem = dragItem || component.dragDetail;
      const checkingIndexProp = this?.checkingIndexProp || this?.indexProp || '_data.index';
      const dragIndex = dragItem.get(checkingIndexProp);
      const onOverItemIndex = detail.get(checkingIndexProp);

      if (dragIndex === onOverItemIndex) {
        component.dropPosition = '';
      } else if (dragIndex < onOverItemIndex) {
        component.dropPosition = 'insert-right';
      } else {
        component.dropPosition = 'insert-left';
      }

      return component.dropPosition || '';
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "dragStartItem", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "dragStartItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOverItem", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "dragOverItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOverItemHorizontally", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "dragOverItemHorizontally"), _class.prototype)), _class));
  _exports.default = DragDropSortService;
});