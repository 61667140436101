define("client/pods/components/open-graph/component", ["exports", "@glimmer/component", "ramda"], function (_exports, _component, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let OpenGraphComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class OpenGraphComponent extends _component.default {
    removeOpenGraphImage() {
      this.args.model.set('_data.openGraph.imageUuid', '');
    }

    onOpenGraphImageChange(onCloseDialogAction, options, uploadsModel) {
      const uuid = R.pathOr('', ['_data', 'uuid'])(uploadsModel);
      const openGraph = R.pipe(R.pathOr({}, ['args', 'model', '_data', 'openGraph']), R.mergeLeft({
        imageUuid: uuid
      }))(this);
      this.args.model.setData('openGraph', openGraph);
      onCloseDialogAction();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "removeOpenGraphImage", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "removeOpenGraphImage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onOpenGraphImageChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onOpenGraphImageChange"), _class.prototype)), _class));
  _exports.default = OpenGraphComponent;
});