define("client/pods/components/products/inventory/inventory-editor-th/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vpaHN4tj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"th\",true],[8],[0,\"\\n  \"],[1,[28,\"tt\",[\"date\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"th\",true],[10,\"class\",\"has-text-left\"],[10,\"colspan\",\"2\"],[8],[0,\"\\n  \"],[1,[28,\"tt\",[\"adjustment\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"th\",true],[10,\"class\",\"has-text-left\"],[8],[0,\"\\n  \"],[1,[28,\"elements/element-icon\",null,[[\"icon\"],[\"fas fa-search\"]]],false],[0,\"\\n  \"],[1,[28,\"tt\",[\"code\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"th\",true],[10,\"class\",\"has-text-left\"],[8],[0,\"\\n  \"],[1,[28,\"tt\",[\"product_description\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"th\",true],[10,\"class\",\"has-text-left\"],[8],[0,\"\\n  \"],[1,[28,\"tt\",[\"description\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"th\",true],[10,\"class\",\"has-text-left\"],[8],[0,\"\\n  \"],[1,[28,\"tt\",[\"unit\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"th\",true],[10,\"class\",\"has-text-left\"],[8],[0,\"\\n  \"],[1,[28,\"tt\",[\"qty\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"th\",true],[10,\"class\",\"has-text-left u-no-wrap\"],[8],[0,\"\\n  \"],[1,[28,\"tt\",[\"price\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/inventory/inventory-editor-th/template.hbs"
    }
  });

  _exports.default = _default;
});