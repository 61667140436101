define("client/pods/components/channels/shop/shipping-zones/shipping-zones-editor/details-editor/free-shipping-rules-editor/rule-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct"], function (_exports, _component, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopShippingZonesShippingZonesEditorDetailsEditorFreeShippingRulesEditorRuleEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember.computed('args.{model._data.productTags.[],model._data.denyProductTags.[],rule._data.productTags.[]}'), _dec4 = Ember._action, _dec5 = Ember._action, (_class = class ChannelsShopShippingZonesShippingZonesEditorDetailsEditorFreeShippingRulesEditorRuleEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "allowedFreeShippingProductTags", _descriptor2, this);

      this.crud.addLists(this, ['products/lists/tags']);
    }

    get matchRule() {
      const freeShippingRule = this.args?.freeShippingRule;
      return freeShippingRule?._data?.matchRule || 'any';
    }

    get availableProductTags() {
      return this.args?.productTags || [];
    }

    get hasTagsOutsideOfZone() {
      const ruleProductTags = this.args?.rule?._data?.productTags;

      if (RA.isNilOrEmpty(ruleProductTags)) {
        return false;
      }

      const model = this.args?.model;
      const allowedProductTags = model?._data?.productTags || [];
      const deniedProductTags = model?._data?.denyProductTags || [];

      if (RA.isNotNilOrEmpty(allowedProductTags)) {
        return !R.equals(allowedProductTags, ruleProductTags);
      }

      if (RA.isNotNilOrEmpty(deniedProductTags)) {
        return R.pipe(R.filter(tagKey => {
          return R.includes(tagKey, ruleProductTags);
        }), RA.isNotNilOrEmpty)(deniedProductTags);
      }

      return false;
    }

    removeRule(freeShippingRule, rule) {
      const rules = freeShippingRule?._data?.rules || [];
      rules.removeObject(rule);
    }

    getAllowedFreeShippingProductTags() {
      const freeShippingRule = this.args?.freeShippingRule;
      const matchRule = freeShippingRule?._data?.matchRule || 'any';
      const allowedProductTags = this.crud.lists['products-lists-tags'] || [];
      const rule = this.args?.rule;
      const currentRuleTags = rule?._data?.productTags || [];
      let allowedFreeShippingProductTags = allowedProductTags || [];

      if (matchRule === 'only') {
        const freeShippingRule = this.args?.freeShippingRule;
        let usedProductTags = R.pipe(R.pathOr([], ['_data', 'rules']), R.map(rule => {
          return rule?._data?.productTags || [];
        }), R.flatten, R.uniq, R.reject(RA.isNilOrEmpty))(freeShippingRule);
        usedProductTags = R.difference(usedProductTags, currentRuleTags);
        allowedFreeShippingProductTags = R.reject(productTag => {
          const value = productTag?._data?._key;
          return R.includes(value, usedProductTags);
        })(allowedFreeShippingProductTags);
      }

      Ember.set(this, 'allowedFreeShippingProductTags', allowedFreeShippingProductTags);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "allowedFreeShippingProductTags", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hasTagsOutsideOfZone", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasTagsOutsideOfZone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeRule", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "removeRule"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getAllowedFreeShippingProductTags", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "getAllowedFreeShippingProductTags"), _class.prototype)), _class));
  _exports.default = ChannelsShopShippingZonesShippingZonesEditorDetailsEditorFreeShippingRulesEditorRuleEditorComponent;
});