define("client/pods/components/msgs/warning-msg/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['msgs__info-msg', 'msgs__info-msg--warning'],
    translate: true,
    hasMessage: true,
    translatedMessage: Ember.computed('msg', 'hasMessage', function () {
      let msg = '';

      if (this.hasMessage) {
        msg = this.msg;
      }

      if (msg) {
        if (this.translate) {
          msg = this.intl.t(msg);
        }
      }

      return msg;
    })
  });

  _exports.default = _default;
});