define("client/pods/components/bot/message-window/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FJWG3c3t",
    "block": "{\"symbols\":[],\"statements\":[[4,\"forms/form-row\",null,[[\"classNames\"],[\"has-text-right\"]],{\"statements\":[[0,\"  \"],[1,[28,\"elements/element-toggler\",null,[[\"label\",\"toggleValue\"],[\"bot\",[24,[\"isBotVisible\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"isBotVisible\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\"],[0,\"      \"],[1,[28,\"messages/tab-container/messages-window\",null,[[\"model\",\"isBot\",\"flows\",\"privateFlows\"],[[24,[\"model\"]],true,[24,[\"flows\"]],[24,[\"privateFlows\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \\n    \"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/bot/message-window/template.hbs"
    }
  });

  _exports.default = _default;
});