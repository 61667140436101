define("client/pods/transporter/lists/pickup-editor-extension/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _nventor, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        value: 'hct',
        label: 'hct',
        component: 'transporters/hct/pickup/editor-extension'
      }, {
        value: 't-cat',
        label: 't-cat',
        component: 'transporters/t-cat/pickup/editor-extension'
      }, {
        value: 'seven-eleven',
        label: 'seven-eleven',
        component: 'transporters/seven-eleven/pickup/editor-extension'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});