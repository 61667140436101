define("client/pods/application/lists/wizards/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/application/lists/wizards/model", "ramda"], function (_exports, _adapter, _adaptersNew, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ApplicationListsWizardsAdapter extends _adapter.default.extend(_adaptersNew.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "resourcePath", 'api/protected/app/lists/wizards');

      _defineProperty(this, "modelObj", _model.default);

      _defineProperty(this, "idParam", false);
    }

  }

  _exports.default = ApplicationListsWizardsAdapter;
});