define("client/pods/news/lists/menu/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let {
        data = {},
        appendPath,
        serverType
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const customData = {
        list: [{
          resource: 'api/protected/news/lists/menu/actions/dash',
          component: 'news/panels-dash',
          icon: 'fas fa-table',
          label: 'overview',
          value: 'overview',
          action: 'refreshDash',
          tabType: 'replaceSubTab'
        }, {
          resource: 'api/protected/news/lists/menu/actions/new',
          component: 'news/panels-new',
          icon: 'fas fa-plus',
          label: 'new',
          value: 'new',
          action: 'new',
          tabType: 'reuseSubTab'
        }]
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});