define("client/pods/components/admin/payments/card/panels-payments/payout-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cUMMNsl6",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[4,\"if\",[[24,[\"hasOutstanding\"]]],null,{\"statements\":[[4,\"helpers/is-loading\",null,[[\"isRunning\"],[[24,[\"onFetchPaymentsTask\",\"isRunning\"]]]],{\"statements\":[[4,\"elements/element-btn-with-modal-dialog\",null,[[\"hasSectionWrapper\",\"label\",\"classNames\",\"onSubmit\"],[true,\"payout\",\"is-primary\",[28,\"action\",[[23,0,[]],\"setPayoutDetails\"],null]]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"panel-grid\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"panel-grid__content\"],[8],[0,\"\\n\\n\"],[4,\"forms/form-input-row\",null,[[\"label\"],[\"amount\"]],{\"statements\":[[0,\"          \"],[1,[28,\"elements/element-input\",null,[[\"isRunning\",\"value\"],[[24,[\"onMarkAsPaidOutTask\",\"isRunning\"]],[24,[\"payoutAmount\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\\n\"],[4,\"layouts/panels/panel-footer\",null,null,{\"statements\":[[0,\"          \"],[1,[28,\"elements/element-btn\",null,[[\"classNames\",\"label\",\"isRunning\",\"onSubmit\"],[\"is-primary\",\"payout\",[24,[\"onMarkAsPaidOut\",\"isRunning\"]],[28,\"perform\",[[24,[\"onMarkAsPaidOutTask\"]],[24,[\"statement\"]],[24,[\"payoutAmount\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/admin/payments/card/panels-payments/payout-btn/template.hbs"
    }
  });

  _exports.default = _default;
});