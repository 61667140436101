define("client/pods/components/operator/change-logs/panels-display/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    crud: Ember.inject.service(),
    adapterName: 'operator/change-logs',

    init() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      const tab = this.tab;
      const model = tab?.model || {};
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, model.getData('name'));
    }

  });

  _exports.default = _default;
});