define("client/pods/components/home/wizard-container/wizard-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kzMBPqS1",
    "block": "{\"symbols\":[\"@pinItem\"],\"statements\":[[5,\"helpers/tab-link\",[],[[\"@hasLink\",\"@isHoverLink\",\"@tabOptions\"],[true,false,[28,\"hash\",null,[[\"component\",\"isFinished\",\"loadInstructions\",\"title\"],[[23,1,[\"_data\",\"component\"]],[23,1,[\"_data\",\"isFinished\"]],[23,1,[\"_data\",\"loadInstructions\"]],[23,1,[\"_data\",\"label\"]]]]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"wizard-card\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"wizard-card__title\"],[8],[0,\"\\n      \"],[7,\"span\",true],[10,\"class\",\"wizard-card__title-text\"],[8],[0,\"\\n        \"],[1,[28,\"tt\",[[23,1,[\"_data\",\"label\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"span\",true],[11,\"class\",[29,[\"tag \",[28,\"if\",[[23,1,[\"_data\",\"isFinished\"]],\"is-primary\",\"is-danger\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"_data\",\"isFinished\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"tt\",[\"wizard success\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"tt\",[\"wizard pending\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"wizard-card__content\"],[8],[0,\"\\n      \"],[1,[28,\"tt\",[[23,1,[\"_data\",\"description\"]]],null],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"wizard-card__footer\"],[8],[0,\"\\n      \"],[5,\"elements/element-btn\",[],[[\"@classNames\",\"@label\"],[\"button button--action\",\"start setting\"]]],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/home/wizard-container/wizard-card/template.hbs"
    }
  });

  _exports.default = _default;
});