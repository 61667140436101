define("client/pods/grapesjs/plugins/tabs/blocks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (editor, _ref) => {
    let {
      tabsBlock,
      typeTabs,
      categories,
      intl
    } = _ref;
    const bm = editor.BlockManager;
    tabsBlock && bm.add(typeTabs, {
      category: categories.complex,
      label: intl.t('tabs'),
      media: `
      <svg width="100%" height="100%" viewBox="0 0 4267 4267" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-miterlimit:1.5;">
          <g transform="matrix(4.16667,0,0,4.16667,0,0)">
              <g transform="matrix(1.76161,0,0,1.852,-15.7958,-702.631)">
                  <rect x="87.22" y="585.137" width="424.78" height="201.562" style="fill:rgb(235,235,235);stroke:black;stroke-width:19.37px;"/>
              </g>
              <g transform="matrix(37.1463,0,0,37.1463,68.1956,83.9336)">
                  <path d="M7,5.013C7,5.006 6.994,5 6.987,5L3.013,5C3.006,5 3,5.006 3,5.013L3,6.987C3,6.994 3.006,7 3.013,7L6.987,7C6.994,7 7,6.994 7,6.987L7,5.013Z"/>
              </g>
              <g transform="matrix(37.1463,0,0,37.1463,68.1956,83.9336)">
                  <path d="M12,5.013C12,5.006 11.994,5 11.987,5L8.013,5C8.006,5 8,5.006 8,5.013L8,6.987C8,6.994 8.006,7 8.013,7L11.987,7C11.994,7 12,6.994 12,6.987L12,5.013Z"/>
              </g>
              <g transform="matrix(37.1463,0,0,37.1463,68.1956,83.9336)">
                  <path d="M17,5.013C17,5.006 16.994,5 16.987,5L13.013,5C13.006,5 13,5.006 13,5.013L13,6.987C13,6.994 13.006,7 13.013,7L16.987,7C16.994,7 17,6.994 17,6.987L17,5.013Z"/>
              </g>
          </g>
      </svg>
      <div class="block-label">${intl.t('tabs')}</div>
    `,
      content: {
        type: typeTabs
      },
      ...tabsBlock
    });
  };

  _exports.default = _default;
});