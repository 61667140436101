define("client/pods/channels/shop/shipping-zones/lists/free-shipping-when/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let data = [{
        value: 'any',
        label: 'free_shipping_any'
      }, {
        value: 'only',
        label: 'free_shipping_only'
      }, {
        value: 'none',
        label: 'free_shipping_none'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});