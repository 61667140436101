define("client/pods/reports/commissions/data/adjustment/lists/filters/model", ["exports", "client/pods/filters/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        resultsProperty: 'results',
        page: 1,
        count: 100,
        query: '',
        match: ['all'],
        item: '',
        dispatchStatus: 'dispatched',
        statuses: [this.get('constants.status.active')],
        name: '',
        username: '',
        tags: [],
        status: 'final',
        segments: [],
        sort: [{
          by: 'dateZ',
          direction: 'ASC'
        }]
      };
    }

  });

  _exports.default = _default;
});