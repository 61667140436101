define("client/pods/components/extensions/apps/tw/hct/create-contact/component", ["exports", "client/mixins/crud", "client/mixins/search-contacts", "client/mixins/transporter-accounts", "ember-concurrency", "ramda-adjunct", "ramda"], function (_exports, _crud, _searchContacts, _transporterAccounts, _emberConcurrency, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, _searchContacts.default, _transporterAccounts.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    contactService: Ember.inject.service('contacts'),
    settingsService: Ember.inject.service('settings'),
    tagName: '',
    isLinkContact: false,
    contactsList: [],
    isDisable: Ember.computed('model._data.{hctUser,hctPassword}', function () {
      const hasUsername = R.pipe(R.path(['model', '_data', 'hctUser']), RA.isNotNilOrEmpty)(this);
      const hasPassword = R.pipe(R.path(['model', '_data', 'hctPassword']), RA.isNotNilOrEmpty)(this);

      if (hasPassword && hasUsername) {
        return false;
      }

      return true;
    }),
    allowCreate: Ember.computed('allowCreate', function () {
      const allowCreate = this.allowCreate;
      const isNilOrEmpty = RA.isNilOrEmpty(allowCreate);

      if (isNilOrEmpty) {
        return true;
      }

      return allowCreate;
    }),
    saveTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        model,
        onCloseDialogAction
      } = _ref;
      return function* () {
        let saved;
        model.setData('isTransporter', _this.get('constants.isTransporter.on'));
        model.setData('transporterExtension', 'hct');
        const isDirty = _this.model.isDirty && _this.model?._data?._key;

        if (_this.isLinkContact) {
          const adapterName = 'contacts';

          const dirty = _this.crud.setupDirty({
            adapterName,
            model
          });

          saved = yield _this.crud.updateRecordTask.perform({
            adapterName,
            model: dirty
          });
        } else if (isDirty) {
          const transporterModel = yield _this.crud.updateRecordTask.perform({
            adapterName: 'contacts',
            model
          });

          _this.set('model', transporterModel);
        } else if (_this.allowCreate) {
          model.setData('relationships', [_this.get('constants.contactsRelationship.supplier')]);
          saved = yield _this.crud.createRecordTask.perform({
            adapterName: 'contacts',
            model
          });
        } else {
          const data = _this.model._data;

          _this.model.set('_data', data);
        }

        onCloseDialogAction();

        if (_this.onLoadTransporterContact) {
          _this.onLoadTransporterContact(saved);
        }
      }();
    }).drop(),
    searchContactsTask: (0, _emberConcurrency.task)(function () {
      var _this2 = this;

      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        relationship: 'supplier'
      };
      return function* () {
        const contactsList = yield _this2.contactService.searchInputTask.perform(args);

        _this2.set('contactsList', contactsList);
      }();
    }),

    setup() {
      const hasExistingModel = RA.isNotNilOrEmpty(this.model);
      let model;

      if (hasExistingModel) {
        model = this.crud.setupDirty({
          adapterName: 'contacts',
          model: this.model
        });
      } else {
        model = this.crud.setupNewRecord({
          adapterName: 'contacts'
        });
      }

      this.set('model', model);
    },

    loadTransporter(model, transporter) {
      this.set('isLinkContact', true);
      this.set('transporter', transporter);
      model.setData('_id', transporter.getData('_id'));
      model.setData('_key', transporter.getData('_key'));
      model.setData('_rev', transporter.getData('_rev'));
      model.setData('code', transporter.getData('code'));
      model.setData('transporterAccounts', transporter.getData('transporterAccounts'));
      model.setData('defaultTransporterAccount', this.defaultTransporterAccountNumber);
      model.setData('hctUser', transporter.getData('hctUser'));
      model.setData('hctPassword', transporter.getData('hctPassword'));
    },

    unloadTransporter(model) {
      this.set('isLinkContact', false);
      this.set('transporter', '');
      model.setData('_id', '');
      model.setData('_key', '');
      model.setData('_rev', '');
      model.setData('code', '');
      model.setData('transporterAccounts', []);
      model.setData('defaultTransporterAccount', '');
      model.setData('hctUser', '');
      model.setData('hctPassword', '');
    },

    cancel(onCloseDialogAction) {
      onCloseDialogAction();
    },

    refreshModel(model) {
      const isDirty = R.pathEq(['model', 'isDirty'], true)(this);

      if (isDirty) {
        const dirtyModel = this.crud.setupDirty({
          adapterName: 'contacts/transporters',
          model
        });
        this.set('model', dirtyModel);
      } else {
        const proxyModel = model || R.propOr({}, 'model')(this);
        this.set('model', proxyModel);
      }
    },

    setDefaultPickupLocationPersonId() {
      let person = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const updateData = {
        personId: person?.personId || '',
        senderName: person?.name || '',
        senderTelephone: person?.telephone || '',
        senderAddress: person?.address || '',
        defaultTransporterAccount: person?.transporterAccount || '',
        defaultTransporterNotes: person?.transporterNotes || '',
        defaultTransporterPackageTemperature: person?.transporterPackageTemperature || ''
      };

      for (const updateDataKey in updateData) {
        this.model.set(`_data.${updateDataKey}`, updateData[updateDataKey]);
      }
    }

  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "loadTransporter", [_dec2], Object.getOwnPropertyDescriptor(_obj, "loadTransporter"), _obj), _applyDecoratedDescriptor(_obj, "unloadTransporter", [_dec3], Object.getOwnPropertyDescriptor(_obj, "unloadTransporter"), _obj), _applyDecoratedDescriptor(_obj, "cancel", [_dec4], Object.getOwnPropertyDescriptor(_obj, "cancel"), _obj), _applyDecoratedDescriptor(_obj, "refreshModel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "refreshModel"), _obj), _applyDecoratedDescriptor(_obj, "setDefaultPickupLocationPersonId", [_dec6], Object.getOwnPropertyDescriptor(_obj, "setDefaultPickupLocationPersonId"), _obj)), _obj)));

  _exports.default = _default;
});