define("client/pods/ifetch/service", ["exports", "client/config/environment", "ramda-adjunct", "ramda"], function (_exports, _environment, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IfetchService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class IfetchService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "guests", _descriptor, this);

      _initializerDefineProperty(this, "storage", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _defineProperty(this, "host", _environment.default.APP.serverUrl);
    }

    async iFetch() {
      let {
        url,
        path = '',
        method = 'GET',
        data
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const token = this.storage.get('token') || this.guests.getToken();
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        }
      };

      if (!url) {
        const headers = {
          Authorization: `Bearer ${token}`
        };
        options.headers = R.pipe(R.propOr({}, 'headers'), R.mergeLeft(headers))(options);
      }

      if (data && method === 'GET') {
        const searchParams = new URLSearchParams(data);
        path += `?${searchParams.toString()}`;
      } else if (data) {
        options.body = JSON.stringify(data);
      }

      url = url || `${this.host}/${path}`;

      try {
        const response = await fetch(url, options);

        if (!response.ok) {
          const error = await response.json();
          const message = this.intl.t(error.message);
          window.alert(message);
          return error;
        }

        const contentType = response.headers.get('Content-Type');

        if (contentType === 'application/pdf') {
          const filename = response.headers.get('content-disposition').split(';').find(n => n.includes('filename=')).replace('filename=', '').trim();
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } else if (contentType.includes('application/json')) {
          return response.json();
        } else if (contentType.includes('text/html')) {
          const data = await response.text();

          if (RA.isNotNilOrEmpty(data)) {
            // const newWindow = window.open('', '_blank', 'width=750,height=850')
            // if (!newWindow) {
            //   const message = this.intl.t('please allow popups to open')
            //   window.alert(message)
            //   return
            // }
            // newWindow.document.write(data)
            // newWindow.document.close()
            return data;
          }
        }

        return response;
      } catch (error) {
        window.alert(error.message);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "guests", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IfetchService;
});