define("client/pods/transporter/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model", "client/constants"], function (_exports, _adapter, _nventor, _adaptersNew, _model, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    findAll() {
      const data = [{
        label: 'other',
        value: ''
      }, {
        label: _constants.default.transporterExtensions.tCat,
        value: _constants.default.transporterExtensions.tCat
      }, {
        label: _constants.default.transporterExtensions.hct,
        value: _constants.default.transporterExtensions.hct
      }];

      if (this.settings.getProp('beta.sevenEleven')) {
        data.push({
          label: _constants.default.transporterExtensions.sevenEleven,
          value: _constants.default.transporterExtensions.sevenEleven
        });
      }

      if (this.settings.getProp('beta.payuniSevenEleven')) {
        data.push({
          label: _constants.default.transporterExtensions.payuniSevenEleven,
          value: _constants.default.transporterExtensions.payuniSevenEleven
        });
      }

      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});