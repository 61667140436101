define("client/pods/wizards/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/wizards/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class WizardsAdapter extends _adapter.default.extend(_adaptersNew.default) {
    constructor() {
      super(...arguments);

      _defineProperty(this, "resourcePath", 'api/protected/app/members-menu/collection');

      _defineProperty(this, "modelObj", _model.default);

      _defineProperty(this, "idParam", 'userKey');

      _defineProperty(this, "idPrefix", 'wizards/collection');
    }

  }

  _exports.default = WizardsAdapter;
});