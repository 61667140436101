define("client/pods/components/lists/list-item/component", ["exports", "client/mixins/translate", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _translate, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_translate.default, {
    tagName: 'div',
    classNameBindings: ['isSelected', 'item.isDeleted:is-deleted', 'hasAdded:disabled', 'isNotSelectable:disabled'],
    tabindex: 0,
    attributeBindings: ['tabindex'],
    isTag: false,
    translate: false,
    item: null,
    // the item model
    itemLabelKey: '_data.label',
    // key that points to item's label
    itemValueKey: '_data.value',
    // key that points to item's value
    itemLabel: Ember.computed('item', function () {
      return this.get('item.' + this.itemLabelKey);
    }),
    itemValue: Ember.computed('item', function () {
      return this.get('item.' + this.itemValueKey);
    }),
    isNotSelectable: Ember.computed('item', function () {
      return R.pathOr(false, ['item', 'isNotSelectable'])(this);
    }),
    hasAdded: Ember.computed('hasAddedItemKey', 'item', function () {
      return this.get('item.' + this.hasAddedItemKey);
    }),
    isSelected: Ember.computed('itemValueKey', 'selected', function () {
      const selected = this.selected;

      if (RA.isNilOrEmpty(selected)) {
        return false;
      }

      const item = this.item;
      const itemValueKey = this.itemValueKey;
      const itemValue = R_.dotPath(itemValueKey)(item);
      const selectedValue = R_.dotPath(itemValueKey)(selected);

      if (itemValue === selectedValue) {
        return true;
      }

      return false;
    }),
    isEditable: Ember.computed('allowEdit', 'item.{isOutOfDateOrDeleted,_data.isNotEditable}', function () {
      const allowEdit = this.allowEdit;
      const isNotEditable = this.get('item._data.isNotEditable');
      const isOutOfDateOrDeleted = this.get('item.isOutOfDateOrDeleted');

      if (isNotEditable) {
        return false;
      }

      if (allowEdit) {
        if (isOutOfDateOrDeleted) {
          return false;
        }

        return true;
      }

      return false;
    }),
    badge: Ember.computed('hasBadge', 'badgePath', function () {
      if (!this.hasBadge) {
        return '';
      }

      if (!this.badgePath) {
        return '';
      }

      const badgeValue = this.get('item.' + this.badgePath);
      return badgeValue;
    }),
    badgeClassNames: Ember.computed('hasBadge', 'badgeClassNamesPath', function () {
      if (!this.hasBadge) {
        return '';
      }

      if (!this.badgeClassNamesPath) {
        return '';
      }

      const classNames = this.get('item.' + this.badgeClassNamesPath);

      if (!classNames) {
        return this.badgeClassNamesDefault;
      }

      return classNames;
    }),
    // allowTranslate: computed('item._data.translate', function () {
    //
    //   let optionTranslate = this.get('option._data.translate');
    //
    //   if (optionTranslate == '' || optionTranslate == null) {
    //
    //     return this.get('translate');
    //   }
    //
    //   return optionTranslate;
    // }),
    shouldTranslate: Ember.computed('item._data.translate', 'translate', 'itemValue', function () {
      const itemValue = this.itemValue;
      const itemTranslate = this.get('item._data.translate');
      return this.shouldTranslateLabel(itemValue, itemTranslate);
    }),

    click() {
      if (this.hasAdded) {
        return false;
      }

      if (this.get('item.isDeleted')) {
        return false;
      }

      if (this.isNotSelectable) {
        return false;
      }

      if (this.onToggle) {
        const item = this.item; // const itemWrapper = this.get('itemWrapper');

        this.onToggle(item, this);
      }
    },

    getValueKey() {
      return this.key;
    },

    actions: {
      edit(e) {
        e.stopPropagation();
        const item = this.item;
        this.onEdit(item);
      }

    }
  });

  _exports.default = _default;
});