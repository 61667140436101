define("client/pods/components/froala-editor/component", ["exports", "ember-froala-editor/components/froala-editor", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _froalaEditor, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FroalaEditor = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('settings'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class FroalaEditor extends _froalaEditor.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "server", _descriptor2, this);

      _initializerDefineProperty(this, "storage", _descriptor3, this);

      _initializerDefineProperty(this, "settingsService", _descriptor4, this);

      _initializerDefineProperty(this, "stringifyContent", _descriptor5, this);

      _initializerDefineProperty(this, "editor", _descriptor6, this);

      _defineProperty(this, "locale", R.pipe(R.pathOr(['en_us'], ['intl', 'locale']), _nventor.default.safeHeadOr('en_us'), R.replace('-', '_'))(this));

      _defineProperty(this, "defaultOptions", {
        // width: '800',
        heightMin: 100,
        height: this.args.basicToolbar ? 100 : 600,
        attribution: false,
        // fontSizeDefaultSelection: '14', // doesnt work
        toolbarBottom: false,
        // toolbarInline: true,
        // toolbarVisibleWithoutSelection: true,
        fontFamilyDefaultSelection: 'Arial',
        fontSizeDefaultSelection: '14',
        fontSize: ['8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '36', '48', '60', '72', '96'],
        // Enable or disable file upload.
        fileUpload: false,
        // Enable or disable image upload.
        imageUpload: false,
        // Enable or disable video upload.
        videoUpload: false,
        quickInsertEnabled: false,
        // iconsTemplate: 'font_awesome_5',
        language: this.locale,
        toolbarSticky: false,
        toolbarStickyOffset: 0,
        // when toolbarBottom is true, should be 40
        charCounterCount: false,
        toolbarButtons: this.setToolbarButtons(),
        imageDefaultWidth: 600,
        imageEditButtons: ['imageReplace', 'imageAlign', 'imageCaption', 'imageRemove', 'imageLink', '|', 'linkOpen', 'linkEdit', 'linkRemove', '-', 'imageDisplay', 'imageStyle', 'imageAlt', 'imageSize'] // iframe: true,
        // iframeStyle: 'body{height:300px;overflow:auto;}'

      });

      _defineProperty(this, "externalOptions", this.args.options || {});

      _defineProperty(this, "options", R.mergeRight(this.defaultOptions, this.externalOptions));
    }

    /**
     * Event on Initialized
     * @link https://froala.com/wysiwyg-editor/docs/events#initialized
     * @param {FroalaEditor} editor - Editor Instance
     */
    'on-initialized'(editor) {
      // Set Left Align as Default
      try {
        editor.align.apply('left');
        this.editor = editor; // passing autofocus into options currently does not set autofocus.
        // temp fix

        this.toggleScrollListenerOnModal(editor, 'initialized');

        if (this.args.onInit) {
          this.args.onInit(this);
        }

        if (this.args?.options?.autofocus) {
          editor.el.focus();
        }

        const contentElementArray = editor.html.blocks();
        const element = contentElementArray[0];
        const froalaModalElement = element.closest('.modal-container__content');
        const froalaModalHtml = froalaModalElement?.outerHTML || '';
        const height = R.pathOr('100%', ['args', 'options', 'height'])(this);
        const logData = {
          before: editor.opts.height,
          after: height
        };
        const logErrorData = {
          errorName: 'froala',
          froalaEditor: {
            height: logData
          },
          froalaModalHtml
        };
        editor.opts.height = height;
        editor.size.refresh();
        const logsEnabled = R.pathEq(['args', 'options', 'logsEnabled'], true)(this);

        if (!logsEnabled) {
          return;
        }

        const logLimit = 5;
        const logLimitCountKey = 'logs_limit';
        const logLimitCount = this.storage.get(logLimitCountKey) || 0;

        if (logLimit && logLimitCount >= logLimit) {
          return;
        } else {
          const newLimitCount = logLimitCount + 1;
          this.storage.set(logLimitCountKey, newLimitCount);
        } // Log by a podKey
        // const podKey = this.settingsService.getProp('podKey')
        // if (podKey !== '885507327447') {
        //   return
        // }


        this.server.logError(logErrorData);
      } catch (error) {
        console.error('Error while resetting Froala height: ', error);
      }
    }

    setToolbarButtons() {
      let toolbarButtons;

      if (this.args.basicToolbar) {
        toolbarButtons = {
          moreText: {
            // List of buttons used in the  group.
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
            // Alignment of the group in the toolbar.
            align: 'left',
            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more
            // button.
            buttonsVisible: 6
          },
          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'],
            align: 'left',
            buttonsVisible: 4
          },
          moreRich: {
            buttons: ['insertLink'],
            align: 'left',
            buttonsVisible: 1
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'selectAll', 'html', 'help'],
            align: 'right',
            buttonsVisible: 2
          }
        };
      } else {
        toolbarButtons = {
          moreText: {
            // List of buttons used in the  group.
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],
            // Alignment of the group in the toolbar.
            align: 'left',
            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more
            // button.
            buttonsVisible: 3
          },
          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
            align: 'left',
            buttonsVisible: 3
          },
          moreRich: {
            buttons: ['insertLink', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly'],
            align: 'left',
            buttonsVisible: 3
          },
          moreMisc: {
            buttons: ['undo', 'redo', 'customOptions', 'print', 'selectAll', 'html', 'help'],
            align: 'right',
            buttonsVisible: 3
          }
        };
      }

      const hasFileBrowser = R.pathOr(false, ['hasFileBrowser'])(this.args);
      const hasVideoBrowser = R.pathOr(false, ['hasVideoBrowser'])(this.args);

      if (hasFileBrowser && !this.args.basicToolbar) {
        toolbarButtons = R.over(R.lensPath(['moreRich', 'buttons']), R.append('imageManager'))(toolbarButtons);
      }

      if (hasVideoBrowser && !this.args.basicToolbar) {
        toolbarButtons = R.over(R.lensPath(['moreRich', 'buttons']), R.append('insertVideo'))(toolbarButtons);
      }

      return toolbarButtons;
    }
    /**
     * Add or Remove event listener scroll, only when Froala is on a Modal
     * @param {FroalaEditor} editor - Editor Instance
     * @param {string} lifeCycle
     */


    toggleScrollListenerOnModal(editor, lifeCycle) {// let fnName = ''
      // const outerModal = editor?.$box?.closest('.modal-dialog__content')?.[0]
      // if (lifeCycle === 'initialized' && outerModal) {
      //   fnName = 'addEventListener'
      // } else if (lifeCycle === 'destroy' && outerModal) {
      //   fnName = 'removeEventListener'
      // } else {
      //   return null
      // }
      // outerModal[fnName]('scroll', () => editor.position.refresh())
    }

    showFileUploader() {
      // SAVE CURRENT CURSOR POSITION
      this.editor.selection.save();
      this.editor.popups.hideAll();
      let currentImage = this.editor.image?.get();

      if (RA.isNotNilOrEmpty(currentImage)) {
        currentImage = R.pipe(cImage => cImage.toArray(), R.head, cImage => {
          cImage.setAttribute('data-replace-id', _nventor.default.random.alpha(40));
          return cImage;
        })(currentImage);
      }

      this.args.showFileUploader(this, currentImage);
    }
    /**
     * Event on Destroy
     * @link https://froala.com/wysiwyg-editor/docs/events#destroy
     * @param {FroalaEditor} editor - Editor Instance
     */


    'on-destroy'(editor) {
      if (this?.isDestroyed === false) {
        this.toggleScrollListenerOnModal(editor, 'destroy');
      }
    }
    /**
     * Triggered after image was loaded in the Froala Rich Text Editor.
     * @link https://froala.com/wysiwyg-editor/docs/events#image.inserted
     * @param {FroalaEditor} editor - Editor Instance
     * @param {any} filesArray - Images array
     */


    'on-image-loaded'(editor, filesArray) {
      if (!editor) {
        // this is when images are loading and modal is closed too quickly
        return;
      }

      const file = R.path([0])(filesArray);

      if (file) {
        editor.selection.setAfter(file);
        const uuid = R.pathOr('', ['dataset', 'uuid'])(file);
        this.addImageToUploadedArray(editor, uuid);
      }
    }

    'on-file-inserted'(editor, filesArray) {
      if (!editor) {
        // this is when images are loading and modal is closed too quickly
        return;
      }

      const file = R.path([0])(filesArray);

      if (file) {
        editor.selection.setAfter(file);
        const uuid = R.pipe(R.propOr('', 'href'), R.replace('https://ucarecdn.com/', ''), R.replace('/', ''))(file);
        this.addImageToUploadedArray(editor, uuid);
      }
    }

    addImageToUploadedArray(editor, uuid) {
      if (!editor || !uuid) {
        // this is when images are loading and modal is closed too quickly
        return;
      }

      const uploaded = R.pathOr([], ['_uploaded'])(editor);
      const uploadedSet = new Set(uploaded);
      uploadedSet.add(uuid);
      editor._uploaded = Array.from(uploadedSet);
    }
    /**
     * Shows the image caption modal.
     *
     * @param {FroalaEditor} editor - The editor object.
     * @param {HTMLImageElement} currentImage - The current image to be displayed in the modal.
     *
     * @return {void}
     */


    showImageCaptionModal(editor, currentImage) {
      this.args.showImageCaptionModal(editor, currentImage);
    }

    getAllSelectedElements(editor) {
      const selectedRanges = editor.selection.ranges();
      const selectedElements = selectedRanges.reduce((acc, range) => {
        let container = range.commonAncestorContainer; // If the container is a text node, get its parent element

        if (container.nodeType === window.Node.TEXT_NODE) {
          container = container.parentElement;
        }
        /** @type {HTMLElement[]} */


        const elements = Array.from(container.getElementsByTagName('*'));

        if (RA.isNilOrEmpty(elements)) {
          return acc;
        }

        elements.forEach(element => {
          if (range.intersectsNode(element) && acc.indexOf(element) === -1) {
            acc.push(element);
          }
        });
        return acc;
      }, []);
      return selectedElements;
    }

    clearElementStyleAndAlignLeft(elements) {
      for (const element of elements) {
        const getChildren = element.querySelectorAll('*') || [];

        for (const child of getChildren) {
          child.removeAttribute('style');
        }

        element.setAttribute('style', 'text-align: left;');
      }
    }

    onAfterClearFormatting(editor) {
      try {
        const clearFormattingAll = this.args?.options?.clearFormattingAll === true;
        let elements;

        if (clearFormattingAll) {
          elements = editor.el.querySelectorAll('*') || [];
        } else {
          elements = this.getAllSelectedElements(editor);
        }

        if (RA.isNilOrEmpty(elements)) {
          return;
        }

        this.clearElementStyleAndAlignLeft(elements);
        editor.html.cleanEmptyTags();
      } catch (error) {
        console.error('Error while removing style: ', error);
      }
    }

    'on-commands-after'(editor, command
    /*param2*/
    ) {
      const onAfterCommandHash = {
        clearFormatting: this.onAfterClearFormatting
      };
      const onAfter = onAfterCommandHash[command];

      if (!onAfter) {
        return;
      }

      onAfter(editor);
    }

    'on-image-beforeRemove'(editor, imageSelector) {
      try {
        const imageArr = imageSelector.get();
        const image = imageArr[0];
        const parent = image.parentElement;
        const isFigure = parent?.nodeName === 'FIGURE';
        const isImageWrapper = parent.classList.contains('fr-img-wrap');

        if (isFigure && isImageWrapper) {
          parent.remove();
        }
      } catch (error) {
        console.error('Error while removing style: ', error);
      }
    }

    'on-paste-beforeCleanup'(editor, clipboardHtml) {
      const pasteAsPlainText = R.pathEq(['args', 'options', 'pasteAsPlainText'], true)(this);

      if (!pasteAsPlainText) {
        return;
      }

      const pasteWrapper = document.createElement('div');
      pasteWrapper.innerHTML = clipboardHtml;
      const elements = pasteWrapper.querySelectorAll('*') || [];
      this.clearElementStyleAndAlignLeft(elements);
      return pasteWrapper.innerHTML;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "server", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stringifyContent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "editor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, 'on-initialized', [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, 'on-initialized'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showFileUploader", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "showFileUploader"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'on-destroy', [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, 'on-destroy'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'on-image-loaded', [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, 'on-image-loaded'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'on-file-inserted', [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, 'on-file-inserted'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addImageToUploadedArray", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "addImageToUploadedArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showImageCaptionModal", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "showImageCaptionModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAfterClearFormatting", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onAfterClearFormatting"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'on-commands-after', [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, 'on-commands-after'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'on-image-beforeRemove', [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, 'on-image-beforeRemove'), _class.prototype), _applyDecoratedDescriptor(_class.prototype, 'on-paste-beforeCleanup', [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, 'on-paste-beforeCleanup'), _class.prototype)), _class));
  _exports.default = FroalaEditor;
});