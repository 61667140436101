define("client/pods/components/operator/change-logs/panels-dash/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    search: Ember.inject.service(),
    crud: Ember.inject.service(),
    adapterName: 'operator/change-logs',
    filtersAdapterName: 'operator/change-logs/filters',

    init() {
      this._super(...arguments);

      this.tabsManager.setHeader(this.tabParent, '');
      this.crud.addLists(this, ['publicStatuses']);
    }

  });

  _exports.default = _default;
});