define("client/pods/base/model", ["exports", "ramda", "ramda-adjunct", "ramda-extension", "client/mixins/big", "client/mixins/data", "client/mixins/date", "client/mixins/statuses", "client/mixins/settings", "client/utils/nventor"], function (_exports, R, RA, R_, _big, _data, _date, _statuses, _settings, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Object.extend(_big.default, _data.default, _date.default, _settings.default, _statuses.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_obj = {
    _data: null,
    incompleteItems: null,
    isDirtyMessage: 'not yet saved',
    syncCount: 1,
    init: function () {
      this._super(...arguments);

      this.set('incompleteItems', []);
      this.set('_data', {});
      this.set('childAdapters', {});
    },

    setupWithDefaults() {
      let originalData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let defaultData;

      if (typeof this.defaults === 'function') {
        defaultData = this.defaults();
      } else {
        defaultData = this.defaults || {};
      }

      Object.keys(defaultData).map(key => {
        const value = R.path(['_data', key])(this);

        if (R.isNil(value)) {
          this.setData(key, defaultData[key]);
        }

        return key;
      });
      Object.keys(defaultData).map(key => {
        const value = R.path([key])(originalData);

        if (R.isNil(value)) {
          originalData[key] = defaultData[key];
        }

        return key;
      });
      return originalData;
    },

    // hasDirtyData () {
    //   // @NOTE: this cannot work as string '999' becomes 999 number on exit of input
    //   // const isDirty = this.get('isDirty')
    //   // if (isDirty) {
    //   //   const originalSerialized = this.get('originalSerialized') || ''
    //   //   const currentData = this.serialize() || {}
    //   //   let currentDataString = ''
    //   //   try {
    //   //     currentDataString = JSON.stringify(currentData)
    //   //   } catch (e) {
    //   //     currentDataString = ''
    //   //   }
    //   //   console.log('currentDataString')
    //   //   console.log(currentDataString)
    //   //   console.log('originalSerialized')
    //   //   console.log(originalSerialized)
    //   //   if (currentDataString !== originalSerialized) {
    //   //     return true
    //   //   }
    //   // }
    //   return true
    // },
    getDefaultProps() {
      const defaults = this.defaults();
      return R.keys(defaults);
    },

    isRead: Ember.computed('isReadDotPath', '_data.notificationStatus', 'synced', function () {
      const isReadDotPath = this.isReadDotPath || '_data.notificationStatus';

      if (this?.read === true) {
        // @TODO: remove once broad cast alerts are refactored
        return true;
      }

      const isRead = R_.dotPath(isReadDotPath)(this);

      if (isRead === true) {
        return true;
      }

      if (isRead === 'true') {
        return true;
      }

      if (isRead === 'read') {
        return true;
      }

      return false;
    }),
    isOutOfDateOrDeleted: Ember.computed('isDeleted', 'isOutOfDate', function () {
      const isOutOfDate = this.isOutOfDate;
      const isDeleted = this.isDeleted;

      if (isOutOfDate || isDeleted) {
        return true;
      }

      return false;
    }),

    hasRequiredData() {
      const requiredAttrs = this.requiredAttrs || [];
      return R.pipe(R.propOr({}, '_data'), R.pick(requiredAttrs), R.values, R.reject(_nventor.default.isNilOrEmpty), R.length, R.equals(requiredAttrs.length))(this);
    },

    /**
     * clear incomplete data properties.
     * eg. when you have an array of child details. remove incomplete children
     */
    _clearIncomplete(prop) {
      let array = this.getData(prop);
      array = R.filter(childModel => {
        return childModel.hasRequiredData();
      })(array);
      this.setData(prop, array);
    },

    reset() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let defaults = this.defaults();
      const adhocResetExcludes = R.propOr([], 'resetExcludes')(options);

      if (RA.isNotNilOrEmpty(adhocResetExcludes)) {
        defaults = R.omit(adhocResetExcludes)(defaults);
      }

      const resetExcludes = R.propOr([], 'resetExcludes')(this);

      if (RA.isNotNilOrEmpty(resetExcludes)) {
        defaults = R.omit(resetExcludes)(defaults);
      }

      const dynamicFilterKeys = R.propOr([], 'dynamicFilterKeys')(this);

      if (RA.isNotNilOrEmpty(dynamicFilterKeys)) {
        defaults = R.omit(dynamicFilterKeys)(defaults);
      }

      R.mapObjIndexed((value, key) => {
        this.setData(key, value);
      })(defaults);
    },

    // setup (data) {
    //   this.populate(data)
    // },
    isModel: true,

    // isModel: function (obj) {
    //   if (obj._data != null) {
    //     return true
    //   }
    //   return false
    // },
    getAdapter(adapterName) {
      // eg 'application/lists/main'
      return Ember.getOwner(this).lookup(`adapter:${adapterName}`);
    },

    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let attrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // may be overridden
      this.setProperties(attrs);
      this.set('_data', data);
      this.setupWithDefaults();
      this.set('_attrs', Object.keys(attrs));
      this.updateSynced();

      this._super(...arguments);
    },

    populatePartial() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let attrs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      // may be overridden
      R.mapObjIndexed((value, key) => {
        this.setData(key, value);
      })(data);
      R.mapObjIndexed((value, key) => {
        this.set(key, value);
      })(attrs);
      this.updateSynced();

      this._super(...arguments);
    },

    updateSynced() {
      const synced = Math.random();
      Ember.set(this, 'synced', synced);
    },

    serialize(options) {
      const exclude = R.propOr([], 'exclude', options);
      let serialized = R.clone(this._serialize(this._data));
      R.forEach(field => {
        serialized = R.dissoc(field)(serialized);
      })(exclude);
      return serialized;
    },

    _serialize(data) {
      if (!R.is(Array, data) && !R.is(Object, data)) {
        if (data == null) {
          return '';
        }

        return data;
      }

      if (R.is(Array, data)) {
        return this._serializeArray(data);
      }

      return this._serializeObject(data);
    },

    _serializeArray(data) {
      data = data.filter(item => item != null);
      return data.map(item => {
        let serialized;

        if (item._data) {
          serialized = this._serialize(item._data);
        } else {
          serialized = this._serialize(item);
        }

        if (serialized._data) {
          return serialized._data;
        }

        return serialized;
      });
    },

    _serializeObject(data) {
      const newData = {};
      Object.keys(data).map(key => {
        const value = data[key];

        if (R.is(Array, value)) {
          newData[key] = this._serializeArray(value);
          return key;
        }

        if (R.prop('_data', value)) {
          newData[key] = this._serialize(value._data);
          return key;
        }

        newData[key] = this._serialize(value);
        return key;
      });
      return newData;
    },

    // isComplete: Ember.computed('incompleteItems.[]', function () {
    //
    //   const incompleteItems = this.get('incompleteItems');
    //
    //   if (incompleteItems.length > 0) {
    //
    //     return false;
    //   }
    //
    //   return true;
    // }),
    addToIncomplete(key, hasIncomplete) {
      if (hasIncomplete) {
        this._addIncomplete(key);
      } else {
        this._removeIncomplete(key);
      }
    },

    _addIncomplete(key) {
      const incompleteItems = this.incompleteItems;
      const found = incompleteItems.findBy('key', key);

      if (!found) {
        incompleteItems.pushObject({
          key: key
        });
      }
    },

    _removeIncomplete(key) {
      const incompleteItems = this.incompleteItems;
      const found = incompleteItems.findBy('key', key);

      if (found) {
        incompleteItems.removeObject(found);
      }
    },

    /**
     * sets validation rules
     * @param {string} rule - the name of the rules to use for validation on the server
     */
    setRule: function (rule) {
      this.setData('rule', rule);
    },
    getRule: function () {
      return this.getData('rule');
    },
    setAdapter: function (name) {
      this.set('adapter', name);
    },

    /**
     * wrapper for get
     * @param  {string} key
     * @return {mixed}
     */
    getData: function (key, defaultValue) {
      key = '_data.' + key; // return nventor.confirm.get(this.get('_data'), key, defaultValue)

      const data = this.get(key);

      if ((data == null || data === '') && defaultValue != null) {
        return defaultValue;
      }

      if (data == null) {
        return '';
      }

      return data;
    },

    setData(key, value) {
      // if (value == null) {
      //   console.log(`!!!!! set data for key: ${key} is undefined/null. value: ${value}`)
      // }
      if (!R.startsWith('_data.')(key)) {
        key = '_data.' + key;
      }

      try {
        Ember.set(this, key, value);

        if (R.is(Object, value) || R.is(Array, value)) {
          this.updateSynced();
        }
      } catch (error) {
        console.error(key);
        console.error(error);
      }
    },

    getOrSetData(key, setValue) {
      // @TODO: test
      var data = this.getData(key);

      if ((data == null || data === '') && setValue != null) {
        this.setData(key, setValue);
        return this.getData(key);
      }

      return data;
    },

    /**
     * get but will set with provided default if none exists
     */
    getAndSet(key, defaultValue) {
      // @TODO: test
      var data = this.get(key);

      if (data == null && defaultValue != null) {
        this.set(key, defaultValue);
        return this.get(key);
      }

      return data;
    },

    getDataInt() {
      return parseInt(this.getData(...arguments)) || 0;
    },

    _transformUsers(data) {
      return R.pipe(R.propOr([], 'users'), R.reject(RA.isNilOrEmpty), R.map(data => {
        return this.dataManager.setPartialRecord({
          adapterName: 'users',
          data
        });
      }))(data);
    },

    _transformRelated(data) {
      return R.pipe(R.propOr([], 'related'), R.reject(RA.isNilOrEmpty), R.map(data => {
        return this.dataManager.setAsRecord({
          adapterName: 'workflows/related',
          data
        });
      }))(data);
    },

    addRelated() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data = data || {};
      const related = this.getOrSetData('related', []); // get last index

      let index = 0;
      const last = related.get('lastObject');

      if (RA.isNotNilOrEmpty(last)) {
        index = last?._data?.index + 1;
      }

      data.index = index;
      const model = this.dataManager.setAsRecord({
        adapterName: 'workflows/related',
        data
      });
      related.pushObject(model);
      this.setData('related', related);
      return model;
    },

    removeRelated(relatedModel) {
      const related = this.getData('related') || [];
      related.removeObject(relatedModel);
    },

    loadSelected(_ref, selected) {
      let {
        props
      } = _ref;
      R.mapObjIndexed((toProp, fromProp) => {
        const fromValue = selected.getData(fromProp);
        this.set(toProp, fromValue);
      })(props);
    },

    unloadSelected(_ref2, selected) {
      let {
        props
      } = _ref2;
      R.mapObjIndexed((toProp, fromProp) => {
        this.set(toProp, '');
      })(props);
    }

  }, (_applyDecoratedDescriptor(_obj, "updateSynced", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateSynced"), _obj), _applyDecoratedDescriptor(_obj, "setData", [_dec2], Object.getOwnPropertyDescriptor(_obj, "setData"), _obj), _applyDecoratedDescriptor(_obj, "getOrSetData", [_dec3], Object.getOwnPropertyDescriptor(_obj, "getOrSetData"), _obj), _applyDecoratedDescriptor(_obj, "addRelated", [_dec4], Object.getOwnPropertyDescriptor(_obj, "addRelated"), _obj), _applyDecoratedDescriptor(_obj, "removeRelated", [_dec5], Object.getOwnPropertyDescriptor(_obj, "removeRelated"), _obj), _applyDecoratedDescriptor(_obj, "loadSelected", [_dec6], Object.getOwnPropertyDescriptor(_obj, "loadSelected"), _obj), _applyDecoratedDescriptor(_obj, "unloadSelected", [_dec7], Object.getOwnPropertyDescriptor(_obj, "unloadSelected"), _obj)), _obj)));

  _exports.default = _default;
});