define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/recurring-schedules-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3+ieucLJ",
    "block": "{\"symbols\":[\"formInitData\",\"@wizardContainerComponent\",\"@resourceList\",\"@tab\",\"@documentationIsMinimized\",\"@outerFormInitData\",\"@model\",\"@isReadonly\",\"@isAutomations\"],\"statements\":[[4,\"if\",[[23,7,[\"_data\",\"itemKey\"]]],null,{\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"pod/get\",[\"beta.allowRecurringSchedules\"],null],[28,\"pod/get\",[\"features.allowRecurringSchedules\"],null]],null]],null,{\"statements\":[[4,\"unless\",[[23,9,[]]],null,{\"statements\":[[0,\"      \"],[5,\"forms/form-container\",[],[[\"@containerId\",\"@hasColumns\",\"@formInitData\",\"@isUserAllowed\",\"@tab\"],[\"recurringSchedules\",false,[28,\"if\",[[23,6,[]],[23,6,[]],[28,\"hash\",null,[[\"documentationResource\",\"documentationIsMinimized\",\"tab\",\"resourceList\",\"wizardContainerComponent\"],[\"campaignsDetails\",[23,5,[]],[23,4,[]],[23,3,[]],[23,2,[]]]]]],null],true,[23,4,[]]]],{\"statements\":[[0,\"\\n        \"],[5,\"forms/form-group\",[],[[\"@tab\",\"@context\",\"@formInitData\",\"@title\"],[[23,4,[]],\"recurringSchedules_group\",[23,1,[]],\"recurring schedules\"]],{\"statements\":[[0,\" \\n          \"],[5,\"recurring/schedules-table\",[],[[\"@model\",\"@isReadonly\"],[[23,7,[]],[28,\"or\",[[28,\"contains\",[\"recurringSchedules\",[23,7,[\"_data\",\"productSync\"]]],null],[23,8,[]]],null]]]],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n      \"]],\"parameters\":[1]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-item-editor/recurring-schedules-editor/template.hbs"
    }
  });

  _exports.default = _default;
});