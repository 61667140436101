define("client/pods/components/extensions/delete-sub/delete-panels-display/editor-item/component", ["exports", "client/mixins/date", "client/mixins/search-contacts", "client/mixins/doc-contacts", "client/mixins/transporter"], function (_exports, _date, _searchContacts, _docContacts, _transporter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, _docContacts.default, _searchContacts.default, _transporter.default, {
    contactsList: null,
    tagName: '',

    init() {
      this._super(...arguments); // const model = this.get('model');
      // this.set('defaultHCTAccountKey', '04449890026');


      this.set('fetchHCTRegionDataOnInit', true);
      this.set('contactsList', []);
    },

    hasDiscount: Ember.computed('model._data.discount', function () {
      if (this.get('model._data.discountAmount') === '0' || this.get('model._data.discountAmount') === '-0' || this.get('model._data.discountAmount') === '') {
        return false;
      }

      return true;
    }),
    actions: {
      selectItem() {
        const model = this.model;

        if (model.getData('hasValidProducts')) {
          model.toggleProperty('isSelected');
        }
      },

      loadContactForEditing(contact) {
        // @TODO TEST
        const self = this;
        const model = this.model;
        self.set('selectedContact', contact);

        if (contact.getData('taxNumber') && !model.getData('taxNumber')) {
          model.setData('taxNumber', contact.getData('taxNumber'));
        }
      },

      unloadContactForEditing() {
        // @TODO: test
        const model = this.model;
        const empty = '';
        const emptyArray = [];
        this.set('selectedContact', empty);
        this.set('addressesList', emptyArray);
        model.setData('contact', empty);
        model.setData('contactKey', empty);
      },

      cancel() {}

    }
  });

  _exports.default = _default;
});