define("client/pods/components/products/panels-inventory/component", ["exports", "client/mixins/crud", "ember-concurrency"], function (_exports, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    inventoryFiltersAdapterName: 'products/inventory/adjustments/filters',
    count: 20,
    page: 1,
    errorMsg: Ember.computed(function () {
      return this.intl.t('error occurred please try again later');
    }),

    init() {
      this._super(...arguments);

      const inventoryFilters = this.search.setupFilters({
        adapterName: this.inventoryFiltersAdapterName
      });
      inventoryFilters.set('showFilters', true);
      Ember.set(this, 'inventoryFilters', inventoryFilters);
    },

    didInsertElement() {
      this._super(...arguments);

      this.fetchAdjustmentsTask.perform();
    },

    fetchAdjustmentsTask: (0, _emberConcurrency.task)(function* () {
      const inventoryFilters = this.inventoryFilters;
      const searchSettings = {
        adapterName: 'products/inventory/adjustments',
        resultsProperty: 'results',
        resultsToggle: '',
        filters: inventoryFilters
      }; // const adjustmentsData = yield this.searchRecordsTask.perform(searchSettings)

      yield this.crud.searchRecordsTask.perform(searchSettings);
    })
  });

  _exports.default = _default;
});