define("client/pods/components/ledgers/accounts/aging/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u7JOzms+",
    "block": "{\"symbols\":[\"subTab\",\"result\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\n\\t\"],[5,\"layouts/sidebar\",[],[[\"@isLeft\"],[true]],{\"statements\":[[0,\"\\n\"],[0,\"\\n\\t\\t\"],[5,\"layouts/sidebar/content\",[],[[],[]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[7,\"div\",true],[10,\"class\",\"u-results\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[2,\" list past 12 months \"],[0,\"\\n\\n\"],[4,\"lists/list-results\",null,[[\"list\",\"onSelect\"],[[24,[\"pastMonths\"]],[28,\"perform\",[[24,[\"displayByMonth\"]]],null]]],{\"statements\":[[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[28,\"tt\",[[23,2,[\"name\"]]],null],false],[0,\" \"],[1,[23,2,[\"yyyy\"]],false],[0,\"\\n\\n\"]],\"parameters\":[2]},null],[0,\"\\t\\t\\t\"],[9],[0,\"\\n\\t\\t\"]],\"parameters\":[]}],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__content\"],[8],[0,\"\\n\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"docType\",\"tabParent\",\"tab\",\"onReloadStatementOnDueDateChangeTask\",\"paymentDueDateZ\",\"paymentDueDate\",\"currentPeriod\",\"filters\",\"onRunningTask\"],[[23,0,[\"docType\"]],[24,[\"tab\"]],[23,1,[]],[23,0,[\"reloadStatementOnDueDateChangeTask\"]],[23,0,[\"paymentDueDateZ\"]],[23,0,[\"paymentDueDate\"]],[23,0,[\"currentPeriod\"]],[23,0,[\"agingFilters\"]],[23,0,[\"displayAgingTask\",\"isRunning\"]]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/ledgers/accounts/aging/tab-container/template.hbs"
    }
  });

  _exports.default = _default;
});