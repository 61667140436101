define("client/pods/components/channels/shop/campaigns/campaigns-editor/settings-editor/style-editor/component", ["exports", "ramda", "ramda-adjunct", "client/mixins/search-contacts", "client/mixins/crud", "client/mixins/transporter", "ember-concurrency-decorators", "ramda-extension", "client/utils/nventor"], function (_exports, R, RA, _searchContacts, _crud, _transporter, _emberConcurrencyDecorators, R_, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { task } from 'ember-concurrency'
  var _default = Ember.Component.extend(_crud.default, _searchContacts.default, _transporter.default, {
    crud: Ember.inject.service(),
    search: Ember.inject.service(),
    settings: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    tabsManager: Ember.inject.service(),
    contacts: Ember.inject.service(),
    constants: Ember.inject.service(),
    transporterService: Ember.inject.service('transporter'),
    transporterAccounts: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, ['contacts/lists/tags', 'dispatch/lists/processes', 'extensions/apps/tw/invoicing', 'extensions/apps/tw/convenience-stores/lists/brands', 'channels/shop/campaigns/lists/review-steps', 'channels/shop/campaigns/lists/statuses', 'channels/shop/campaigns/lists/variantSelectorStyle', 'channels/shop/campaigns/lists/cartBtnStyle', 'settings/checkout-date-method', 'channels/shop/campaigns/lists/noOfLines', 'channels/shop/campaigns/lists/productsOverview', 'channels/shop/campaigns/lists/productsImagesRatio', 'channels/shop/campaigns/lists/productsImagesSize', 'settings/imagesHoverFilter', 'settings/imagesHoverTransform', 'channels/shop/campaigns/lists/productsNameFontSize', 'products/lists/onSaleTagDisplayPosition', 'products/lists/onSaleTagDisplayStyle', 'products/lists/bigordrProductsPerRow', 'channels/shop/campaigns/lists/productsNameLineCount', 'channels/shop/campaigns/lists/products-tags-display-style', {
        name: 'workflows/lists/items/activities/templates',
        appendPath: 'authorization'
      }]);
    }

  });

  _exports.default = _default;
});