define("client/pods/components/products/products-editor/seo-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NgCc0Q8x",
    "block": "{\"symbols\":[\"formInitData\",\"@wizardContainerComponent\",\"@tab\",\"@resourceList\",\"@isReadonly\",\"@model\"],\"statements\":[[5,\"forms/form-container\",[],[[\"@containerId\",\"@formInitData\",\"@tab\"],[\"seo\",[28,\"hash\",null,[[\"documentationResource\",\"resourceList\",\"tab\",\"wizardContainerComponent\"],[\"products\",[23,4,[]],[23,3,[]],[23,2,[]]]]],[23,3,[]]]],{\"statements\":[[0,\"\\n  \"],[5,\"forms/form-group\",[],[[\"@context\",\"@formInitData\",\"@tab\",\"@title\"],[\"seo_group\",[23,1,[]],[23,3,[]],\"seo\"]],{\"statements\":[[0,\"\\n    \"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@formInitData\",\"@label\"],[\"keywords\",[23,1,[]],\"website_seo_keywords\"]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/element-input\",[],[[\"@readonly\",\"@value\",\"@context\",\"@model\",\"@tab\"],[[23,5,[]],[23,6,[\"_data\",\"seoKeywords\"]],\"seoKeywords\",[23,6,[]],[23,3,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@formInitData\",\"@label\"],[\"description\",[23,1,[]],\"website_seo_description\"]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/element-textarea\",[],[[\"@maxlength\",\"@readonly\",\"@value\",\"@context\",\"@model\",\"@tab\"],[\"300\",[22,\"isReadonly\"],[23,6,[\"_data\",\"seoDescription\"]],\"seoDescription\",[23,6,[]],[23,3,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[5,\"forms/form-input-row\",[],[[\"@formInitData\",\"@context\",\"@isRequired\",\"@label\"],[[23,1,[]],\"urlProductName\",false,\"product url\"]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/element-input\",[],[[\"@isRequired\",\"@readonly\",\"@value\",\"@context\",\"@model\",\"@tab\"],[false,[23,5,[]],[23,6,[\"_data\",\"urlProductName\"]],\"urlProductName\",[23,6,[]],[23,3,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/products-editor/seo-editor/template.hbs"
    }
  });

  _exports.default = _default;
});