define("client/pods/notifications/service", ["exports", "ember-concurrency-decorators", "ramda"], function (_exports, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NotificationsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class NotificationsService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "constants", _descriptor2, this);

      _initializerDefineProperty(this, "updates", _descriptor3, this);

      _initializerDefineProperty(this, "notificationsManager", _descriptor4, this);
    }

    get unreadCount() {
      const unread = this.unread || [];
      let unreadCount = unread.length || 0;

      if (this.updates.hasNewVersion) {
        unreadCount = unreadCount + 1;
      }

      return unreadCount;
    }

    *setupNotificationsManagerTask() {
      const notificationsManager = yield this.crud.findTask.perform({
        adapterName: 'notifications/manager'
      });
      Ember.set(this, 'notificationsManager', notificationsManager);
      return notificationsManager;
    }

    /**
     * register resource with notifications manager to display unread count
     */
    registerResource(_ref) {
      let {
        resource,
        notifications = []
      } = _ref;
      this.notificationsManager.registerResource({
        resource,
        notifications
      });
    }

    markAllBroadcastAlertsAsRead() {
      this.notificationsManager.markAllBroadcastAlertsAsRead();
    }

    markAsReadLocallyOnDestroy(notification) {
      this.notificationsManager.markAsReadLocallyOnDestroy(notification);
    }

    markAsReadTask(_ref2) {
      var _this = this;

      let {
        notification,
        resource
      } = _ref2;
      return function* () {
        const isRead = notification?.isRead || notification?.isReadSubmitted;

        if (isRead) {
          return;
        }

        let data = {};

        if (notification?._data) {
          data = notification.serialize();
        } else {
          data = notification;
        }

        data.status = _this.constants.notificationsStatus.read;
        data.resource = resource;
        const adapterName = 'notifications';

        const model = _this.crud.setupNewRecord({
          adapterName,
          data
        });

        notification.set('isReadSubmitted', true);
        return yield _this.crud.queueCreateRecordTask.perform({
          adapterName,
          model
        });
      }();
    }

    markAsReadBulkTask(_ref3) {
      var _this2 = this;

      let {
        notifications,
        resource
      } = _ref3;
      return function* () {
        const promises = R.map(notification => {
          _this2.markAsReadTask.perform({
            notification,
            resource
          });
        })(notifications);
        yield Ember.RSVP.all(promises);
        return;
      }();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "updates", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "notificationsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupNotificationsManagerTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupNotificationsManagerTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerResource", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "registerResource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markAllBroadcastAlertsAsRead", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "markAllBroadcastAlertsAsRead"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markAsReadLocallyOnDestroy", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "markAsReadLocallyOnDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markAsReadTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "markAsReadTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markAsReadBulkTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "markAsReadBulkTask"), _class.prototype)), _class));
  _exports.default = NotificationsService;
});