define("client/mixins/doc-list-by", ["exports", "client/mixins/date", "client/mixins/crud", "ember-concurrency"], function (_exports, _date, _crud, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  // import { isEmpty } from '@ember/utils'
  var _default = Ember.Mixin.create(_crud.default, _date.default, (_dec = Ember._action, (_obj = {
    listByTask: (0, _emberConcurrency.task)(function () {
      var _this = this;

      let {
        adapterName,
        appendPath,
        match,
        documentStatus,
        dispatchStatus,
        filters,
        query,
        notDispatchStatus,
        date,
        dateStart,
        dateEnd,
        showCampaignPeriodDataOnly,
        campaignPeriodDateStart,
        campaignPeriodDateEnd,
        isVoid,
        contact,
        contactKey,
        sourceKey,
        resultsProperty,
        resultsToggle
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let onHideDropdown = arguments.length > 1 ? arguments[1] : undefined;
      return function* () {
        filters.reset();

        if (match) {
          filters.setData('match', match);
        }

        if (sourceKey) {
          filters.setData('sourceKey', sourceKey);
        }

        if (contactKey) {
          filters.setData('contactKey', contactKey);
        } else if (contact) {
          filters.setData('contact', contact);
        }

        if (isVoid) {
          filters.setData('isVoid', true);
        }

        if (documentStatus) {
          if (!Ember.isArray(documentStatus)) {
            documentStatus = [documentStatus];
          }

          filters.setData('documentStatus', documentStatus);
          filters.setData('documentStatuses', documentStatus);
        }

        if (dispatchStatus) {
          if (!Ember.isArray(dispatchStatus)) {
            dispatchStatus = [dispatchStatus];
          }

          filters.setData('dispatchStatus', dispatchStatus);
        }

        if (notDispatchStatus) {
          if (!Ember.isArray(notDispatchStatus)) {
            notDispatchStatus = [notDispatchStatus];
          }

          filters.setData('notDispatchStatus', notDispatchStatus);
        }

        if (date) {
          filters.setData('match', ['dateZ']);
          filters.setData('query', date);
        }

        if (dateStart) {
          filters.setData('dateStart', dateStart);
        }

        if (dateEnd) {
          filters.setData('dateEnd', dateEnd);
        }

        if (showCampaignPeriodDataOnly) {
          filters.setData('showCampaignPeriodDataOnly', showCampaignPeriodDataOnly);
        }

        if (campaignPeriodDateStart) {
          filters.setData('campaignPeriodDateStart', campaignPeriodDateStart);
        }

        if (campaignPeriodDateEnd) {
          filters.setData('campaignPeriodDateEnd', campaignPeriodDateEnd);
        }

        const searchSettings = {
          adapterName,
          appendPath,
          resultsProperty,
          resultsToggle,
          query,
          filters
        };
        const results = yield _this.searchRecordsTask.perform(searchSettings);

        if (onHideDropdown) {
          onHideDropdown();
        }

        return results;
      }();
    }),
    listByDetailsTask: (0, _emberConcurrency.task)(function () {
      var _this2 = this;

      let {
        filters,
        adapterName,
        match,
        documentStatus,
        dispatchStatus,
        notDispatchStatus,
        date,
        dateStart,
        dateEnd,
        query = '',
        contact,
        contactKey,
        itemKey,
        sourceKey,
        resultsProperty,
        resultsToggle
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let onHideDropdown = arguments.length > 1 ? arguments[1] : undefined;
      return function* () {
        filters.setData('page', 1);

        if (match) {
          filters.setData('match', match);
        }

        if (sourceKey) {
          filters.setData('sourceKey', sourceKey);
        }

        if (itemKey) {
          filters.setData('itemKey', itemKey);
        }

        if (contactKey) {
          filters.setData('contactKey', contactKey);
        } else if (contact) {
          filters.setData('contact', contact);
        }

        if (documentStatus) {
          if (!Ember.isArray(documentStatus)) {
            documentStatus = [documentStatus];
          }

          filters.setData('documentStatus', documentStatus);
        }

        if (dispatchStatus) {
          if (!Ember.isArray(dispatchStatus)) {
            dispatchStatus = [dispatchStatus];
          }

          filters.setData('dispatchStatus', dispatchStatus);
        }

        if (notDispatchStatus) {
          if (!Ember.isArray(notDispatchStatus)) {
            notDispatchStatus = [notDispatchStatus];
          }

          filters.setData('notDispatchStatus', notDispatchStatus);
        }

        if (date) {
          filters.setData('match', ['dateZ']);
          query = date;
        }

        if (dateStart) {
          filters.setData('dateStart', dateStart);
        }

        if (dateEnd) {
          filters.setData('dateEnd', dateEnd);
        }

        const searchSettings = {
          adapterName,
          appendPath: '/details',
          resultsProperty,
          resultsToggle,
          query,
          filters
        };
        const results = yield _this2.searchRecordsTask.perform(searchSettings);

        if (onHideDropdown) {
          onHideDropdown();
        }

        return results;
      }();
    }),

    listByDate(onHideDropdown, dateZ, date) {
      return this.listByTask.perform({
        filters: this.filters,
        date: this.getMoment(date).format('YYYY-MM-DD'),
        dateZ,
        resultsToggle: 'resultsToggle'
      }, onHideDropdown);
    }

  }, (_applyDecoratedDescriptor(_obj, "listByDate", [_dec], Object.getOwnPropertyDescriptor(_obj, "listByDate"), _obj)), _obj)));

  _exports.default = _default;
});