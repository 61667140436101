define("client/pods/channels/shop/rewards/lists/sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let {
        data = {},
        appendPath,
        options,
        serverType
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const list = [{
        value: 'details',
        label: 'details',
        component: 'channels/shop/rewards/panels-display/sub-panels-details',
        tabType: 'reuseSubTab',
        resource: 'api/protected/channels/shop/rewards',
        editingComponent: 'channels/shop/rewards/rewards-editor/details-editor'
      }, {
        value: 'member levels',
        label: 'member levels',
        component: 'channels/shop/rewards/panels-display/sub-panels-member-levels',
        tabType: 'reuseSubTab',
        resource: 'api/protected/channels/shop/rewards',
        editingComponent: 'channels/shop/rewards/rewards-editor/member-levels-editor'
      }, {
        value: 'emails',
        label: 'emails',
        component: 'channels/shop/rewards/panels-display/sub-panels-emails',
        tabType: 'reuseSubTab',
        resource: 'api/protected/channels/shop/rewards',
        editingComponent: 'channels/shop/rewards/rewards-editor/emails-editor'
      }];
      const customData = {
        list
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }

  });

  _exports.default = _default;
});