define("client/pods/flows/lists/actions/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let {
        data = {}
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      data = [{
        label: 'flow_generate promo code',
        value: 'generatePromoCode'
      }, {
        label: 'flow_game',
        value: 'games'
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});