define("client/pods/components/channels/shop/campaigns/campaigns-item-editor/component", ["exports", "ramda", "client/mixins/crud", "ember-concurrency", "client/utils/nventor", "ramda-extension", "ramda-adjunct"], function (_exports, R, _crud, _emberConcurrency, _nventor, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    uploadcare: Ember.inject.service(),
    productsService: Ember.inject.service('products'),
    campaignsProducts: Ember.inject.service(),
    big: Ember.inject.service(),
    date: Ember.inject.service(),
    tagName: 'div',
    classNames: ['col-container'],
    isEditing: false,
    isNew: false,
    translate: true,
    allowUploads: true,
    activeTemplateIndex: null,
    productsFiltersAdapterName: 'products/filters',

    willDestroyElement() {
      if (this?.isDestroyed === false) {
        if (this?.model?.isDirty) {
          this.crud.cancelEditing({
            adapterName: this?.model?.adapterName,
            dirty: this?.model
          });
        }
      }
    },

    init() {
      this._super(...arguments);

      this.set('model', null);
      this.crud.addLists(this, ['users', 'toggles/auto', 'toggles/onOff', 'channels/shop/campaigns/deliveryDateSettings', 'channels/shop/campaigns/deliveryDateSettingsItems', 'channels/shop/campaigns/overSalesLimitedQtyStatuses', 'channels/shop/campaigns/lists/itemEditorSubPanels', 'products/lists/tags', 'products/lists/display-tags', 'products/lists/product-display-position', 'extensions/apps/tw/invoicingAuto', 'shipping/feeType', 'commissions/methods', 'statuses', 'products/lists/pageImportBtnSubPanels', 'products/lists/sync']);

      if (this.isAutomations) {
        this.crud.addLists(this, ['channels/shop/campaigns/automations/statuses']);
      } else {
        this.crud.addLists(this, ['channels/shop/campaigns/statuses']);
      }

      this.setupNew();
      this.setupEditing();

      if (this.isReadonly) {
        this.set('model', this.item);
      } // const subPanels = R_.dotPath('crud.lists.channels-shop-campaigns-lists-itemEditorSubPanels')(this) || []
      // if (RA.isNotNilOrEmpty(subPanels)) {
      //   // reset if there's still lingering tabs from add bulk (will causes error since add bulk are using product model instead of campaigns model)
      //   this.tabsManager.closeAllSubTabs(this?.tab?.subTabs || [])
      //   this.tabsManager.displaySubPanel(this, subPanels.get('firstObject'))
      // }

    },

    didInsertElement() {
      this._super(...arguments);

      if (!this.model) {
        return false;
      }

      const templateModel = this.model.getData('templates').get('firstObject');
      this.selectTemplate(this, templateModel);
    },

    setupNew() {
      const isNew = this.isNew;

      if (isNew) {
        const campaign = this.campaign;
        const adapterName = this.adapterName;
        const model = this.setupNewRecord({
          adapterName
        });
        const master = campaign.getData('_key');
        const overSalesLimitedQtyStatus = campaign.getData('overSalesLimitedQtyStatus');
        model.setData('master', master);
        model.setData('index', '');
        model.setData('overSalesLimitedQtyStatus', overSalesLimitedQtyStatus);

        if (this.isAutomations) {
          this._setupIsAutomations(model);
        } else {
          this._setupIsCampaignDetail(model, campaign);
        }

        this.set('model', model);

        if (this.loadProduct) {
          this.campaignsProducts.loadProductTask.perform(campaign, model, this.loadProduct);
        }
      }
    },

    headerTabs: Ember.computed('crud.lists.channels-shop-campaigns-lists-itemEditorSubPanels.[]', function () {
      return R_.dotPath('crud.lists.channels-shop-campaigns-lists-itemEditorSubPanels')(this) || [];
    }),

    _setupIsAutomations(model) {
      const automationModel = this.automationModel;
      const automationKey = automationModel?._data?._key;
      model.setData('automationKey', automationKey);
      const campaign = this.campaign;
      const campaignKey = campaign._data._key;
      model.setData('master', campaignKey);
    },

    _setupIsCampaignDetail(model, campaign) {
      const onSale = campaign.getData('onSale');
      model.setData('onSale', onSale);
    },

    setupEditing() {
      const isEditing = this.isEditing;
      const editingDefaults = this.editingDefaults;

      if (isEditing) {
        const model = this.item;
        const adapterName = this.adapterName;
        const dirty = this.setupDirty({
          adapterName,
          model: model
        });
        R.mapObjIndexed((value, key) => {
          dirty.setData(key, value);
        })(editingDefaults);

        if (R_.dotPathEq('_data.isSetWithChoices', true, dirty)) {
          R.forEach(choice => {
            const isSetPriceOnBundle = R.pathOr('', ['_data', 'isSetPriceOnBundle'], choice);

            if (!isSetPriceOnBundle) {
              choice.setData('isSetPriceOnBundle', false);
            }
          })(_nventor.default.ensurePathOr([], ['_data', 'choices'], dirty));
        }

        const noIsSetNew = R.pipe(R_.dotPath('_data.isSetNew'), RA.isNilOrEmpty)(dirty);

        if (R_.dotPathEq('_data.isSet', true, dirty) && noIsSetNew) {
          dirty.setData('isSetNew', false);
        }

        this.set('model', dirty);
      }
    },

    cancel() {
      const model = this.model;
      const adapterName = this.adapterName;
      this.crud.cancelEditing({
        adapterName,
        dirty: model
      });

      if (this.onCancel) {
        const originalModel = this.item;
        this.onCancel(originalModel);
      }
    },

    bulkDiscountSchedule: Ember.computed('model._data.bulkDiscountId', 'campaign._data.bulkDiscounts.@each.synced', function () {
      const model = this.model;
      const campaign = this?.campaign || {};

      if (RA.isNilOrEmpty(model)) {
        return {};
      }

      const bulkDiscountSchedule = model.getBulkDiscountSchedule({
        campaign
      });
      return {
        hasAnyActive: bulkDiscountSchedule.hasAnyActive,
        hasIsActive: bulkDiscountSchedule.hasIsActive,
        hasIsActiveNext: bulkDiscountSchedule.hasIsActiveNext,
        isActive: bulkDiscountSchedule.isActive,
        isActiveNext: bulkDiscountSchedule.isActiveNext
      };
    }),
    _isEditing: Ember.computed('isEditing', function () {
      // this is only needed for old components.
      // isEditing may be passed in from outside the component and set inside the component.
      // this will need to be refactored
      if (this.isEditing) {
        return true;
      }

      return false;
    }),
    isReadonly: Ember.computed('isEditing', 'isNew', 'saveTask.isRunning', function () {
      if (this.get('saveTask.isRunning')) {
        return true;
      }

      if (this.get('isEditing') === true) {
        return false;
      }

      if (this.get('isNew') === true) {
        return false;
      } // @TODO: QUICK FIX...


      if (this?.model?.isDirty === true) {
        return false;
      }

      return true;
    }),
    setDetailsData: Ember.computed('model._data.setDetails.@each._data', function () {
      const detailsData = R.pipe(R_.dotPathOr([], 'model._data.setDetails'), R.map(detail => {
        const data = R_.dotPathOr('', '_data')(detail);
        return data;
      }))(this);
      return detailsData;
    }),

    _filterByProductTags(zone, itemTags) {
      const allowedProductTagsInZone = R_.dotPathOr([], '_data.productTags')(zone);
      const deniedProductTagsInZone = R_.dotPathOr([], '_data.denyProductTags')(zone);
      let allowedItemsByProductTagsInZone = R_.containsAny(itemTags, allowedProductTagsInZone);

      if (RA.isNilOrEmpty(allowedProductTagsInZone)) {
        allowedItemsByProductTagsInZone = true;
      }

      let deniedItemsByProductTagsInZone = R_.containsAny(itemTags, deniedProductTagsInZone);

      if (RA.isNilOrEmpty(deniedProductTagsInZone)) {
        deniedItemsByProductTagsInZone = false;
      }

      return R.and(R.equals(true)(allowedItemsByProductTagsInZone), R.equals(false)(deniedItemsByProductTagsInZone));
    },

    // overSalesLimitedQtyStatusClass: computed('model._data.overSalesLimitedQtyStatus', function () {
    //   const status = this.get('model._data.overSalesLimitedQtyStatus')
    //   return this.get('productsService').getStatusClass(status)
    // }),
    afterReplaceRecordTask() {
      if (this.onDone) {
        this.onDone();
      }
    },

    afterCreateRecordTask() {
      if (this.onDone) {
        this.onDone();
      }
    },

    afterCancelEditing() {
      if (this.onDone) {
        this.onDone();
      }
    },

    updateContent(template) {
      this.model.setData('template', template);
    },

    selectTemplate() {
      let templateModel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const templates = this.model?._data?.templates || [];
      let index = templates?.indexOf(templateModel) || 0;

      if (index < 0) {
        index = 0;
      }

      this.set('activeTemplateIndex', index);
    },

    saveAsDraftTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        adapterName,
        model,
        onAfter
      } = _ref;
      return function* () {
        model.setData('status', _this.get('constants.productsStatus.draft'));
        yield _this.replaceRecordTask.perform({
          adapterName,
          model,
          onAfter
        });
      }();
    }),
    actions: {
      // replaceTemplate (onCloseDialogAction, template, templateImages = []) {
      //   const model = this.get('model')
      //   model.replaceTemplate(template, templateImages)
      //   onCloseDialogAction()
      // },
      // insertTemplate (onCloseDialogAction, template, templateImages = []) {
      //   const model = this.get('model')
      //   model.insertTemplate(template, templateImages)
      //   onCloseDialogAction()
      // },
      showBulkDiscountConflictsModal(saved) {
        if (!saved.getData('hasBulkDiscountConflictDetails')) {
          this.onDone();
        } else {
          const model = this.model;
          model.set('isShowBulkDiscountConflictDialogInDetailEditor', true);
        }
      },

      edit() {
        this.set('isEditing', true);
        this.setupEditing();
      },

      cancel() {
        this.cancel();
      },

      updateContent(template) {
        this.model.setData('template', template);
      },

      onSubmitDeleteProductDisplayTags(productDisplayTag) {
        const productDisplayTags = this.model.getData('productDisplayTags');
        productDisplayTags.removeObject(productDisplayTag);
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "updateContent", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateContent"), _obj), _applyDecoratedDescriptor(_obj, "selectTemplate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectTemplate"), _obj)), _obj)));

  _exports.default = _default;
});