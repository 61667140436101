define("client/pods/components/products/page-import-btn/component", ["exports", "ramda", "client/utils/nventor", "ember-concurrency", "ramda-extension"], function (_exports, R, _nventor, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    crud: Ember.inject.service(),
    tagName: 'span',

    init() {
      this._super(...arguments);

      const productsFilter = this.search.setupFilters({
        adapterName: 'products/filters',
        defaultFiltersData: {
          resultsProperty: 'productsList'
        }
      });
      const campaignsFilter = this.search.setupFilters({
        adapterName: 'channels/shop/campaigns/details/filters',
        defaultFiltersData: {
          resultsProperty: 'campaignsDetailsList'
        }
      });
      this.set('productsFilter', productsFilter);
      this.set('campaignsFilter', campaignsFilter);
    },

    setupPanels() {
      this.searchTask.perform();
    },

    pageImportBtnSubPanels: Ember.computed('crud.lists.products-lists-pageImportBtnSubPanels.[]', 'productsList.[]', 'campaignsDetailsList.[]', function () {
      const subPanels = R_.dotPath('crud.lists.products-lists-pageImportBtnSubPanels')(this) || [];

      if (!R.isEmpty(subPanels)) {
        const productsList = this.productsList || [];
        const campaignsDetailsList = this.campaignsDetailsList || [];
        return R.map(panel => {
          // if (panel._data.value === 'products') {
          //   panel.setData('badge', productsList.length)
          // }
          // if (panel._data.value === 'campaigns') {
          //   panel.setData('badge', campaignsDetailsList.length)
          // }
          return panel;
        }, subPanels);
      }

      return subPanels;
    }),
    searchTask: (0, _emberConcurrency.task)(function* () {
      const q = [];
      const query = R.propOr('', 'query')(this);
      this.productsFilter.set('_data.query', query);
      this.campaignsFilter.set('_data.query', query); // FILTER OUT EMPTY TEMPLATES

      this.productsFilter.set('_data.ignoreEmptyTemplates', true);
      this.campaignsFilter.set('_data.ignoreEmptyTemplates', true);
      q.push(this._searchTask.perform({
        adapterName: 'products',
        resultsProperty: 'productsList',
        query,
        filters: this.productsFilter
      }));
      q.push(this._searchTask.perform({
        adapterName: 'channels/shop/campaigns/details/templates',
        resultsProperty: 'campaignsDetailsList',
        query,
        filters: this.campaignsFilter
      }));
      yield (0, _emberConcurrency.all)(q);
    }),
    _searchTask: (0, _emberConcurrency.task)(function (_ref) {
      var _this = this;

      let {
        adapterName,
        query,
        filters,
        resultsProperty
      } = _ref;
      return function* () {
        const results = yield _this.crud.searchRecordsConcurrentlyTask.perform({
          adapterName,
          query,
          filters
        });

        _this.set(resultsProperty, results);

        return results;
      }();
    }).enqueue(),
    actions: {
      replaceTemplate(onCloseDialogAction, template) {
        let templateImages = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
        const model = this.model;
        model.replaceTemplate(template, templateImages);
        this.onUpdateContent(model.getData('template'));
        onCloseDialogAction();
      },

      insertTemplate(onCloseDialogAction, template) {
        let templateImages = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
        const model = this.model;
        model.insertTemplate(template, templateImages);
        this.onUpdateContent(model.getData('template'));
        onCloseDialogAction();
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "setupPanels", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupPanels"), _obj)), _obj)));

  _exports.default = _default;
});