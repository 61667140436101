define("client/pods/components/websites/panels-new/component", ["exports", "client/mixins/crud"], function (_exports, _crud) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_crud.default, {
    crud: Ember.inject.service(),
    adapterName: 'websites',
    init: function () {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      // set default campaign settings
      const tab = this.tab;
      const model = this.crud.setupNewRecord({
        adapterName: this.adapterName,
        tab
      });
      this.set('model', model);
      this.tabsManager.setHeader(this.tabParent, this.intl.t('new'));
    },

    actions: {
      onAfter(onCloseDialogAction, model) {
        onCloseDialogAction();
        this.onDisplay(model);
      },

      cancel() {
        return this.onDash();
      }

    }
  });

  _exports.default = _default;
});