define("client/pods/components/flows/tab-container/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "client/utils/nventor"], function (_exports, _component, _emberConcurrencyDecorators, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FlowsTabContainerComponent = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = class FlowsTabContainerComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "applicationService", _descriptor, this);

      _initializerDefineProperty(this, "crud", _descriptor2, this);

      _initializerDefineProperty(this, "search", _descriptor3, this);

      _initializerDefineProperty(this, "tabsManager", _descriptor4, this);

      _initializerDefineProperty(this, "query", _descriptor5, this);

      _initializerDefineProperty(this, "showResults", _descriptor6, this);

      _defineProperty(this, "adapterName", 'flows');

      _defineProperty(this, "filtersAdapterName", 'flows/filters');

      this.crud.addLists(this, [// 'filters/count',
      'flows/lists/menu', 'statuses']);
      const filters = this.search.setupFilters({
        adapterName: this.filtersAdapterName
      });
      this.filters = filters;
    }

    afterLoadLists() {
      this.loadTabTask.perform();
    }

    *loadTabTask() {
      const tab = this.args.tab;
      const loadInstructions = this.tabsManager.getLoadInstructions(tab);

      if (loadInstructions) {
        const model = loadInstructions.getData('model');

        if (model) {
          this.display(loadInstructions.getData('model'));
        } else {
          const id = loadInstructions.getData('find.id');
          const found = yield this.crud.findTask.perform({
            appendPath: id
          });
          this.display(found);
        }
      } else {
        this.dash({});
      }
    }

    dash(model, results) {
      this.showResults = false;
      const tab = this.args.tab;

      if (_nventor.default.isNilOrEmpty(results)) {
        const adapterName = this.adapterName;
        const filters = this.filters;
        filters.setData('query', '');
        this.crud.searchRecordsTask.perform({
          adapterName,
          filters
        });
      }

      const subTabOptions = {
        component: 'flows/panels-dash'
      };
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }
    /**
     * display the product
     * @param  {object} product model
     */


    display(model) {
      let additionalOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      this.showResults = false;
      const panelOptions = {
        component: 'flows/panels-display',
        model: model
      };
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
    }

    new(subTabOptions) {
      const tab = this.args.tab;
      this.tabsManager.replaceSubTab(tab, subTabOptions);
    }

    edit(model, additionalOptions) {
      this.allowQuickAdd = false;
      const tab = this.args.tab;
      const panelOptions = {
        component: 'flows/panels-edit',
        model: model
      };
      this.tabsManager.replaceSubTab(tab, panelOptions, additionalOptions);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "search", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "query", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showResults", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadTabTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadTabTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dash", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "dash"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "display", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "display"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "new", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "new"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype)), _class));
  _exports.default = FlowsTabContainerComponent;
});