define("client/pods/components/ledgers/accounts/aging/panels-display/component", ["exports", "client/mixins/date", "ramda"], function (_exports, _date, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_date.default, {
    crud: Ember.inject.service(),
    init: function () {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      const period = this.get('tab.period');
      this.set('period', period);
    },

    monthName: Ember.computed('currentPeriod.mm', function () {
      const pastMonths = this.getPastMonths(12);
      const mm = this?.currentPeriod?.mm;
      return R.pipe(R.find(R.propEq('mm', mm)), R.propOr('', 'name'))(pastMonths);
    })
  });

  _exports.default = _default;
});