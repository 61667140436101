define("client/pods/components/elements/element-btn-with-modal-dialog/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, (_obj = {
    tagName: 'span',
    tabindex: 0,
    translate: true,
    modalDialogTranslate: true,
    classNames: ['element-btn-with-modal-dialog'],
    modalDialogIsVisible: false,
    modalDialogClassName: 'has-text-left',
    modalDialogTitle: '',
    closeOnOverlayClick: true,
    isSetTypeModalVisible: false,
    hasToggleIcon: false,
    hasToggleIconRight: true,

    init() {
      this._super(...arguments);

      this.set('alreadyClosed', false);

      if (this.onInit) {
        this.onInit(this);
      }

      if (this.initialModalDialogIsVisible) {
        this.set('modalDialogIsVisible', this.initialModalDialogIsVisible);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      if (!this.modalDialogTitle) {
        this.set('modalDialogTitle', this.label);
        this.set('modalDialogTranslate', this.translate);
      }

      if (this.onDidInsert) {
        this.onDidInsert(this);
      }
    },

    showModalDialog() {
      this.set('modalDialogIsVisible', true);
    },

    submitTask: (0, _emberConcurrency.task)(function* () {
      if (this?.isDestroyed === false) {
        if (!this.disabled) {
          this.set('alreadyClosed', false);

          if (this.onSubmit) {
            yield this.onSubmit();
          }
        }
      }
    }).drop(),
    toggleAndSubmitTask: (0, _emberConcurrency.task)(function* () {
      if (this?.isDestroyed === false) {
        if (!this.disabled) {
          this.showModalDialog();
          this.set('alreadyClosed', false);

          if (this.onSubmit) {
            yield this.onSubmit();
          }
        }
      }
    }).drop(),
    actions: {
      closeDialog() {
        if (this?.isDestroyed === false) {
          this.set('modalDialogIsVisible', false);

          if (this.onClose) {
            if (!this.alreadyClosed) {
              this.set('alreadyClosed', true);
              return this.onClose();
            }
          }
        }
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "showModalDialog", [_dec], Object.getOwnPropertyDescriptor(_obj, "showModalDialog"), _obj)), _obj)));

  _exports.default = _default;
});