define("client/pods/components/documents/mixins/pre-submit", ["exports", "client/mixins/hct-fetch", "ramda"], function (_exports, _hctFetch, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_hctFetch.default, {
    dispatch: Ember.inject.service(),
    intl: Ember.inject.service(),

    async preSubmit(model, onSubmit) {
      const transporterExtension = R.pathOr('', ['_data', 'transporterExtension'])(model); // @note: Temp fix, dont validate tcat address
      // if (transporterExtension === this.dispatch.tCatExtension) {
      //   try {
      //     const validatedAddress = await this.dispatch.tCatValidateDocAddress.perform(model)
      //     R.forEachObjIndexed(
      //       (value, key) => {
      //         model.setData(key, value)
      //       }
      //     )(validatedAddress)
      //   } catch (err) {
      //     const errors = R.pathOr([], ['data', 'errors'])(err)
      //     this.set('errors', errors)
      //     return
      //   }
      // }

      if (model.getData('docType') === 'sales' && (model.getData('docName') === 'invoices' || model.getData('docName') === 'consignments') && model.getData('transporterExtension') === 'hct' && this.settings.getProp('features.extensions.tw_hct') && this.dontFetchRegionCode === false) {
        // show modal
        const transporterRegion = model.getData('transporterRegion');

        if (!transporterRegion) {
          // fetch
          await this.fetchHCTRegionDataTask.perform(model);
        }

        if (!this.isHCTIncomplete(model)) {
          return onSubmit();
        } else {
          alert(this.intl.t('incomplete telephone/address'));
        }
      } else {
        return onSubmit();
      }
    },

    actions: {
      preSubmit(model, onSubmit) {
        return this.preSubmit(model, onSubmit);
      }

    }
  });

  _exports.default = _default;
});