define("client/pods/components/channels/shop/campaigns/campaigns-editor/filters-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vL03f+ha",
    "block": "{\"symbols\":[\"dragAndDropManager\",\"tag\",\"@model\"],\"statements\":[[0,\"\\n\"],[7,\"div\",false],[3,\"did-insert\",[[28,\"perform\",[[23,0,[\"getAllProductTagsForFilterTask\"]]],null]]],[8],[0,\"\\n  \"],[5,\"helpers/drag-and-drop\",[],[[],[]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,3,[\"productTagsWithIndexesSorted\"]]],null,{\"statements\":[[4,\"if\",[[23,2,[\"_data\",\"label\"]]],null,{\"statements\":[[0,\"        \"],[5,\"helpers/drag-and-drop/drop-container\",[],[[\"@dropPosition\",\"@onDrop\",\"@onDragOver\"],[[23,1,[\"dropPosition\"]],[28,\"perform\",[[23,0,[\"reorderProductTagsWithIndexes\"]],[23,3,[\"productTagsWithIndexesSorted\"]],[23,2,[]],[23,1,[\"dragDetail\"]]],null],[28,\"fn\",[[23,1,[\"dragDropSort\",\"dragOverItem\"]],[23,1,[]],[23,2,[]],[23,1,[\"dragDetail\"]]],null]]],{\"statements\":[[0,\"\\n          \"],[5,\"helpers/drag-and-drop/drag-container\",[],[[\"@draggable\",\"@classNames\",\"@onDragStart\",\"@data\",\"@hasGrip\"],[true,\"has-grip\",[28,\"fn\",[[23,1,[\"dragDropSort\",\"dragStartItem\"]],[23,1,[]],[23,2,[]]],null],[28,\"hash\",null,[[\"index\"],[[23,2,[\"_data\",\"index\"]]]]],true]],{\"statements\":[[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"tag product-tags-filters\"],[8],[1,[23,2,[\"_data\",\"label\"]],false],[9],[0,\"\\n          \"]],\"parameters\":[]}],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[1]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/channels/shop/campaigns/campaigns-editor/filters-editor/template.hbs"
    }
  });

  _exports.default = _default;
});