define("client/pods/components/channels/shop/campaigns/filters-advanced/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChannelsShopCampaignsFiltersAdvancedComponent = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service('products'), _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class ChannelsShopCampaignsFiltersAdvancedComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "applicationService", _descriptor, this);

      _initializerDefineProperty(this, "productsService", _descriptor2, this);

      _initializerDefineProperty(this, "crud", _descriptor3, this);

      _initializerDefineProperty(this, "recurringFilters", _descriptor4, this);

      this.setup();
    }

    setup() {
      this.crud.addLists(this, ['filters/count', 'channels/shop/campaigns/lists/statuses']);
    }

    addSalesPerson(filters, person) {
      let salesPersons = filters?._data?.salesPersons || [];
      salesPersons.pushObject(person);
      salesPersons = R.uniq(salesPersons);
      filters.setData('salesPersons', salesPersons);
    }

    removeSalesPerson(filters, person) {
      let salesPersons = filters?._data?.salesPersons || [];
      salesPersons.removeObject(person);
      filters.setData('salesPersons', salesPersons);
    }

    get showStatusTags() {
      // @TODO: move to filter model
      const statuses = this?.crud?.lists?.statuses || [];
      const selectedStatuses = this.args.filters?._data?.statuses || [];

      if (selectedStatuses.length === 0) {
        return false;
      }

      if (selectedStatuses.length === statuses.length) {
        return false;
      }

      return true;
    }

    get showResetFilter() {
      // @TODO: move to filter model
      const filtersData = this?.args?.filters?._data;

      if (RA.isNilOrEmpty(filtersData)) {
        return false;
      }

      const statuses = filtersData.statuses;
      const hasImages = filtersData.hasImages;
      const item = filtersData.item;
      const itemCode = filtersData.itemCode;
      const price = filtersData.price;
      const setTypes = filtersData.setTypes;
      const tags = filtersData.tags;

      if (statuses.length !== 5 || tags.length !== 0 || setTypes.length !== 0 || RA.isNilOrEmpty(hasImages) !== true || item !== '' || itemCode !== '' || price !== '') {
        return true;
      }

      return false;
    }

    *updateFiltersTask() {
      // @TODO: how can this be improved?
      const filters = this.args.filters || {};
      Ember.set(filters, '_data', filters?._data || {}); // reset data

      return yield this.args.onSearchTask(...arguments);
    }

    *clearSelectedItemsTask() {
      yield this?.crud?.reloadSearchRecordsTask.perform({
        filters: this.args.filters,
        reset: true
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "productsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "recurringFilters", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addSalesPerson", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "addSalesPerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeSalesPerson", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "removeSalesPerson"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFiltersTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateFiltersTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearSelectedItemsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "clearSelectedItemsTask"), _class.prototype)), _class));
  _exports.default = ChannelsShopCampaignsFiltersAdvancedComponent;
});