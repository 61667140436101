define("client/pods/contacts/service", ["exports", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactsService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('date'), _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = class ContactsService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "search", _descriptor, this);

      _initializerDefineProperty(this, "dateService", _descriptor2, this);

      _initializerDefineProperty(this, "crud", _descriptor3, this);

      _initializerDefineProperty(this, "constants", _descriptor4, this);

      _initializerDefineProperty(this, "all", _descriptor5, this);

      _initializerDefineProperty(this, "customer", _descriptor6, this);

      _initializerDefineProperty(this, "supplier", _descriptor7, this);

      _initializerDefineProperty(this, "dataGroupsFilters", _descriptor8, this);
    }

    // @restartableTask
    // * findTask (contactKey) {
    //   // const filters = this.setupFilters()
    //   const results = yield this.crud.findTask.perform(
    //     {
    //       adapterName: 'contacts',
    //       appendPath: `/${contactKey}`
    //     }
    //   )
    //   return results
    // }
    searchContactsByGroupsTask(_ref) {
      var _this = this;

      let {
        filters,
        relationship = 'all',
        query = '',
        group,
        groupKey,
        hasEmailOnly,
        resultsProperty = '',
        component,
        resultsToggle = false,
        global = true,
        match = [],
        onAfter
      } = _ref;
      return function* () {
        // if (RA.isNilOrEmpty(filters)) {
        //   filters = this.dataGroupsFilters
        // }
        filters.setData('group', group);
        filters.setData('groupKey', groupKey);
        filters.setData('hasEmailOnly', hasEmailOnly);
        const results = yield _this.crud.searchInputTask.perform({
          adapterName: 'contacts/data/groups',
          filters,
          query,
          resultsProperty,
          component,
          resultsToggle,
          allowEmptyQuery: true
        });

        if (global && resultsProperty) {
          _this[resultsProperty] = results;
        }

        if (onAfter) {
          return onAfter(results);
        }

        return results;
      }();
    }

    setupFilters() {
      let defaultFiltersData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      defaultFiltersData = RA.isNotNilOrEmpty(defaultFiltersData) ? defaultFiltersData : {
        sort: [{
          by: 'name',
          direction: 'ASC'
        }],
        status: [this.constants.status.active]
      };
      return this.search.setupFilters({
        adapterName: 'contacts/filters',
        defaultFiltersData
      });
    }

    searchInputTask(_ref2) {
      var _this2 = this;

      let {
        relationship = 'all',
        query = '',
        filters,
        resultsProperty = '',
        component,
        resultsToggle = false,
        global = true,
        match = []
      } = _ref2;
      return function* () {
        const relationships = [];

        if (relationship === 'all') {
          relationships.push('customer');
          relationships.push('supplier');
        } else {
          relationships.push(relationship);
        }

        if (RA.isNilOrEmpty(filters)) {
          filters = _this2.setupFilters();
        }

        filters.setData('relationships', relationships);
        filters.setData('query', query);
        filters.setData('match', match);
        const results = yield _this2.crud.searchInputTask.perform({
          adapterName: 'contacts',
          filters,
          query,
          resultsProperty,
          component,
          resultsToggle,
          allowEmptyQuery: true
        }); // if (global && resultsProperty) {
        //   this[resultsProperty] = results
        // }
        // if (component) {
        //   component.set('contactsFilters', filters)
        // }

        return results;
      }();
    }

    loadContactProps(_ref3, selected) {
      let {
        props,
        model
      } = _ref3;
      R.mapObjIndexed((toProp, fromProp) => {
        const fromValue = selected.getData(fromProp);
        model.set(toProp, fromValue);
      })(props);
    }

    unloadContactProps(_ref4, selected) {
      let {
        props,
        model
      } = _ref4;
      R.mapObjIndexed((toProp, fromProp) => {
        model.set(toProp, '');
      })(props);
    }

    *loadContactTask(filters) {
      const results = yield this.crud.searchRecordsTask.perform({
        adapterName: 'contacts',
        filters: filters
      });
      return results;
    }

    get birthdayPeriodYears() {
      const pastYears = this.dateService.getPastYears({
        number: 130
      });
      const data = [];
      pastYears.forEach(period => {
        const item = {
          value: period.yyyy,
          label: period.yyyy
        };
        data.pushObject(item);
      });
      return data;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dateService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "all", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "supplier", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dataGroupsFilters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "searchContactsByGroupsTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchContactsByGroupsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupFilters", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setupFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchInputTask", [_emberConcurrencyDecorators.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "searchInputTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadContactProps", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "loadContactProps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadContactProps", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "unloadContactProps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadContactTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "loadContactTask"), _class.prototype)), _class));
  _exports.default = ContactsService;
});