define("client/pods/components/elements/btn-cancel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qZ7Fspcm",
    "block": "{\"symbols\":[\"@translate\"],\"statements\":[[1,[28,\"elements/element-btn\",null,[[\"isCancelBtn\",\"classNames\",\"label\",\"tooltipLabel\",\"icon\",\"disabled\",\"isRunning\",\"onSubmit\",\"translate\"],[true,[24,[\"btnClassNames\"]],[24,[\"label\"]],[24,[\"tooltipLabel\"]],[24,[\"icon\"]],[24,[\"disabled\"]],[24,[\"isRunning\"]],[24,[\"onSubmit\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/elements/btn-cancel/template.hbs"
    }
  });

  _exports.default = _default;
});