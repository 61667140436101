define("client/pods/components/products/products-editor/component", ["exports", "ramda", "ramda-adjunct", "client/utils/nventor", "ember-concurrency", "ramda-extension"], function (_exports, R, RA, _nventor, _emberConcurrency, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_obj = {
    applicationService: Ember.inject.service('application'),
    crud: Ember.inject.service(),
    constants: Ember.inject.service(),
    tabsManager: Ember.inject.service(),
    productsService: Ember.inject.service('products'),
    tagName: 'div',
    classNames: ['col-container'],
    disabled: false,
    translate: true,
    defaultProductType: null,
    defaultProductStatus: null,
    isAllowedToDelete: false,
    isEditingVariants: false,
    isDoneEditingVariants: false,
    deleteMsg: 'this product exists in documents/campaigns! the products will be set as inactive instead',
    headerBottom: null,

    init() {
      this._super(...arguments);

      this.crud.addLists(this, [{
        name: 'taxes',
        appendPath: 'products'
      }, 'barcodes', 'products/brands', 'products/lists/tags', 'products/lists/display-tags', 'products/lists/product-display-position', 'products/lists/types', 'products/lists/setTypes', 'products/lists/tracking', 'products/lists/invoiceDescriptions', 'products/lists/editorSubPanels', 'settings/roundingAuto', 'units', 'units/dimensions', 'units/weight', 'statuses', 'products/lists/pageImportBtnSubPanels', 'products/lists/sync']);

      if (this.isEditing) {
        this.set('isDoneEditingVariants', true);
      }

      this.setupTask.perform(); // if (this.get('isEditing') || this.get('isNew')) {
      // const model = this.get('model')
      // const originalModel = this.get('originalModel')
      // model.updateVariantChildrenList(originalModel)
      // }
      // this.set('editingPanels', [])
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      const itemKey = model?._data?._key || '';

      if (itemKey) {
        const result = yield this.server.call('GET', `api/protected/products/isAllowedToDelete/${itemKey}`, {
          itemKey
        });
        const isAllowedToDelete = R.propOr(false, 'data')(result);
        this.set('isAllowedToDelete', isAllowedToDelete);
      }
    }),
    currentSubTab: Ember.computed('tab.subTabs.@each.isActive', function () {
      const tab = this?.tab || {};
      return this.tabsManager.getActiveSubTab(tab);
    }),
    editorSubPanels: Ember.computed('crud.lists.products-lists-editorSubPanels.[]', 'model._data.{setType,name}', function () {
      const model = this.model;
      let panels = R_.dotPath('crud.lists.products-lists-editorSubPanels')(this) || [];
      const setType = this.get('model._data.setType');

      const isEmpty = _nventor.default.isNilOrEmpty(setType);

      if (isEmpty) {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
      } else if (setType === 'isSet') {
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'customizations'))(panels);
      } else if (setType === 'isSetWithChoices') {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'customizations'))(panels);
      } else if (setType === 'isVariantChild') {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'hasVariants'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
      } else if (setType === 'hasVariants') {
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSet'))(panels);
        panels = R.reject(R.pathEq(['_data', 'value'], 'isSetWithChoices'))(panels);
      }

      if (!model?._data?.name) {
        panels = R.filter(R.pathEq(['_data', 'value'], 'details'))(panels);
      }

      return panels;
    }),
    isProductReadonly: Ember.computed('isReadonly', 'isNew', 'isEditing', function () {
      const isNew = this?.isNew || false;

      if (isNew) {
        return false;
      }

      const isEditing = this?.isEditing || false;

      if (isEditing) {
        return false;
      }

      return this?.isReadonly || false;
    }),

    updateVariantChildrenAndSaveTask() {
      const subTabs = this.tab.subTabs; // check if isDoneEditingVariants

      const isDoneEditingVariants = this.isDoneEditingVariants; // check if the variants tab has been created so we know that the isDoneEditingVariants is changed from this tab

      const hasVariantsTab = R.find(R.propEq('value', 'hasVariants'))(subTabs); // check if product type is still variants

      const setType = this?.model?._data?.setType || '';
      let confirmed = false;

      if (hasVariantsTab && !isDoneEditingVariants && setType === 'hasVariants') {
        const msg = this.intl.t('variant_options_save_warning');
        confirmed = window.alert(msg);
        return;
      } else {
        confirmed = true;
      }

      if (confirmed) {
        // filter the customizations if exist
        const hasCustomizations = R.pathOr(false, ['model', '_data', 'hasCustomizations'])(this);

        if (hasCustomizations) {
          this.model.filterEmptyCustomizations();
        }

        if (this.isEditingVariants) {
          this.updateVariantChildrenList({
            originalModel: this.originalModel,
            isNew: this.isNew
          });
        }

        if (this.onSaveTask) {
          this.onSaveTask();
        }
      }
    },

    updateVariantChildrenList(_ref) {
      let {
        originalModel,
        isNew = false
      } = _ref;
      const model = this.model;
      return model.updateVariantChildrenList({
        originalModel,
        isNew,
        setInitialValue: this.isEditing
      });
    },

    displaySubPanel(resourceData) {
      if (resourceData.getData('value') === 'hasVariants') {
        const model = this.model; // if (!this.isDoneEditingVariants && !model?.isCopying) {

        if (!this.isDoneEditingVariants) {
          this.set('isEditingVariants', true);
        }
      } else {
        this.set('isEditingVariants', false);
      }

      this.tabsManager.displaySubPanel(this, resourceData);
    },

    // displaySubPanel (resourceData) {
    //   const subTabOptions = {
    //     component: resourceData.getData('component'),
    //     value: resourceData.getData('value')
    //   }
    //   const tab = this.tab
    //   this.tabsManager.reuseSubTab(tab, subTabOptions)
    // },
    actions: {
      /**
       * sales tax selected. if salesTaxRate selected, auto set purchasesTaxRate (and vice versa)
       */
      salesTaxRateSelected(taxModel) {
        const model = this.model;
        const taxRate = taxModel.getData('value');
        const purchasesTaxKey = model.getData('purchasesTaxRateKey'); // manually set tax rate

        model.setData('salesTaxRate', taxRate);

        if (purchasesTaxKey == null || purchasesTaxKey === 'auto') {
          // auto setData values for purchases
          // model.setData('purchasesTaxRateLabel', taxModel.getData('label'));
          model.setData('purchasesTaxRateKey', taxModel.getData('_key')); // model.setData('purchasesTaxRate', taxRate);
        }
      },

      /**
       * purchases tax selected. if salesTaxRate selected, auto set purchasesTaxRate (and vice versa)
       */
      purchasesTaxRateSelected(taxModel) {
        const model = this.model;
        const taxRate = taxModel.getData('value');
        const salesTaxKey = model.getData('salesTaxRateKey'); // manually set tax rate

        model.setData('purchasesTaxRate', taxRate);

        if (salesTaxKey == null || salesTaxKey === 'auto') {
          // auto setData values for purchases
          // model.setData('salesTaxRateLabel', taxModel.getData('label'));
          model.setData('salesTaxRateKey', taxModel.getData('_key')); // model.setData('salesTaxRate', taxRate);
        }
      },

      updateVariantChildrenList(_ref2) {
        let {
          originalModel,
          isNew = false
        } = _ref2;
        const model = this.model;
        return model.updateVariantChildrenList({
          originalModel,
          isNew,
          setInitialValue: this.isEditing
        });
      }

    }
  }, (_applyDecoratedDescriptor(_obj, "updateVariantChildrenAndSaveTask", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateVariantChildrenAndSaveTask"), _obj), _applyDecoratedDescriptor(_obj, "updateVariantChildrenList", [_dec2], Object.getOwnPropertyDescriptor(_obj, "updateVariantChildrenList"), _obj), _applyDecoratedDescriptor(_obj, "displaySubPanel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "displaySubPanel"), _obj)), _obj)));

  _exports.default = _default;
});