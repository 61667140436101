define("client/pods/channels/shop/shipping-zones/free-shipping-rule/model", ["exports", "client/pods/base/model", "ramda"], function (_exports, _model, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    defaults() {
      return {
        matchRule: 'none',
        rules: []
      };
    },

    populate(data, attrs) {
      data.rules = this._transformRules(data);

      this._super(data, attrs);
    },

    _transformRules(data) {
      const rules = R.propOr([], 'rules')(data);
      return R.map(rule => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/free-shipping-rule/rule',
          data: rule
        });
      })(rules);
    },

    addRule() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const rules = this?._data?.rules || [];
      const ruleModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/free-shipping-rule/rule',
        data
      });
      rules.pushObject(ruleModel);
      this.setData('rules', rules);
    },

    rulesLength: Ember.computed('rules.[]', function () {
      const rules = this?._data?.rules || [];
      return R.length(rules);
    })
  });

  _exports.default = _default;
});