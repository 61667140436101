define("client/pods/components/extensions/apps/tw/seven-eleven/create-contact/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct"], function (_exports, _component, _emberConcurrencyDecorators, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwSevenElevenCreateContactComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('contacts'), _dec4 = Ember.inject.service('transporter'), _dec5 = Ember.inject.service('settings'), _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = class ExtensionsAppsTwSevenElevenCreateContactComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "contactService", _descriptor3, this);

      _initializerDefineProperty(this, "transporterService", _descriptor4, this);

      _initializerDefineProperty(this, "settingsService", _descriptor5, this);

      _initializerDefineProperty(this, "constants", _descriptor6, this);

      _initializerDefineProperty(this, "contactsList", _descriptor7, this);

      _initializerDefineProperty(this, "model", _descriptor8, this);

      _initializerDefineProperty(this, "isLinkContact", _descriptor9, this);

      _initializerDefineProperty(this, "transporter", _descriptor10, this);

      _initializerDefineProperty(this, "refreshed", _descriptor11, this);

      _defineProperty(this, "adapterNameContactsTransporters", 'contacts');

      _defineProperty(this, "adapterNameContacts", 'contacts');

      _defineProperty(this, "transporterExtension", this.constants.transporterExtensions.sevenEleven);

      _defineProperty(this, "productTypeDefault", 'general food');
    }

    get linkIcon() {
      return this.isLinkContact ? 'fas fa-link' : null;
    }

    get allowCreate() {
      const allowCreate = this.args.allowCreate;
      const isNilOrEmpty = RA.isNilOrEmpty(allowCreate);

      if (isNilOrEmpty) {
        return true;
      }

      return allowCreate;
    }

    get senderNamePlaceHolder() {
      return R.pipe(R.pathOr('', ['settingsService', 'settingsModel', '_data', 'company']), R.take(5))(this);
    }

    setup() {
      const hasExistingModel = RA.isNotNilOrEmpty(this.args.model);

      if (hasExistingModel) {
        this.model = this.crud.setupDirty({
          adapterName: 'contacts',
          model: this.args.model
        });
      } else {
        this.model = this.crud.setupNewRecord({
          adapterName: 'contacts'
        });
      }
    }

    *saveTask(onCloseDialogAction) {
      let transporterModel;
      this.model.setData('relationships', [this.constants.contactsRelationship.supplier]);
      this.model.setData('isTransporter', this.constants.isTransporter.on);
      this.model.setData('transporterExtension', this.transporterExtension);
      this.model.setData('isAllowedLinkMember', false);
      const isDirty = this.model.isDirty && this.model?._data?._key;

      if (this.isLinkContact) {
        const dirty = this.crud.setupDirty({
          adapterName: this.adapterNameContactsTransporters,
          model: this.model
        });
        transporterModel = yield this.crud.updateRecordTask.perform({
          adapterName: this.adapterNameContactsTransporters,
          model: dirty
        });
      } else if (isDirty) {
        transporterModel = yield this.crud.updateRecordTask.perform({
          adapterName: this.adapterNameContactsTransporters,
          model: this.model
        });
      } else if (this.allowCreate) {
        transporterModel = yield this.crud.createRecordTask.perform({
          adapterName: 'contacts',
          model: this.model
        });
      } else {
        const data = this.model._data;
        this.args.model.set('_data', data);
      }

      if (this?.args?.onLoadTransporterContact) {
        this.args.onLoadTransporterContact(transporterModel);
        this.model = transporterModel;
      }

      if (onCloseDialogAction) {
        onCloseDialogAction();
      }
    }

    searchContactsTask() {
      var _this = this;

      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        relationship: 'supplier'
      };
      return function* () {
        _this.contactsList = yield _this.contactService.searchInputTask.perform(args);
      }();
    }

    *setupLists() {
      this.crud.addLists(this, ['extensions/apps/tw/t-cat/lists/thermosphere', 'extensions/apps/tw/t-cat/lists/package-size', 'extensions/apps/tw/t-cat/lists/product-type', 'extensions/apps/tw/t-cat/lists/waybill-print-type', 'extensions/apps/tw/t-cat/lists/delivery-time']);
      this.setup();
      const options = {
        query: this.args?.model?._data?.name,
        relationship: 'supplier'
      };
      yield this.searchContactsTask.perform(options);
    }

    loadTransporter(transporter) {
      this.isLinkContact = true;
      this.transporter = transporter;
      R.pipe(R.propOr({}, '_data'), R.forEachObjIndexed((value, key) => {
        this.model.setData(key, value);
      }))(transporter);
      this.model.setData('_key', transporter.getData('_key'));
      this.model.setData('_rev', transporter.getData('_rev'));
      this.model.setData('code', transporter.getData('code'));
      this.model.setData('transporterAccounts', this.crud.lists['extensions-apps-tw-t-cat-lists-thermosphere']);
      const defaultTransporterAccount = transporter?._data?.defaultTransporterAccount || this.transporterService.defaultTransporterAccountNumber;
      this.model.setData('defaultTransporterAccount', defaultTransporterAccount);
      this.refreshModel(this.model);
    }

    unloadTransporter() {
      this.isLinkContact = false;
      this.transporter = '';
      this.model.setData('_id', '');
      this.model.setData('_key', '');
      this.model.setData('_rev', '');
      this.model.setData('code', '');
      this.model.setData('transporterAccounts', []);
      this.model.setData('defaultTransporterAccount', '');
      this.setupModel({
        isNew: true
      });
      this.refreshModel();
    }

    setupModel() {
      let {
        isNew = false
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const existingModel = R.pathOr(null, ['args', 'model'])(this);

      if (existingModel && !isNew) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterNameContactsTransporters,
          model: existingModel
        });
      } else {
        this.model = this.crud.setupNewRecord({
          adapterName: this.adapterNameContacts
        });
      }
    }

    refreshModel(model) {
      model = model || R.propOr({}, 'model')(this);
      const isDirty = R.pathEq(['isDirty'], true)(model);

      if (isDirty) {
        this.model = this.crud.setupDirty({
          adapterName: this.adapterNameContactsTransporters,
          model
        });
        this.model.set('_data', this.model._data);
      } else {
        this.model = model;
      }

      if (this.args?.model?._data) {
        this.args.model.set('_data', this.model._data);
      }

      this.refreshed = !this.refreshed;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contactService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "transporterService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "settingsService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "contactsList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isLinkContact", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "transporter", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "refreshed", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTask", [_emberConcurrencyDecorators.dropTask], Object.getOwnPropertyDescriptor(_class.prototype, "saveTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchContactsTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "searchContactsTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupLists", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "setupLists"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadTransporter", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "loadTransporter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unloadTransporter", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "unloadTransporter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupModel", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "setupModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwSevenElevenCreateContactComponent;
});