define("client/pods/channels/shop/shipping-zones/model", ["exports", "client/pods/base/model", "ramda", "ramda-adjunct", "client/constants", "client/utils/nventor"], function (_exports, _model, R, RA, _constants, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _obj;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var _default = _model.default.extend((_dec = Ember._action, (_obj = {
    defaults() {
      return {
        zoneId: '',
        zoneType: _constants.default.shippingFeeZoneType.single,
        name: '',
        isSplit: false,
        isDefault: false,
        isLabelOnly: false,
        priorityZone: '',
        productTags: [],
        denyProductTags: [],
        shopPaymentMethods: [],
        shopPaymentMethodsDefault: '',
        supplierKey: '',
        supplierLabel: '',
        transporterKey: '',
        transporterLabel: '',
        transporterAccount: '',
        transporterAccountNumber: '',
        transporterExtension: '',
        additionalFreeShippingRule: {},
        countries: [],
        surcharges: [],
        hasSurchargeWhenFreeShipping: false,
        weightUnit: 'g',
        hasNoRates: false,
        isValueBased: true,
        isWeightBased: false,
        isQuantityBased: false,
        valueBasedRates: [],
        weightBasedRates: [],
        quantityBasedRates: [],
        productTagsWhen: 'any',
        transporterDefaultPickupLocationPersonId: '',
        transporterUseDefaultPickupLocation: true,
        hasCustomAdditionalFreeShippingMessage: false,
        customAdditionalFreeShippingMessage: ''
      };
    },

    populate(data, attrs) {
      data.countries = this._transformCountries(data);
      data.rates = this._transformRates(data);
      data.surcharges = this._transformSurcharges(data);
      data.freeShippingRules = this._transformFreeShippingRules(data);
      data.valueBasedRates = this._transformBasedRates({
        data,
        prop: 'valueBasedRates'
      });
      data.weightBasedRates = this._transformBasedRates({
        data,
        prop: 'weightBasedRates'
      });
      data.quantityBasedRates = this._transformBasedRates({
        data,
        prop: 'quantityBasedRates'
      });

      if (RA.isNotNilOrEmpty(data.campaigns)) {
        this._transformCampaigns(data);
      }

      this._super(data, attrs);
    },

    _transformCampaigns(data) {
      const campaigns = R.propOr([], 'campaigns')(data);
      const campaignModels = R.map(campaign => {
        return this.dataManager.setPartialRecord({
          adapterName: 'channels/shop/campaigns',
          data: campaign
        });
      })(campaigns);
      this.set('campaigns', campaignModels);
      delete data.campaigns;
    },

    _transformCountries(data) {
      const countries = R.propOr([], 'countries')(data);
      return R.map(country => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/countries',
          data: country
        });
      })(countries);
    },

    _transformRates(data) {
      const rates = R.propOr([], 'rates')(data);
      return R.map(rate => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/rates',
          data: rate
        });
      })(rates);
    },

    _transformSurcharges(data) {
      const surcharges = R.propOr([], 'surcharges')(data);
      return R.map(surcharge => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/surcharges',
          data: surcharge
        });
      })(surcharges);
    },

    addSurcharge() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const surcharges = this.getData('surcharges') || [];
      const surchargeModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/surcharges',
        data
      });
      surcharges.pushObject(surchargeModel);
      this.setData('surcharges', surcharges);
    },

    _transformFreeShippingRules(data) {
      const freeShippingRules = R.propOr([], 'freeShippingRules')(data);
      return R.map(freeShippingRule => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/free-shipping-rule',
          data: freeShippingRule
        });
      })(freeShippingRules);
    },

    addFreeShippingRule() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const freeShippingRules = this?._data?.freeShippingRules || [];
      const freeShippingRuleModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/free-shipping-rule',
        data
      });
      freeShippingRules.pushObject(freeShippingRuleModel);
      this.setData('freeShippingRules', freeShippingRules);
    },

    clearIncomplete() {
      this._clearIncomplete('rates');

      this.set('updated', Math.random());
    },

    isLastRateIndex(rate) {
      const rates = this.getData('rates') || [];
      const rateIndex = rates.indexOf(rate);

      if (rateIndex === rates.length - 1) {
        return true;
      }

      return false;
    },

    updateMinRates() {
      const rates = this.getData('rates') || [];
      let previousRate;
      const newRates = RA.mapIndexed((rate, index) => {
        let previousMax = 0;

        if (previousRate) {
          previousMax = previousRate.getData('max');
        }

        let currentMin = previousMax;
        const initialMin = rate.getData('min') || 0;

        if (index === 0 && RA.isNotNilOrEmpty(initialMin)) {
          currentMin = initialMin;
        }

        if (index > 0) {
          currentMin = previousMax + 1;
        }

        rate.setData('min', currentMin);
        let currentMax = parseFloat(rate.getData('max')) || 0;

        if (currentMax > 0) {
          if (currentMax < currentMin) {
            currentMax = 0;
          }

          rate.setData('max', currentMax);
        }

        previousRate = rate;
        return rate;
      })(rates);
      this.setData('rates', newRates);
    },

    newBlankRate() {
      let rate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let rateData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      // check if current rate is the last one
      if (this.isLastRateIndex(rate)) {
        const hasRequiredData = rate.hasRequiredData();

        if (hasRequiredData) {
          this.newRate(rateData);
        }
      }
    },

    newRate() {
      let rateData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const rateModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/rates',
        data: rateData
      });
      this.addRate(rateModel);
    },

    addRate(rate) {
      const rates = this.getData('rates') || [];
      rates.pushObject(rate);
      this.setData('rates', rates);
    },

    removeRate(rate) {
      const rates = this.getData('rates') || [];
      const currentRateIndex = rates.indexOf(rate);
      const isLast = this.isLastRateIndex(rate);
      rates.removeObject(rate);
      const previousRate = rates[currentRateIndex - 1] || {};
      const isZeroPrevMax = parseInt(previousRate?._data?.max || 0) === 0;
      const isZeroOrOneCurrentMin = parseInt(rates?._data?.min || 0) === 0 || parseInt(rates?._data?.max || 0) === 1;
      const isRateRemoveable = isZeroPrevMax && isZeroOrOneCurrentMin;
      const isOnlyOne = rates.length === 0;

      if (isOnlyOne) {
        this.newRate();
      } else if (isLast && !isRateRemoveable) {
        this.newRate();
      } else {
        this.setData('rates', rates);
      }

      return rate;
    },

    newCountry() {
      let countryData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/countries',
        data: countryData
      });
    },

    addCountry(country) {
      const countries = this.getData('countries') || [];
      countries.pushObject(country);
      this.setData('countries', countries);
    },

    removeCountry(country) {
      const countries = this.getData('countries') || [];
      countries.removeObject(country);
      this.setData('countries', countries);
      return country;
    },

    _transformBasedRates(_ref) {
      let {
        data,
        prop
      } = _ref;
      const rates = R.propOr([], prop)(data);
      return R.map(rate => {
        return this.dataManager.setAsRecord({
          adapterName: 'channels/shop/shipping-zones/rates',
          data: rate
        });
      })(rates);
    },

    isLastBasedRateIndex(_ref2) {
      let {
        rateProp,
        rate
      } = _ref2;
      const rates = this.getData(rateProp) || [];
      const rateIndex = rates.indexOf(rate);

      if (rateIndex === rates.length - 1) {
        return true;
      }

      return false;
    },

    updateMinBasedRates(_ref3) {
      let {
        rateProp
      } = _ref3;
      const rates = this.getData(rateProp) || [];
      let previousRate;
      const newRates = RA.mapIndexed((rate, index) => {
        let previousMax = 0;

        if (previousRate) {
          previousMax = previousRate.getData('max');
        }

        let currentMin = previousMax;
        const initialMin = rate.getData('min') || 0;

        if (index === 0 && RA.isNotNilOrEmpty(initialMin)) {
          currentMin = initialMin;
        }

        if (index > 0) {
          currentMin = previousMax + 1;
        }

        rate.setData('min', currentMin);
        let currentMax = parseFloat(rate.getData('max')) || 0;

        if (currentMax > 0) {
          if (currentMax < currentMin) {
            currentMax = 0;
          }

          rate.setData('max', currentMax);
        }

        previousRate = rate;
        return rate;
      })(rates);
      this.setData(rateProp, newRates);
    },

    newBlankBasedRate(_ref4) {
      let {
        rateProp,
        rate = false,
        rateData = {}
      } = _ref4;

      // check if current rate is the last one
      if (this.isLastBasedRateIndex({
        rateProp,
        rate
      })) {
        const hasRequiredData = rate.hasRequiredData();

        if (hasRequiredData) {
          rateData.rateProp = rateProp;
          const prevMax = Number(rate?._data?.max) || 0;

          if (prevMax) {
            const prevMaxStr = this.big.newBig(prevMax).plus(1).toFixed(4);
            rateData.min = Number(prevMaxStr);
          }

          this.newBasedRate({
            rateProp,
            rateData
          });
        }
      }
    },

    newBasedRate(_ref5) {
      let {
        rateProp,
        rateData = {}
      } = _ref5;
      const rateModel = this.dataManager.setAsRecord({
        adapterName: 'channels/shop/shipping-zones/rates',
        data: rateData
      });
      this.addBasedRate({
        rateProp,
        rateModel
      });
    },

    addBasedRate(_ref6) {
      let {
        rateProp,
        rateModel
      } = _ref6;
      const rates = this.getData(rateProp) || [];
      rates.pushObject(rateModel);
      this.setData(rateProp, rates);
    },

    removeBasedRate(_ref7) {
      let {
        rateProp,
        rate
      } = _ref7;
      const rates = this.getData(rateProp) || [];
      const isLast = this.isLastBasedRateIndex({
        rateProp,
        rate
      });
      rates.removeObject(rate);

      if (isLast) {
        this.newBasedRate({
          rateProp
        });
      } else {
        this.setData(rateProp, rates);
      }

      return rate;
    },

    hasManyCampaigns: Ember.computed('campaigns.[]', function () {
      return R.pipe(R.propOr([], 'campaigns'), R.propSatisfies(R.gte(R.__, 3), 'length'))(this);
    }),
    hasNotZeroMinimumRates: Ember.computed('_data.{valueBasedRates.@each.synced,weightBasedRates.@each.synced,quantityBasedRates.@each.synced}', function () {
      const rateBases = ['valueBasedRates', 'weightBasedRates', 'quantityBasedRates'];
      const hasNotZeroMinimumRates = {};
      R.forEach(rateBase => {
        const rates = R.pathOr([], ['_data', rateBase])(this);
        let hasNotZeroMinimumRate = true;

        const firstRate = _nventor.default.safeHeadOr({}, rates);

        if (RA.isNilOrEmpty(firstRate)) {
          hasNotZeroMinimumRate = false;
        }

        const minRate = parseInt(firstRate?._data?.min) || 0;

        if (minRate === 0) {
          hasNotZeroMinimumRate = false;
        }

        hasNotZeroMinimumRates[rateBase] = hasNotZeroMinimumRate;
      })(rateBases);
      return hasNotZeroMinimumRates;
    }),
    minimumRates: Ember.computed('_data.{valueBasedRates.@each.synced,weightBasedRates.@each.synced,quantityBasedRates.@each.synced}', function () {
      const rateBases = ['valueBasedRates', 'weightBasedRates', 'quantityBasedRates'];
      const minimumRates = {};
      R.forEach(rateBase => {
        const rates = R.pathOr([], ['_data', rateBase])(this);

        const firstRate = _nventor.default.safeHeadOr({}, rates);

        minimumRates[rateBase] = parseInt(firstRate?._data?.min) || 0;
      })(rateBases);
      return minimumRates;
    }),
    hasIncorrectRates: Ember.computed('_data.{valueBasedRates.@each.synced,weightBasedRates.@each.synced,quantityBasedRates.@each.synced}', function () {
      const rateBases = ['valueBasedRates', 'weightBasedRates', 'quantityBasedRates'];
      const hasIncorrectRates = {};
      R.forEach(rateBase => {
        const rates = R.pathOr([], ['_data', rateBase])(this);
        hasIncorrectRates[rateBase] = R.pipe(RA.mapIndexed((rate, index) => {
          const nextRate = rates[index + 1];

          if (RA.isNotNilOrEmpty(nextRate)) {
            const currentMin = parseInt(rate?._data?.min || 0);
            const nextMin = nextRate?._data?.min; // dont set default value

            if (nextMin && parseInt(nextMin) < currentMin) {
              return true;
            }
          }

          return false;
        }), R.includes(true, R.__))(rates);
      })(rateBases);
      return hasIncorrectRates;
    }),
    hasAnyIncorrectRates: Ember.computed('hasIncorrectRates.{valueBasedRates,weightBasedRates,quantityBasedRates}', function () {
      const hasIncorrectRates = this.hasIncorrectRates || {};

      if (hasIncorrectRates?.valueBasedRates || hasIncorrectRates?.weightBasedRates || hasIncorrectRates?.quantityBasedRates) {
        return true;
      }

      return false;
    }),
    hasEnableDeliveryDateOptions: Ember.computed('_data.deliveryDateOptionsKey', '_data.deliveryDateOptionsEnabled', function () {
      const deliveryDateOptionsKey = R.pathOr('', ['_data', 'deliveryDateOptionsKey'])(this);
      const deliveryDateOptionsEnabled = R.pathOr(false, ['_data', 'deliveryDateOptionsEnabled'])(this);

      if (RA.isNotNilOrEmpty(deliveryDateOptionsKey) && deliveryDateOptionsEnabled) {
        return true;
      }

      return false;
    }),
    hasCashPaymentMethod: Ember.computed('_data.shopPaymentMethods.[]', function () {
      const shopPaymentMethods = this?._data?.shopPaymentMethods || [];

      if (RA.isNilOrEmpty(shopPaymentMethods)) {
        return true;
      }

      if (R.includes('cash', shopPaymentMethods)) {
        return true;
      }

      return false;
    })
  }, (_applyDecoratedDescriptor(_obj, "newBlankBasedRate", [_dec], Object.getOwnPropertyDescriptor(_obj, "newBlankBasedRate"), _obj)), _obj)));

  _exports.default = _default;
});