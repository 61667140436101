define("client/pods/components/transporters/seven-eleven/pickup-location/return-store-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Db7bX161",
    "block": "{\"symbols\":[\"@isRunning\",\"@transporterModel\",\"@model\",\"@onCloseDialogAction\"],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,3,[\"convenienceStoreData\",\"storeId\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,3,[\"convenienceStoreData\",\"storeName\"]],false],[0,\" - \"],[1,[23,3,[\"convenienceStoreData\",\"storeAddress\"]],false],[0,\" (\"],[1,[28,\"tt\",[\"storeId\"],null],false],[0,\"\\n      : \"],[1,[23,3,[\"convenienceStoreData\",\"storeId\"]],false],[0,\")\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"tt\",[\"seven-eleven return store description\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[23,3,[\"isDirty\"]],[23,3,[\"convenienceStoreData\",\"storeId\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"elements/btn-delete\",[],[[\"@typeToDelete\",\"@label\",\"@icon\",\"@onSubmit\"],[false,\"\",\"fas fa-trash\",[28,\"perform\",[[23,0,[\"clearReturnConvenienceStoreTask\"]],[23,4,[]],[23,3,[]],[23,2,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[5,\"elements/element-btn\",[],[[\"@translate\",\"@label\",\"@isRunning\",\"@onSubmit\"],[true,\"change return convenience store\",[23,1,[]],[28,\"perform\",[[23,0,[\"updateReturnConvenienceStoreTask\"]],[23,4,[]],[23,3,[]],[23,2,[]]],null]]]],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/transporters/seven-eleven/pickup-location/return-store-editor/template.hbs"
    }
  });

  _exports.default = _default;
});