define("client/pods/components/settings/settings-editor/invoices-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-extension"], function (_exports, _component, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let SettingsSettingsEditorInvoicesEditorComponent = (_dec = Ember._action, (_class = class SettingsSettingsEditorInvoicesEditorComponent extends _component.default {
    toggleEInvoice(provider, value) {
      const model = this.args.model;
      const systemleadBeta = R_.dotPath('_data.beta.systemlead')(model);
      const ecPayBeta = R_.dotPath('_data.beta.ecPay')(model);

      if (systemleadBeta && ecPayBeta) {
        if (provider === 'systemlead') {
          if (value) {
            model.setData('tw_eInvoice_ecPay.enabled', false);
          }

          if (provider === 'ecPay') {
            if (value) {
              model.setData('tw_eInvoice_systemlead.enabled', false);
            }
          }
        }
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "toggleEInvoice", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEInvoice"), _class.prototype)), _class));
  _exports.default = SettingsSettingsEditorInvoicesEditorComponent;
});