define("client/pods/grapesjs/plugins/product", ["exports", "ramda"], function (_exports, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = editor => {
    const pluginName = 'bigordr-product';
    const columnComponentClassName = 'bigordr-cell';
    const blockId = `${pluginName}-block`;
    const pluginOptions = R.pathOr({}, ['Config', 'pluginsOpts', pluginName])(editor);
    const {
      disabled,
      onDbClick,
      onDrop,
      intl,
      isReadonly,
      onRender,

      /** @type {Categories} */
      categories
    } = pluginOptions;

    if (disabled) {
      return;
    }

    const blockLabel = intl.t('product');
    const {
      DomComponents,
      BlockManager
    } = editor;
    const styles = `
    <style>
      .bigordr-product {
        display: inline-block;
      }
    </style>
  `;

    const setProductImage = (component, value) => {
      if (!value) {
        value = component.get('defaultImage');
      }

      const image = component.findType('bigordr-product-image').firstObject;

      if (image) {
        image.setAttributes({
          src: value
        });
      }
    };

    const setProductId = (component, value) => {
      if (!value) {
        value = component.get('itemKey');
      }

      const bigordrProductsId = component.findType('bigordr-product-id').firstObject;

      if (bigordrProductsId) {
        const bigordrProductsIdAttr = bigordrProductsId.getAttributes();
        bigordrProductsIdAttr['data-bigordr-product'] = value;
        bigordrProductsId.setAttributes(bigordrProductsIdAttr);
      }
    };

    DomComponents.addType(pluginName, {
      model: {
        defaults: {
          itemKey: '0000000',
          name: blockLabel,
          defaultImage: 'https://via.placeholder.com/200?text=Product',
          tagName: 'span',
          draggable: `.${columnComponentClassName}`,
          droppable: false,
          classes: ['bigordr-product'],
          components: [{
            type: 'bigordr-product-id',
            badgable: false,
            copyable: false,
            draggable: false,
            droppable: false,
            editable: false,
            highlightable: false,
            hoverable: false,
            layerable: false,
            propagate: ['badgable', 'copyable', 'draggable', 'droppable', 'editable', 'highlightable', 'hoverable', 'layerable', 'removable', 'resizable', 'selectable', 'stylable'],
            removable: false,
            resizable: false,
            selectable: false,
            stylable: false,
            components: [{
              type: 'bigordr-product-image',
              tagName: 'img',
              traits: []
            }],
            traits: []
          }],
          traits: []
        },

        init() {
          setProductId(this);
          setProductImage(this);
        },

        updated(property, value) {
          if (property === 'itemKey') {
            setProductId(this, value);
          }

          if (property === 'defaultImage') {
            setProductImage(this, value);
          }
        }

      },
      view: {
        events: {
          dblclick: 'onDbClick'
        },

        onDbClick() {
          const parentType = this.model.parent().get('type');

          if (onDbClick && !isReadonly && parentType !== 'bigordr-products') {
            onDbClick(this.model);
          }
        },

        onRender() {
          if (onRender) {
            onRender(this.model);
          }
        }

      }
    }); // BlockManager.add(blockId, {
    //   label: blockLabel,
    //   category: categories.shop,
    //   content: {
    //     type: pluginName,
    //     draggable: `.${columnComponentClassName}`,
    //     content: styles
    //   },
    //   render: ({model}) => `
    //   <div class="gjs-block__media">
    //     <svg width="100%" height="100%" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    //       <g transform="matrix(1.26996,0,0,1.26996,138.624,158.728)">
    //           <g transform="matrix(-0.694823,-3.04913e-16,1.52756e-16,-0.602169,647.959,604.311)">
    //               <rect x="209.331" y="237.609" width="600.169" height="507.391" style="fill:white;"/>
    //               <path d="M809.5,237.609L209.331,237.609L209.331,745L809.5,745L809.5,237.609ZM769.835,283.377L248.996,283.377L248.996,699.232L769.835,699.232L769.835,283.377Z"/>
    //           </g>
    //           <g transform="matrix(0.993398,0,0,0.663184,-259.355,-401.328)">
    //               <path d="M766.929,839.922L347.146,839.922L408.125,748.581L705.95,748.581L766.929,839.922Z"/>
    //           </g>
    //           <g transform="matrix(4.57099e-17,0.841621,-1.0989,7.04059e-17,695.157,-185.091)">
    //               <rect x="494.977" y="314.602" width="40.355" height="100.893"/>
    //           </g>
    //           <g transform="matrix(-1.74942,0,0,1.09689,1527.02,-330.238)">
    //               <rect x="656.97" y="593.001" width="15.497" height="84.821"/>
    //           </g>
    //           <g transform="matrix(-2.00868,0,0,1.09689,1744.48,-330.238)">
    //               <rect x="656.97" y="593.001" width="15.497" height="84.821"/>
    //           </g>
    //           <g transform="matrix(-1.96583,0,0,1.09689,1595.75,-330.238)">
    //               <rect x="656.97" y="593.001" width="15.497" height="84.821"/>
    //           </g>
    //           <g transform="matrix(-1.05195,0,0,1.09689,1028.68,-330.238)">
    //               <rect x="656.97" y="593.001" width="15.497" height="84.821"/>
    //           </g>
    //           <g transform="matrix(-1.05195,0,0,1.09689,1147.26,-330.238)">
    //               <rect x="656.97" y="593.001" width="15.497" height="84.821"/>
    //           </g>
    //       </g>
    //   </svg>
    //   <div class="block-label">${model.get('label')}</div>
    //   </div>
    //   `
    // })

    editor.on('block:drag:stop', component => {
      if (component && component.is && component.is(pluginName)) {
        editor.select(component);

        if (onDrop) {
          onDrop(component);
        }
      }
    });
  };

  _exports.default = _default;
});