define("client/pods/components/reports/dashboard/tab-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ktKPiLIu",
    "block": "{\"symbols\":[\"subTab\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tab-grid\"],[8],[0,\"\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__content\"],[8],[0,\"\\n\\t\\t\"],[5,\"tabs/for-each\",[],[[\"@tabs\"],[[24,[\"tab\",\"subTabs\"]]]],{\"statements\":[[0,\"\\n\\t\\t\\t\"],[1,[28,\"component\",[[23,1,[\"component\"]]],[[\"tabParent\",\"tab\",\"onDash\"],[[24,[\"tab\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"dash\"],null]]]],false],[0,\"\\n\\t\\t\"]],\"parameters\":[1]}],[0,\"\\n\\t\"],[9],[0,\"\\n\\n\\t\"],[7,\"div\",true],[10,\"class\",\"tab-grid__modal\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/reports/dashboard/tab-container/template.hbs"
    }
  });

  _exports.default = _default;
});