define("client/pods/channels/shop/campaigns/lists/import/price/specs/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor", "ramda", "ramda-extension"], function (_exports, _adapter, _adaptersNew, _model, _nventor, R, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,

    async findAll() {
      return [{
        headerValue: this.intl.t('index'),
        label: 'index',
        value: 'index'
      }, {
        headerValue: this.intl.t('product'),
        label: 'product',
        value: 'item'
      }, {
        headerValue: this.intl.t('product code'),
        label: 'product code',
        value: 'itemCode'
      }, {
        //   headerValue: this.intl.t('product tags'),
        //   label: 'product tags',
        //   value: 'productTags',
        //   transform (value, row, spec) {
        //     if (R.is(String, value)) {
        //       return R.pipe(
        //         R.split(','),
        //         R.reject(R.equals(''))
        //       )(value)
        //     }
        //     return value
        //   }
        // }, {
        //   headerValue: this.intl.t('campaign detail product tags'),
        //   label: 'campaign detail product tags',
        //   value: 'campaignDetailProductTags'
        // }, {
        headerValue: this.intl.t('product type'),
        label: 'product type',
        value: 'productType',

        transform(value, row, spec, intl) {
          if (R.equals(value, intl.t('product_set'))) {
            return 'isSet';
          }

          if (R.equals(value, intl.t('product_sets_contents'))) {
            return 'isSetChild';
          }

          if (R.equals(value, intl.t('product_sets_with_choices'))) {
            return 'isSetWithChoices';
          }

          if (R.equals(value, intl.t('variants'))) {
            return 'hasVariants';
          }

          if (R.equals(value, intl.t('variant child'))) {
            return 'isVariantChild';
          }

          if (R.equals(value, intl.t('choices'))) {
            return 'choices';
          }

          if (R.equals(value, intl.t('product_general'))) {
            return 'normal';
          }

          return value;
        }

      }, {
        headerValue: this.intl.t('status'),
        label: 'status',
        value: 'status',

        transform(value, row, spec, intl) {
          if (R.equals(value, intl.t('active'))) {
            return 'active';
          }

          if (R.equals(value, intl.t('inactive'))) {
            return 'inactive';
          }

          if (R.equals(value, intl.t('preOrder'))) {
            return 'preOrder';
          }

          if (R.equals(value, intl.t('soldOut'))) {
            return 'soldOut';
          }

          if (R.equals(value, intl.t('draft'))) {
            return 'draft';
          }

          return value;
        }

      }, {
        headerValue: this.intl.t('on sale'),
        label: 'on sale',
        value: 'onSale',

        transform(value, row, spec) {
          if (_nventor.default.try(R.toUpper, value) === 'Y') {
            return 'on';
          }

          return 'off';
        }

      }, {
        headerValue: this.intl.t('inclusive price'),
        label: 'inclusive price',
        value: 'price'
      }, {
        headerValue: this.intl.t('inclusive discounted price'),
        label: 'inclusive discounted price',
        value: 'priceDiscounted'
      }, {
        headerValue: this.intl.t('choose qty'),
        label: 'choose qty',
        value: 'chooseQty'
      }, {
        headerValue: this.intl.t('set price on bundle'),
        label: 'set price on bundle',
        value: 'isSetPriceOnBundle',

        transform(value, row, spec) {
          if (_nventor.default.try(R.toUpper, value) === 'Y') {
            return true;
          }

          return false;
        }

      }, {
        headerValue: `${this.intl.t('product_sets_contents')} ${this.intl.t('qty')}`,
        label: 'qty',
        value: 'qty'
      }];
    }

  });

  _exports.default = _default;
});