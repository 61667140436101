define("client/pods/components/extensions/apps/tw/e-invoices/tax-number-input/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ExtensionsAppsTwEInvoicesTaxNumberInputComponent = (_dec = Ember.inject.service('documents'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed('args.model._data.twEInvoiceCarrierType'), (_class = class ExtensionsAppsTwEInvoicesTaxNumberInputComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "documentsService", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "crud", _descriptor3, this);

      _initializerDefineProperty(this, "taxNumber", _descriptor4, this);

      _initializerDefineProperty(this, "companyDetails", _descriptor5, this);

      _initializerDefineProperty(this, "mobileBarcode", _descriptor6, this);

      this.crud.addLists(this, ['extensions/apps/tw/e-invoices/carrier-types']);
    }

    get iconClassName() {
      const classNames = ['icon'];

      if (this.companyDetails) {
        if (this.companyDetails?.error) {
          classNames.push('has-text-danger');
        } else {
          classNames.push('has-text-info');
        }
      }

      return classNames.join(' ');
    }

    get placeholder() {
      if (!this.args?.model?._data?.twEInvoiceCarrierType) {
        return;
      }

      const twEInvoiceCarrierType = this.args?.model?._data?.twEInvoiceCarrierType;

      if (!twEInvoiceCarrierType) {
        return '';
      }

      const carrierTypeLabel = R.pipe(R.pathOr([], ['crud', 'lists', 'extensions-apps-tw-e-invoices-carrier-types']), R.find(R.pathEq(['_data', 'value'], twEInvoiceCarrierType)), R.pathOr('', ['_data', 'label']))(this);
      return this.intl.t(carrierTypeLabel);
    }

    get isReadonly() {
      if (this.args?.isReadonly === true) {
        return true;
      }

      if (this.args?.isDisabled === true) {
        return true;
      }

      if (!this.args?.model?._data?.twEInvoiceCarrierType) {
        return true;
      }

      return false;
    }

    *onSearchTask() {
      this.companyDetails = null;
      this.mobileBarcode = null;
      const taxNumber = R.trim(this.args?.model?._data?.taxNumber) || '';

      if (!taxNumber) {
        return;
      }

      this.mobileBarcode = '';
      const response = yield this.documentsService.fetchAllDetailsFromGovTask.perform(taxNumber);
      response.value = taxNumber;
      this.companyDetails = response;
      return this.args.model.setupTaxDetails(response);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "documentsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "taxNumber", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "companyDetails", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mobileBarcode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isReadonly", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "isReadonly"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSearchTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "onSearchTask"), _class.prototype)), _class));
  _exports.default = ExtensionsAppsTwEInvoicesTaxNumberInputComponent;
});