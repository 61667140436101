define("client/pods/components/settings/settings-editor/shop-editor/component", ["exports", "@glimmer/component", "ramda-adjunct"], function (_exports, _component, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SettingsSettingsEditorShopEditorComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class SettingsSettingsEditorShopEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "crud", _descriptor, this);

      this.crud.addLists(this, ['channels/shop/campaigns/automations/shipping-behavior', 'channels/shop/campaigns/lists/cartBtnStyle', 'channels/shop/campaigns/lists/commission-types', 'channels/shop/campaigns/lists/limit-adjust-types', 'channels/shop/campaigns/lists/products-filters', 'channels/shop/campaigns/lists/productsImagesRatio', 'channels/shop/campaigns/lists/productsImagesSize', 'channels/shop/campaigns/lists/productsNameFontSize', 'channels/shop/campaigns/lists/productsNameLineCount', 'channels/shop/campaigns/lists/show-customizations-price-type', 'channels/shop/campaigns/lists/review-steps', 'channels/shop/campaigns/lists/variantSelectorStyle', 'channels/shop/campaigns/statuses', 'periods', 'products/lists/bigordrProductsPerRow', 'products/lists/onSaleTagDisplayPosition', 'products/lists/onSaleTagDisplayStyle', 'recurring/discounts', 'recurring/discounts', 'recurring/shipping', 'settings/checkout-date-method', 'settings/imagesHoverFilter', 'settings/imagesHoverTransform', 'settings/salesInvoicePanelOptions', {
        name: 'workflows/lists/items/activities/templates',
        appendPath: 'authorization'
      }]);
    }

    afterLoadLists() {
      const model = this.args.model;
      const selectedCommissionBaseCostMethod = model.getData('selectedCommissionBaseCostMethod');
      const isCommissionsUsingAverage = model.getData('isCommissionsUsingAverage');
      const isCommissionsCalculatedByExclAmt = model.getData('isCommissionsCalculatedByExclAmt');
      const isCommissionsUsingSetChild = model.getData('isCommissionsUsingSetChild');
      const isCommissionsHasAdjustedShippingFee = model.getData('isCommissionsHasAdjustedShippingFee');

      if (RA.isNilOrEmpty(selectedCommissionBaseCostMethod)) {
        model.setData('selectedCommissionBaseCostMethod', 'normalPercent');
      }

      if (RA.isNilOrEmpty(isCommissionsUsingAverage)) {
        model.setData('isCommissionsUsingAverage', true);
      }

      if (RA.isNilOrEmpty(isCommissionsCalculatedByExclAmt)) {
        model.setData('isCommissionsCalculatedByExclAmt', true);
      }

      if (RA.isNilOrEmpty(isCommissionsUsingSetChild)) {
        model.setData('isCommissionsUsingSetChild', false);
      }

      if (RA.isNilOrEmpty(isCommissionsHasAdjustedShippingFee)) {
        model.setData('isCommissionsHasAdjustedShippingFee', false);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterLoadLists", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "afterLoadLists"), _class.prototype)), _class));
  _exports.default = SettingsSettingsEditorShopEditorComponent;
});