define("client/pods/products/customizations/model", ["exports", "client/pods/base/model", "client/mixins/products-children", "client/mixins/sortable-details", "ramda", "ramda-adjunct"], function (_exports, _model, _productsChildren, _sortableDetails, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend(_productsChildren.default, _sortableDetails.default, {
    toSortProp: '_data.options',
    indexProp: '_data.index',
    toSortArray: Ember.computed('_data.options.[]', 'synced', function () {
      return this.get('_data.options');
    }),

    populate() {
      let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (RA.isNotNilOrEmpty(data?.options || [])) {
        data.options = R.pipe(R.propOr([], 'options'), R.map(option => {
          return this.dataManager.setAsRecord({
            adapterName: 'products/customizations/options',
            data: option
          });
        }))(data);
      }

      this._super(...arguments);
    },

    defaults() {
      return {};
    }

  });

  _exports.default = _default;
});