define("client/pods/components/dispatch/import-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r1C2tMT7",
    "block": "{\"symbols\":[\"onCloseDialogAction\"],\"statements\":[[4,\"lists/list-btn\",null,[[\"label\",\"isRunning\",\"icon\",\"autoPosition\"],[\"import\",[24,[\"onSearchRecordsTask\",\"isRunning\"]],\"fas fa-file-import\",true]],{\"statements\":[[0,\"\\t\"],[7,\"div\",true],[10,\"class\",\"u-btn-menu\"],[8],[0,\"\\n\\t\\t\"],[1,[28,\"dispatch/import-btn/import-dispatched\",null,[[\"onRefresh\",\"onRefreshDispatched\",\"onCloseDialogAction\"],[[24,[\"onRefresh\"]],[24,[\"onRefreshDispatched\"]],[23,1,[]]]]],false],[0,\"\\n\\t\\t\\n\\t\\t\"],[1,[28,\"dispatch/import-btn/import-paid\",null,[[\"onRefresh\"],[[24,[\"onRefreshPaid\"]]]]],false],[0,\"\\n\"],[0,\"\\t\"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/dispatch/import-btn/template.hbs"
    }
  });

  _exports.default = _default;
});