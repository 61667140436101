define("client/pods/flows/lists/steps/presets/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model", "client/utils/nventor", "ramda"], function (_exports, _adapter, _adaptersNew, _model, _nventor, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,

    findAll() {
      let {
        data = {}
      } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const collectUserInfoSteps = [{
        isPreset: true,
        isPresetLocked: true,
        stepType: 'text',
        text: 'welcome',
        type: 'TEXT',
        // hasReply: false,
        isReplyRequired: false // hasChoices: false

      }, {
        isPreset: true,
        isPresetLocked: true,
        stepType: 'replyText',
        key: 'name',
        text: 'name',
        type: 'QUICK_REPLY',
        dataKey: 'name',
        dataType: 'text',
        // hasReply: true,
        isReplyRequired: true // hasChoices: false

      }, {
        isPreset: true,
        isPresetLocked: true,
        stepType: 'replyText',
        key: 'telephone',
        text: 'telephone',
        dataKey: 'telephone',
        dataType: 'mobile',
        type: 'QUICK_REPLY',
        // hasReply: true,
        isReplyRequired: true // hasChoices: false

      }, {
        isPreset: true,
        isPresetLocked: true,
        stepType: 'replyText',
        key: 'email',
        text: 'email',
        dataKey: 'email',
        dataType: 'email',
        type: 'QUICK_REPLY',
        // hasReply: true,
        isReplyRequired: true // hasChoices: false

      }];
      const stepsForPromo = R.clone(collectUserInfoSteps);
      stepsForPromo.pushObject({
        isPreset: true,
        isPresetLocked: true,
        text: 'generating promo code',
        type: 'ACTION',
        dataType: '',
        key: 'promo code',
        stepType: 'action',
        // hasReply: false,
        isReplyRequired: false,
        // hasChoices: false,
        autoTriggerAction: true,
        actionType: 'generatePromoCode',
        actionData: {
          // label: 'generate promo code',
          defaults: {
            dateStartZ: '',
            dateEndZ: '',
            discountAmount: '',
            status: 'active',
            type: 'rate',
            usageLimit: 1
          }
        }
      });
      const stepsForGame = R.clone(collectUserInfoSteps);
      stepsForGame.pushObject({
        isPreset: true,
        isPresetLocked: true,
        text: 'starting game',
        type: 'ACTION',
        dataType: '',
        stepType: 'action',
        // hasReply: false,
        isReplyRequired: false,
        // hasChoices: false,
        hasAction: true,
        autoTriggerAction: true,
        actionType: 'games',
        gameType: 'gameScratchCard',
        backgroundType: 'backgroundColor',
        actionData: {
          // label: 'wheel of fortune',
          numberOfPlays: 1,
          prizes: []
        }
      });
      data = [{
        isPreset: true,
        value: 'generatePromoCode',
        label: 'flow_collect user information, generate and email promo code',
        steps: stepsForPromo
      }, {
        isPreset: true,
        value: 'games',
        label: 'flow_collect user information and play a game',
        steps: stepsForGame
      }, {
        isPreset: false,
        value: 'text',
        label: 'flow_message',
        steps: [{
          isPreset: false,
          isPresetLocked: false,
          text: 'message',
          type: 'TEXT',
          stepType: 'text',
          dataType: '',
          isReplyRequired: false // hasReply: false,
          // hasChoices: false

        }]
      }, {
        isPreset: false,
        label: 'flow_reply',
        value: 'replyText',
        steps: [{
          isPreset: false,
          isPresetLocked: false,
          text: 'name',
          type: 'QUICK_REPLY',
          stepType: 'replyText',
          dataType: 'text',
          isReplyRequired: true // hasReply: true,
          // hasChoices: false

        }]
      }, {
        isPreset: false,
        isPresetLocked: false,
        value: 'replyChoices',
        label: 'flow_reply_choices',
        steps: [{
          text: 'email',
          type: 'QUICK_REPLY',
          stepType: 'replyChoices',
          isReplyRequired: true,
          dataType: 'email',
          // hasReply: true,
          // hasChoices: true,
          choices: []
        }]
      }, {
        isPreset: false,
        isPresetLocked: false,
        value: 'action',
        label: 'flow_generate promo code',
        steps: [{
          text: 'generating promo code',
          type: 'ACTION',
          stepType: 'action',
          actionType: 'generatePromoCode',
          isReplyRequired: false,
          autoTriggerAction: true,
          dataType: 'action',
          key: 'promo code',
          // hasReply: false,
          // hasChoices: false,
          choices: [],
          actionData: {}
        }]
      }, {
        isPreset: false,
        isPresetLocked: false,
        value: 'action',
        label: 'flow_wheel of fortune',
        steps: [{
          text: 'start game',
          type: 'ACTION',
          stepType: 'action',
          actionType: 'games',
          gameType: 'gameScratchCard',
          autoTriggerAction: true,
          isReplyRequired: false,
          dataType: 'action',
          // hasReply: false,
          // hasChoices: false,
          choices: [],
          actionData: {}
        }]
      }];
      return _nventor.default.resolve(data);
    }

  });

  _exports.default = _default;
});