define("client/helpers/sum", ["exports", "ramda", "client/mixins/big"], function (_exports, R, _big) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend(_big.default, {
    compute(params, hash) {
      const rounding = hash.rounding || 0;
      const values = R.values(params) || [];
      const total = R.reduce((acc, value) => {
        return acc + value;
      }, 0, values);

      if (rounding != null) {
        return Math.round(total, rounding);
      }

      return total;
    }

  });

  _exports.default = _default;
});