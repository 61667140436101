define("client/pods/components/settings/settings-editor/beta-editor/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    users: Ember.inject.service(),
    applicationService: Ember.inject.service('application'),

    init() {
      this._super(...arguments);

      if (!this.get('model._data.beta')) {
        this.set('model._data.beta', {});
      }
    },

    isAdmin: Ember.computed('users.{isAdmin}', function () {
      return this.users.getProp('isAdmin');
    })
  });

  _exports.default = _default;
});